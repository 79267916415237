/** UI Components to make your own calendar. */

import { useLocale } from "../i18n/context";
import { createRef } from "../../utils/reactRefs";
import { createSignal, For, JSX, mergeProps, ParentProps, Show } from "solid-js";
import _ from "lodash";
import { useClock } from "../../utils/clock";
import { Button } from "./components";
import { useOnClickOutside } from "./Dropdown";

/** Wraps an element so when it's clicked, it shows a date picker. */
function DatePicker(
    props: ParentProps<{
        value?: Temporal.PlainDate;
        onChange?: (value: Temporal.PlainDate) => void;
    }>,
) {
    const p = mergeProps(
        {
            value: Temporal.Now.plainDateISO(),
            onChange: () => {},
        },
        props,
    );

    // Native <input type="date" /> already implements a date picker for us
    const dateInputRef = createRef<HTMLInputElement>();

    // Hack to make Safari close the date picker
    const [dateInputKey, setDateInputKey] = createSignal(1);
    const closeDatePicker = () => setDateInputKey(s => s + 1);
    useOnClickOutside(dateInputRef, closeDatePicker);

    return (
        <button class="relative">
            <div onClick={() => dateInputRef.current!.showPicker()}>{p.children}</div>
            <Show keyed when={dateInputKey()}>
                <input
                    ref={dateInputRef}
                    type="date"
                    value={p.value.toString()}
                    onInput={e => {
                        try {
                            p.onChange(Temporal.PlainDate.from(e.currentTarget.value));
                        } catch (error) {
                            if (error instanceof RangeError) {
                                // This may happen if the user clicks the "clear" button
                                p.onChange(Temporal.Now.plainDateISO());
                            } else throw error;
                        } finally {
                            closeDatePicker();
                        }
                    }}
                    class="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                    required
                />
            </Show>
        </button>
    );
}

/** The missing Temporal.Week object, based on the [ISO week](https://en.wikipedia.org/wiki/ISO_week_date).
 * https://github.com/js-temporal/proposal-temporal-v2/issues/11
 */
export class Week {
    /**
     * @param yearOfWeek - https://tc39.es/proposal-temporal/docs/plaindate.html#yearOfWeek
     * @param weekOfYear - https://tc39.es/proposal-temporal/docs/plaindate.html#weekOfYear
     */
    constructor(
        readonly yearOfWeek: number,
        readonly weekOfYear: number,
    ) {}

    static now(): Week {
        const today = Temporal.Now.plainDateISO();
        return new Week(today.yearOfWeek!, today.weekOfYear!);
    }

    static fromPlainDate(date: Temporal.PlainDate): Week {
        return new Week(date.yearOfWeek!, date.weekOfYear!);
    }

    toPlainDate(day: { dayOfWeek: number }): Temporal.PlainDate {
        /* Thank you, ChatGPT! */

        // Determine the day of the week of January 4th (which is always in week 1 of the ISO year)
        const jan4 = new Temporal.PlainDate(this.yearOfWeek, 1, 4);
        const jan4DayOfWeek = jan4.dayOfWeek;

        // Calculate the start of the ISO week 1
        const startOfWeek1 = jan4.subtract({ days: jan4DayOfWeek - 1 });

        // Calculate the start of the target week
        const startOfTargetWeek = startOfWeek1.add({ days: (this.weekOfYear - 1) * 7 });

        // Calculate the target date by adding the day offset within the week
        const targetDate = startOfTargetWeek.add({ days: day.dayOfWeek - 1 });

        return targetDate;
    }

    add(durationLike: { weeks: number }): Week {
        const date = this.toPlainDate({ dayOfWeek: 1 }).add(durationLike);
        return Week.fromPlainDate(date);
    }

    subtract(durationLike: { weeks: number }): Week {
        const date = this.toPlainDate({ dayOfWeek: 1 }).subtract(durationLike);
        return Week.fromPlainDate(date);
    }
}

/** Renders a column for each day of the week. */
export function WeekColumns(props: {
    /** The week to show. */
    week: Week;
    /** Render function for the contents of the column corresponding to a particular day. */
    children?: (date: Temporal.PlainDate) => JSX.Element;
    /** 5 if the week is Monday to Friday. 6 if includes Saturday. 7 if also includes Sunday.
     * @default 7
     */
    numWorkDays?: number;
}) {
    const p = mergeProps({ numWorkDays: 7 }, props);
    const { today } = useClock();
    const [locale] = useLocale();

    return (
        <div
            class="mt-6 grid auto-cols-fr grid-flow-col gap-x-px bg-light-gray-300"
            style={{
                //"grid-template-columns": `repeat(${p.numWorkDays}, 1fr)`,
                "grid-template-rows": "auto 1fr",
            }}
        >
            <For
                each={_.range(1, (p.numWorkDays ?? 7) + 1).map(dayOfWeek =>
                    p.week.toPlainDate({ dayOfWeek }),
                )}
            >
                {date => (
                    <>
                        <div
                            class={`sticky top-0 ${
                                date.equals(today()) ? "bg-primary-25" : "bg-white"
                            }`}
                        >
                            <div class="flex flex-col items-center justify-center pt-1">
                                <div class="uppercase">
                                    {Intl.DateTimeFormat(locale().codeWithCountry, {
                                        weekday: "short",
                                    }).format(date)}
                                </div>
                                <div
                                    class="h-8 w-8 max-w-full rounded-full text-center text-xl leading-8"
                                    classList={{
                                        "bg-primary-500 text-white": date.equals(today()),
                                    }}
                                >
                                    {date.day}
                                </div>
                            </div>
                        </div>
                        <div class={date.equals(today()) ? "bg-primary-25" : "bg-white"}>
                            {p.children?.(date)}
                        </div>
                    </>
                )}
            </For>
        </div>
    );
}

export type CalendarControlsProps = {
    /** Left button was pressed. */
    onPrev?: () => void;
    /** Right button was pressed. */
    onNext?: () => void;
    /** Currently selected date by the date picker. If none yet, a good default is today. */
    date?: Temporal.PlainDate;
    /** User selected a date using the date picker. */
    onChangeDate?: (date: Temporal.PlainDate) => void;
    /** Today button was pressed. Set `date` to today. */
    onToday?: () => void;
};

/** These buttons are generic enough to be used to select a day, week, or month. */
export function CalendarControls(props: CalendarControlsProps) {
    return (
        <div class="flex items-center gap-x-4">
            <div class={"buttonGroup flex"}>
                <Button
                    bgStyle={"outline"}
                    class={"!rounded-r-0 !border-light-gray-300 !text-light-gray-700"}
                    onClick={props.onPrev}
                >
                    <i class="fas fa-chevron-left" />
                </Button>
                <Button
                    bgStyle={"outline"}
                    class={"-ml-[1px] !rounded-l-0 !border-light-gray-300 !text-light-gray-700"}
                    onClick={props.onNext}
                >
                    <i class="fas fa-chevron-right" />
                </Button>
            </div>
            <DatePicker value={props.date} onChange={props.onChangeDate}>
                <Button bgStyle={"outline"} class={"!border-light-gray-300 !text-light-gray-700"}>
                    <i class="fas fa-calendar-alt" />
                </Button>
            </DatePicker>
            <Button
                bgStyle={"outline"}
                class={"!border-light-gray-300 !text-sm !font-semibold !text-light-gray-700"}
                onClick={props.onToday}
            >
                Hoy
            </Button>
        </div>
    );
}
