import { Capacitor } from "@capacitor/core";
import {
    describeSignInMethod,
    FederatedSignInMethod,
} from "../../api/services/organization/signInMethods";
import { showErrorToast } from "../../utils/errorHandling";
import { PopupBlockedError, SignInCanceledError } from "../../api/services/auth/interface";

export default function usePopupBlockedWorkaround() {
    return {
        async beforeSignIn(_method: FederatedSignInMethod) {
            if (isMobileBrowser()) {
                showErrorToast("Solo soportado desde la app o computador");
                throw new SignInCanceledError(false);
            }
        },

        async onPopupBlockedError(_error: PopupBlockedError, method: FederatedSignInMethod) {
            // This ugly workaround is the only thing that works in practice
            alert(
                `Tu navegador ha bloqueado la ventana emergente del inicio de sesión con ` +
                    `${describeSignInMethod(method)}.\n\n` +
                    `Por favor, acepta las ventanas emergentes de ${describeSignInMethod(
                        method,
                    )} antes ` +
                    `de continuar (debe haberte aparecido un mensaje en la barra de arriba para ello).\n\n` +
                    `Una vez hecho eso, cierra este mensaje para recargar la página e intenta nuevamente ` +
                    `(la ventana emergente anterior ya no funcionará).`,
            );
            location.reload();
        },
    };
}

function isMobileBrowser() {
    return (
        Capacitor.getPlatform() === "web" &&
        // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
}
