import { createApiQuery, Json, queryClient } from "../../utils";
import { Accessor } from "solid-js";

export function createUserActivitiesSliceQuery(
    params: Accessor<{ startDate: Temporal.PlainDate; endDate: Temporal.PlainDate }>,
) {
    return createApiQuery(api => ({
        queryKey: ["userActivitiesSlice", params()],
        queryFn: () =>
            queryClient.fetchQuery({
                queryKey: ["myCalendar"],
                queryFn: api.taskManager.retrieveMyCalendar.fetchJson,
            }),
        select: (json: Json) => {
            const calendar = api.taskManager.retrieveMyCalendar.select(json);
            return calendar.getActivities(params().startDate, params().endDate);
        },
    }));
}

export function createMyCalendarQuery() {
    return createApiQuery(api => ({
        queryKey: ["myCalendar"],
        queryFn: api.taskManager.retrieveMyCalendar.fetchJson,
        select: api.taskManager.retrieveMyCalendar.select,
    }));
}

export function createRawActivitiesQuery() {
    return createApiQuery(api => ({
        queryKey: ["rawActivities"],
        queryFn: () => api.taskManager.retrieveActivities.fetchJson(),
        select: api.taskManager.retrieveActivities.select,
    }));
}

export function createActivityTasksQuery(activityId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["activityTasks"],
        queryFn: () => api.taskManager.getActivityTasks.fetchJson(activityId()!),
        select: api.taskManager.getActivityTasks.select,
    }));
}

export function createActivityDetailQuery(
    activityId: Accessor<string>,
    recurrenceId: Accessor<string>,
) {
    return createApiQuery(api => ({
        queryKey: ["activityDetail", activityId(), recurrenceId()],
        queryFn: () =>
            api.taskManager.retrieveActivityDetail.fetchJson(activityId(), recurrenceId()),
        select: api.taskManager.retrieveActivityDetail.select,
    }));
}

export function createGetOrCreateRecurrenceActivityQuery(
    activityId: Accessor<string>,
    recurrenceId: Accessor<string>,
) {
    return createApiQuery(api => ({
        queryKey: ["recurrenceActivity", activityId(), recurrenceId()],
        queryFn: () =>
            api.taskManager.getOrCreateRecurrenceActivity.fetchJson(activityId(), recurrenceId()),
        select: api.taskManager.getOrCreateRecurrenceActivity.select,
    }));
}

export function createActivityFormQuery(formId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["activityForm", formId()],
        queryFn: () => api.taskManager.retrieveForm.fetchJson(formId()),
        select: api.taskManager.retrieveForm.select,
    }));
}

export function createRecurrenceTasksQuery(recurrenceActivityId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["recurrenceTasks", recurrenceActivityId()],
        queryFn: () => api.taskManager.getRecurrenceTasks.fetchJson(recurrenceActivityId()!),
        select: api.taskManager.getRecurrenceTasks.select,
        enabled: !!recurrenceActivityId(),
    }));
}

export function createActivityFormResponseQuery(formResponseId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["activityFormResponse", formResponseId()],
        queryFn: () => api.taskManager.retrieveFormResponse.fetchJson(formResponseId()),
        select: api.taskManager.retrieveFormResponse.select,
    }));
}
