import { PageWrapper } from "../ui/pageWrappers";
import ChecklistExecutionsTable from "./checklistExecutions/ChecklistExecutionsTable";
import { createChecklistExecutionsListQuery } from "../../api/services/checklist/queries";
import { Show, Suspense } from "solid-js";
import { useLocale } from "../i18n/context";
import { P } from "../../utils/typography";

export default function ChecklistExecutionsListPage() {
    const listExecutions = createChecklistExecutionsListQuery();
    const [locale] = useLocale();
    const t = locale().checklists;

    return (
        <PageWrapper>
            <h1 class={"mb-10 text-display-xs font-normal"}>{t.checklistExecutions}</h1>

            <Suspense fallback={<ChecklistExecutionsTable data={[]} />}>
                <Show when={listExecutions.data}>
                    {data => {
                        return (
                            <>
                                <Show when={data().length === 0 && !listExecutions.isLoading}>
                                    <P class={"text-center"}>{t.noChecklistExecutions}</P>
                                </Show>
                                <Show when={data().length > 0}>
                                    <ChecklistExecutionsTable data={data()} />
                                </Show>
                            </>
                        );
                    }}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}
