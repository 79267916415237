import { FirebaseAuthentication, SignInCustomParameter } from "@capacitor-firebase/authentication";
import {
    AlreadyInProgressError,
    NeedConfirmError,
    OutsideOrganizationError,
    PopupBlockedError,
    SignInCanceledError,
    WrongPasswordError,
} from "../interface";
import { sleep } from "../../../../utils/mocks";
import { adaptUser, dispatchSignInEvent, getUserOr401 } from "../../../../modules/auth/authContext";
import { enums, is, literal, nullable, optional, pattern, string, type, union } from "superstruct";
import { Capacitor } from "@capacitor/core";
import { useLocale } from "../../../../modules/i18n/context";
import { onMount } from "solid-js";
import { useThrowToErrorBoundary } from "../../../../utils/solidjs";
import { FrontOrganization, parseFirebaseSignInMethod } from "../../organization/interface";
import { parsedEnv } from "../../../../utils/parsedEnv";
import { getAuth, SAMLAuthProvider, signInWithPopup } from "@firebase/auth";
import _ from "lodash";
import {
    FederatedSignInMethod,
    FrontSignInMethod,
    SignInMethodType,
} from "../../organization/signInMethods";

class FirebaseAuthService {
    signInWithEmailAndPassword = async (
        organization: FrontOrganization,
        email: string,
        password: string,
    ): Promise<void> => {
        await this.setTenantId(organization.firebaseTenantId);

        try {
            await FirebaseAuthentication.signInWithEmailAndPassword({ email, password });
        } catch (error) {
            if (isFirebaseWrongCredentialsError(error)) throw new WrongPasswordError();
            else throw error;
        }
    };

    signInWithGoogle = async (
        organization: FrontOrganization,
        email: string | undefined,
    ): Promise<void> => {
        try {
            await this.setTenantId(organization.firebaseTenantId);
            await FirebaseAuthentication.signInWithGoogle({
                customParameters: await this.selectAccount(email),
            });
        } catch (error) {
            await this.handleFederatedAuthError(error, { type: SignInMethodType.GOOGLE });
        }
    };

    signInWithSaml = async (
        organization: FrontOrganization,
        _email: string | undefined,
        method: FrontSignInMethod & { type: SignInMethodType.SAML },
    ) => {
        const auth = getAuth();
        const provider = new SAMLAuthProvider(method.providerId);

        try {
            await this.setTenantId(organization.firebaseTenantId);
            await signInWithPopup(auth, provider);
        } catch (error) {
            await this.handleFederatedAuthError(error, method);
        }
    };

    signInWithOidc = async (
        organization: FrontOrganization,
        email: string | undefined,
        method: FrontSignInMethod & { type: SignInMethodType.OIDC },
    ) => {
        try {
            await this.setTenantId(organization.firebaseTenantId);
            await FirebaseAuthentication.signInWithOpenIdConnect({
                providerId: method.providerId,
                customParameters: await this.selectAccount(email),
            });
        } catch (error) {
            await this.handleFederatedAuthError(error, method);
        }
    };

    private async selectAccount(email: string | undefined): Promise<SignInCustomParameter[]> {
        const customParameters: SignInCustomParameter[] = [];
        if (email) customParameters.push({ key: "login_hint", value: email });

        /* If the user chose the wrong account, let them choose again. */
        // - Desktop: https://stackoverflow.com/questions/62043170/firebase-google-auth-wont-let-me-choose-the-account-to-connect-with
        customParameters.push({ key: "prompt", value: "select_account" });
        // - Android: https://stackoverflow.com/questions/72058545/firebase-google-auth-automatically-selecting-user-how-to-force-it-to-pop-up-gma
        await FirebaseAuthentication.signOut();
        // This combination also works on iOS.

        return customParameters;
    }

    // separate method to avoid code duplication
    private async handleFederatedAuthError(
        error: unknown,
        currentMethod: FederatedSignInMethod,
    ): Promise<never> {
        if (isPopupBlockedError(error)) throw new PopupBlockedError();
        if (isPopupClosedByUserError(error)) throw new SignInCanceledError(true);
        if (isAdminRestrictedError(error)) throw new OutsideOrganizationError();
        if (isHeadfulOperationInProgressError(error)) throw new AlreadyInProgressError();
        if (isNeedConfirmError(error)) {
            const email = error.customData?.email;
            /* Attempt to use deprecated method because it's the only way to get the sign-in method
             * that was used for the other account, so we can craft a better message to the user. */
            let prevMethods: FrontSignInMethod[] | undefined;
            try {
                if (email) {
                    const result = await FirebaseAuthentication.fetchSignInMethodsForEmail({
                        email,
                    });
                    prevMethods = result.signInMethods
                        .map(parseFirebaseSignInMethod)
                        .filter(method => method.type !== currentMethod.type);
                }
                if (_.isEmpty(prevMethods)) prevMethods = undefined;
            } catch (error) {
                prevMethods = undefined;
            }
            console.debug({ error });
            throw new NeedConfirmError(email, currentMethod, prevMethods);
        }
        throw error;
    }

    sendPasswordResetEmail = async (
        organization: FrontOrganization,
        email: string,
    ): Promise<void> => {
        await this.setTenantId(organization.firebaseTenantId);
        await FirebaseAuthentication.sendPasswordResetEmail({ email });
    };

    getIdToken = async (maxRetries = 10): Promise<string> => {
        try {
            const { token } = await FirebaseAuthentication.getIdToken();
            return token;
        } catch (error) {
            if (maxRetries === 0) throw error;
            await sleep(200);
            return await this.getIdToken(maxRetries - 1);
        }
    };

    signOut = async (): Promise<void> => {
        await FirebaseAuthentication.signOut();
    };

    sendSigninLinkToEmail = async (
        organization: FrontOrganization,
        email: string,
    ): Promise<void> => {
        const host = window.location.host;
        const protocol = window.location.protocol;
        const url = Capacitor.isNativePlatform()
            ? `${parsedEnv.VITE_WEB_URL}/from-magic-link`
            : `${protocol}//${host}/from-magic-link`;
        await this.setTenantId(organization.firebaseTenantId);
        return await FirebaseAuthentication.sendSignInLinkToEmail({
            email,
            actionCodeSettings: {
                url: url,
                handleCodeInApp: true,
            },
        });
    };

    sendInvite = async (email: string): Promise<void> => {
        const user = await getUserOr401();
        const host = window.location.host;
        const protocol = window.location.protocol;
        const url = Capacitor.isNativePlatform()
            ? `${parsedEnv.VITE_WEB_URL}/from-invite`
            : `${protocol}//${host}/from-invite`;
        await this.setTenantId(user.firebaseTenantId);
        return await FirebaseAuthentication.sendSignInLinkToEmail({
            email,
            actionCodeSettings: {
                url: url,
                handleCodeInApp: true,
            },
        });
    };

    isSignInWithEmailLink = async (link: string) => {
        const res = await FirebaseAuthentication.isSignInWithEmailLink({ emailLink: link });
        return res.isSignInWithEmailLink;
    };

    signInWithEmailLink = async ({ email, link }: { email: string; link: string }) => {
        // We don't need to `setTenantId` as the `link` includes the `tenantId`.
        const res = await FirebaseAuthentication.signInWithEmailLink({ email, emailLink: link });
        if (res.user) {
            dispatchSignInEvent(adaptUser(res.user));
        } else {
            throw new Error("Sign in with email link failed");
        }
    };

    private async setTenantId(tenantId: string | null): Promise<void> {
        /* Caveat: FirebaseAuthentication doesn't allow setting the tenantId
         * back to null because the Firebase SDK for Android doesn't allow it.
         * For context, see https://github.com/firebase/firebase-android-sdk/issues/3398.
         * `FirebaseAuthentication.setTenantId({ tenantId: null })` works on web,
         * but not on iOS even if the Firebase SDK for iOS allows resetting it to null.
         *
         * As a workaround, if the user is on mobile and their organization
         * would make us reset the tenantId to null, we ask the user to restart
         * the app, as null is the default tenantId.
         * See `FirebaseAuthService.useRestartCheck`. */
        if (!(Capacitor.isNativePlatform() && tenantId === null))
            await FirebaseAuthentication.setTenantId({ tenantId: tenantId! });
    }

    /** Workaround for issue with FirebaseAuthService.setTenantId. */
    useRestartCheck(organization: FrontOrganization): void {
        const [locale] = useLocale();
        const throwToErrorBoundary = useThrowToErrorBoundary();

        onMount(async () => {
            if (Capacitor.isNativePlatform() && organization.firebaseTenantId === null) {
                const prev = await FirebaseAuthentication.getTenantId();
                if (prev.tenantId !== null) {
                    const message = locale().auth.restartNeeded(organization.name);
                    alert(message);
                    /* Crash the app so the user is forced to manually close it.
                     * Note that `App.exitApp()` and `window.location.reload()`
                     * won't close the app. */
                    throwToErrorBoundary(new Error(message));
                }
            }
        });
    }
}

export const firebaseAuthService = new FirebaseAuthService();

function isFirebaseWrongCredentialsError(error: unknown): boolean {
    return is(
        error,
        type({
            code: enums([
                "auth/wrong-password",
                "wrong-password",
                "auth/user-not-found",
                "user-not-found",
                "auth/invalid-email",
                "invalid-email",
                "auth/invalid-credential",
                "invalid-credential",
            ]),
        }),
    );
}

function isPopupBlockedError(error: unknown): boolean {
    return is(
        error,
        type({
            code: enums(["auth/popup-blocked", "popup-blocked"]),
        }),
    );
}

function isPopupClosedByUserError(error: unknown): boolean {
    // Use regex for cases where the message string is undocumented and redaction may change
    return (
        // Web (Sign in with Google)
        is(error, type({ code: literal("auth/popup-closed-by-user") })) ||
        // Web (Sign in with OIDC)
        is(error, type({ code: literal("auth/cancelled-popup-request") })) ||
        // Android (Sign in with Google)
        is(error, type({ message: pattern(string(), /12501/i) })) ||
        // Android (Sign in with OIDC) "The web operation was canceled by the user" (canceled with 1 L)
        is(error, type({ message: pattern(string(), /cancel+ed.*user/i) })) ||
        // iOS (Sign in with Google)
        is(error, type({ message: pattern(string(), /user cancel+ed/i) })) ||
        // iOS (Sign in with OIDC) "The interaction was cancelled by the user." (cancelled with 2 L)
        is(error, type({ message: pattern(string(), /interaction cancel+ed/i) }))
    );
}

function isAdminRestrictedError(error: unknown): boolean {
    return is(
        error,
        union([
            type({
                code: enums(["auth/admin-restricted-operation", "admin-restricted-operation"]),
            }),
            type({
                message: literal("ADMIN_ONLY_OPERATION"),
            }),
        ]),
    );
}

function isHeadfulOperationInProgressError(error: unknown): boolean {
    // "A headful operation is already in progress. Please wait for that to finish."
    return is(error, type({ message: pattern(string(), /progress/i) }));
}

// https://stackoverflow.com/a/75608487
function isNeedConfirmError(error: unknown): error is { customData?: { email?: string } } {
    return is(
        error,
        type({
            code: pattern(string(), /account-exists-with-different-credential/),
            customData: optional(
                type({
                    email: optional(nullable(string())),
                }),
            ),
        }),
    );
}
