import { useLocale } from "../i18n/context";
import { useNavigate } from "@solidjs/router";
import { Button } from "../ui/components";
import { Show, createEffect, createSignal } from "solid-js";
import { H1 } from "../../utils/typography";
import LanguageSelector from "../i18n/LanguageSelector";
import VersionNumber from "../../utils/VersionNumber";
import { PageWrapper } from "../ui/pageWrappers";
import { parsedEnv } from "../../utils/parsedEnv";

export default function SettingsPage() {
    const [locale] = useLocale();

    return (
        <div class="pb-12">
            <PageWrapper>
                <H1>{locale().settings.title}</H1>
                <SettingsBody />
            </PageWrapper>
            <div class="absolute bottom-1 right-3">
                <VersionNumber />
            </div>
        </div>
    );
}

function SettingsBody() {
    const [locale] = useLocale();
    const navigate = useNavigate();

    return (
        <div class="flex max-w-80 flex-col gap-3">
            <Show when={parsedEnv.VITE_FEATURE_ONBOARDING_PERSONAL_DATA_FORM}>
                <Button onClick={() => navigate("/onboarding")} bgStyle="outline">
                    {locale().settings.simulateOnboarding}
                </Button>
            </Show>
            <Show when={parsedEnv.VITE_FEATURE_FIREBASE_INVITE_FROM_FRONTEND}>
                <Button onClick={() => navigate("/invite")} bgStyle="outline">
                    {locale().settings.createInvite}
                </Button>
            </Show>
            <Show when={parsedEnv.VITE_FEATURE_SIMULATE_ERROR_BUTTON}>
                <SimulateError />
            </Show>
            <LanguageSelector />
        </div>
    );
}

function SimulateError() {
    const [locale] = useLocale();
    const t = () => locale().settings;

    const [error, setError] = createSignal(false);
    createEffect(() => {
        if (error()) {
            setError(false);
            throw new Error(t().simulatedError);
        }
    });

    return (
        <Button
            variant="danger"
            icon="fas fa-exclamation-circle"
            bgStyle="outline"
            onClick={() => setError(true)}
        >
            {t().simulateError}
        </Button>
    );
}
