import { CalendarControls, Week, WeekColumns } from "../ui/calendar";
import { createUserActivitiesSliceQuery } from "../../api/services/task-manager/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { createMemo, createSignal, Match, Show, Suspense, Switch } from "solid-js";
import { formatDateAndMonth, formattedDate, getHmsDurationBetween } from "../../utils/formatDate";
import { P } from "../../utils/typography";
import { ActivityIcon, Countdown } from "./ActivityItemDesktop";
import { ActivityItem } from "../../api/services/task-manager/interface";
import { useClock } from "../../utils/clock";

export default function WeeklyActivities() {
    const [week, setWeek] = createSignal(Week.now());
    const userActivitiesSliceQuery = createUserActivitiesSliceQuery(() => ({
        startDate: week().toPlainDate({ dayOfWeek: 1 }),
        endDate: week().toPlainDate({ dayOfWeek: 7 }),
    }));

    return (
        <div>
            <div class="flex items-baseline gap-4 pt-4">
                <CalendarControls
                    onPrev={() => setWeek(w => w.subtract({ weeks: 1 }))}
                    onNext={() => setWeek(w => w.add({ weeks: 1 }))}
                    date={week().toPlainDate({ dayOfWeek: 1 })}
                    onChangeDate={d => setWeek(Week.fromPlainDate(d))}
                    onToday={() => setWeek(Week.now())}
                />
                <div>
                    {formattedDate(week().toPlainDate({ dayOfWeek: 1 }))} &ndash;{" "}
                    {formattedDate(week().toPlainDate({ dayOfWeek: 7 }))}
                </div>
            </div>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={userActivitiesSliceQuery.data}>
                    {activities => (
                        <WeekColumns week={week()}>
                            {date => {
                                const filteredActivities = activities()?.filter(act =>
                                    act.dueDate.toPlainDate().equals(date),
                                );
                                return filteredActivities.map(activity => (
                                    <WeeklyActivityItem activity={activity} />
                                ));
                            }}
                        </WeekColumns>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

function WeeklyActivityItem(props: { activity: ActivityItem }) {
    const { nowZdt } = useClock();
    const remainingTime = createMemo(() => getHmsDurationBetween(nowZdt(), props.activity.dueDate));

    return (
        <div class={"px-3 py-2"}>
            <P class={"text-sm font-medium"}>
                <ActivityIcon priority={props.activity.priority} />
                {props.activity.title}
                <span class={"flex pt-1"}>
                    <Switch
                        fallback={
                            <Countdown
                                remainingTime={remainingTime()}
                                priority={props.activity.priority}
                            />
                        }
                    >
                        <Match when={remainingTime().hours >= 24}>
                            <P class={"!mb-0 text-sm"}>
                                <i class="far fa-calendar text-sm" />{" "}
                                {formatDateAndMonth(props.activity.dueDate.toPlainDate())}
                            </P>
                        </Match>
                    </Switch>
                </span>
            </P>
        </div>
    );
}
