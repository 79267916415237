import { createResource, Show } from "solid-js";
import DynamicPage from "./DynamicPage";
import { PageWrapper } from "../ui/pageWrappers";
import { AtlasWidgetType } from "../../api/services/home/implementations/atlas";
import { getApiInstance } from "../../api";
import { Page } from "../../api/services/home/interface";
import Warning from "../ui/Warning";

export default function PostsPage() {
    const [page] = createResource(retrievePostsPage);

    return (
        <PageWrapper>
            <div class="mb-3">
                <Warning>
                    Esto es una maqueta, este módulo aún no se ha comenzado a desarrollar.
                </Warning>
            </div>
            <Show when={page()}>{page => <DynamicPage page={page()} />}</Show>
        </PageWrapper>
    );
}

/** Virtual endpoint with just the posts of the homepage */
export async function retrievePostsPage(): Promise<Page> {
    const api = getApiInstance();
    const page = await api.home.retrieveHomePage();
    return {
        ...page,
        widgets: page.widgets.filter(widget => widget.type === AtlasWidgetType.POSTS),
    };
}
