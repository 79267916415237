import { LogicParserOptions } from "../../../../api/services/logic-parser/interface";
import RadioField from "../../../forms/fields/RadioField";

export function OperatorSection(props: {
    index: number;
    parserOptions: LogicParserOptions;
    defaultValue?: string;
}) {
    return (
        <div>
            <RadioField
                name={`entities[${props.index}].operator`}
                label={"Operador"}
                parentCheckboxClass="flex gap-x-3"
                parentClass="flex gap-x-3"
                defaultValue={props.defaultValue}
                options={props.parserOptions.operators.map(op => [op, op])}
            />
        </div>
    );
}
