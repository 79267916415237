import { useLocale } from "../i18n/context";
import { useNavigate } from "@solidjs/router";
import { Button, Icon } from "./components";
import { OnClickButton } from "../../utils/solidjs";

export default function BackButton(props: {
    onClick?: OnClickButton;
    iconClass?: string;
    class?: string;
}) {
    const [locale] = useLocale();

    return (
        <Button onClick={props.onClick} bgStyle="text-only" size="sm" class={props.class}>
            <Icon name={`fas fa-angle-left ${props.iconClass ?? ""}`} />
            {locale().posts.goBack}
        </Button>
    );
}

/** Returns a callback that goes back in history if possible, or navigates to
 * the fallback href. */
export function useGoBack(fallbackHref: string): () => void {
    const navigate = useNavigate();

    return () => {
        if (history.length > 1) {
            navigate(-1);
        } else {
            navigate(fallbackHref);
        }
    };
}
