import { FieldController } from "../../state";
import { JSX } from "solid-js";
import { FieldEvent, FieldProps } from "./types";
import { parsedEnv } from "../../../../utils/parsedEnv";

/** This function must be called inside JSX.
 *
 * @param inputId - See `LabelLayoutProps`.
 * @param props - The props passed to the Field component.
 */
export function getCommonInputProps<TElement>(
    inputId: string,
    props: Omit<FieldProps<unknown, TElement>, "label">,
) {
    // Disable linter as this function is always called inside JSX
    /* eslint-disable solid/reactivity */
    return {
        id: inputId,
        ref: props.staticRef,
        name: props.name,
        placeholder: props.placeholder,
        disabled: props.disabled,
        onChange: props.onChange,
        readOnly: props.readOnly,
    };
    /* eslint-enable solid/reactivity */
}

/** This function must be called inside JSX.
 *
 * @param field - The object returned by {@see createField}.
 * @param props - The props passed to the Field component.
 */
export function getInputValidationProps(
    field: FieldController<string>,
    props: {
        name: string;
        optional?: boolean;
        format?: (value: string) => string;
        validate?: (value: string) => true | string;
        serialize?: (value: string) => string;
    },
): JSX.IntrinsicElements["input"] & JSX.IntrinsicElements["textarea"] {
    // Disable linter as this function is always called inside JSX
    /* eslint-disable solid/reactivity */

    const serialize = props.serialize ?? ((value: string) => value);

    return {
        required: !props.optional,
        onInput: (event: FieldEvent) => {
            field.setValue(serialize(event.currentTarget.value), { deferValidation: true });
            if (parsedEnv.VITE_EASTER_EGG_TUPNI_ESREVER) {
                event.currentTarget.selectionStart = 0;
                event.currentTarget.selectionEnd = 0;
            }
        },
        onBlur: (event: FieldEvent) => {
            if (props.format) {
                event.currentTarget.value = props.format(event.currentTarget.value);
                field.setValue(serialize(event.currentTarget.value));
            }
            field.updateValidation();
        },
    };
    /* eslint-enable solid/reactivity */
}
