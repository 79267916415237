import { PageWrapper } from "../ui/pageWrappers";
import { useNavigate, useParams } from "@solidjs/router";
import { P } from "../../utils/typography";
import { BorderedCard } from "../ui/cards";
import { Button } from "../ui/components";
import { createModalController, Modal, ModalController } from "../ui/Modal";
import { createChecklistExecutionsCreateMutation } from "../../api/services/checklist/mutations";
import {
    createChecklistEntitiesListQuery,
    createChecklistRetrieveQuery,
    createGetChecklistEntityTargetType,
} from "../../api/services/checklist/queries";
import { For, Show } from "solid-js";
import { Asset, Checklist, EntityTarget, Venue } from "../../api/services/checklist/interface";

export default function ChecklistsExecutePage() {
    const params = useParams();
    const checkListID = () => params.id;
    const confirmExecutionModal = createModalController<EntityTarget, boolean>();
    const checklistRetrieve = createChecklistRetrieveQuery(checkListID);

    return (
        <PageWrapper>
            <Show when={checklistRetrieve.data}>
                {checklist => (
                    <ChecklistExecuteContent
                        checklist={checklist()}
                        modal={confirmExecutionModal}
                    />
                )}
            </Show>

            <Modal controller={confirmExecutionModal}>
                {data => (
                    <>
                        <Show when={checklistRetrieve.data}>
                            {checklist => {
                                return (
                                    <P class={"text-center text-xl font-medium"}>
                                        ¿Está seguro que desea ejecutar el checklist{" "}
                                        {checklist().name}?
                                    </P>
                                );
                            }}
                        </Show>
                        <ModalHeaderMessage data={data} />
                        <div class={"flex justify-between"}>
                            <Button
                                bgStyle={"outline"}
                                onClick={() => confirmExecutionModal.closeWithAnswer(false)}
                            >
                                Cancelar
                            </Button>
                            <Button onClick={() => confirmExecutionModal.closeWithAnswer(true)}>
                                Si, ejecutar
                            </Button>
                        </div>
                    </>
                )}
            </Modal>
        </PageWrapper>
    );
}

function ChecklistExecuteContent(props: {
    checklist: Checklist;
    modal: ModalController<EntityTarget, boolean>;
}) {
    const targetEntityQuery = () => props.checklist.target_entity_query;
    const entitiesListQuery = createChecklistEntitiesListQuery(targetEntityQuery);
    const getEntityType = createGetChecklistEntityTargetType(targetEntityQuery);
    return (
        <>
            <Show when={getEntityType.data}>
                {data => (
                    <h1 class={"text-display-sm font-medium"}>
                        {data().model === "venue" ? "Recintos" : "Activos"}
                    </h1>
                )}
            </Show>

            <P class={"text-lg"}>
                Ejecución del checklist <span class={"font-medium"}>{props.checklist.name}</span>
            </P>

            <div class={"grid grid-cols-1 gap-4 md:grid-cols-3"}>
                <Show when={entitiesListQuery.data}>
                    {data => (
                        <For each={data()}>
                            {entity => (
                                <ExecutionCard
                                    modal={props.modal}
                                    checklistID={props.checklist.id}
                                    targetEntity={entity}
                                />
                            )}
                        </For>
                    )}
                </Show>
            </div>
        </>
    );
}

type ExecutionCardProps = {
    modal: ModalController<EntityTarget, boolean>;
    checklistID: string;
    targetEntity: EntityTarget;
};
function ExecutionCard(props: ExecutionCardProps) {
    return (
        <>
            <Show when={"address" in props.targetEntity}>
                <VenueCard {...props} />
            </Show>
            <Show when={"venue" in props.targetEntity}>
                <AssetCard {...props} />
            </Show>
        </>
    );
}

function VenueCard(props: ExecutionCardProps) {
    const targetEntity = () => props.targetEntity as Venue;

    return (
        <BorderedCard>
            <div class={"mb-4 flex items-center justify-between"}>
                <P class={"!mb-0 text-md font-medium"}>{targetEntity().name}</P>
                <div>
                    <ExecutionButton {...props} />
                </div>
            </div>
            <P>{targetEntity().address}</P>
        </BorderedCard>
    );
}

function AssetCard(props: ExecutionCardProps) {
    const targetEntity = () => props.targetEntity as Asset;

    return (
        <BorderedCard>
            <div class={"mb-4 flex items-center justify-between"}>
                <P class={"!mb-0 text-md font-medium"}>{targetEntity().name}</P>
                <div>
                    <ExecutionButton {...props} />
                </div>
            </div>
            <P>{targetEntity().venue_name}</P>
        </BorderedCard>
    );
}

function ExecutionButton(props: ExecutionCardProps) {
    const executeChecklistMutation = createChecklistExecutionsCreateMutation();
    const navigate = useNavigate();
    return (
        <Button
            bgStyle={"outline"}
            size={"sm"}
            onClick={async () => {
                const prompt = await props.modal.prompt(props.targetEntity);
                if (prompt) {
                    await executeChecklistMutation.mutateAsync(
                        { checklist: props.checklistID, target_entity: props.targetEntity.id },
                        {
                            onSuccess: data => {
                                //window.location.href = `/checklists/executions/${data.id}`;
                                navigate(`/checklists/executions/${data.id}`, {}); // Throw endpoint 404 in when new page is loaded
                            },
                        },
                    );
                }
            }}
        >
            Ejecutar
        </Button>
    );
}

function ModalHeaderMessage(props: { data: EntityTarget }) {
    return (
        <>
            <Show when={"address" in props.data && props.data}>
                {entityData => (
                    <P>
                        Lo ejecutarás para el <span class={"font-medium"}>{entityData().name}</span>{" "}
                        en {entityData().address}.
                    </P>
                )}
            </Show>
            <Show when={"venue" in props.data && props.data}>
                {entityData => (
                    <P>
                        Lo ejecutarás para el <span class={"font-medium"}>{entityData().name}</span>
                        {entityData().venue_name ? ` en ${entityData().venue_name}.` : "."}
                    </P>
                )}
            </Show>
        </>
    );
}
