import CheckboxField from "../forms/fields/CheckboxField";
import TextField from "../forms/fields/TextField";
import FilepondField from "../forms/fields/FilepondField";
import SelectField from "../forms/fields/SelectField";
import { InputUnion } from "../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { InputTypeEnum } from "../../api/services/formbuilder/interfaces/InputTypeEnum";
import IntegerField from "../forms/fields/IntegerField";
import FloatField from "../forms/fields/FloatField";
import DecimalField from "../forms/fields/DecimalField";
import DateField from "../forms/fields/DateField";
import { Show } from "solid-js";
import CheckboxesField from "../forms/fields/CheckboxesField";

export const RenderInput = (props: InputUnion) => {
    return (
        /* Use keyed to avoid crashing due to the user changing the field name
         * in the Form Builder, as the Field components don't support changing
         * the `name` prop. */
        <Show keyed when={props.name}>
            {/* Frontend forms core use optional instead of required. */}
            <InputDispatcher {...props} optional={!props.required} />
        </Show>
    );
};

// This intermediate component is needed so Solid.js merge the props correctly
function InputDispatcher(props: InputUnion & { optional: boolean }) {
    // <Switch> would be too cumbersome, let's use an old school `switch`.
    return getInput(props);
}

function getInput(input: InputUnion & { optional: boolean }) {
    switch (input.type) {
        case InputTypeEnum.boolean:
            return <CheckboxField {...input} />;
        case InputTypeEnum.checkboxes:
            return (
                <CheckboxesField
                    {...input}
                    options={input.choices.map(option => [
                        option.choice.toString(),
                        option.choice.toString(),
                    ])}
                />
            );
        case InputTypeEnum.date:
            /* input.format is a string, but DateField.format is a function.
             * As input.format is not supported by frontend, pass a noop.
             * Use `x => x` as undefined doesn't work here (see https://github.com/solidjs/solid/issues/958).
             */
            return <DateField {...input} format={x => x} />;
        case InputTypeEnum.datetime:
            return <input {...input} type="datetime-local" />;
        case InputTypeEnum.code:
            return <TextField {...input} />;
        case InputTypeEnum.date_range:
            return <input {...input} />;
        case InputTypeEnum.decimal:
            return <DecimalField {...input} />;
        case InputTypeEnum.files:
            return <FilepondField {...input} />;
        case InputTypeEnum.float:
            return <FloatField {...input} />;
        case InputTypeEnum.gps:
            return <TextField {...input} />;
        case InputTypeEnum.integer:
            return <IntegerField {...input} />;
        case InputTypeEnum.multiple_choice:
            return (
                <SelectField
                    {...input}
                    options={input.choices.map(option => [option.choice, option.choice])}
                />
            );
        case InputTypeEnum.num_range:
            return <TextField {...input} placeholder="num_range" />;
        case InputTypeEnum.stars:
            return <TextField {...input} placeholder="stars" />;
        case InputTypeEnum.text:
            return <TextField {...input} />;
        case InputTypeEnum.time:
            return <input {...input} type="time" />;
        case InputTypeEnum.value_in_num_range:
            return <IntegerField {...input} min={input.start} max={input.stop} step={input.step} />;
        case InputTypeEnum.voice_message:
            return <TextField {...input} placeholder="voice_message" />;
    }
}
