import { P } from "../../../utils/typography";
import { FormWrapper } from "../../forms/FormWrapper";
import {
    ChecklistSections,
    CreateChecklistSectionsPayload,
} from "../../../api/services/checklist/interface";
import TextField from "../../forms/fields/TextField";
import SubmitButton from "../../forms/SubmitButton";
import { createChecklistSectionsCreateMutation } from "../../../api/services/checklist/mutations";
import { createSignal, For, Show, Suspense } from "solid-js";
import { Button } from "../../ui/components";
import { ChecklistSection } from "./checklistSection/ChecklistSection";
import { useLocale } from "../../i18n/context";
import { createChecklistSectionsListQuery } from "../../../api/services/checklist/queries";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { createForm } from "../../forms/state";
import { getApiInstance } from "../../../api";
import { after } from "../../../api/services/checklist/implementations/v3";

export function CreateChecklistSection(props: { checklistId: string }) {
    const [createSections, setCreateSections] = createSignal(false);
    const listSections = createChecklistSectionsListQuery(() => props.checklistId);

    return (
        <div class={"mt-12 grid grid-cols-12"}>
            <div class={"col-span-12 mb-4  md:col-span-6"}>
                <div class={"mb-8 flex items-center justify-between"}>
                    <P class={"!mb-0 text-display-xs font-medium"}>Crear nueva sección</P>
                    <Button onClick={() => setCreateSections(prev => !prev)}>
                        Crear secciones
                    </Button>
                </div>
                <Show when={createSections()}>
                    <CreateSectionForm checklistId={props.checklistId} />
                </Show>
                <Suspense fallback={<GenericSuspenseFallback />}>
                    <Show when={listSections.data}>
                        {sections => <SectionList sections={sections()} />}
                    </Show>
                </Suspense>
            </div>
        </div>
    );
}

function CreateSectionForm(props: { checklistId: string }) {
    const createMutation = createChecklistSectionsCreateMutation();
    const form = createForm<CreateChecklistSectionsPayload>();

    async function createChecklistSectionSubmit(values: CreateChecklistSectionsPayload) {
        const api = getApiInstance();
        const currentSteps = await api.checklist.listChecklistSections(props.checklistId);
        await createMutation.mutateAsync({
            name: values.name,
            order: after(currentSteps),
            checklist: props.checklistId,
        });
        form.reset();
    }

    return (
        <FormWrapper staticForm={form} onSubmit={createChecklistSectionSubmit} class={"!space-y-4"}>
            <TextField name={"name"} label={"Nombre"} />
            <SubmitButton>Agregar sección</SubmitButton>
        </FormWrapper>
    );
}

function SectionList(props: { sections: ChecklistSections[] }) {
    const [locale] = useLocale();

    return (
        <div class="flex flex-col gap-8">
            <For each={props.sections} fallback={<P>{locale().checklists.noSections}</P>}>
                {section => <ChecklistSection section={section} />}
            </For>
        </div>
    );
}
