import DateField from "../../../forms/fields/DateField";
import SwitchField from "../../../forms/fields/SwitchField";
import { DottedSection } from "../NewActivityConfigTab";
import SelectField from "../../../forms/fields/SelectField";
import { Show } from "solid-js";
import { useFormState } from "../../../forms/state";
import { MultipleDays, NewActivityFormValues, RepeatActivity } from "../../CreateActivityPage";
import { P } from "../../../../utils/typography";
import TimeField from "../../../forms/fields/TimeField";
import { Frequency, RRule } from "rrule";
import RadioField from "../../../forms/fields/RadioField";
import IntegerField from "../../../forms/fields/IntegerField";

export function ActivityDate() {
    const form = useFormState<NewActivityFormValues>();

    return (
        <DottedSection
            title={"¿Cuándo se realizará la actividad?"}
            subTitle={
                "Ingrese la información de cuándo se realizará la actividad y si es que esta se debe repetir en el futuro (revisar o eliminar texto)"
            }
        >
            <div class="flex gap-2">
                <DateField
                    name={"startDate"}
                    type={"date"}
                    label={"Ingrese una fecha para la actividad"}
                />
                <Show when={form.values.moreThanOneDay && !form.values.allDay}>
                    <TimeField name={"startTime"} label={"Hora de inicio"} type="time" />
                </Show>
            </div>
            <Show when={!form.values.moreThanOneDay && !form.values.allDay}>
                <div class="flex gap-2">
                    <TimeField name={"startTime"} label={"Hora de inicio"} type="time" />

                    <TimeField name={"endTime"} label={"Hora de término"} type="time" />
                </div>
            </Show>
            <Show when={form.values.moreThanOneDay}>
                <div class="flex gap-2">
                    <DateField
                        name={"endDate"}
                        type={"date"}
                        label={"Ingrese una fecha para el término de la actividad"}
                    />
                    <Show when={!form.values.allDay}>
                        <TimeField name={"endTime"} label={"Hora de término"} type="time" />
                    </Show>
                </div>
            </Show>

            <div>
                <SwitchField
                    name={"allDay"}
                    defaultValue={true}
                    label={"Durante todo el día"}
                    labelClass={"flex flex-row-reverse justify-end"}
                />
                <P class={"!mb-0"}>
                    La actividad puede ejecutarse en cualquier momento del día seleccionado
                </P>
            </div>

            <SwitchField
                name={"moreThanOneDay"}
                label={"La actividad necesita más de un día para ser finalizada"}
                labelClass={"flex flex-row-reverse justify-end"}
            />

            <SwitchField
                name={"repeatActivity"}
                label={"Quiero que esta actividad se repita"}
                labelClass={"flex flex-row-reverse justify-end"}
            />
            <Show when={form.values.repeatActivity}>
                <SelectField
                    label={"Repetir"}
                    name="repeatPeriod"
                    options={[
                        ["", "Seleccione un periodo"],
                        [RRule.DAILY, "Cada día"],
                        [RRule.WEEKLY, "Semanal"],
                        [RRule.MONTHLY, "Mensual"],
                        [RRule.YEARLY, "Anual"],
                    ]}
                />
            </Show>

            <Show when={form.values.repeatActivity}>
                <RadioField
                    name="whenFinish"
                    label="¿Cuándo finaliza la actividad?"
                    options={[
                        ["never", "Nunca"],
                        [
                            "specificDate",
                            <>
                                <span>El</span>
                                <DateField
                                    name={"until"}
                                    optional={
                                        form.values.repeatActivity &&
                                        form.values.whenFinish !== "specificDate"
                                            ? true
                                            : false
                                    }
                                    type={"date"}
                                    disabled={
                                        form.values.repeatActivity &&
                                        form.values.whenFinish !== "specificDate"
                                    }
                                />
                            </>,
                        ],
                        [
                            "afterXTimes",
                            <>
                                <span>Después de</span>
                                <IntegerField
                                    optional={
                                        form.values.repeatActivity &&
                                        form.values.whenFinish !== "afterXTimes"
                                            ? true
                                            : false
                                    }
                                    name="repeatTimes"
                                    disabled={
                                        form.values.repeatActivity &&
                                        form.values.whenFinish !== "afterXTimes"
                                    }
                                />
                                <span>repeticiones</span>
                            </>,
                        ],
                    ]}
                />
            </Show>
            <ActivityDateSummary />
        </DottedSection>
    );
}

export function ActivityDateSummary() {
    const form = useFormState<NewActivityFormValues>();
    const getStartAndEndTime = () => {
        let startTime: Temporal.PlainTime = Temporal.PlainTime.from("00:00");
        let endTime: Temporal.PlainTime = Temporal.PlainTime.from("23:59");
        const startDate: Temporal.PlainDate = Temporal.PlainDate.from(form.values.startDate);
        let endDate: Temporal.PlainDate;
        if (form.values.allDay) {
            startTime = Temporal.PlainTime.from("00:00");
            endTime = Temporal.PlainTime.from("23:59");
        } else if (form.values.startTime && form.values.endTime) {
            startTime = Temporal.PlainTime.from(form.values.startTime);
            endTime = Temporal.PlainTime.from(form.values.endTime);
        }

        if (form.values.moreThanOneDay && form.values.endDate) {
            endDate = Temporal.PlainDate.from(form.values.endDate);
        } else {
            endDate = Temporal.PlainDate.from(form.values.startDate);
        }

        const start = startDate.toPlainDateTime(startTime);
        const end = endDate.toPlainDateTime(endTime);

        return { start, end, duration: end.since(start).round({ largestUnit: "day" }) };
    };
    return (
        <div>
            <StartDateSummary start={getStartAndEndTime().start} end={getStartAndEndTime().end} />
            <TimeDurationSummary duration={getStartAndEndTime().duration} />
            <RepeatPeriodAndFinishSummary />
        </div>
    );
}

export function StartDateSummary(props: {
    start: Temporal.PlainDateTime;
    end: Temporal.PlainDateTime;
}) {
    const form = useFormState<NewActivityFormValues>();
    return (
        <Show when={form.values.startDate} fallback={<P>La actividad comienza el --</P>}>
            <Show
                when={form.values.moreThanOneDay}
                fallback={
                    <Show
                        when={form.values.allDay}
                        fallback={
                            <P>
                                La actividad comienza el {form.values.startDate} a las{" "}
                                {props.start.toPlainTime().toString()} y finaliza a las{" "}
                                {props.end.toPlainTime().toString()}
                            </P>
                        }
                    >
                        <P>La actividad ocurre el {form.values.startDate}, durante todo el día</P>
                    </Show>
                }
            >
                <P>
                    La actividad comienza el {form.values.startDate} a las{" "}
                    {props.start.toPlainTime().toString()} y finaliza el{" "}
                    {(form.values as NewActivityFormValues & MultipleDays).endDate || "--"} a las{" "}
                    {props.end.toPlainTime().toString()}
                </P>
            </Show>
        </Show>
    );
}

function TimeDurationSummary(props: { duration: Temporal.Duration }) {
    const form = useFormState<NewActivityFormValues>();

    return (
        <Show when={form.values.startDate} fallback={<P>La actividad tiene una duración de --</P>}>
            <P>
                La actividad tiene una duración de{" "}
                <span>
                    <DurationRender duration={props.duration} />
                </span>
            </P>
        </Show>
    );
}
function DurationRender(props: { duration: Temporal.Duration }) {
    const parseDuration = () => {
        const arr = [props.duration.days, props.duration.hours, props.duration.minutes];
        const units = ["días", "horas", "minutos"];
        const unitsSingular = ["día", "hora", "minuto"];
        let str = "";
        let skipped = 0;
        arr.forEach((unit, i) => {
            let connector = ", ";
            if (unit > 0) {
                if (i === arr.length - 1) connector = " y ";
                str += `${i - skipped === 0 ? "" : `${connector}`}${unit} ${
                    unit == 1 ? unitsSingular[i] : units[i]
                } `;
            } else {
                skipped++;
            }
        });
        return `${str}`;
    };
    return <span>{parseDuration()}</span>;
}
function RepeatPeriodAndFinishSummary() {
    const form = useFormState<NewActivityFormValues>();

    function parseRepeatPeriod(period: Frequency) {
        switch (Number(period)) {
            case RRule.DAILY:
                return "Diariamente";
            case RRule.WEEKLY:
                return "Semanalmente";
            case RRule.MONTHLY:
                return "Mensualmente";
            case RRule.YEARLY:
                return "Anualmente";
            default:
                return period;
        }
    }

    function parseWhenFinish(option: string) {
        switch (option) {
            case "never":
                return ", indefinidamente.";
            case "specificDate":
                return `, hasta el ${
                    (form.values as NewActivityFormValues & RepeatActivity).until || "--"
                }.`;
            case "afterXTimes":
                return `, ${
                    (form.values as NewActivityFormValues & RepeatActivity).repeatTimes || "X"
                } ${
                    (form.values as NewActivityFormValues & RepeatActivity).repeatTimes == 1
                        ? "vez"
                        : "veces"
                }`;
            default:
                return "";
        }
    }
    return (
        <Show when={form.values.repeatActivity} fallback={<P>La actividad no se repite</P>}>
            <Show
                when={
                    (form.values as NewActivityFormValues & RepeatActivity).repeatPeriod &&
                    (form.values as NewActivityFormValues & RepeatActivity).whenFinish
                }
                fallback={<P>La actividad se repite --</P>}
            >
                <P>
                    La actividad se repite{" "}
                    {parseRepeatPeriod(
                        (form.values as NewActivityFormValues & RepeatActivity).repeatPeriod,
                    )}
                    {parseWhenFinish(
                        (form.values as NewActivityFormValues & RepeatActivity).whenFinish,
                    )}
                </P>
            </Show>
        </Show>
    );
}
