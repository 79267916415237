import { useLocale } from "../../modules/i18n/context";

export default function BackButton(props: { url: string }) {
    const [locale] = useLocale();
    const t = () => locale().utils;
    return (
        <a href={props.url} class={"flex items-center gap-x-1 py-2"}>
            <i class="fas fa-chevron-left"></i>
            <span class={"text-sm font-semibold text-primary-700"}>{t().goBack}</span>
        </a>
    );
}
