import { For } from "solid-js";
import { LegacyPerson } from "../../api/services/person/interface";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import { createModalController } from "../ui/Modal";
import { SearchBar } from "../ui/SearchBar";
import { Button, Icon } from "../ui/components";
import { CreatePersonSidePanel } from "./CreatePersonSidePanel";
import { UserListCard } from "./UserListCard";
import { VenuesByUserPreviewSidePanel } from "./VenuesByUserPreviewSidePanel";
import PeopleBulkLoadModal from "./bulk-load/PeopleBulkLoadModal";
import { createExportPersons } from "../../api/services/person/queries";
import { generateAndDownload } from "../../api/utils";

const buttonHeight = "2.5rem";

export function PeopleHeader() {
    const [l] = useLocale();
    const createUserPanelController = createModalController<void, void>();
    const venuesPanelController = createModalController<void, void>();
    const bulkLoadModal = createModalController();
    const hardcodedTenantId = "5bfa800d-9fea-4cef-8993-ae7524c40516";
    const exportPersonQuery = createExportPersons(hardcodedTenantId);

    const onClickDownload = async () => {
        const result = await exportPersonQuery.refetch();
        const date = new Date();
        if (result.data)
            generateAndDownload(
                `Exportar personas ${date
                    .toISOString()
                    .split(".")[0]
                    .replaceAll(":", ".")
                    .replace("T", " a las ")}.xlsx`,
                result.data,
            );
    };

    const onClickUpload = () => {
        bulkLoadModal.open({});
    };

    return (
        <div>
            <div class="flex justify-between">
                <div>
                    <div class="flex items-center gap-3">
                        <P class="text-display-sm font-bold">{l().auth.people}</P>
                        <Button
                            onClick={() => createUserPanelController.open()}
                            style={{ height: buttonHeight }}
                        >
                            {l().auth.createPerson}
                        </Button>
                    </div>
                </div>
                <div class={"flex gap-x-3"}>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        onClick={onClickDownload}
                        class={"!font-normal"}
                        style={{ height: buttonHeight }}
                    >
                        <Icon name="fas fa-file-excel" />
                        {l().auth.exportPeopleToXLSX}
                    </Button>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        onClick={onClickUpload}
                        class={"!font-normal"}
                        style={{ height: buttonHeight }}
                    >
                        <Icon name="fas fa-upload" />
                        {l().auth.peopleBulkLoad}
                    </Button>
                </div>
            </div>
            <div class="grid-col-12 my-12 grid space-y-2 text-center">
                <div class={"col-span-12"}>
                    <P class={"!mb-0 text-xl text-dark-gray-400"}>{l().auth.peopleSearchLabel}</P>
                </div>
                <div class={"col-span-6 col-start-4"}>
                    <SearchBar variant="gray" autofocus placeholder={l().auth.searchUser} />
                </div>
            </div>
            <CreatePersonSidePanel
                controller={createUserPanelController}
                venuesPanelController={venuesPanelController}
            />
            <VenuesByUserPreviewSidePanel controller={venuesPanelController} />

            <PeopleBulkLoadModal controller={bulkLoadModal} />
        </div>
    );
}

export function PeopleList(props: { persons: LegacyPerson[] }) {
    return (
        <div>
            <PeopleListHeader />
            <div class="">
                <For each={props.persons}>{person => <UserListCard person={person} />}</For>
            </div>
        </div>
    );
}

export function PeopleListHeader() {
    const [l] = useLocale();
    return (
        <div class="my-2 grid grid-cols-12 border-b border-light-gray-300">
            <div class="col-span-4 pl-4">
                <P class="font-bold">{l().personalData.name}</P>
            </div>
            <div class="col-span-3">
                <P class="font-bold">{l().personalData.roles}</P>
            </div>
            <div class="col-span-3">
                <P class="font-bold">{l().personalData.groups}</P>
            </div>
            <div class="col-span-2" />
        </div>
    );
}
