import { Match, Show, Switch } from "solid-js";
import { RecurrenceTask } from "../../../api/services/task-manager/interface";
import { UnknownTaskCard } from "./TaskCardDispatcher";
import { TaskCard } from "./TaskCard";
import { createActivityFormResponseQuery } from "../../../api/services/task-manager/queries";
import { ErrorBlock } from "../../../utils/GenericErrorBoundary";

export default function RecurrenceTaskCardDispatcher(props: { recurrenceTask: RecurrenceTask }) {
    return (
        <Switch fallback={<UnknownRecurrenceTaskCard recurrenceTask={props.recurrenceTask} />}>
            <Match when={props.recurrenceTask.content_type_model === "formresponse"}>
                <FormResponseRecurrenceTaskCard recurrenceTask={props.recurrenceTask} />
            </Match>
        </Switch>
    );
}

function FormResponseRecurrenceTaskCard(props: { recurrenceTask: RecurrenceTask }) {
    const query = createActivityFormResponseQuery(() => props.recurrenceTask.object_id);

    return (
        <Show when={query.data} fallback={<ErrorBlock error={query.error} />}>
            {formResponse => (
                <TaskCard
                    title={props.recurrenceTask.id}
                    description=""
                    isReady={true}
                    isDone={true}
                    taskType={props.recurrenceTask.content_type_model}
                    isOptional={false}
                    data={{ recurrenceTask: props.recurrenceTask, formResponse: formResponse() }}
                />
            )}
        </Show>
    );
}

function UnknownRecurrenceTaskCard(props: { recurrenceTask: RecurrenceTask }) {
    return <UnknownTaskCard task={props.recurrenceTask} />;
}
