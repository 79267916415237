import { Show, Suspense } from "solid-js";
import DynamicPage from "./DynamicPage";

import { PageWrapper } from "../ui/pageWrappers";
import { createHomePageQuery } from "../../api/services/home/queries";
import Warning from "../ui/Warning";

export default function HomePage() {
    const homePageQuery = createHomePageQuery();

    return (
        <PageWrapper>
            <Suspense fallback={<HomePageSkeleton />}>
                <Warning>El home es una maqueta y su apariencia podría cambiar.</Warning>
                <Show when={homePageQuery.data}>{page => <DynamicPage page={page()} />}</Show>
            </Suspense>
        </PageWrapper>
    );
}

function HomePageSkeleton() {
    return (
        <div>
            <div class="skeleton mb-4 h-[40vh] w-full rounded-xl" />
            <div class="skeleton mb-4 h-[40vh] w-full rounded-xl" />
        </div>
    );
}
