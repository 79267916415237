import { Show } from "solid-js";
import CheckboxField from "../forms/fields/CheckboxField";
import RadioField from "../forms/fields/RadioField";
import SelectField from "../forms/fields/SelectField";
import { useFormState } from "../forms/state";
import { useLocale } from "../i18n/context";
import { PersonalDataFormValues } from "./PersonalDataForm";
import { createCurrentOrganizationQuery } from "../../api/services/organization/queries";
import { P } from "../../utils/typography";
import { Button } from "../ui/components";
import { ModalController } from "../ui/Modal";

export type PersonalDataWithOrganizationFormValues = PersonalDataFormValues & {
    email: string;
    send_invitation: boolean;
    invitation_type: string;
    workplaces: string;
    roles: string;
};

export function OrganizationInvitationFields(props: {
    venuesController: ModalController<void, void>;
}) {
    const [locale] = useLocale();
    const t = () => locale().personalData;
    const form = useFormState<PersonalDataWithOrganizationFormValues>();
    const organizationQuery = createCurrentOrganizationQuery();
    const invitation_type = () => form.values.invitation_type;

    const invitationOptions = () =>
        [
            [
                "1",
                t().wherePersonBelongsOptions.ownOrganization(organizationQuery.data?.name ?? ""),
            ],
        ] as [string, string][];
    const invitationOptions_two = () =>
        [
            ["2", t().wherePersonBelongsOptions.comercialPartner],
            ["3", t().wherePersonBelongsOptions.contractorBusiness],
        ] as [string, string][];

    const workSpaceOptions = () =>
        [
            ["1", "Workplace 1"],
            ["2", "Workplace 2"],
        ] as [string, string][];

    const rolesOptions = () =>
        [
            ["1", "Role 1"],
            ["2", "Role 2"],
        ] as [string, string][];

    const hasInvitation = () => form.values.send_invitation;
    return (
        <section class="flex w-full flex-col gap-4">
            <CheckboxField name="send_invitation" label={t().sendInvitation} />
            <Show when={hasInvitation()}>
                <div class={"flex flex-col gap-y-3"}>
                    <P class={"!mb-0 text-sm font-bold"}>
                        ¿Dónde pertenece la persona que está creando?
                    </P>
                    <div>
                        <RadioField name="invitation_type" options={invitationOptions()} />
                        <Show when={invitation_type() === "1"}>
                            <div class={"flex flex-col gap-y-3 pl-3"}>
                                <SelectField
                                    name={"workplaces"}
                                    label={t().selectWorkspace}
                                    options={workSpaceOptions()}
                                    labelClass={"text-sm !font-bold"}
                                />
                                <SelectField
                                    name={"roles"}
                                    label={t().selectRoles}
                                    options={rolesOptions()}
                                    labelClass={"text-sm !font-bold"}
                                />

                                <P class={"!mb-0"}>
                                    No encuentras el cargo que estás buscando? Puedes{" "}
                                    <a href={""}>crear un cargo</a>
                                </P>

                                <div
                                    class={
                                        "rounded-xs border border-light-gray-300 bg-light-gray-200 p-3"
                                    }
                                >
                                    <P class={"!mb-0"}>
                                        No existen recintos asociados a <strong>José Dosal</strong>
                                    </P>
                                </div>
                                <div
                                    class={
                                        "flex items-center justify-between rounded-xs border border-light-gray-300 bg-light-gray-200 p-3"
                                    }
                                >
                                    <P class={"!mb-0"}>
                                        Se asociaron <strong>18 recintos</strong> a José Dosal
                                    </P>
                                    <Button
                                        variant={"primary"}
                                        bgStyle={"text-only"}
                                        class={"!p-0 !font-normal"}
                                        type="button"
                                        onClick={() => props.venuesController.open()}
                                    >
                                        Ver lista de recintos
                                    </Button>
                                </div>
                            </div>
                        </Show>
                        <RadioField
                            name="invitation_type_two"
                            options={invitationOptions_two()}
                            withoutDefaultValue
                        />
                    </div>
                </div>
            </Show>
        </section>
    );
}
