import { Asset, Checklist, EntityTarget, Venue } from "../../../api/services/checklist/interface";
import { P } from "../../../utils/typography";
import TextField from "../../forms/fields/TextField";
import SubmitButton from "../../forms/SubmitButton";
import { FormWrapper } from "../../forms/FormWrapper";
import {
    createChecklistCreateMutation,
    createChecklistPatchMutation,
} from "../../../api/services/checklist/mutations";
import { useNavigate } from "@solidjs/router";
import { createForm, useFormState } from "../../forms/state";
import { createEffect, createSignal, For, Match, onMount, Show, Switch, untrack } from "solid-js";
import {
    createChecklistRetrieveQuery,
    createListCategoriesQuery,
} from "../../../api/services/checklist/queries";
import SelectField from "../../forms/fields/SelectField";
import { Button } from "../../ui/components";
import { TargetEntityFieldsSection } from "./logicParser/TargetEntityFieldsSection";
import {
    CreateUpdateLogicPayload,
    FunctionExpression,
    LogicJson,
    Operator,
    OperatorExpression,
} from "../../../api/services/logic-parser/interface";
import {
    createEvaluateLogicParserMutation,
    createMakeLogicParserMutation,
    createPatchLogicParserMutation,
} from "../../../api/services/logic-parser/mutations";
import { createRetrieveParserLogicQuery } from "../../../api/services/logic-parser/queries";
import Toastify from "toastify-js";
import IntegerField from "../../forms/fields/IntegerField";
import { ColorPickerChecklistField } from "./ColorPickerChecklistField";
import _ from "lodash";
import UuidAudienceField from "../../audiences/UuidAudienceField";

export type FrontendTargetEntity = {
    filter: string;
    filterValue: string | number | (string | number)[];
    operator: string;
    inputType: string;
    frontendInputType?: string;
};

export type CreateChecklistFormValues = {
    name: string;
    min_grade: number;
    max_grade: number;
    step_grade: number;
    entities: FrontendTargetEntity[];
    model: string;
    color: string;
    execution_audience: string | null;
};

export function CreateChecklist(props: { checklistId: string | undefined }) {
    const listCategories = createListCategoriesQuery();
    const createChecklistMutation = createChecklistCreateMutation();
    const checklistID = untrack(() => props.checklistId);
    const updateChecklistMutation = createChecklistPatchMutation(checklistID ?? "");
    const navigate = useNavigate();
    const [results, setResults] = createSignal<EntityTarget[]>([]);
    const createLogicParserMutation = createMakeLogicParserMutation();
    const evaluateMutation = createEvaluateLogicParserMutation();
    const getChecklist = createChecklistRetrieveQuery(() => props.checklistId);
    const updateLogicParserMutation = createPatchLogicParserMutation(
        () => getChecklist.data?.target_entity_query,
    );
    const form = createForm<CreateChecklistFormValues>();

    async function createUpdateSubmit(values: CreateChecklistFormValues) {
        const payload = createTargetQueryPayload(values);
        //await evaluateMutation.mutateAsync(payload);
        if (props.checklistId) {
            //const isValid = form.triggerSecondarySubmit()
            const res = await updateLogicParserMutation.mutateAsync(payload);
            await updateChecklistMutation.mutateAsync(
                {
                    //...values,
                    min_grade: Number(values.min_grade),
                    max_grade: Number(values.max_grade),
                    step_grade: Number(values.step_grade),
                    execution_audience: values.execution_audience || null,
                    target_entity_query: res.id,
                    color: values.color,
                    name: String(values.name),
                    category: listCategories.data?.[0].id ?? "",
                },
                {
                    onSuccess: () => {
                        Toastify({
                            text: `Checklist ${getChecklist.data?.name} actualizado con éxito`,
                            duration: 3000,
                            backgroundColor: "#2facd9",
                        }).showToast();
                        //navigate(`/checklists/create/${data.id}`, { replace: true });
                    },
                },
            );
        } else {
            const res = await createLogicParserMutation.mutateAsync(payload);

            await createChecklistMutation.mutateAsync(
                {
                    //...values,
                    min_grade: Number(values.min_grade),
                    max_grade: Number(values.max_grade),
                    step_grade: Number(values.step_grade),
                    execution_audience: values.execution_audience || null,
                    target_entity_query: res.id,
                    color: values.color,
                    name: String(values.name),
                    category: listCategories.data?.[0].id ?? "",
                },
                {
                    onSuccess: data => {
                        navigate(`/checklists/create/${data.id}`, { replace: true });
                    },
                },
            );
        }
    }
    function validatePayload(payload: CreateUpdateLogicPayload) {
        if (payload.expression.length === 0) {
            return true;
        }
        const allValidValues = payload.expression.every(item => {
            if ("function" in item) {
                const isArray = Array.isArray(item.value);
                if (isArray) {
                    return (item.value as []).every(val => {
                        if (typeof val === "number") {
                            return val > 0;
                        } else if (typeof val === "string") {
                            return !_.isEmpty(val);
                        }
                        return !_.isEmpty(val);
                    });
                }
                return !_.isEmpty(item.value);
            } else if ("operator" in item) {
                return !!item.operator;
            }
            return false;
        });
        console.log({ allValidValues });
        return allValidValues;
    }
    const evaluateResults = async (values: CreateChecklistFormValues) => {
        const payload = createTargetQueryPayload(values);
        if (!validatePayload(payload)) {
            return;
        }

        const results = await evaluateMutation.mutateAsync(payload);
        console.log({ results });
        setResults(results);
    };
    function createTargetQueryPayload(
        values: Record<string, string | number | null | FrontendTargetEntity[]>,
    ): CreateUpdateLogicPayload {
        const expression: (FunctionExpression | OperatorExpression)[] = [];

        if (Array.isArray(values.entities)) {
            (values.entities ?? []).forEach((entity, index) => {
                if (index !== 0) {
                    expression.push({ operator: entity.operator as Operator });
                }
                expression.push({
                    function: entity.filter,
                    value: parseValue(
                        entity.filterValue,
                        entity.inputType,
                        entity.frontendInputType,
                    ),
                });
            });
        }

        const payload: CreateUpdateLogicPayload = {
            model: values.model as string,
            expression: expression,
        };
        return payload;
    }
    function parseValue(
        value: string | number | (string | number)[],
        inputType: string,
        fronetendInputType?: string,
    ) {
        if (fronetendInputType == "select") {
            switch (inputType) {
                case "int":
                    return [Number(value)];
                case "uuid":
                    return [String(value)];
                case "str":
                    return [String(value)];
            }
        }
        if (inputType == "int") {
            return Number(value);
        } else if (inputType == "uuid") {
            return [String(value)];
        } else if (inputType == "str") {
            return String(value);
        }
        return value;
    }

    return (
        <Show
            when={getChecklist.data}
            fallback={
                <FormWrapper<CreateChecklistFormValues>
                    onSubmit={createUpdateSubmit}
                    class={"w-full space-y-4"}
                    staticForm={form}
                    onChange={values => {
                        evaluateResults(values);
                    }}
                >
                    <CreateUpdateChecklistForm results={results()} />
                </FormWrapper>
            }
        >
            {checklist => (
                <FormWrapper<CreateChecklistFormValues>
                    onSubmit={createUpdateSubmit}
                    class={"w-full space-y-4"}
                    staticForm={form}
                    onChange={values => {
                        evaluateResults(values);
                    }}
                >
                    <CreateUpdateChecklistForm
                        results={results()}
                        target_entity_query={checklist().target_entity_query}
                        defaultData={checklist()}
                    />
                </FormWrapper>
            )}
        </Show>
    );
}

function CreateUpdateChecklistForm(props: {
    results: EntityTarget[];
    target_entity_query?: string;
    defaultData?: Checklist;
}) {
    const query = createRetrieveParserLogicQuery(() => props.target_entity_query);
    return (
        <>
            <Show when={query.data} fallback={<FormContent results={props.results} />}>
                {data => (
                    <FormContent
                        data={data()}
                        results={props.results}
                        defaultDataChecklist={props.defaultData}
                    />
                )}
            </Show>
        </>
    );
}

function FormContent(props: {
    data?: LogicJson;
    results: EntityTarget[];
    defaultDataChecklist?: Checklist;
}) {
    const formState = useFormState<{
        entities: FrontendTargetEntity[];
        model: string;
    }>();

    const onClickAddEntity = () => {
        formState.setValues(values => {
            if (!values.entities) {
                values.entities = [{ operator: "", filter: "", filterValue: "", inputType: "" }];
            } else {
                values.entities.push({ operator: "", filter: "", filterValue: "", inputType: "" });
            }
        });
    };

    createEffect(() => {
        // formState.setValues(values => {
        //     if (props.data?.() && props.data?.().expression !== null){
        //         values.entities = props.data().expression!.map((item) => {
        //             if ("function" in item) {
        //                 return {
        //                     filter: item.function,
        //                     filterValue: item.value,
        //                     operator: "",
        //                     inputType: "str",
        //                     frontendInputType: "text",
        //                 };
        //             } else if ("operator" in item) {
        //                 return {
        //                     filter: "",
        //                     filterValue: "",
        //                     operator: item.operator,
        //                     inputType: "",
        //                 };
        //             }
        //             return {
        //                 filter: "",
        //                 filterValue: "",
        //                 operator: "",
        //                 inputType: "",
        //             };
        //         })
        //     }
        // })
    });
    //const data = untrack(() => props.data?.());
    // createEffect(() => {
    //     if (data){
    //         const len = (data.expression?.length??0) > 0;
    //         if (len){
    //             const entities = data.expression?.map((item) => {
    //                 if ("function" in item) {
    //                     return {
    //                         filter: item.function,
    //                         filterValue: item.value,
    //                         operator: "",
    //                         inputType: "str",
    //                         frontendInputType: "text",
    //                     };
    //                 } else if ("operator" in item) {
    //                     return {
    //                         filter: "",
    //                         filterValue: "",
    //                         operator: item.operator,
    //                         inputType: "",
    //                     };
    //                 }
    //                 return {
    //                     filter: "",
    //                     filterValue: "",
    //                     operator: "",
    //                     inputType: "",
    //                 };
    //             })??[]
    //             formState.setValues(values => {
    //                 values.entities = entities;
    //             })
    //         }
    //     }
    // })
    onMount(() => {
        if (props.data) {
            const entities =
                props.data.expression?.map(item => {
                    if ("function" in item) {
                        return {
                            filter: item.function,
                            filterValue: item.value,
                            operator: "",
                            inputType: Array.isArray(item.value)
                                ? typeof item.value[0] == "number"
                                    ? "int"
                                    : typeof item.value == "number"
                                    ? "int"
                                    : "str"
                                : "str",
                            frontendInputType: Array.isArray(item.value) ? "select" : "text",
                        };
                    } else if ("operator" in item) {
                        return {
                            filter: "",
                            filterValue: "",
                            operator: item.operator,
                            inputType: "",
                        };
                    }
                    return {
                        filter: "",
                        filterValue: "",
                        operator: "",
                        inputType: "",
                    };
                }) ?? [];
            formState.setValues(values => {
                values.entities = entities;
            });
        }
    });
    return (
        <>
            <div class={"w-full space-y-4 md:w-120"}>
                <TextField
                    name={"name"}
                    label={"Nombre"}
                    defaultValue={props.defaultDataChecklist?.name}
                />
                <IntegerField
                    name="min_grade"
                    label={"Nota mínima"}
                    defaultValue={
                        props.defaultDataChecklist?.min_grade
                            ? String(props.defaultDataChecklist.min_grade)
                            : "0"
                    }
                />
                <IntegerField
                    name="max_grade"
                    label={"Nota máxima"}
                    defaultValue={
                        props.defaultDataChecklist?.max_grade
                            ? String(props.defaultDataChecklist.max_grade)
                            : "100"
                    }
                />
                <IntegerField
                    name="step_grade"
                    label={"Intervalo"}
                    defaultValue={
                        props.defaultDataChecklist?.step_grade
                            ? String(props.defaultDataChecklist.step_grade)
                            : "10"
                    }
                />
                <UuidAudienceField
                    name={"execution_audience"}
                    label={"Audiencia de ejecución"}
                    defaultValue={
                        props.defaultDataChecklist?.execution_audience
                            ? props.defaultDataChecklist.execution_audience
                            : undefined
                    }
                    optional
                />
                <ColorPickerChecklistField defaultValue={props.defaultDataChecklist?.color} />
            </div>
            <div class="flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div class={"w-full"}>
                    <P class={"text-lg font-medium"}>Elementos asociados</P>
                    <div
                        class={
                            "flex flex-col gap-y-3 space-y-2 rounded-md border border-dark-gray-400 p-4"
                        }
                    >
                        <SelectField
                            name={`model`}
                            label={undefined}
                            parentClass="border-b-2 pb-8"
                            options={[
                                ["asset", "Activo"],
                                ["venue", "Recinto"],
                                ["location", "Tienda"],
                            ]}
                            defaultValue={props.data?.model}
                            onChange={() => {
                                formState.setValues(values => {
                                    values.entities = [];
                                });
                            }}
                        />
                        <For each={formState.values.entities}>
                            {(i, index) => <TargetEntityFieldsSection index={index()} />}
                        </For>
                        <Button type="button" bgStyle={"outline"} onClick={onClickAddEntity}>
                            Agregar nuevo filtro
                        </Button>
                    </div>
                </div>

                <div class="w-ful h-56 overflow-y-scroll">
                    <P class={"text-h5 font-medium"}>
                        Previsualización Resultado
                        <span class={"text-sm font-normal text-dark-gray-600"}>
                            {" "}
                            ({props.results.length} encontrados)
                        </span>
                    </P>
                    <div class={"h-full overflow-y-scroll"}>
                        <For each={props.results}>
                            {result => <EntityPreview entity={result} />}
                        </For>
                    </div>
                </div>
            </div>
            <SubmitButton size={"lg"} class={"w-full md:w-120"}>
                {props.data ? "Editar" : "Crear"} checklist
            </SubmitButton>
        </>
    );
}

function EntityPreview(props: { entity: EntityTarget }) {
    return (
        <div
            class={
                "py-2 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-light-gray-300"
            }
        >
            <Switch>
                <Match when={props.entity.target_entity === "venue"}>
                    <P class={"!mb-0"}>{(props.entity as Venue).name}</P>
                    <P class={"!mb-0 text-sm text-dark-gray-600"}>
                        {(props.entity as Venue).address}
                    </P>
                </Match>
                <Match when={props.entity.target_entity === "asset"}>
                    <P class={"!mb-0"}>
                        {(props.entity as Asset).name ?? (props.entity as Asset).type_name}
                    </P>
                </Match>
            </Switch>
        </div>
    );
}
