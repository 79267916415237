import SubmitButton from "../../forms/SubmitButton";
import SubdomainField from "../../forms/fields/SubdomainField";
import { AimLogo, Button } from "../../ui/components";
import { useLocale } from "../../i18n/context";
import { Show, onMount } from "solid-js";
import Toastify from "toastify-js";
import { parsedEnv } from "../../../utils/parsedEnv";
import { FormWrapper } from "../../forms/FormWrapper";

export default function SubdomainStep(props: {
    onSubmitSubdomain: (subdomain: string) => void;
    onForgotSubdomain: () => void;
}) {
    const [locale] = useLocale();

    onMount(() => {
        if (parsedEnv.MODE === "development")
            Toastify({
                text: 'Nuestro subdominio de prueba es "mycompany".',
            }).showToast();
    });

    return (
        <div class="flex flex-col items-center gap-4">
            <AimLogo />
            <div class="mb-8 mt-12 self-center">
                <h1 class="text-center text-xl font-normal text-dark-gray-800">
                    {locale().auth.welcomeTo} AIM Manager
                </h1>
                <p class="text-center text-lg text-dark-gray-400">
                    {locale().auth.organizationUrl}
                </p>
            </div>
            <FormWrapper<{ subdomain: string }>
                onSubmit={form => props.onSubmitSubdomain(form.subdomain)}
                class="flex w-full flex-col gap-12"
            >
                <div class={"mb-10 flex flex-col gap-4"}>
                    <SubdomainField name="subdomain" label="" placeholder="mycompany" />
                    <SubmitButton class={"mt-4"}>{locale().auth.continue}</SubmitButton>
                    <ForgotSubdomain onForgotSubdomain={props.onForgotSubdomain} />
                </div>
            </FormWrapper>
        </div>
    );
}

function ForgotSubdomain(props: { onForgotSubdomain: () => void }) {
    const [locale] = useLocale();

    return (
        <Show when={parsedEnv.VITE_FEATURE_SIGN_IN_WITH_MAGIC_CODE}>
            <div class={"mt-4 flex items-center gap-x-2"}>
                <p class="font-nor text-dark-gray-700">{locale().auth.forgotOrganizationUrl}</p>
                <Button
                    type="button"
                    bgStyle="text-only"
                    onClick={props.onForgotSubdomain}
                    class={"!p-0 !font-normal"}
                >
                    <span class={"font-bold"}>{locale().auth.findYourOrganization}</span>
                </Button>
            </div>
        </Show>
    );
}
