import { ServiceQuery } from "../../utils";
import { DynamicForm } from "../formbuilder/DynamicForm";
import {
    CheckboxesInput,
    MultipleChoiceInput,
    ValueInNumRangeInput,
} from "../formbuilder/interfaces/inputs/BaseInput";
import { FormValues } from "../../../modules/forms/state";
import { UserProfile } from "../person/interface";
import { sAsset } from "./implementations/v3";
import { Infer } from "superstruct";

export interface ChecklistService {
    retrieveUniqChecklist: ServiceQuery<[id: string], Checklist>;
    retrieveUniqChecklistExecution: ServiceQuery<[id: string], ChecklistExecutionsRetrieve>;
    retrieveUniqSection: ServiceQuery<[id: string], ChecklistSections>;
    getChecklistEntityTargetType: ServiceQuery<[id: string], EntityTargetType>;
    retrieveStep: ServiceQuery<[id: string], SectionStep>;
    listChecklist: ServiceQuery<[], Checklist[]>;
    listChecklistEntities: ServiceQuery<[checklistID: string], EntityTarget[]>;
    listChecklistExecutions: ServiceQuery<[], ChecklistExecutions[]>;
    listChecklistSections: ServiceQuery<[checklistID: string], ChecklistSections[]>;
    /** Given a checklist section, returns an array of forms, where each form
     * corresponds to a single "step" of that section.
     */
    listSectionSteps: ServiceQuery<[sectionId: string], SectionStep[]>;
    listFormChecklistResponses: ServiceQuery<
        [checklistExecutionID: string],
        ChecklistFormResponse[]
    >;
    listChecklistOverview: ServiceQuery<[checklistID: string], ChecklistOverview[]>;
    listChecklistFormConceptsWithQuestion: ServiceQuery<
        [evaluationID: string],
        ChecklistFormConceptsWithQuestion[]
    >;
    listGrades: ServiceQuery<[evaluationID: string, executionID: string], Grade[]>;
    listChecklistCategories: ServiceQuery<[], ChecklistCategory[]>;
    createSectionStep: (payload: CreateSectionStep) => Promise<void>;
    updateSectionStep: (payload: SectionStep) => Promise<void>;
    deleteSectionStep: (id: string) => Promise<void>;
    createChecklist: (payload: CreateChecklistPayload) => Promise<CreateChecklistResponse>;
    updateChecklist: (id: string, payload: CreateChecklistPayload) => Promise<void>;
    createChecklistExecutions: (
        payload: CreateChecklistExecutionsPayload,
    ) => Promise<ChecklistExecutionsResponse>;
    createChecklistSections: (payload: CreateChecklistSectionsPayload) => Promise<void>;
    patchChecklistSection: (payload: PatchChecklistSectionPayload) => Promise<ChecklistSections>;
    deleteChecklistSection: (id: string) => Promise<void>;
    createChecklistActivation: (id: string) => Promise<void>;
    /** Returns `id: null` if every step has been completed. */
    getCurrentStep: ServiceQuery<[executionId: string], { id: string | null }>;
    /** Submits the form responses to a step. */
    answerStep: (params: AnswerStepParams) => Promise<void>;
    completeChecklist: (executionId: string) => Promise<void>;

    // #region evaluations

    updateDefaultEvaluationAudiences: (
        params: UpdateDefaultEvaluationAudiencesParams,
    ) => Promise<void>;
    listEvaluations: ServiceQuery<[checklist: string], Evaluation[]>;
    listConcepts: ServiceQuery<[evaluation: string], Concept[]>;
    listConceptSteps: ServiceQuery<[concept: string], ConceptStep[]>;
    createEvaluation: (params: CreateEvaluationParams) => Promise<void>;
    createConcept: (params: CreateConceptParams) => Promise<void>;
    createConceptStep: (params: CreateConceptStepParams) => Promise<void>;

    // #endregion
}

export interface Checklist {
    id: string;
    created_at?: Temporal.Instant;
    updated_at?: Temporal.Instant;
    is_active: boolean;
    name: string;
    execution_audience: string | null;
    color: string;
    min_grade: number;
    max_grade: number;
    step_grade: number;
    target_entity_query: string;
    category: string;
}

export type ChecklistExecutions = {
    id: string;
    checklist_name: string;
    user: UserProfile;
    created_at: Temporal.Instant;
    updated_at: Temporal.Instant;
    target_entity: string;
    checklist: string;
    completed_at: Temporal.Instant | null;
};

export type ChecklistExecutionsRetrieve = Omit<
    ChecklistExecutions,
    "checklist_name" | "updated_at" | "target_entity"
>;

export interface ChecklistSections {
    id: string;
    created_at: Temporal.Instant;
    updated_at: Temporal.Instant;
    name: string;
    order: number;
    checklist: string;
}

export type CreateChecklistPayload = {
    name: string;
    execution_audience: string | null;
    target_entity_query: string;
    color: string;
    min_grade: number;
    max_grade: number;
    step_grade: number;
    category: string;
};

export type CreateChecklistResponse = {
    id: string;
};

export interface CreateChecklistExecutionsPayload {
    checklist: string;
    target_entity: string;
}

export interface ChecklistExecutionsResponse {
    id: string;
    // created_at: Temporal.Instant;
    // updated_at: Temporal.Instant;
    checklist: string;
    completed?: string | null;
}

export type CreateChecklistSectionsPayload = {
    name: string;
    order: number;
    checklist: string;
};

export type PatchChecklistSectionPayload = Partial<CreateChecklistSectionsPayload> & {
    id: string;
};

/** Checklist "steps" within a section are represented by a `checklist_form`
 * object on V3.
 */
export interface SectionStep extends CreateSectionStep, DynamicForm {
    createdAt: Temporal.Instant;
    updatedAt: Temporal.Instant;
}

export interface CreateSectionStep {
    /** ID of the checklist section containing this step. */
    sectionId: string;
    /** This is needed for V3 as the steps are sorted by this integer. */
    order: number;
    /** Every checklist form has a "main" field, that is shown in the UI as a
     * "question", separated from the other fields (the "tasks").
     */
    mainField: ValueInNumRangeInput | MultipleChoiceInput | CheckboxesInput;
    /** If true, this step counts towards the final grade, and the grade for this
     * step is computed according to the `metadata` object.
     */
    evaluable: boolean;
    /** If the step is evaluable, this object contains additional information
     * needed to convert the answer to this step to a grade. */
    metadata: {
        /** Not needed when the main field type is `value_in_num_range`. */
        choices_with_grade?: ChoiceWithGrade[];
        /** Needed when the main field type is `checkboxes`. */
        evaluation_function?: EvaluationFunction;
    };
}

/** When a checklist step is evaluable and its main field has choices,
 * this maps one of those choices to a grade.
 *
 * @example
 * { choice: "Not working at all", grade: 0 }
 *
 * { choice: "With some defects", grade: 5 }
 *
 * { choice: "Perfect", grade: 10 }
 *
 * @remarks
 * Choices with grades can't have images on V3.
 */
export type ChoiceWithGrade = {
    /** The identifier and also the label of the choice. */
    choice: string;
    /** Used for the checklist evaluations to compute a final grade for the
     * whole checklist.
     */
    grade: number;
};

/** As the user can select multiple checkboxes, this function
 * will be used to aggregate the selected checkboxes into a
 * single grade.
 */
export enum EvaluationFunction {
    avg = "avg",
    min = "min",
    max = "max",
}

export type EntityTarget = Asset | Venue;

export interface Venue {
    target_entity: "venue";
    id: string;
    // created_at: string;
    // updated_at: string;
    name: string;
    description: string;
    code: string;
    address: string;
    country: string;
    timezone: string;
    gla: string;
    business_line: number;
    categories: number[];
}

export type Asset = Infer<ReturnType<typeof sAsset>> & {
    target_entity: "asset";
};
export enum EntityTargetModel {
    asset = "asset",
    venue = "venue",
    location = "location",
}

export interface EntityTargetType {
    id: string;
    model: EntityTargetModel;
}

export interface ChecklistFormResponse {
    id: string;
    // "created_at": "2019-08-24T14:15:22Z",
    // "updated_at": "2019-08-24T14:15:22Z",
    // optional(nullable(record(string(), any())))
    response: FormValues;
    grade: string | null;
    checklist_form: string;
    user: number;
    checklist_execution: string;
}

export interface ChecklistOverview {
    id: string;
    checklist_forms: string[];
}

export interface AnswerStepParams {
    executionId: string;
    stepId: string;
    formValues: FormValues;
}

export interface ConceptStep {
    id: string;
    order: number;
    weight: number;
    checklist_form: string;
    concept: string;
}

export type CreateConceptStepParams = Omit<ConceptStep, "id">;

export interface ChecklistFormConceptsWithQuestion extends ConceptStep {
    checklist_form_question: string;
    // created_at: string;
    // updated_at: string;
}

export interface Grade {
    id: string;
    // created_at: string;
    // updated_at: string;
    final_grade: number;
    grades: {
        id: string;
        grade: string;
        weight: number;
    }[];
    evaluation: string;
    checklist_execution: string;
}

export interface ChecklistCategory {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    icon: string;
    description: string;
    starred: boolean;
    parent: string | null;
}

/** Evaluations **/

export interface Evaluation {
    id: string;
    // created_at: string;
    // updated_at: string;
    name: string;
    use_percentages: boolean;
    report_audience: string;
    read_audience: string;
    is_active: boolean;
    checklist: string;
}

export type CreateEvaluationParams = Omit<Evaluation, "id">;

export interface Concept {
    id: string;
    created_at: Temporal.Instant;
    updated_at: Temporal.Instant;
    name: string;
    weight: number;
    evaluation: string;
    parent: string | null;
}

export type CreateConceptParams = Omit<Concept, "id" | "created_at" | "updated_at">;

export type UpdateDefaultEvaluationAudiencesParams = {
    checklistId: string;
    report_audience: string;
    read_audience: string;
};
