import { Accessor } from "solid-js";
import { createApiQuery } from "../../../utils";

export function createWorkflowListQuery() {
    return createApiQuery(api => ({
        queryKey: ["workflowList"],
        queryFn: () => api.workflow.listWorkflows(),
    }));
}

export function createWorkflowDetailQuery(workflowId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["workflowDetail", workflowId()],
        queryFn: () => api.workflow.retrieveWorkflow(workflowId()!),
        enabled: !!workflowId(),
    }));
}
