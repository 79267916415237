import { PageWrapper } from "../ui/pageWrappers";
import { createPeopleQuery } from "../../api/services/person/queries";
import { DevSuspense, GenericSuspenseFallback } from "../ui/skeletons";
import { Show } from "solid-js";
import { PeopleHeader, PeopleList } from "./PeopleList";
import Warning from "../ui/Warning";

export default function PeoplePage() {
    const peopleQuery = createPeopleQuery();

    return (
        <PageWrapper>
            <Warning>Esto es una maqueta incompleta del módulo de Organización.</Warning>
            <div>
                <PeopleHeader />
            </div>
            <DevSuspense fallback={<GenericSuspenseFallback />}>
                <Show when={peopleQuery.data}>{people => <PeopleList persons={people()} />}</Show>
            </DevSuspense>
        </PageWrapper>
    );
}
