import { P } from "../../../utils/typography";
import { createSignal, ParentProps } from "solid-js";
import { NewActivityTaskItem } from "./NewActivityTasksTab";
import { NewActivityNotificationItem } from "./NewActivityNotificationsTab";
import { ActivityDateSummary } from "./configTab/ActivityDate";
import { useFormState } from "../../forms/state";
import { NewActivityFormValues } from "../CreateActivityPage";

export default function NewActivitySummary() {
    const [open, setOpen] = createSignal<boolean>(true);
    const form = useFormState<NewActivityFormValues>();

    return (
        <div class={"sticky top-0 pt-8"}>
            <header
                class={
                    "flex cursor-pointer items-center justify-between rounded-t-2 bg-light-gray-100 px-4 py-3"
                }
                onClick={() => setOpen(!open())}
            >
                <P class={"font-semibold"}>Resumen</P>
                <i class={`fas fa-chevron-${open() ? "up" : "down"}`} />
            </header>
            <section class={open() ? "block" : "hidden"}>
                <SidebarItem title={"Nombre de la actividad"}>
                    <div class={"rounded-2 bg-light-gray-100 p-2"}>
                        <P class={"text-light-gray-500"}>
                            {form.values.activityName || "Aún no existe nombre para la actividad"}
                        </P>
                    </div>
                </SidebarItem>
                <SidebarItem title={"¿Cuándo se realizará la actividad?"}>
                    {/* <ul class={"ml-2 list-disc space-y-2 pl-3"}>
                        <li>
                            <P>La actividad ocurre el 16-02-2024, durante todo el día.</P>
                        </li>
                        <li>
                            <P>La actividad ocurre el 16-02-2024, durante todo el día.</P>
                        </li>
                        <li>
                            <P>La actividad ocurre el 16-02-2024, durante todo el día.</P>
                        </li>
                    </ul> */}
                    <ActivityDateSummary />
                </SidebarItem>
                <SidebarItem title={"Responsables de la actividad"}>
                    <div class={"flex flex-wrap gap-2"}>
                        <P
                            class={
                                "py-o.5 rounded-full border border-light-gray-300 bg-light-gray-50 px-1 text-sm"
                            }
                        >
                            Carlos Contreras
                        </P>
                    </div>
                </SidebarItem>
                <SidebarItem title={"¿Cómo se debe completar la actividad?"}>
                    <P>Todos los responsables asignados deben completar la actividad</P>
                </SidebarItem>
                <SidebarItem title={"Tareas"} contentClass={"space-y-3"}>
                    <NewActivityTaskItem nonEditable />
                    <NewActivityTaskItem nonEditable />
                    <NewActivityTaskItem nonEditable />
                </SidebarItem>
                <SidebarItem title={"Notificaciones"} contentClass={"space-y-3"}>
                    <NewActivityNotificationItem nonEditable />
                    <P>
                        Todos los colaboradores seleccionados como responsables recibirán esta
                        notificación
                    </P>
                </SidebarItem>
            </section>
        </div>
    );
}

function SidebarItem(props: ParentProps<{ title: string; contentClass?: string }>) {
    return (
        <div class={"space-y-1 p-4"}>
            <P class={"text-sm text-light-gray-500"}>{props.title}</P>
            <div class={props.contentClass}>{props.children}</div>
        </div>
    );
}
