import { Capacitor } from "@capacitor/core";
import DefaultBrowser from "../../utils/plugins/DefaultBrowser";
import { createModalController, Modal } from "../ui/Modal";
import { JSX } from "solid-js";
import { P } from "../../utils/typography";
import { Button } from "../ui/components";
import { showInfoToast } from "../../utils/errorHandling";
import {
    describeSignInMethod,
    FrontSignInMethod,
    SignInMethodType,
} from "../../api/services/organization/signInMethods";
import { SignInCanceledError } from "../../api/services/auth/interface";

/** Workaround for https://github.com/firebase/firebase-js-sdk/issues/4256 */
export default function useMissingInitialStateWorkaround() {
    const modal = createModalController<void, boolean>();

    return {
        async onSignInCanceled(
            error: SignInCanceledError,
            method: FrontSignInMethod,
            retry: () => Promise<void>,
        ): Promise<void> {
            if (
                error.canceledByUser &&
                method.type === SignInMethodType.OIDC &&
                (await isProblematicBrowser()) &&
                (await modal.prompt())
            )
                await retry();
            // Just in case it's another bug, the user can tell customer support about this message
            else showInfoToast(`Inicio de sesión con ${describeSignInMethod(method)} cancelado`);
        },

        render(): JSX.Element {
            return (
                <Modal controller={modal}>
                    {() => (
                        <>
                            <Modal.Header>
                                <h1 class="text-display-sm">¿Necesitas ayuda?</h1>
                            </Modal.Header>
                            <div class="mt-3 flex flex-col gap-3">
                                <P class="font-medium">
                                    Creemos que te puede haber aparecido este error:
                                </P>
                                <div class="border border-black bg-white px-3 pb-10">
                                    <P>
                                        Unable to process request due to missing initial state. This
                                        may happen if browser sessionStorage is inaccessible or
                                        accidentally cleared.
                                    </P>
                                </div>
                                <P class="font-medium">
                                    Lo bueno es que reintentar lo soluciona en la mayoría de los
                                    casos.
                                </P>
                                <Button onClick={() => modal.closeWithAnswer(true)}>
                                    Reintentar
                                </Button>
                                <Button
                                    onClick={() => modal.closeWithAnswer(false)}
                                    bgStyle="text-only"
                                >
                                    Solo quería cancelar el inicio de sesión
                                </Button>
                            </div>
                        </>
                    )}
                </Modal>
            );
        },
    };
}

async function isProblematicBrowser(): Promise<boolean> {
    // At least Safari with default config (i.e. with tracking protections) triggers this error sometimes
    if (Capacitor.getPlatform() === "ios") return true;

    // Android Firefox systematically triggers this error
    if (Capacitor.getPlatform() === "android") {
        const { value: defaultBrowser } = await DefaultBrowser.getDefaultBrowser();
        if (defaultBrowser === "org.mozilla.firefox") return true;
    }

    return false;
}
