import { Show } from "solid-js";
import ProfileCardContent from "./ProfileCardContent";
import { AuthenticatedUser } from "../auth/useAuthReturn";
import { useAuth } from "../auth/authContext";
import { PageWrapper } from "../ui/pageWrappers";
import { parsedEnv } from "../../utils/parsedEnv";
import Accordion, { AccordionItem } from "../ui/Accordion";
import { useLocale } from "../i18n/context";

export default function ProfilePage() {
    const { user } = useAuth();

    return (
        <PageWrapper padding="none">
            <Show when={user()}>{user => <Profile user={user()} />}</Show>
        </PageWrapper>
    );
}

function Profile(props: { user: AuthenticatedUser }) {
    const [locale] = useLocale();

    return (
        <div class="h-full bg-white">
            <div class="py-3">
                <ProfileCardContent />
            </div>
            <Show when={parsedEnv.MODE === "development"}>
                <Accordion>
                    <AccordionItem
                        summary={locale().utils.showTechnicalDetails}
                        summaryOpen={locale().utils.hideTechnicalDetails}
                    >
                        <pre class="overflow-scroll px-3 font-mono">
                            {JSON.stringify(props.user, null, 4)}
                        </pre>
                    </AccordionItem>
                </Accordion>
            </Show>
        </div>
    );
}
