import Toastify from "toastify-js";
import SubmitButton from "../forms/SubmitButton";
import { createForm } from "../forms/state";
import EmailField from "../forms/fields/EmailField";
import { useLocale } from "../i18n/context";
import { FormWrapper } from "../forms/FormWrapper";
import { createSendInviteMutation } from "../../api/services/auth/mutations";

type CreateInviteFormValues = {
    email: string;
};
export default function InvitePage() {
    const form = createForm<CreateInviteFormValues>();
    const [locale] = useLocale();
    const t = () => locale().auth;
    const sendInviteMutation = createSendInviteMutation();

    const handleSubmit = async (values: CreateInviteFormValues) => {
        await sendInviteMutation.mutateAsync(values.email);
        form.reset();
        Toastify({
            text: t().emailLinkSent,
        }).showToast();
    };

    return (
        <div class="container m-10 px-10">
            <FormWrapper
                staticForm={form}
                class="flex w-full max-w-160 flex-col items-stretch gap-4"
                onSubmit={handleSubmit}
            >
                <InviteHeaderSection />
                <InviteFormSection />
            </FormWrapper>
        </div>
    );
}

function InviteHeaderSection() {
    const [locale] = useLocale();
    const t = () => locale().auth;

    return <div>{t().createInviteDescription}</div>;
}

function InviteFormSection() {
    const [locale] = useLocale();
    const t = () => locale().auth;

    return (
        <section class="flex flex-col gap-4">
            <EmailField name="email" label={t().email} placeholder={"email@email.com"} />
            <SubmitButton size="lg" class="w-80">
                {t().sendEmail}
            </SubmitButton>
        </section>
    );
}
