export function CircleNumber(props: { number: number }) {
    return (
        <span
            class={
                "flex h-5 w-5 items-center justify-center rounded-full border border-light-gray-300 text-xs text-primary-800"
            }
        >
            {props.number}
        </span>
    );
}
