import { Button } from "../ui/components";
import { useLocale } from "../i18n/context";
import VersionNumber from "../../utils/VersionNumber";
import { useAuth } from "./authContext";
import LanguageSelector from "../i18n/LanguageSelector";
import { P } from "../../utils/typography";

export default function LoginDemoPage() {
    const [locale] = useLocale();
    const auth = useAuth();

    return (
        <div class="center-items h-screen flex-col gap-4">
            <div class="fixed right-2 top-1 min-w-32">
                <LanguageSelector />
            </div>
            <h1 class="text-center text-display-lg font-thin sm:text-display-2xl">
                Sandbox AIM&nbsp;Manager&nbsp;X
            </h1>
            <P>
                Bienvenido a nuestra app de test interno.
                <br />
                Esta pantalla no aparecerá en la versión final.
            </P>
            <Button onClick={auth.openLoginPage}>{locale().auth.continue}</Button>
            <div class="fixed bottom-0 right-24 pe-1">
                <VersionNumber />
            </div>
        </div>
    );
}
