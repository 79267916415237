import { useLocale } from "../../i18n/context";
import { StringField } from "./abstract";

import { FieldProps } from "./parts/types";

export default function PasswordField(props: FieldProps<string, HTMLInputElement>) {
    const [locale] = useLocale();

    return (
        <StringField type="password" requiredMessage={locale().auth.passwordRequired} {...props} />
    );
}
