import { Component, createSignal, For, Show } from "solid-js";
import { useLocale } from "../i18n/context";
import { Button, GargamelButtonProps } from "../ui/components";
import googleIcon from "../../assets/imgs/google.svg";
import { Code, P, Strong } from "../../utils/typography";
import { useThrowToErrorBoundary } from "../../utils/solidjs";
import SubmitButton from "../forms/SubmitButton";
import { getApiInstance } from "../../api";
import {
    AlreadyInProgressError,
    NeedConfirmError,
    OutsideOrganizationError,
    PopupBlockedError,
    SignInCanceledError,
    WrongEmailFormatError,
    WrongPasswordError,
} from "../../api/services/auth/interface";
import EmailField from "../forms/fields/EmailField";
import PasswordField from "../forms/fields/PasswordField";
import { Dynamic } from "solid-js/web";
import { createSendSignInLinkToEmailMutation } from "../../api/services/auth/mutations";
import { createForm, useFormState } from "../forms/state";
import { FormWrapper } from "../forms/FormWrapper";
import { Preferences } from "@capacitor/preferences";
import { FrontOrganization } from "../../api/services/organization/interface";
import { ErrorAlert } from "../../utils/components/ErrorAlert";
import { Capacitor } from "@capacitor/core";
import { showErrorToast } from "../../utils/errorHandling";
import { useNeedConfirmWorkaround } from "./useNeedConfirmWorkaround";
import {
    describeSignInMethod,
    EmailSignInMethod,
    FederatedSignInMethod,
    SignInMethodType,
} from "../../api/services/organization/signInMethods";
import useMissingInitialStateWorkaround from "./useMissingInitialStateWorkaround";
import usePopupBlockedWorkaround from "./usePopupBlockedWorkaround";
import useAlreadyInProgressWorkaround from "./useAlreadyInProgressWorkaround";
import { usePopupCancelDelayWorkaround } from "./usePopupCancelDelayWorkaround";

const buttonWidth = "20rem";

type FederatedMethodListProps = {
    federatedMethods: FederatedSignInMethod[];
    organization: FrontOrganization;
    defaultEmail: string | undefined;
    onGoBack: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FederatedWidgetProps<T extends FederatedSignInMethod["type"] = any>
    extends FederatedMethodListProps {
    signInMethod: FederatedSignInMethod & { type: T };
    signInWithErrorHandling: (naiveSignIn: () => Promise<void>) => Promise<void>;
}

export function FederatedMethodList(props: FederatedMethodListProps) {
    const [locale] = useLocale();
    const accountLinkingWorkaround = useNeedConfirmWorkaround();
    const missingInitialStateWorkaround = useMissingInitialStateWorkaround();
    const popupBlockedWorkaround = usePopupBlockedWorkaround();
    const alreadyInProgressWorkaround = useAlreadyInProgressWorkaround();
    usePopupCancelDelayWorkaround();
    const [outsideOrganization, setOutsideOrganization] = createSignal(false);
    const signInWithErrorHandling = async (
        naiveSignIn: (
            organization: FrontOrganization,
            defaultEmail: string | undefined,
        ) => Promise<void>,
        method: FederatedSignInMethod,
    ) => {
        const retry = () => signInWithErrorHandling(naiveSignIn, method);

        setOutsideOrganization(false);
        try {
            await popupBlockedWorkaround.beforeSignIn(method);
            await accountLinkingWorkaround.beforeSignIn(method);
            await naiveSignIn(props.organization, props.defaultEmail);
            await accountLinkingWorkaround.afterSignIn(method);
        } catch (e) {
            if (e instanceof PopupBlockedError)
                await popupBlockedWorkaround.onPopupBlockedError(e, method);
            else if (e instanceof SignInCanceledError)
                await missingInitialStateWorkaround.onSignInCanceled(e, method, retry);
            else if (e instanceof OutsideOrganizationError) setOutsideOrganization(true);
            else if (e instanceof NeedConfirmError)
                await accountLinkingWorkaround.onNeedConfirmError(e);
            else if (e instanceof AlreadyInProgressError)
                await alreadyInProgressWorkaround.onAlreadyInProgressError(e, method);
            else throw e;
        }
    };

    return (
        <div class="flex flex-col gap-4">
            {missingInitialStateWorkaround.render()}

            {accountLinkingWorkaround.render(props.organization)}
            <Show when={outsideOrganization()}>
                <ErrorAlert text={locale().auth.youAreNotInOrganization(props.organization.name)} />
            </Show>

            <For each={props.federatedMethods}>
                {method => (
                    <FederatedWidgetDispatcher
                        signInMethod={method}
                        signInWithErrorHandling={naiveSignIn =>
                            signInWithErrorHandling(naiveSignIn, method)
                        }
                        {...props}
                    />
                )}
            </For>
        </div>
    );
}

function FederatedWidgetDispatcher(props: FederatedWidgetProps) {
    function widget(method: FederatedSignInMethod): Component<FederatedWidgetProps> {
        switch (method.type) {
            case SignInMethodType.GOOGLE:
                return GoogleWidget;
            case SignInMethodType.SAML:
                return SamlWidget;
            case SignInMethodType.OIDC:
                return OidcWidget;
            case SignInMethodType.UNKNOWN:
                return NotImplementedWidget;
        }
    }

    return <Dynamic component={widget(props.signInMethod)} {...props} />;
}

export function DynamicEmailMethodsForm(props: {
    emailMethods: EmailSignInMethod[];
    organization: FrontOrganization;
    defaultEmail: string | undefined;
    onMagicLinkSent: (email: string) => void;
    onContinueWithPassword: (organization: FrontOrganization, email: string) => void;
}) {
    const magicLinkEnabled = () =>
        props.emailMethods.some(method => method.type === SignInMethodType.MAGIC_LINK);
    const passwordEnabled = () =>
        props.emailMethods.some(method => method.type === SignInMethodType.PASSWORD);

    return (
        <Show when={magicLinkEnabled()} fallback={<EmailAndPasswordForm {...props} />}>
            <EmailWithMagicLinkForm passwordEnabled={passwordEnabled()} {...props} />
        </Show>
    );
}

function EmailWithMagicLinkForm(props: {
    passwordEnabled: boolean;
    organization: FrontOrganization;
    defaultEmail: string | undefined;
    onMagicLinkSent: (email: string) => void;
    onContinueWithPassword: (organization: FrontOrganization, email: string) => void;
}) {
    const [locale] = useLocale();
    const form = createForm<{ email: string }>();
    const sendMagicLinkMutation = createSendSignInLinkToEmailMutation();

    function handleSubmitPassword(formValues: { email: string }): void {
        props.onContinueWithPassword(props.organization, formValues.email);
    }

    async function handleSubmitMagicLink(formValues: { email: string }): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            showErrorToast(
                "Lo sentimos, los vínculos mágicos no están disponibles en la app móvil aún.",
            );
            return;
        }

        await sendMagicLinkMutation.mutateAsync({
            organization: props.organization,
            email: formValues.email,
        });
        await Preferences.set({ key: "email", value: formValues.email });
        props.onMagicLinkSent(formValues.email);
    }

    return (
        <FormWrapper
            staticForm={form}
            onSubmit={props.passwordEnabled ? handleSubmitPassword : handleSubmitMagicLink}
            class={"max-w-full"}
        >
            <EmailField
                name="email"
                label={locale().auth.email}
                placeholder={locale().auth.emailPlaceholder}
                defaultValue={props.defaultEmail}
                parentClass="mb-4"
            />
            <Show when={!props.passwordEnabled}>
                <SubmitButton
                    style={{ width: buttonWidth, "max-width": "100%" }}
                    submittingText={locale().auth.continuingWithMagicLink}
                >
                    {locale().auth.continueWithMagicLink}
                </SubmitButton>
            </Show>
            <Show when={props.passwordEnabled}>
                <Button
                    type="submit"
                    style={{ width: buttonWidth, "max-width": "100%" }}
                    pending={false} // don't show spinner here when continuing with magic link
                >
                    {locale().auth.continueWithPassword}
                </Button>
                <Button
                    type="button"
                    style={{ width: buttonWidth, "max-width": "100%" }}
                    onClick={() => form.triggerSecondarySubmit(handleSubmitMagicLink)}
                    pending={sendMagicLinkMutation.isPending} // show spinner here when continuing with magic link
                    pendingText={locale().auth.continuingWithMagicLink}
                    class={"mt-4"}
                >
                    {locale().auth.continueWithMagicLink}
                </Button>
            </Show>
            <MagicLinkHelp />
        </FormWrapper>
    );
}

function MagicLinkHelp() {
    const [locale] = useLocale();
    const t = () => locale().auth;
    return (
        <div
            class="mt-4 flex max-w-full gap-x-2 rounded-md border border-light-gray-700 bg-light-gray-200 p-4"
            style={{ width: buttonWidth }}
        >
            <i class="fas fa-magic mt-2 text-light-gray-700" />
            <P class={"!mb-0"}>{t().magicLinkParagraph}</P>
        </div>
    );
}

type EmailPass = { email: string; password: string };

export function EmailAndPasswordForm(props: {
    organization: FrontOrganization;
    defaultEmail: string | undefined;
}) {
    const [locale] = useLocale();
    const t = () => locale().auth;
    const throwToErrorBoundary = useThrowToErrorBoundary();
    const form = createForm<EmailPass>();
    const emailField = form.getFieldController("email");
    const passwordField = form.getFieldController("password");
    const handleSubmit =
        (organization: FrontOrganization) =>
        async ({ email, password }: EmailPass): Promise<void> => {
            try {
                const api = getApiInstance();
                await api.auth.signInWithEmailAndPassword(organization, email, password);
            } catch (error) {
                if (error instanceof WrongPasswordError)
                    passwordField.setBackendErrorMessage(t().wrongPassword);
                else if (error instanceof WrongEmailFormatError)
                    emailField.setBackendErrorMessage(t().invalidEmail);
                else throwToErrorBoundary(error);
            }
        };

    return (
        <FormWrapper
            staticForm={form}
            onSubmit={handleSubmit(props.organization)}
            class="flex max-w-full flex-col gap-4"
        >
            <EmailField
                name="email"
                label={t().email}
                placeholder={t().emailPlaceholder}
                defaultValue={props.defaultEmail}
            />
            <div class={"flex flex-col"}>
                <PasswordField name="password" label={t().password} />

                <div class="self-end">
                    <ForgotPassword organization={props.organization} />
                </div>
            </div>
            <SubmitButton
                style={{ width: buttonWidth }}
                class={"max-w-full"}
                submittingText={t().signingIn}
            >
                {t().signIn}
            </SubmitButton>
        </FormWrapper>
    );
}

function ForgotPassword(props: { organization: FrontOrganization }) {
    const [locale] = useLocale();
    const t = () => locale().auth;
    const form = useFormState<EmailPass>();
    const emailField = form.getFieldController("email");
    const throwToErrorBoundary = useThrowToErrorBoundary();

    const handleClick =
        (organization: FrontOrganization, message: string) => async (): Promise<void> => {
            // Edge case: clicked forgot password, but email field is invalid
            if (!emailField.updateValidation()) return;

            const api = getApiInstance();
            return api.auth
                .sendPasswordResetEmail(organization, emailField.value)
                .then(() => alert(message))
                .catch(throwToErrorBoundary);
        };

    return (
        <Button
            type="button"
            onClick={handleClick(props.organization, t().passwordResetEmailSent)}
            bgStyle="text-only"
            size="sm"
            class={"px-0"}
        >
            {t().forgotPassword}
        </Button>
    );
}

function FederatedSignInButton(props: GargamelButtonProps) {
    return (
        <Button
            {...props}
            bgStyle="outline"
            size="lg"
            style={{ width: buttonWidth }}
            class="max-w-full bg-white"
        >
            {props.children}
        </Button>
    );
}

function GoogleWidget(props: FederatedWidgetProps<SignInMethodType.GOOGLE>) {
    const [locale] = useLocale();
    const t = () => locale().auth;

    const api = getApiInstance();
    const naiveSignIn = () => api.auth.signInWithGoogle(props.organization, props.defaultEmail);

    return (
        <div class={"w-full"}>
            <FederatedSignInButton onClick={() => props.signInWithErrorHandling(naiveSignIn)}>
                <img src={googleIcon} alt="" class="h-6 w-6" />
                {t().loginWithGoogle}
            </FederatedSignInButton>
        </div>
    );
}

function SamlWidget(props: FederatedWidgetProps<SignInMethodType.SAML>) {
    const naiveSignIn = async () => {
        const api = getApiInstance();
        await api.auth.signInWithSaml(props.organization, props.defaultEmail, props.signInMethod);
    };

    return (
        <FederatedSignInButton onClick={() => props.signInWithErrorHandling(naiveSignIn)}>
            Iniciar sesión con {describeSignInMethod(props.signInMethod)}
        </FederatedSignInButton>
    );
}

function OidcWidget(props: FederatedWidgetProps<SignInMethodType.OIDC>) {
    const naiveSignIn = async () => {
        const api = getApiInstance();
        await api.auth.signInWithOidc(props.organization, props.defaultEmail, props.signInMethod);
    };

    return (
        <FederatedSignInButton onClick={() => props.signInWithErrorHandling(naiveSignIn)}>
            Iniciar sesión con {describeSignInMethod(props.signInMethod)}
        </FederatedSignInButton>
    );
}

function NotImplementedWidget(props: FederatedWidgetProps<SignInMethodType.UNKNOWN>) {
    const [locale] = useLocale();
    const t = () => locale().auth;

    return (
        <div style={{ width: buttonWidth }}>
            <P>
                {t().notImplementedWidget1}
                <Strong>{props.organization.name}</Strong>
                {t().notImplementedWidget2}
                <Code>{props.signInMethod.name}</Code>
                {t().notImplementedWidget3}
            </P>
        </div>
    );
}
