import { createEffect, createSignal } from "solid-js";
import { createRef } from "../../utils/reactRefs";
import { Button, GargamelButtonProps, Icon } from "./components";

export default function EditableName(props: {
    nameOnBackend: string;
    onRename: (name: string) => Promise<void>;
}) {
    const inputRef = createRef<HTMLInputElement>();
    const [pending, setPending] = createSignal(false);
    const [value, setValue] = createSignal("");
    createEffect(() => setValue(props.nameOnBackend));

    const rename = (name: string) => {
        if (name === props.nameOnBackend) return;
        setPending(true);
        props.onRename(name).finally(() => setPending(false));
    };

    return (
        <div class="flex flex-1 items-baseline">
            <form
                class="flex-1"
                onSubmit={event => {
                    event.preventDefault();
                    rename(inputRef.current!.value);
                }}
            >
                <input
                    name="name"
                    value={value()}
                    onInput={event => setValue(event.currentTarget.value)}
                    onBlur={event => rename(event.currentTarget.value)}
                    class="hide-lastpass w-full bg-transparent text-md font-medium"
                    ref={inputRef}
                />
            </form>
            <EditButton onClick={() => inputRef.current!.focus()} pending={pending()}>
                <Icon name="far fa-edit text-lg text-dark-gray-400" />
            </EditButton>
        </div>
    );
}

export function EditButton(props: GargamelButtonProps) {
    return (
        <Button bgStyle="text-only" {...props}>
            <Icon name="far fa-edit text-lg text-dark-gray-400" />
        </Button>
    );
}
