import { useLocale } from "../../i18n/context";
import { RutFormat, formatRut, validateRut } from "@fdograph/rut-utilities";
import { StringField } from "./abstract";

import { FieldProps } from "./parts/types";

export default function RutField(props: FieldProps<string, HTMLInputElement>) {
    const [locale] = useLocale();

    return (
        <StringField
            type="text"
            placeholder={locale().personalData.rutPlaceholder}
            format={value => formatRut(value.trim(), RutFormat.DOTS_DASH)}
            validate={value => validateRut(value) || locale().personalData.invalidRut}
            {...props}
        />
    );
}
