import { WorkflowElementType, WorkflowExecution } from "../../api/services/workflow/interface";
import { getApiInstance } from "../../api";
import { queryClient } from "../../api/utils";
import { useLocale } from "../i18n/context";
import { createExecuteWorkflowMutation } from "../../api/services/workflow/mutations";
import { Button } from "../ui/components";
import { createRetrieveWorkflowFormQuery } from "../../api/services/workflow/queries";
import { FormValues } from "../forms/state";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { FormWrapper } from "../forms/FormWrapper";
import { RenderDynamicFields } from "../FormBuilder/RenderDynamicForm";
import SubmitButton from "../forms/SubmitButton";
import type { ElementDetailProps } from "./WorkflowDetailPage";
import { Show, Suspense } from "solid-js";
import { Dynamic } from "solid-js/web";
import { StartEvent } from "./bpmn";
import Warning from "../ui/Warning";
import { useNavigate } from "@solidjs/router";
import { H2 } from "../../utils/typography";
import { ExecutionAudienceSection } from "./utils";

export function StartEventDetail(props: ElementDetailProps<StartEvent>) {
    const navigate = useNavigate();
    const onExecutionSuccess = (newExecution: WorkflowExecution) => {
        // Hack for workflows demo
        getApiInstance().notifications.handleNewWorkflowExecution(newExecution);
        queryClient.invalidateQueries({ queryKey: ["inbox"] });

        navigate(`/workflows/executions/${newExecution.id}`);
    };

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Dynamic
                component={
                    props.element.hasMessageEventDefinition()
                        ? ExecuteMessageStartEvent
                        : ExecuteVanillaStartEvent
                }
                {...props}
                onExecutionSuccess={onExecutionSuccess}
            />
        </Suspense>
    );
}

type ExecuteStartEventProps = ElementDetailProps<StartEvent> & {
    onExecutionSuccess: (newExecution: WorkflowExecution) => void;
};

function ExecuteVanillaStartEvent(props: ExecuteStartEventProps) {
    const [locale] = useLocale();
    const executeWorkflowMutation = createExecuteWorkflowMutation();

    const execute = async () => {
        const newExecution = await executeWorkflowMutation.mutateAsync({
            workflowId: props.workflow.id,
            startEventElementId: props.element.id,
            startEventType: WorkflowElementType.VanillaStartEvent,
        });
        props.onExecutionSuccess(newExecution);
    };

    return (
        <div>
            <ExecutionAudienceSection element={props.element} mode="viewer" />
            <Button
                onClick={execute}
                icon="fas fa-play"
                disabled={!props.workflow.is_active}
                disabledReason={locale().workflows.cannotExecuteInactiveWorkflow}
                pendingText={locale().workflows.executingWorkflow}
            >
                {locale().workflows.executeWorkflow}
            </Button>
        </div>
    );
}

function ExecuteMessageStartEvent(props: ExecuteStartEventProps) {
    const [locale] = useLocale();
    const executeWorkflowMutation = createExecuteWorkflowMutation();
    const formQuery = createRetrieveWorkflowFormQuery(() => props.element.formId);

    const handleExecute = async (formValues: FormValues) => {
        const newExecution = await executeWorkflowMutation.mutateAsync({
            workflowId: props.workflow.id,
            startEventElementId: props.element.id,
            startEventType: WorkflowElementType.MessageStartEvent,
            formValues: formValues,
        });
        props.onExecutionSuccess(newExecution);
    };

    return (
        <div>
            <ExecutionAudienceSection element={props.element} mode="viewer" />
            <H2>Formulario</H2>
            <Show when={!props.element.formId}>
                <Warning>{locale().workflows.configure.form.elementDoesntHaveIt}</Warning>
            </Show>
            <Show when={formQuery.data}>
                {form => (
                    <FormWrapper class="flex max-w-80 flex-col gap-3" onSubmit={handleExecute}>
                        <RenderDynamicFields fields={form().fields} />
                        <SubmitButton
                            icon="fas fa-play"
                            disabled={!props.workflow.is_active}
                            disabledReason={locale().workflows.cannotExecuteInactiveWorkflow}
                            submittingText={locale().workflows.executingWorkflow}
                        >
                            {locale().workflows.executeWorkflow}
                        </SubmitButton>
                    </FormWrapper>
                )}
            </Show>
        </div>
    );
}
