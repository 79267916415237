import { P } from "../../../utils/typography";
import { Modal, ModalController } from "../../ui/Modal";
import { Match, Switch } from "solid-js";
import { Button } from "../../ui/components";
import { FormWrapper } from "../../forms/FormWrapper";
import { createForm } from "../../forms/state";
import HTMLFileField from "../../forms/fields/HTMLFileField";
import { createBulkPeopleLoadMutation } from "../../../api/services/person/mutations";
import SubmitButton from "../../forms/SubmitButton";

type FileForm = {
    file: File;
};

export default function PeopleBulkLoadModal(props: {
    controller: ModalController<unknown, unknown>;
}) {
    const form = createForm<FileForm>();
    const hardcodedTenantId = "5bfa800d-9fea-4cef-8993-ae7524c40516";
    const uploadBulkMutation = createBulkPeopleLoadMutation(hardcodedTenantId);

    const stepConditions = () => ({
        1: uploadBulkMutation.status === "idle" || uploadBulkMutation.status === "error",
        2: uploadBulkMutation.status === "pending",
        3: uploadBulkMutation.status === "success",
    });

    async function onSubmit(values: FileForm) {
        const body = {
            file: values.file,
            tenant_id: hardcodedTenantId,
        };
        try {
            await uploadBulkMutation.mutateAsync(body);
            form.reset();
        } catch (error) {
            console.error(error);
            const field = form.getFieldController("file");
            field.setBackendErrorMessage(
                "Error al procesar archivo, revisa que esté en el formato correcto.",
            );
        }
    }

    return (
        <Modal controller={props.controller} containerClass={"flex flex-col gap-8"} size={"lg"}>
            <P class={"text-display-xs font-medium"}>Carga masiva de personas</P>

            <FormWrapper staticForm={form} onSubmit={onSubmit}>
                <div class={"pb-8"}>
                    <Switch>
                        <Match when={stepConditions()["1"]}>
                            <HTMLFileField
                                name={"file"}
                                labelClass={"mb-4"}
                                label={
                                    <>
                                        Para realizar una carga masiva de personas, puedes{" "}
                                        <a href={"!#"} download>
                                            descargar el archivo de muestra
                                        </a>
                                        .
                                    </>
                                }
                            />
                        </Match>

                        <Match when={stepConditions()["2"]}>
                            <P>Esto puede tardar algunos minutos...</P>
                        </Match>
                        <Match when={stepConditions()["3"]}>
                            <div
                                class={
                                    "rounded-1.5 border border-dark-gray-300 bg-dark-gray-200 p-4"
                                }
                            >
                                Se cargó correctamente el archivo.
                            </div>
                            <Button
                                bgStyle={"text-only"}
                                class={"ml-auto"}
                                onClick={() => {
                                    uploadBulkMutation.reset();
                                }}
                            >
                                Cargar otro archivo
                            </Button>
                        </Match>
                    </Switch>
                </div>

                <div class={"flex w-full justify-end gap-x-8"}>
                    <Button bgStyle={"outline"} class={"!border-dark-gray-500 text-dark-gray-500"}>
                        Cancelar
                    </Button>
                    <SubmitButton
                        // disabled={uploadBulkMutation.status === "pending" || !fileValue()}
                        submittingText={"Cargando..."}
                    >
                        Cargar archivo
                    </SubmitButton>
                </div>
            </FormWrapper>
        </Modal>
    );
}
