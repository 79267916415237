import { checklistQuestionToV3ChecklboxesInput } from "../../api/services/formbuilder/adapters/checklistQuestionToV3ChecklboxesInput";
import { RenderDynamicForm } from "../FormBuilder/RenderDynamicForm";
import { PageWrapper } from "../ui/pageWrappers";
import jsonData from "../../api/services/formbuilder/adapters/reporte-de-mantenimiento.json";
import { For } from "solid-js";
import { ChecklistPayload } from "../../api/services/workflow/interface";

export function MaintenanceChecklistPage() {
    return (
        <PageWrapper>
            <For
                each={checklistQuestionToV3ChecklboxesInput(
                    jsonData as unknown as ChecklistPayload,
                )}
            >
                {payload => <RenderDynamicForm fields={payload.fields} />}
            </For>
        </PageWrapper>
    );
}
