import { useLocale } from "../../i18n/context";
import { Tab } from "../../ui/Tabs";
import { RoleList } from "../RoleList";

export function RolesTab() {
    const [l] = useLocale();
    return (
        <Tab title={l().personalData.tabs.rolesTab}>
            <div class={"py-4"}>
                <RoleList />
            </div>
        </Tab>
    );
}
