import { Position, PositionService, sPosition } from "../interface";
import { makeServiceQuery } from "../../../utils";
import { array } from "superstruct";

export class MockPositionService implements PositionService {
    listPositions = makeServiceQuery({
        fetchJson: async () => this.mockPositions,
        responseSchema: array(sPosition()),
        deserialize: x => x,
    });

    private mockPositions: Position[] = [
        {
            id: "3843a46c-b93f-4e42-a4bd-13380dfc119c",
            title: "CEO",
            department: "Ltd",
            is_root: true,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: null,
            people: [
                "0b135ff3-2ed4-4d5c-a576-65d36179bebc",
                "ee86a7d5-c26f-4830-9e60-fd604fd59ec7",
                "b22c0625-29fb-4e5f-880f-8f36c26a6cc0",
                "c8fe92f7-91c4-4e00-bd45-d4bc4d716baf",
                "3cbe7798-fca6-4a15-ac64-cd71db30229f",
                "ff6722be-989c-408d-a644-fa8bffaf38fb",
                "3fc98269-8d26-459b-965d-a19b9ce2bc18",
                "8dd12002-fa7a-4f70-8ebb-28e710cd9d0c",
                "4b2c8e82-be51-40b2-860a-b089915436fe",
                "647d3d3b-c35c-455d-ab80-507698acee9a",
                "d68654b3-f3fc-4daa-aed5-b25453d55488",
                "690ca666-8d2d-4a3a-90c3-16651b10aa2b",
                "abce23de-be44-4222-ae50-bfff77f93661",
                "aad0793e-4872-40ea-afde-20d05905158e",
            ],
        },
        {
            id: "b2cf0ad7-8e7c-4269-80e6-ebde395581f1",
            title: "CFO",
            department: "Ltd",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "3843a46c-b93f-4e42-a4bd-13380dfc119c",
            people: [
                "9e102089-abff-44fb-88cc-d98665dfa3f1",
                "2d17594b-3715-44a1-98be-18c5054f35c2",
                "253ee1d4-6d8c-4eb2-bd61-cdcbbf7e6479",
                "1167ab5d-b673-4b65-a783-4fd19ac1e262",
                "c026ebab-4f99-4877-942f-07d5fc13af7c",
                "3a9d1c42-3819-4185-ab5d-dc78feaeb737",
                "721bd84c-ccbc-491e-a18a-73df8618196e",
                "6ed6aaf9-b8d3-4891-ace8-6c91b08d656d",
                "b4d2511a-2b4b-4382-8821-1a68ea064d71",
                "de633935-14c3-4eae-a3d2-d193a8c672f3",
                "bb5bf0dd-3f09-461c-aea8-a84815ec2973",
                "1aa6bb6e-9b8f-4321-959f-2fcff833606c",
            ],
        },
        {
            id: "30ec5194-abee-4717-a29d-5e55c54e3f4a",
            title: "CTO",
            department: "Inc",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "3843a46c-b93f-4e42-a4bd-13380dfc119c",
            people: [
                "1f4ddc58-478f-45e6-a5aa-491ba1c77808",
                "d8a1e59a-c187-40ba-b5da-997e74b94f4c",
                "5b9be03b-7793-4e21-ab93-675307815643",
                "c2f49f93-2855-4f34-b3e2-ae74b56ec895",
                "39623964-dde0-4187-a7e0-deaac6ed0f9a",
                "27511e32-9343-485b-913b-e115ca1ccd46",
                "1fd3cc2f-656c-4a4b-936c-5bb3d5b7a37a",
                "410ad030-fba6-49c5-8cba-69f7b7067aa5",
                "6af3d3ba-e99d-4ed1-8e3e-9e233f65155b",
                "debd2c0e-fc70-4d92-aa8a-f210c058cb65",
                "91786618-8423-485d-a81a-234c86a524d6",
                "4aeff30a-e33a-4ade-af11-267c55a5cdb3",
                "a3af47b9-6b80-427f-a19e-1e6da096b86b",
                "d4554cb0-8983-4c1f-8bca-cccc9ce09797",
                "262bb102-d38d-4861-aa9d-2606f706850d",
                "817848fa-1fca-4062-9477-e07cd30e8b19",
            ],
        },
        {
            id: "9c2d1b7d-541c-4c38-8e70-2d3e460bc283",
            title: "COO",
            department: "Ltd",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "3843a46c-b93f-4e42-a4bd-13380dfc119c",
            people: [
                "15441846-9b07-4e51-ba5b-cb6189fa029c",
                "93e3cec0-c9c5-4f89-898f-088a275fc9f7",
                "5696b1ac-8cc9-42a7-993d-4b0f4bad2548",
                "36662823-3f00-46fb-b819-9d01326d273b",
                "fc21bfcf-e032-41e1-a3a5-9d1f6891cbc7",
                "0ceeef58-e124-4759-836e-3a4d42dfa746",
                "df90192a-bd70-44b6-80b7-ac31dd45fb80",
                "54f53056-556c-4fd5-bf9b-eb36e1e24354",
                "20cc8106-bc33-4d35-88a4-3c1a681ececa",
                "844e6599-99fe-4357-8d45-308d7d55bdd6",
                "5ba08a4b-2f9c-49b9-a7d8-42928e73b97a",
                "3e674eaa-8fb6-4dd4-ab11-d88637ba0cab",
            ],
        },
        {
            id: "1d7ca103-5cf9-42b9-a249-1b1366fa1719",
            title: "Finance Head",
            department: "PLC",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "b2cf0ad7-8e7c-4269-80e6-ebde395581f1",
            people: [
                "244bab6d-b6df-4e0b-8285-69450c0d9ea9",
                "16c9cfbc-89c3-41d8-a782-23dfb3cfd3bf",
                "bc5ab224-8041-4b84-9b24-94c5827be93a",
                "2d4e2cc5-3fc4-4ea4-9b61-787a54c52caa",
                "13eace0c-fc65-469b-ad6d-29e7a38b081d",
                "050b779e-73b2-4dd0-a5da-971afdc3ed13",
                "15582ecf-84c6-4bbc-b0d9-cbeac080c654",
                "82b240da-521d-4938-9d61-762fd96f1441",
                "14199ebe-4f05-4a4a-a2c7-fdc66da74901",
            ],
        },
        {
            id: "bf07b897-3ea6-4a39-b4ec-54378f1430bf",
            title: "IT Head",
            department: "and Sons",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "30ec5194-abee-4717-a29d-5e55c54e3f4a",
            people: [
                "4e95c88f-f3f9-4ff1-a619-1296c4f80b7d",
                "e6a6f332-5206-4fa1-bea5-3ea250f428b9",
                "544636d0-4d88-4ab8-8b0a-9268333a9baa",
                "a44a7e23-2008-4751-86da-5ffee57e0bdd",
                "1206dfd1-cc9d-4258-b138-117bcba196a1",
                "c983b6f8-8c4f-496c-ad24-b0d0ae8d1da8",
                "fbdd4528-25d2-47fa-a431-d434be7cae2e",
                "a9dd5da4-38fc-4486-8401-31b680f87bf7",
                "16b68565-c8dd-46b8-99ab-81eaf35249da",
                "fc517a31-1cc6-4fc8-af43-e6fe5c60297b",
                "10edd4a6-97e0-482c-bae9-84a50f1f547e",
                "0ec01599-70a7-4098-b305-c6970c5bbc28",
                "0808bb1e-4515-4023-bb95-c54718ea8d45",
                "4d7acb63-da66-43a5-b55a-83f3d45f07d5",
                "f623134e-5f3d-41d7-b47a-609006782466",
                "89576578-4ac3-49ec-9225-eda217efcf57",
                "ba1b58bd-dc87-49ea-aa3a-c084b5a51961",
                "ad53b79c-1229-4751-82c5-15038d2d59f5",
                "445f4264-3ec9-41ec-924f-f158ff6e38ec",
                "cd132f35-864e-4d8a-a5e9-ecca951c0842",
                "2b55ec42-fdf7-4854-8271-df88972d3f39",
            ],
        },
        {
            id: "477dd9f0-ca1d-48b6-8359-4c09d63e2404",
            title: "Operations Head",
            department: "Inc",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "9c2d1b7d-541c-4c38-8e70-2d3e460bc283",
            people: [
                "9cb9efd5-ec0d-47a5-865c-27ed5ce00f81",
                "3546c356-4aa7-403e-8c8b-9c955297ac42",
                "2ff956f0-5798-4677-9945-be20f113a3a5",
                "ffec2b11-8fbe-430e-852c-e8d8a815f252",
                "23ee3f01-6a64-4a71-b504-f0b3709f4db8",
                "5baf94fa-7e54-4653-ba54-ac16f29cee81",
                "93f203ad-8837-499a-9563-21e1e38ea0cc",
                "30039894-b1a8-43a6-b623-e85758e32ab1",
                "e27b9019-89ad-4271-a6d7-e4b50aeaccab",
                "d7c87a7d-3022-4a06-b7f7-ba23c0b45611",
                "54786974-d713-427f-81a1-281da40da869",
                "9396d6d4-7ee0-45e9-9690-3665d0f21615",
                "efbc0698-e8b2-4df8-9113-75ea96b1ec89",
            ],
        },
        {
            id: "2f4441bf-d16d-42f0-b2f4-fe008f8deb39",
            title: "Finance Manager",
            department: "Group",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "1d7ca103-5cf9-42b9-a249-1b1366fa1719",
            people: [
                "202c8249-8314-4e3e-96ab-e1e4e55bdeeb",
                "e58a522f-9ea9-4289-bd03-cf83e228bc2e",
                "9ca16c19-3367-4b0e-990f-6aded010d22e",
                "3cc0edbb-35d3-4f2e-b3c3-23499dae26d2",
                "54edccd0-9dd7-4da5-8a80-a705844eebde",
                "2ee3345c-aa21-4457-bd93-20e3135267fa",
                "98a563b7-064e-4aa4-b63a-85ff7011dd52",
                "edb6a90e-5319-4e80-b7ac-4bdb5dfa3615",
                "9606566a-a048-4fdd-a0bb-83fe6984efaf",
                "1b8c1f3b-510a-4778-8af7-02ab2a5b35b2",
                "fd3b1fe8-769c-4fd7-984c-d768e3f214d6",
                "3e666bc4-c517-444e-b730-ce78d844edfc",
                "ee44fb56-5135-4497-a0d8-43e93c1f27d7",
                "ef948f78-7049-4639-8d75-a9f979ea3643",
            ],
        },
        {
            id: "20adba34-ff52-48fb-9a5b-6f86213596aa",
            title: "Accountant",
            department: "Inc",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "1d7ca103-5cf9-42b9-a249-1b1366fa1719",
            people: [
                "af51e7ea-ba48-4704-bbb1-c1028b735212",
                "bdac909d-8ab0-4abf-ace8-35b1c75c6784",
                "e883a151-7bd3-41c4-9156-ca61e47d0203",
                "64d03ae2-9197-41e9-b23c-274696f6e9cc",
                "089029d1-aa56-4bb4-8c35-784615b5a30a",
                "03c1c2a5-1990-439b-9912-101578c5f3f7",
                "27302f93-00f8-44f5-bee2-6882ca1e687e",
            ],
        },
        {
            id: "948c3d26-9c74-4934-8d03-4028db91cf4f",
            title: "IT Manager",
            department: "LLC",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "bf07b897-3ea6-4a39-b4ec-54378f1430bf",
            people: [
                "912310a3-e142-49a4-93be-bdb4bc015b92",
                "79ea8ded-fc58-4506-a1f0-db3ed2d3fd23",
                "68900a7d-73ca-45fa-8ba2-552d454b3fa1",
                "f75293c3-3011-46a8-9510-f9432c7bd49e",
                "664fdb95-e1d4-4fb3-8819-f6631872b907",
                "fdbb6b44-afea-4186-8020-cf80a6e545d8",
                "43e4d2de-0d2a-4fd9-888b-88e71a07ad9a",
                "7ea747e1-52e3-46cf-a9d2-0538a8d0dc43",
                "bef81c3c-3d11-4bfa-80f1-648cc9f4de60",
                "7108812d-b982-443e-8dd9-72b1382729c5",
                "c039a7c1-35e4-4d17-baa5-31bd463e42b9",
                "1a2051a5-c4f2-4dc6-84a4-f2bd2e06435e",
                "80bd8439-4fa0-45f0-8b31-7af8b9795fa1",
                "34642d3c-496c-4c31-996c-df212e1f0ea0",
                "8e32222f-02ea-4d78-8977-a5c43ab4db58",
                "c735607e-49dc-4960-bfa9-5cc95adee8bd",
                "7e2bff20-3320-4eae-9d63-4d357e05b44f",
                "f6f9c6d4-58b2-4c13-a710-3a81e5e8108e",
                "3710b8b2-b08a-4374-9c81-aa8eca57153a",
            ],
        },
        {
            id: "5bdece25-cb08-4426-a92b-985530ec829b",
            title: "Software Developer",
            department: "LLC",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "bf07b897-3ea6-4a39-b4ec-54378f1430bf",
            people: [
                "b34215c6-9557-4edc-a190-4248788b639f",
                "8d77f9ce-0c35-4604-ace7-dbe32ecce5e0",
                "882241d5-94d5-4eb4-936d-b7f1fd040d0f",
                "3a27f0e3-a2fa-45a8-9820-5ffa22fc01ca",
                "50f2d101-99ae-4868-80bc-9a0bc20a638a",
                "638a4c77-389a-4db8-9f84-cc03234bffc0",
                "553ff5d2-51e1-49fa-b6eb-ace481c3c318",
                "da067306-d754-405c-9158-9a7fe0109d96",
                "f0b55943-ed7e-4801-8c9a-ce0b72e55810",
                "6d2fdf88-e25c-4d0c-b3a7-23522f24f337",
                "b09e50cb-84c5-4286-8499-8945d30a345d",
            ],
        },
        {
            id: "fbbc03ea-2c10-4187-af7d-acf6879b96ec",
            title: "Operations Manager",
            department: "Group",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "477dd9f0-ca1d-48b6-8359-4c09d63e2404",
            people: [
                "e4b5407b-1514-4453-96c0-af97d3db4a8e",
                "83682ef0-33f9-4ccc-a434-41787f8b0c03",
                "9f876cba-defd-474e-a0b6-2234ace8d31c",
                "f0083394-f1cc-4854-a988-eb441198d298",
                "8c66862b-cc0b-4ddb-8428-f2d5dac949c1",
                "ad9618a9-4fc9-4207-af93-398a4e8d4b59",
                "494d73fa-e78d-4606-bbcf-7e472feb33d7",
                "61d0272f-87ae-4be6-85dd-23b5a41efd2b",
                "c71faaa3-200e-47a5-96d4-d91872f988dc",
                "e04f2757-b186-4703-9914-ac1174c43cc9",
                "edf7e2c5-760f-4aef-aaa4-b56959ecd522",
                "19fe4d9c-a09e-47f3-8f3e-b20636e7df80",
                "33594d14-2dad-4de3-98ca-9bd9cfc2a5fe",
                "4104aee5-da2f-4a74-a97f-683dc3e24ac5",
                "783d0328-adc3-4a7b-85d4-31b109822554",
                "3e725127-e649-4016-92fe-0972c287a3c8",
                "aeb58bb2-871d-4ceb-b641-107007e894c2",
                "9d992daa-0aa6-4eda-855d-0354766431d2",
            ],
        },
        {
            id: "0e1acdbc-478b-4fa4-adb0-d6ea81985c88",
            title: "Logistics Coordinator",
            department: "and Sons",
            is_root: false,
            organization: "9f693cda-66cc-4864-9176-130b1714d42c",
            tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            parent: "477dd9f0-ca1d-48b6-8359-4c09d63e2404",
            people: [
                "53992106-35af-42a1-b721-b882ebff3d5a",
                "acc7741f-b137-4738-a837-a3c98b684079",
                "a067cfbb-c538-4049-b438-7e537dc72f69",
                "d4d32037-1cfb-4c9a-b832-fbcae596af64",
                "3421adcb-e430-4da9-8bda-8e8468e7d2f3",
                "4d206cca-14b7-4bcd-a4ba-29622aacb3e7",
                "e37963e9-f2cc-430b-b5a5-1aff2ae39e34",
                "2f905d47-3684-420b-b62b-20a3c93ff15d",
                "45ead6f1-f047-4a96-862c-08bc5847c439",
                "0e6d0ade-748b-4594-9609-5fc1eeb827be",
                "7803bd17-f217-4d98-8343-2273faba1622",
                "63ce27d2-30ce-4f59-bbb0-0a85cd9ea9d2",
                "0eebf899-f9b2-46b9-9f34-fae1bdf6c435",
                "29c38579-c0bc-443c-ba79-bf47b35a62c5",
            ],
        },
    ];
}
