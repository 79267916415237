import { useLocale } from "../../i18n/context";
import { SearchBar } from "../../ui/SearchBar";
import { Tab } from "../../ui/Tabs";
import { Button } from "../../ui/components";
import { DocumentsTable } from "../DocumentsTable";

export function DocumentsTab() {
    const [l] = useLocale();
    return (
        <Tab title={l().personalData.tabs.documentsTab}>
            <div class="flex justify-between py-4">
                <SearchBar variant="white" placeholder={l().personalData.searchDocuments} />
                <Button variant="primary" bgStyle="outline" class={"!font-normal"}>
                    {l().personalData.solicitNewDocument}
                </Button>
            </div>
            <DocumentsTable />
        </Tab>
    );
}
