import { Modal, ModalController } from "../../ui/Modal";
import { Button } from "../../ui/components";
import { FormWrapper } from "../../forms/FormWrapper";

export function LimitDateModal(props: { controller: ModalController<unknown, unknown> }) {
    return (
        <Modal controller={props.controller}>
            <FormWrapper class={"mt-3 flex flex-col gap-y-2"}>
                <div
                    class={"mt-3 flex justify-between gap-x-3 border-t border-light-gray-200 pt-3"}
                >
                    <Button class={"w-full"} bgStyle={"outline"} onClick={props.controller.dismiss}>
                        Cancelar
                    </Button>
                    <Button class={"w-full"} type={"submit"}>
                        Aplicar
                    </Button>
                </div>
            </FormWrapper>
        </Modal>
    );
}
