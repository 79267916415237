import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { onCleanup, onMount } from "solid-js";
import { getOrCreateRef } from "../../../utils/reactRefs";
import { StringField } from "./abstract";

import { FieldProps } from "./parts/types";

export default function IntlTelField(props: FieldProps<string, HTMLInputElement>) {
    const inputRef = getOrCreateRef(props.staticRef);

    let iti: intlTelInput.Plugin;
    onMount(() => (iti = initializePluginInstance(inputRef.current!)));
    onCleanup(() => destroyPluginInstance(iti));

    return (
        <StringField type="tel" {...props} staticRef={inputRef} serialize={() => iti.getNumber()} />
    );
}

function initializePluginInstance(inputElement: HTMLInputElement): intlTelInput.Plugin {
    const iti = intlTelInput(inputElement, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.js",
        initialCountry: "cl",
        preferredCountries: ["cl", "pe", "co", "mx", "sv"],
        placeholderNumberType: "MOBILE",
        separateDialCode: true,
    });
    document.querySelector(".iti")?.classList.add("w-full");
    inputElement.classList.add("w-full");
    return iti;
}

function destroyPluginInstance(iti: intlTelInput.Plugin | undefined): void {
    iti?.destroy();
}
