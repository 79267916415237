import { P } from "../../utils/typography";
import { Tabs } from "../ui/Tabs";
import NewActivityTasksTab from "./NewActivity/NewActivityTasksTab";
import NewActivityNotificationsTab from "./NewActivity/NewActivityNotificationsTab";
import { Button } from "../ui/components";
import { createActivityMutation } from "../../api/services/task-manager/mutations";
import { FormWrapper } from "../forms/FormWrapper";
import NewActivityConfigTab from "./NewActivity/NewActivityConfigTab";
import { createForm } from "../forms/state";
import { CreateActivityPayload, Priority } from "../../api/services/task-manager/interface";
import { Frequency, RRule } from "rrule";
import NewActivitySummary from "./NewActivity/NewActivitySummary";
import Toastify from "toastify-js";
import { useNavigate, useParams } from "@solidjs/router";

export type CommonNewActivityFormValues = {
    activityName: string;
    priority: number;
    startDate: string;
    description: string;
    tags: string[];
    audience: string;
    isGrouped: boolean;
    groupedType: string;
    completionTimes: string;
};

export type AllDay = {
    allDay: true;
};
export type NotAllDay = {
    allDay: false;
    startTime: string;
    endTime: string;
};
export type SingleDay = {
    moreThanOneDay: false;
};
export type MultipleDays = {
    moreThanOneDay: true;
    endDate: string;
};

export type RepeatActivity = {
    repeatActivity: true;
    repeatPeriod: Frequency;
    whenFinish: string; //Never, specific date, after x times
    until: string; // specific date
    repeatTimes: number;
};
export type NotRepeatActivity = {
    repeatActivity: false;
};

export type NewActivityFormValues = CommonNewActivityFormValues &
    (AllDay | NotAllDay) &
    (SingleDay | MultipleDays) &
    (RepeatActivity | NotRepeatActivity);

export default function CreateActivityPage() {
    const params = useParams<{ id?: string }>();

    const form = createForm<NewActivityFormValues>();
    const activityMutation = createActivityMutation();
    const navigate = useNavigate();
    const onSubmit = (values: NewActivityFormValues) => {
        if (params.id) {
            alert("EDIT ACTIVITY WIP");
        } else {
            activityMutation.mutate(newActivityFormToPayload(values), {
                onSuccess: data => {
                    console.log("Activity created");
                    Toastify({
                        text: "Actividad creada",
                    }).showToast();
                    navigate(`/activities/create/${data.id}`);
                },
                onError: error => {
                    console.error("Error creating activity", error);
                    Toastify({
                        text: "Error creando actividad",
                        backgroundColor: "red",
                    }).showToast();
                },
            });
        }
    };

    const onClickFakeFill = () => {
        form.setValues(values => {
            values.activityName = "Actividad de prueba";
            values.priority = Priority.OneUpArrow;
            values.startDate = "2024-07-07";
            values.moreThanOneDay = false;
            values.repeatActivity = true;
            values.description = "Descripción de la actividad de prueba";
            values.tags = [];
            values.audience = "Público";
            values.isGrouped = true;
            values.groupedType = "Grupal";
            values.completionTimes = "1";
        });
    };
    return (
        <div class={"relative min-h-screen md:px-8"}>
            <FormWrapper onSubmit={onSubmit} staticForm={form}>
                <div class={"grid min-h-screen grid-cols-12"}>
                    <div class={"relative col-span-8 h-full"}>
                        <div class={"sticky top-0 z-[3] bg-white pb-6 pt-8"}>
                            <P class={"font-semibold md:text-display-sm"}>Nueva actividad</P>
                        </div>

                        <Tabs
                            parentClass={"h-full"}
                            headerTabClass={"sticky top-[93px] z-[3] bg-white"}
                        >
                            <NewActivityConfigTab />
                            <NewActivityTasksTab />
                            <NewActivityNotificationsTab />
                        </Tabs>

                        <div
                            class={
                                "sticky bottom-0 z-[3] flex items-center justify-end gap-x-6 border-t border-light-gray-300 bg-white pb-4 pt-6"
                            }
                        >
                            <Button bgStyle={"outline"}>Guardar como borrador</Button>
                            <Button>Guardar y publicar</Button>
                            <Button bgStyle={"outline"} type="button" onClick={onClickFakeFill}>
                                Fake fill
                            </Button>
                        </div>
                    </div>
                    <div class={"col-span-4"}>
                        <NewActivitySummary />
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
}

export function newActivityFormToPayload(values: NewActivityFormValues): CreateActivityPayload {
    const startDate: Temporal.PlainDate = Temporal.PlainDate.from(values.startDate);
    let freq: Frequency | undefined = undefined;
    let count: number | undefined = undefined;
    let until: Date | undefined = undefined;
    let endDate: Temporal.PlainDate;
    let startTime: Temporal.PlainTime;
    let endTime: Temporal.PlainTime;

    if (values.allDay) {
        startTime = Temporal.PlainTime.from("00:00:00");
        endTime = Temporal.PlainTime.from("23:59:59");
    } else {
        startTime = Temporal.PlainTime.from(values.startTime);
        endTime = Temporal.PlainTime.from(values.endTime);
    }
    if (values.moreThanOneDay) {
        endDate = Temporal.PlainDate.from(values.endDate);
    } else {
        endDate = Temporal.PlainDate.from(values.startDate);
    }

    if (values.repeatActivity && values.repeatPeriod) {
        freq = values.repeatPeriod;
        if (values.whenFinish) {
            if (values.whenFinish == "afterXTimes" && values.repeatTimes) {
                count = values.repeatTimes;
            } else if (values.whenFinish == "specificDate" && values.until) {
                until = new Date(values.until);
            }
        }
    }

    const startDatetime = startDate.toPlainDateTime(startTime);

    const endDatetime = endDate.toPlainDateTime(endTime);

    const duration = endDatetime.since(startDatetime).round({ largestUnit: "day" });

    const rRule = new RRule({
        freq,
        until,
        count,
    });
    return {
        categories: values.tags || [],
        description: values.description,
        duration: duration.toString(),
        execution_audience: values.audience,
        name: values.activityName,
        priority: values.priority,
        rrule: rRule.toString(),
        start_at: values.startDate,
    };
}
