import Toastify from "toastify-js";

export function showSuccessToast(message: string, options?: Toastify.Options): void {
    Toastify({ text: message, close: true, ...options }).showToast();
}

export function showErrorToast(error: unknown, options?: Toastify.Options): void {
    Toastify({
        text: error?.toString() ?? "Error",
        duration: 10_000,
        close: true,
        ...options,
        style: { background: "#FF476F", ...options?.style },
    }).showToast();
}

export function showInfoToast(message: string, options?: Toastify.Options): void {
    Toastify({
        text: message,
        duration: 10_000,
        close: true,
        ...options,
        style: {
            color: "#1a2f33",
            background: "#b6e9f2",
            borderColor: "#bee5eb",
            ...options?.style,
        },
    }).showToast();
}
