import { Button } from "../ui/components";
import { Tabs } from "../ui/Tabs";
import { TaskTab } from "./detailPage/TaskTab";
import { DetailTab } from "./detailPage/DetailTab";
import { PageWrapper } from "../ui/pageWrappers";
import { Show, Suspense } from "solid-js";
import { createModalController } from "../ui/Modal";
import { TaskDetailModal } from "./modals/TaskDetailModal";
import { EndTaskModal } from "./modals/EndTaskModal";
import { createRef } from "../../utils/reactRefs";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { createActivityDetailQuery } from "../../api/services/task-manager/queries";
import { useParams } from "@solidjs/router";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { DetailActivityHeader } from "./detailPage/DetailActivityHeader";

export default function ActivitiesDetailPage() {
    const { sm } = useResponsiveBreakpoints();

    const taskDetailModalController = createModalController<void, void>();
    const endTaskModalController = createModalController<void, void>();
    const params = useParams<{ id: string; recurrenceId: string }>();
    const id = () => params.id;
    const recurrenceId = () => params.recurrenceId;
    const activityDetailQuery = createActivityDetailQuery(id, recurrenceId);
    // const recurrenceActivityQuery = createGetOrCreateRecurrenceActivityQuery(id, recurrenceId);
    // const recurrenceActivityId = () => recurrenceActivityQuery.data?.id;
    const ref = createRef<HTMLDialogElement>();
    return (
        <PageWrapper class={"bg-white !pb-0"}>
            <div class={"grid grid-cols-12 gap-x-6"}>
                <div
                    class={
                        "relative col-span-12 overflow-y-scroll md:col-span-8 md:max-h-[calc(100vh-130px)]"
                    }
                >
                    <Suspense fallback={<GenericSuspenseFallback />}>
                        <Show
                            when={activityDetailQuery.data}
                            fallback={<ErrorBlock error={activityDetailQuery.error} />}
                        >
                            {data => (
                                <>
                                    <DetailActivityHeader activity={data()} />
                                    <Tabs>
                                        <TaskTab
                                            activityId={id()}
                                            recurrenceId={recurrenceId()}
                                            modalController={taskDetailModalController}
                                        />
                                        <DetailTab activity={data()} />
                                    </Tabs>
                                </>
                            )}
                        </Show>

                        {/* <Show 
                            when={recurrenceActivityQuery.data}
                            fallback={<ErrorBlock error={recurrenceActivityQuery.error}/>}
                        >
                            {data => (
                                <>
                                    <P> RecurrenceActivity </P>
                                    <JsonDebug value={data()} />
                                </>
                            )}
                        </Show> */}
                    </Suspense>

                    <Show when={!sm()}>
                        <div class={`fixed bottom-24 left-0 z-dropdown w-full bg-white p-2`}>
                            <Button
                                variant={"primary"}
                                size={"lg"}
                                class={"w-full"}
                                onClick={() => {
                                    ref.current?.showModal();
                                    endTaskModalController.open();
                                }}
                            >
                                Finalizar actividad
                            </Button>
                        </div>
                    </Show>
                    <Show when={sm()}>
                        <div
                            class={
                                "sticky bottom-0 flex w-full justify-end gap-x-6 bg-white pb-4 pt-6"
                            }
                        >
                            <Button
                                variant={"primary"}
                                bgStyle={"outline"}
                                size={"lg"}
                                onClick={() => {
                                    ref.current?.showModal();
                                    endTaskModalController.open();
                                }}
                            >
                                Finalizar en otro momento
                            </Button>
                            <Button
                                variant={"primary"}
                                size={"lg"}
                                onClick={() => {
                                    console.log("Finalizar actividad");
                                }}
                            >
                                Finalizar actividad
                            </Button>
                        </div>
                    </Show>
                </div>
                <div class={"hidden md:flex"} />
            </div>

            <TaskDetailModal controller={taskDetailModalController} />
            <EndTaskModal controller={endTaskModalController} />
        </PageWrapper>
    );
}
