import { create, optional, string, type } from "superstruct";
import { createRetrieveWorkflowFormQuery } from "../../api/services/workflow/queries";
import {
    createUpdateWorkflowFormMutation,
    createWorkflowFormMutation,
} from "../../api/services/workflow/mutations";
import { useNavigate, useParams } from "@solidjs/router";
import { InputUnion } from "../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { PageWrapper } from "../ui/pageWrappers";
import { FormBuilderUI } from "../FormBuilder/FormBuilderUI";

/** Used to edit a {@link WorkflowForm}. */
export default function WorkflowFormBuilderPage() {
    const params = create(useParams(), type({ workflowId: string(), formId: optional(string()) }));
    const retrieveQuery = createRetrieveWorkflowFormQuery(() => params.formId);
    const createMutation = createWorkflowFormMutation();
    const updateMutation = createUpdateWorkflowFormMutation();
    const navigate = useNavigate();

    const saveForm = async (fields: InputUnion[]) => {
        if (params.formId) {
            await updateMutation.mutateAsync({
                formId: params.formId,
                fields,
                processVars: makeDefaultProcessVars(fields),
            });
        } else {
            const form = await createMutation.mutateAsync({
                fields,
                processVars: makeDefaultProcessVars(fields),
                workflowId: params.workflowId,
            });
            navigate(`/workflows/${params.workflowId}/form-builder/${form.id}`, { replace: true });
        }
    };

    return (
        <PageWrapper>
            <FormBuilderUI retrieveFormQuery={retrieveQuery} onSaveForm={saveForm} />
        </PageWrapper>
    );
}

/** While we don't support editing the {@link WorkflowForm} `processVars` yet,
 * just expose every field as a variable with the same name as the field. */
function makeDefaultProcessVars(fields: InputUnion[]): Record<string, string> {
    return Object.fromEntries(fields.map(field => [field.name, field.name]));
}
