import { any, array, boolean, Describe, enums, optional, string, Struct, type } from "superstruct";
import { Position, sPosition } from "../positions/interface";
import { Json, sPlainDateString } from "../../utils";
import { Group, sGroup } from "../groups/interface";

export interface LuchoPerson extends Omit<LuchoPersonJson, "birth_date" | "hire_date"> {
    birth_date?: Temporal.PlainDate;
    hire_date?: Temporal.PlainDate;
}

export type LuchoPersonJson = {
    id: string;
    passport: string;
    name: string;
    employee_id: string;
    phone_number?: string;
    address?: string;
    birth_date?: string;
    hire_date?: string;
    emails: Email[];
    identifiers: Identifier[];
    positions: Position[];
    preferred_notification_channels?: PreferredNotificationChannel[];
    unstructured_metadata?: Json;
    groups: Group[];
    tenant: string;
};

export function sLuchoPerson(): Struct<LuchoPersonJson> {
    return type({
        id: string(),
        passport: string(),
        name: string(),
        employee_id: string(),
        phone_number: optional(string()),
        address: optional(string()),
        birth_date: optional(sPlainDateString()),
        hire_date: optional(sPlainDateString()),
        emails: array(sEmail()),
        identifiers: array(sIdentifier()),
        positions: array(sPosition()),
        preferred_notification_channels: optional(array(sPreferredNotificationChannel())),
        unstructured_metadata: optional(any()),
        groups: array(sGroup()),
        tenant: string(),
    });
}

export function deserializeLuchoPerson(json: LuchoPersonJson): LuchoPerson {
    return {
        ...json,
        birth_date: json.birth_date ? Temporal.PlainDate.from(json.birth_date) : undefined,
        hire_date: json.hire_date ? Temporal.PlainDate.from(json.hire_date) : undefined,
    };
}

export type Email = {
    email: string;
    is_primary: boolean;
};

function sEmail(): Describe<Email> {
    return type({
        email: string(),
        is_primary: boolean(),
    });
}

export type Identifier = {
    type: string;
    value: string;
};

function sIdentifier(): Describe<Identifier> {
    return type({
        type: string(),
        value: string(),
    });
}

export enum PreferredNotificationChannel {
    EMAIL = "EMAIL",
    SMS = "SMS",
    PUSH = "PUSH",
    WHATSAPP = "WHATSAPP",
}

function sPreferredNotificationChannel(): Describe<PreferredNotificationChannel> {
    return enums(Object.values(PreferredNotificationChannel));
}
