import { IntermediateThrowEvent, Message } from "./bpmn";
import { FormWrapper } from "../forms/FormWrapper";
import SwitchField from "../forms/fields/SwitchField";
import TextField from "../forms/fields/TextField";
import { For, ParentProps, Show } from "solid-js";
import { Entry, RecordTable } from "../ui/RecordTable";
import TextAreaField from "../forms/fields/TextAreaField";
import { Mail, P } from "../../utils/typography";
import Dropdown from "../ui/Dropdown";
import { createForm } from "../forms/state";
import { Badge } from "../ui/components";
import Warning from "../ui/Warning";

export default function MessageTemplate(props: {
    message: Message;
    element: IntermediateThrowEvent;
    mode: "viewer" | "modeler";
}) {
    const labels = {
        push: "Enviar como notificación push",
        mail: "Enviar como correo electrónico",
        emails: "Correos destinatarios",
        title: "Asunto / título notificación",
        body: "Contenido del mensaje",
    };
    const bodyLabelWithHelp = (
        <span>
            {labels.body}
            <Help>
                HTML está soportado para los mensajes de correo usando un lenguaje de templates
                (consultar con Alan Rivas) en el cual se pueden insertar las process_vars.
            </Help>
        </span>
    );

    const form = createForm<Message>();

    return (
        <Show
            when={props.mode === "modeler"}
            fallback={
                <RecordTable>
                    <Entry name={labels.push} value={props.message.push ? "Sí" : "No"} />
                    <Entry name={labels.mail} value={props.message.mail ? "Sí" : "No"} />
                    <Entry
                        name={labels.emails}
                        value={
                            <div class="flex gap-1">
                                <For each={props.message.emails.split(",")}>
                                    {email => (
                                        <Badge>
                                            <Mail to={email} />
                                        </Badge>
                                    )}
                                </For>
                            </div>
                        }
                    />
                    <Entry name={labels.title} value={props.message.title} />
                    <Entry
                        name={labels.body}
                        value={<pre class="pt-1">{props.message.body}</pre>}
                    />
                </RecordTable>
            }
        >
            <FormWrapper
                staticForm={form}
                defaultValues={props.message}
                class="grid grid-cols-2 gap-5"
                onCleanup={message => props.element.setMessage(message)}
            >
                <div
                    class="col-span-2"
                    classList={{ invisible: form.values.mail || form.values.push }}
                >
                    <Warning>No se ha seleccionado un canal de envío.</Warning>
                </div>
                <SwitchField name="mail" label={labels.mail} />
                <SwitchField name="push" label={labels.push} />
                <TextField
                    name="emails"
                    label={labels.emails + " (separados por coma)"}
                    placeholder="alicia@empresa.com,bob@empresa.com"
                />
                <div class="col-start-1">
                    <TextField name="title" label={labels.title} />
                </div>
                <div class="col-span-2 col-start-1">
                    <TextAreaField name="body" label={bodyLabelWithHelp} />
                </div>
                <P class="col-span-2">
                    Para guardar los cambios, cierra este panel y presiona{" "}
                    <i class="fas fa-save mx-1" />
                    <span class="font-medium">Guardar cambios</span>
                </P>
            </FormWrapper>
        </Show>
    );
}

function Help(props: ParentProps) {
    return (
        <span class="ml-2 inline-block">
            {/* Alternative icons: https://fontawesome.com/v5/search?q=info&o=r */}
            <Dropdown trigger={onClick => <i class="fas fa-question-circle" onClick={onClick} />}>
                <div class="w-120 translate-x-2/3 rounded-1 bg-dark-gray-600 px-3 py-2 text-dark-gray-100">
                    {props.children}
                </div>
            </Dropdown>
        </span>
    );
}
