import { createApiQuery } from "../../utils";
import { Accessor } from "solid-js";

export function createHomePageQuery() {
    return createApiQuery(api => ({
        queryKey: ["homePage"],
        queryFn: () => api.home.retrieveHomePage(),
    }));
}

export function createPostsByChannelQuery(channelId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["postsByChannel", channelId()],
        queryFn: () => api.home.listPostsByChannel(channelId()!),
        enabled: channelId() !== undefined,
    }));
}

export function createWidgetConfigQuery(widgetConfigId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["widgetConfig", widgetConfigId()],
        queryFn: () => api.home.retrieveWidgetConfig(widgetConfigId()),
    }));
}
