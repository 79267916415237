import { createContext, createSignal, JSX, ParentProps, Show } from "solid-js";
import { useRequiredContext } from "../../utils/solidjs";
import { Button } from "./components";
import { P } from "../../utils/typography";

const AccordionContext = createContext();

export default function AccordionCard(props: ParentProps) {
    return <AccordionContext.Provider value={{}}>{props.children}</AccordionContext.Provider>;
}

export function AccordionCardItem(
    props: ParentProps<{ title: string | JSX.Element; class?: string; isOpen?: boolean }>,
) {
    useRequiredContext(AccordionContext, "AccordionItem", "Accordion");
    const [isOpen, setIsOpen] = createSignal(props.isOpen ?? false);
    const toggle = () => setIsOpen(x => !x);

    return (
        <div class={`space-y-2 border-b border-light-gray-200 pb-4 ${props.class}`}>
            <Button
                onClick={toggle}
                // icon={isOpen() ? "fas fa-caret-down" : "fas fa-caret-right"}
                bgStyle="text-only"
                class={"flex w-full items-center !justify-between rounded-xs bg-light-gray-100"}
            >
                <P class={"!mb-0 text-left text-black"}>{props.title}</P>
                {isOpen() ? (
                    <i class="fas fa-chevron-up text-light-gray-300"></i>
                ) : (
                    <i class="fas fa-chevron-down text-light-gray-300"></i>
                )}
            </Button>
            <Show when={isOpen()}>{props.children}</Show>
        </div>
    );
}
