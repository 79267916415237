import { PageWrapper } from "../ui/pageWrappers";
import PeopleBulkLoadHeader from "./bulk-load/PeopleBulkLoadHeader";
import { Tab, Tabs } from "../ui/Tabs";
import PeopleBulkLoadTable from "./bulk-load/PeopleBulkLoadTable";
import { SearchBar } from "../ui/SearchBar";

export default function PeopleBulkLoadPage() {
    return (
        <PageWrapper>
            <PeopleBulkLoadHeader />
            <Tabs parentClass={"mt-8"}>
                <Tab title={"20 registros importados correctamente"}>
                    <div class={"my-8 w-full md:w-80"}>
                        <SearchBar variant={"white"} />
                    </div>
                    <PeopleBulkLoadTable />
                </Tab>
                <Tab title={"4 registros con errores"} titleClass={"!text-error-500"}>
                    <div class={"my-8 w-full md:w-80"}>
                        <SearchBar variant={"white"} />
                    </div>
                    <PeopleBulkLoadTable />
                </Tab>
            </Tabs>
        </PageWrapper>
    );
}
