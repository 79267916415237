import { Tab } from "../../ui/Tabs";
import { ParentProps } from "solid-js";
import { ConfigInformation } from "./configTab/ConfigInformation";
import { ActivityDate } from "./configTab/ActivityDate";
import { ActivityResponsible } from "./configTab/ActivityResponsible";
import SwitchField from "../../forms/fields/SwitchField";
import RadioField from "../../forms/fields/RadioField";
import SelectField from "../../forms/fields/SelectField";
import { P } from "../../../utils/typography";
export default function NewActivityConfigTab() {
    return (
        <Tab title={"Configuración"}>
            <div class={"pt-12"}>
                <ConfigInformation />
                <ActivityDate />
                <ActivityResponsible />
                {/* <GroupSection />
                <CompleteActivity/> */}
                {/* <Button type={"submit"}>Guardar</Button> */}
            </div>
        </Tab>
    );
}

export function DottedSection(props: ParentProps<{ title: string; subTitle: string }>) {
    return (
        <section class="flex gap-4">
            <div class="flex flex-col items-center">
                <div class="flex h-8 w-8 items-center justify-center rounded-full border-4 border-[#E8DEFB] bg-primary-500 font-medium">
                    <div class={"h-2 w-2 rounded-full bg-white"} />
                </div>
                <div class="flex-1 border border-transparent border-l-light-gray-200" />
                <div class="h-4" />
            </div>
            <div class="w-full pb-12">
                <div class={"mb-6"}>
                    <h2 class="mb-1 mt-1 text-lg font-semibold">{props.title}</h2>
                    <h3>{props.subTitle}</h3>
                </div>
                <div class={"space-y-6"}>{props.children}</div>
            </div>
        </section>
    );
}

export function GroupSection() {
    return (
        <DottedSection
            title={"¿Cómo se debe completar la actividad?"}
            subTitle={
                "Seleccione los responsables de la actividad (revisar o eliminar texto) Este paso sale solo si los responsables asignados a la actividad es mayor a uno"
            }
        >
            <div>
                <SwitchField
                    name={"isGrouped"}
                    label={"Agrupar la actividad"}
                    labelClass={"flex flex-row-reverse justify-end"}
                />
                <P>
                    Al seleccionar esta opción, usted podrá agrupar las actividades por locales,
                    lineas de negocio, mercados, entre otras.
                </P>
            </div>

            <RadioField
                name={"groupedType"}
                options={[
                    ["1", "Todos compartiran la misma actividad"],
                    [
                        "2",
                        "Cada responsable debe realizar la actividad de forma individual e independiente",
                    ],
                ]}
            />

            <div class={"flex items-center gap-x-1"}>
                <P class={"!mb-0"}>La actividad debe ser realizada al menos</P>
                <SelectField
                    name={"completionTimes"}
                    parentClass={"!w-16"}
                    options={[
                        ["1", "1"],
                        ["2", "2"],
                        ["3", "3"],
                        ["4", "4"],
                        ["5", "5"],
                    ]}
                />
                <P class={"!mb-0"}>Veces</P>
            </div>
        </DottedSection>
    );
}
