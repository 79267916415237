import { P } from "../../utils/typography";
import { createIsEmailLinkQuery } from "../../api/services/auth/queries";
import { createEffect, createResource, Show } from "solid-js";
import { createSignInWithEmailLinkMutation } from "../../api/services/auth/mutations";
import { Preferences } from "@capacitor/preferences";
import EmailField from "../forms/fields/EmailField";
import { useLocale } from "../i18n/context";
import SubmitButton from "../forms/SubmitButton";
import { FormWrapper } from "../forms/FormWrapper";
import { LavandaCard } from "../ui/cards";
import { GradientBackgroundWrapper } from "../auth/SignInPage";

export default function CreateAccountFromInvite() {
    const [locale] = useLocale();
    const l = locale().auth;
    const link = () => window.location.href;
    const [storeEmail] = createResource(() =>
        Preferences.get({ key: "email" }).then(res => res.value),
    );
    const isEmailLinkQuery = createIsEmailLinkQuery(link);
    const signInWithLinkMutation = createSignInWithEmailLinkMutation();
    const loginWithEmail = (email: string) => {
        signInWithLinkMutation.mutate(
            {
                email: email,
                link: link(),
            },
            {
                onSuccess: () => {},
            },
        );
    };
    createEffect(() => {
        const email = storeEmail();
        if (isEmailLinkQuery.data && email) {
            loginWithEmail(email);
        }
    });

    const submit = (values: { email: string }) => {
        loginWithEmail(values.email);
    };
    return (
        <GradientBackgroundWrapper>
            <LavandaCard>
                <div>
                    <P class={"text-lg"}>Crear cuenta</P>
                    <Show when={!storeEmail()}>
                        <FormWrapper<{ email: string }> onSubmit={submit}>
                            <EmailField name="email" label={l.email} />
                            <SubmitButton submittingText={l.validatingEmail} class={"mt-3"}>
                                {l.validateEmail}
                            </SubmitButton>
                        </FormWrapper>
                    </Show>
                    <Show when={isEmailLinkQuery.data} fallback={<P>Invalid link</P>}>
                        <P>Es link de email </P>
                    </Show>
                </div>
            </LavandaCard>
        </GradientBackgroundWrapper>
    );
}
