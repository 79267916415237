import { nonCheckboxInputStyling, selectIcon } from "../presentation";
import { InputWrapper, ValidationIconWrapper } from "./parts/icons";
import { FieldProps } from "./parts/types";
import { createField } from "../state";
import { createSignal, createUniqueId } from "solid-js";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";

export default function DecimalField(
    props: FieldProps<string, HTMLInputElement> & { required?: boolean | string },
) {
    const field = createField(props, "");
    const inputId = createUniqueId();

    const [lastValidValue, setLastValidValue] = createSignal<string>("");
    const onChange = (
        e: Event & {
            currentTarget: HTMLInputElement;
            target: HTMLInputElement;
        },
    ) => {
        const value = e.target.value;
        if (!Number.isNaN(Number(value))) {
            setLastValidValue(Number(value).toFixed(4));
            field.setValue(Number(value).toFixed(4));
        } else {
            field.setValue(lastValidValue());
        }
    };

    return (
        <div>
            <InputWrapper icon={selectIcon}>
                <LabelAbove {...props} field={field} inputId={inputId}>
                    <ValidationIconWrapper field={field}>
                        <input
                            type={"number"}
                            {...getCommonInputProps(inputId, props)}
                            {...getInputValidationProps(field, props)}
                            {...nonCheckboxInputStyling}
                            value={field.value}
                            onChange={e => {
                                onChange(e);
                            }}
                        />
                    </ValidationIconWrapper>
                </LabelAbove>
            </InputWrapper>
        </div>
    );
}
