import { ActivityTask } from "../../../api/services/task-manager/interface";
import { createActivityFormQuery } from "../../../api/services/task-manager/queries";
import { ErrorBlock } from "../../../utils/GenericErrorBoundary";
import { JsonDebug, TechnicalDetails } from "../../../utils/debug";
import { InputUnion } from "../../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { createActivityFormResponseMutation } from "../../../api/services/task-manager/mutations";
import { FormValues } from "../../forms/state";
import { getApiInstance } from "../../../api";
import { serializeFormValues } from "../../../api/services/formbuilder/DynamicForm";
import { TaskCard } from "./TaskCard";
import { FormWrapper } from "../../forms/FormWrapper";
import { RenderDynamicFields } from "../../FormBuilder/RenderDynamicForm";
import { Button } from "../../ui/components";
import SubmitButton from "../../forms/SubmitButton";
import { createChecklistRetrieveQuery } from "../../../api/services/checklist/queries";
import { BorderedCard } from "../../ui/cards";
import { Code, P } from "../../../utils/typography";
import SupportParagraph from "../../../utils/SupportParagraph";
import { Match, Show, Switch } from "solid-js";
import { useParams } from "@solidjs/router";

export default function TaskCardDispatcher(props: { task: ActivityTask }) {
    return (
        <Switch fallback={<UnknownTaskCard task={props.task} />}>
            <Match when={props.task.content_type_model === "form"}>
                <FormTaskCard task={props.task} />
            </Match>
            <Match when={props.task.content_type_model === "checklist"}>
                <ChecklistTaskCard task={props.task} />
            </Match>
        </Switch>
    );
}

function FormTaskCard(props: { task: ActivityTask }) {
    const formQuery = createActivityFormQuery(() => props.task.object_id);

    return (
        <Show when={formQuery.data} fallback={<ErrorBlock error={formQuery.error} />}>
            {form => (
                <Show
                    when={form().fields.length !== 1}
                    fallback={<InputTaskCard input={form().fields[0]} task={props.task} />}
                >
                    <JsonDebug value={props.task} />
                    <JsonDebug value={form()} />
                </Show>
            )}
        </Show>
    );
}

function InputTaskCard(props: { input: InputUnion; task: ActivityTask }) {
    const params = useParams<{ id: string; recurrenceId: string }>();
    const mutation = createActivityFormResponseMutation();

    const save = async (formValues: FormValues): Promise<void> =>
        mutation.mutateAsync({
            recurrence_activity: (
                await getApiInstance().taskManager.getOrCreateRecurrenceActivity(
                    params.id,
                    params.recurrenceId,
                )
            ).id,
            task: props.task.id,
            form: props.task.object_id,
            response: await serializeFormValues(formValues),
        });

    return (
        <TaskCard
            title={props.input.label}
            description=""
            isReady={false}
            taskType={props.input.type}
            isOptional={!props.input.required}
            data={props.input}
        >
            {(input, close) => (
                <FormWrapper class="mt-5 flex flex-col items-center gap-10" onSubmit={save}>
                    <RenderDynamicFields fields={[input]} />
                    <div class="flex gap-3">
                        <Button onClick={close} bgStyle="outline">
                            Cancelar
                        </Button>
                        <SubmitButton submittingText="Guardando...">Guardar</SubmitButton>
                    </div>
                </FormWrapper>
            )}
        </TaskCard>
    );
}

function ChecklistTaskCard(props: { task: ActivityTask }) {
    const checklistQuery = createChecklistRetrieveQuery(() => props.task.object_id);

    return (
        <Show when={checklistQuery.data} fallback={<ErrorBlock error={checklistQuery.error} />}>
            {checklist => (
                <TaskCard
                    title={checklist().name}
                    description=""
                    isReady={false}
                    taskType="Checklist"
                    isOptional={false}
                    data={{
                        task: props.task,
                        checklist: checklist(),
                    }}
                >
                    {data => <JsonDebug value={data} />}
                </TaskCard>
            )}
        </Show>
    );
}

export function UnknownTaskCard(props: { task: { content_type_model: string } }) {
    return (
        <BorderedCard>
            <P>
                Esta tarea es de tipo <Code>"{props.task.content_type_model}"</Code>, y no está
                disponible en esta versión de AIM Manager X. Puede que necesites actualizar.
            </P>
            <SupportParagraph />
            <TechnicalDetails>
                <JsonDebug value={props.task} />
            </TechnicalDetails>
        </BorderedCard>
    );
}
