import { createSignal, For, JSX, Show, untrack } from "solid-js";
import { JsonDebug } from "../../utils/debug";
import { createRef, MutableRefObject } from "../../utils/reactRefs";
import { useOnClickOutside } from "./Dropdown";
import { OnClickDiv, SeparatedFor } from "../../utils/solidjs";
import { Button } from "./components";
import { P } from "../../utils/typography";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import TextField from "../forms/fields/TextField";
import TextAreaField from "../forms/fields/TextAreaField";
import ColorField from "../forms/fields/ColorField";
import CheckboxField from "../forms/fields/CheckboxField";
import IconField from "../forms/fields/IconField";

type Folder = {
    id: string;
    name: string;
    parent: Folder["id"] | null;
    icon?: string;
    color?: string;
    description?: string;
    starred?: boolean;
};

export default function GenericFileExplorer<T extends Folder>(props: {
    folders: T[];
    createFolder?: (name: string) => Promise<T>;
    updateFolder?: (body: UpdateFolder) => Promise<void>;
    deleteFolder?: (folder: T) => Promise<void>;
}) {
    const actionBarRef = createRef<HTMLElement>();
    const panelRef = createRef<HTMLElement>();
    const [selectedFolder, setSelectedFolder] = createSignal<T>();
    const [path, setPath] = createSignal<Folder["id"][]>([]);
    const openFolder = (folder: Folder) => setPath(prev => [...prev, folder.id]);

    return (
        <div>
            <FileExplorerNavbar path={path()} setPath={setPath} folders={props.folders} />
            <div ref={actionBarRef}>
                <FileExplorerActionBar
                    createFolder={props.createFolder}
                    deleteFolder={props.deleteFolder}
                    selectedFolder={selectedFolder()}
                    setSelectedFolder={setSelectedFolder}
                />
            </div>
            <div class="flex items-start gap-x-3">
                <div class="flex-1">
                    <FolderGrid
                        folders={props.folders}
                        path={path()}
                        selectedFolder={selectedFolder()}
                        onSelect={setSelectedFolder}
                        onOpen={openFolder}
                        ignoreRefs={[actionBarRef, panelRef]}
                    />
                </div>
                <div ref={panelRef} class="w-[20rem]">
                    <FileExplorerPanel
                        selectedFolder={selectedFolder()}
                        updateFolder={props.updateFolder}
                    />
                </div>
            </div>
        </div>
    );
}

function FileExplorerNavbar(props: {
    path: Folder["id"][];
    setPath: (path: Folder["id"][]) => void;
    folders: Folder[];
    root?: JSX.Element;
}) {
    const btn = { bgStyle: "text-only", class: "!text-dark-gray-700" } as const;
    return (
        <div class="flex items-baseline text-xl">
            <Button
                {...btn}
                icon="fas fa-chevron-left"
                onClick={() => props.setPath(props.path.slice(0, -1))}
                disabled={props.path.length === 0}
            />
            <Show
                when={props.root}
                fallback={<Button {...btn} icon="fas fa-home" onClick={() => props.setPath([])} />}
            >
                {props.root}
            </Show>
            /
            <SeparatedFor each={props.path} separator="/">
                {(folderId, index) => (
                    <Button
                        {...btn}
                        onClick={() => props.setPath(props.path.slice(0, index() + 1))}
                        icon={props.folders.find(folder => folder.id === folderId)?.icon}
                    >
                        {props.folders.find(folder => folder.id === folderId)?.name}
                    </Button>
                )}
            </SeparatedFor>
        </div>
    );
}

function FileExplorerActionBar<T extends Folder>(props: {
    createFolder: ((name: string) => Promise<T>) | undefined;
    deleteFolder: ((folder: T) => Promise<void>) | undefined;
    selectedFolder: T | undefined;
    setSelectedFolder: (folder: T | undefined) => void;
}) {
    const onClickNewFolder = async () => {
        const newFolder = await props.createFolder?.(
            `Categoría ${Temporal.Now.plainDateTimeISO()
                .toString()
                .split(".")[0]
                .replace("T", " ")}`,
        );
        props.setSelectedFolder(newFolder);
    };
    const onClickDeleteFolder = async (folder: T) => {
        await props.deleteFolder?.(folder);
        props.setSelectedFolder(undefined);
    };

    return (
        <div class="sticky top-0 flex gap-3 bg-white/80" onClick={e => e.stopPropagation()}>
            <Show when={props.createFolder}>
                <Button bgStyle="text-only" onClick={onClickNewFolder}>
                    Nueva categoría
                </Button>
            </Show>
            <Show when={props.selectedFolder}>
                {folder => (
                    <>
                        <div>|</div>
                        <Show when={props.deleteFolder}>
                            <Button
                                bgStyle="text-only"
                                variant="danger"
                                onClick={() => onClickDeleteFolder(folder())}
                            >
                                Eliminar
                            </Button>
                        </Show>
                    </>
                )}
            </Show>
        </div>
    );
}

function FolderGrid(props: {
    folders: Folder[];
    path: Folder["id"][];
    selectedFolder: Folder | undefined;
    onSelect: (folder: Folder | undefined) => void;
    onOpen: (folder: Folder) => void;
    ignoreRefs: MutableRefObject<HTMLElement>[];
}) {
    const ref = createRef<HTMLDivElement>();
    useOnClickOutside(
        ref,
        () => props.onSelect(undefined),
        untrack(() => props.ignoreRefs),
    );
    const onClickFolder = (e: Event, folder: Folder) => {
        e.stopPropagation();
        props.onSelect(folder);
    };
    const onDblClickFolder = (e: Event, folder: Folder) => {
        e.stopPropagation();
        props.onSelect(undefined);
        props.onOpen(folder);
    };

    return (
        <div
            ref={ref}
            class="grid gap-x-1 gap-y-4"
            style={{ "grid-template-columns": "repeat(auto-fill, 10rem)" }}
        >
            <For
                each={props.folders
                    .filter(folder => folder.parent === (props.path[0] ?? null))
                    .toSorted((a, b) => Number(b.starred) - Number(a.starred))}
                fallback={<P>No hay nada aquí...</P>}
            >
                {folder => (
                    <FolderItem
                        folder={folder}
                        onClick={e => onClickFolder(e, folder)}
                        onDblClick={e => onDblClickFolder(e, folder)}
                        isSelected={folder.id === props.selectedFolder?.id}
                    />
                )}
            </For>
        </div>
    );
}

function FolderItem(props: {
    folder: Folder;
    onClick: OnClickDiv;
    onDblClick: OnClickDiv;
    isSelected: boolean;
}) {
    return (
        <div
            onClick={e => props.onClick(e)}
            onDblClick={e => props.onDblClick(e)}
            class={`flex flex-col items-center ${props.isSelected && "bg-primary-200 font-medium"}`}
        >
            <div class="relative">
                <i // Use || as icon/color may be an empty string
                    class={`${props.folder.icon || "fas fa-folder"} text-display-xl`}
                    style={{ color: props.folder.color || "#808080" }}
                />
                <Show when={props.folder.starred}>
                    <i class="fas fa-thumbtack absolute -right-2 -top-1 rounded-full bg-white p-1" />
                </Show>
            </div>
            <div
                // Show ellipsis after two lines of text
                class="w-full overflow-hidden break-words text-center leading-tight"
                style={{
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                }}
                title={props.folder.name}
            >
                {props.folder.name}
            </div>
        </div>
    );
}

type UpdateFolder = {
    id: string;
    name?: string;
    description?: string;
    starred?: boolean;
    icon?: string;
    color?: string;
};

function FileExplorerPanel(props: {
    selectedFolder: Folder | undefined;
    updateFolder: ((body: UpdateFolder) => Promise<void>) | undefined;
}) {
    return (
        <Show
            when={props.selectedFolder}
            fallback={
                <div>
                    <P>Un click: Seleccionar categoría.</P>
                    <P>Doble click: Abrir categoría.</P>
                </div>
            }
        >
            {folder => (
                <Show keyed when={folder()}>
                    <FormWrapper
                        onSubmit={props.updateFolder}
                        defaultValues={folder()}
                        class="flex flex-col gap-3"
                    >
                        <JsonDebug value={folder()} />
                        <TextField name="name" label="Nombre" />
                        <Show when={"description" in folder()}>
                            <TextAreaField name="description" label="Descripción" optional />
                        </Show>
                        <Show when={"starred" in folder()}>
                            <CheckboxField name="starred" label="Fijar al inicio" />
                        </Show>
                        <Show when={"icon" in folder()}>
                            <IconField name="icon" label="Icono" placeholder="fas fa-folder" />
                        </Show>
                        <Show when={"color" in folder()}>
                            <ColorField name="color" label="Color" optional />
                        </Show>
                        <SubmitButton submittingText="Guardando cambios...">
                            Guardar cambios
                        </SubmitButton>
                    </FormWrapper>
                </Show>
            )}
        </Show>
    );
}
