import { useLocale } from "../../i18n/context";
import { AimLogo } from "../../ui/components";
import { P } from "../../../utils/typography";
import BackButton from "../../ui/BackButton";
import EmailField from "../../forms/fields/EmailField";
import { getApiInstance } from "../../../api";
import SubmitButton from "../../forms/SubmitButton";
import { FormWrapper } from "../../forms/FormWrapper";

export default function FindOrganizationStep(props: {
    onSubmitEmail: (email: string) => void;
    onGoBack: () => void;
}) {
    const [locale] = useLocale();
    const t = () => locale().auth;

    async function handleSubmit(form: { email: string }): Promise<void> {
        const api = getApiInstance();
        await api.auth.sendMagicCode(form.email);
        props.onSubmitEmail(form.email);
    }

    return (
        <div class="relative flex flex-col items-center gap-y-8">
            <div class="left-0 top-0 absolute">
                <BackButton onClick={props.onGoBack} class={"!text-primary-900"} />
            </div>
            <div class={"flex flex-col items-center"}>
                <div class={"mt-12"}>
                    <AimLogo />
                </div>
                <div class="mt-12 text-center">
                    <h1 class="hd:tex-display-sm text-xl font-medium">
                        {t().letsFindYourOrganization}
                    </h1>
                    <P class={"text-md text-dark-gray-400 md:text-lg"}>
                        {t().weSuggestUseYourEnterpriseEmail}
                    </P>
                </div>
            </div>
            <FormWrapper<{ email: string }>
                onSubmit={handleSubmit}
                class="flex w-full flex-col gap-8"
            >
                <EmailField name="email" label={t().email} placeholder={t().emailPlaceholder} />
                <SubmitButton submittingText={t().continuingWithEmail}>
                    {t().continueWithEmail}
                </SubmitButton>
            </FormWrapper>
        </div>
    );
}
