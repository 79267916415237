import { createApiQuery } from "../../../utils";
import { Accessor } from "solid-js";
import { WorkflowCategoriesQueryParams } from "./interface";

export function createListWorkflowCategoriesQuery(
    queryParams: Accessor<WorkflowCategoriesQueryParams>,
) {
    return createApiQuery(api => ({
        queryKey: ["listWorkflowCategories", queryParams()],
        queryFn: () => api.workflow.listWorkflowCategories.fetchJson(queryParams()),
        select: api.workflow.listWorkflowCategories.select,
    }));
}
