import { useLocale } from "../i18n/context";
import { createModalController } from "../ui/Modal";
import { createListPositionsQuery } from "../../api/services/positions/queries";
import { Button } from "../ui/components";
import SidePanel from "../ui/SidePanel";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { H2 } from "../../utils/typography";
import { JsonDebug } from "../../utils/debug";
import { createPersonQuery } from "../../api/services/person/queries";
import { Show, Suspense } from "solid-js";

export function OrganizationChartDebug() {
    const [locale] = useLocale();
    const panel = createModalController<void, void>();
    const positionsQuery = createListPositionsQuery();

    return (
        <>
            <Button onClick={() => panel.open()} bgStyle="text-only">
                {locale().utils.showTechnicalDetails}
            </Button>
            <SidePanel controller={panel} position="right">
                <Suspense fallback={<GenericSuspenseFallback />}>
                    <div class="flex gap-10">
                        <Show
                            when={positionsQuery.data}
                            fallback={<ErrorBlock error={positionsQuery.error} />}
                        >
                            {positions => (
                                <>
                                    <div>
                                        <H2>Positions</H2>
                                        <JsonDebug value={positions()} />
                                    </div>
                                    <PersonEndpointDebug id={positions()[0]?.people[0]} />
                                </>
                            )}
                        </Show>
                    </div>
                </Suspense>
            </SidePanel>
        </>
    );
}

function PersonEndpointDebug(props: { id: string }) {
    const personQuery = createPersonQuery(() => props.id);

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={personQuery.data} fallback={<ErrorBlock error={personQuery.error} />}>
                {person => (
                    <div>
                        <H2>Person</H2>
                        <JsonDebug value={person()} />
                    </div>
                )}
            </Show>
        </Suspense>
    );
}
