import { ColumnDefinition, Table } from "../../ui/Table";
import { ChecklistExecutions } from "../../../api/services/checklist/interface";
import { P } from "../../../utils/typography";

const tableColumns: ColumnDefinition<ChecklistExecutions>[] = [
    {
        title: "Col",
        accessor: "checklist_name",
    },
    {
        title: "Nombre",
        accessor: "checklist_name",
    },
    {
        title: "Correo",
        accessor: "checklist_name",
    },
    {
        title: "Tipo de documento",
        accessor: "checklist_name",
    },
    {
        title: "N° de documento",
        accessor: "checklist_name",
    },
    {
        title: "Invitación",
        accessor: "checklist_name",
    },
    {
        title: "Area de trabajo",
        accessor: "checklist_name",
    },
    {
        title: "Cargo",
        accessor: "checklist_name",
    },
    {
        title: "",
        cell: () => <div>Acciones</div>,
    },
];

export default function PeopleBulkLoadTable() {
    return <Table columns={tableColumns} data={[]} />;
}
