import { Accessor } from "solid-js";
import { createApiMutation } from "../../utils";
import { CreateUpdateLogicPayload } from "./interface";

export const createMakeLogicParserMutation = () => {
    return createApiMutation(api => ({
        mutationKey: ["makeLogicParserMutation"],
        mutationFn: (payload: CreateUpdateLogicPayload) => api.logicParser.createLogic(payload),
    }));
};

export const createPutLogicParserMutation = (id: Accessor<string>) => {
    return createApiMutation(api => ({
        mutationKey: ["putLogicParserMutation"],
        mutationFn: (payload: CreateUpdateLogicPayload) => api.logicParser.putLogic(id(), payload),
    }));
};

export const createPatchLogicParserMutation = (id: Accessor<string | undefined>) => {
    return createApiMutation(api => ({
        mutationKey: ["patchLogicParserMutation"],
        mutationFn: (payload: CreateUpdateLogicPayload) =>
            api.logicParser.patchLogic(id()!, payload),
    }));
};

export const createEvaluateLogicParserMutation = () => {
    return createApiMutation(api => ({
        mutationKey: ["evaluateLogicParserMutation"],
        mutationFn: (payload: CreateUpdateLogicPayload) => api.logicParser.evaluateLogic(payload),
    }));
};
