import { LavandaCard } from "../ui/cards";
import { GradientBackgroundWrapper } from "./SignInPage";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import { createEffect, createResource, Show } from "solid-js";
import { Preferences } from "@capacitor/preferences";
import { createIsEmailLinkQuery } from "../../api/services/auth/queries";
import { createSignInWithEmailLinkMutation } from "../../api/services/auth/mutations";
import { FormWrapper } from "../forms/FormWrapper";
import EmailField from "../forms/fields/EmailField";
import SubmitButton from "../forms/SubmitButton";

export default function SignInFromMagicLink() {
    const [locale] = useLocale();
    const l = locale().auth;
    const link = () => window.location.href;
    const [storeEmail] = createResource(() =>
        Preferences.get({ key: "email" }).then(res => res.value),
    );
    const isEmailLinkQuery = createIsEmailLinkQuery(link);
    const signInWithLinkMutation = createSignInWithEmailLinkMutation();
    const loginWithEmail = (email: string) => {
        signInWithLinkMutation.mutate(
            {
                email: email,
                link: link(),
            },
            {
                onSuccess: () => {},
            },
        );
    };
    createEffect(() => {
        const email = storeEmail();
        if (isEmailLinkQuery.data && email) {
            loginWithEmail(email);
        }
    });

    const submit = (values: { email: string }) => {
        loginWithEmail(values.email);
    };

    return (
        <GradientBackgroundWrapper>
            <LavandaCard>
                <Show when={isEmailLinkQuery.isLoading || signInWithLinkMutation.isPending}>
                    <div class={"my-4 flex items-center gap-x-2 py-12"}>
                        <div class={"flex items-center"}>
                            <i class={"fas fa-spinner fa-spin text-lg text-dark-gray-700"} />
                        </div>
                        <P class={"!mb-0 text-lg text-dark-gray-700"}>{l.signingInWithMagicLink}</P>
                    </div>
                </Show>
                <Show
                    when={
                        !storeEmail() &&
                        !isEmailLinkQuery.isLoading &&
                        !signInWithLinkMutation.isPending
                    }
                >
                    <P class={"text-lg"}>{l.signInWithMagicLink}</P>
                    <FormWrapper<{ email: string }> onSubmit={submit}>
                        <EmailField name="email" label={l.email} />
                        <SubmitButton submittingText={l.validatingEmail} class={"mt-4"}>
                            {l.validateEmail}
                        </SubmitButton>
                    </FormWrapper>
                </Show>
            </LavandaCard>
        </GradientBackgroundWrapper>
    );
}
