import { ColumnDefinition, Table } from "../../ui/Table";
import { ChecklistExecutions } from "../../../api/services/checklist/interface";
import { A } from "@solidjs/router";
import { P } from "../../../utils/typography";
import { useLocale } from "../../i18n/context";
import { Show } from "solid-js";

export default function ChecklistExecutionsTable(props: { data: ChecklistExecutions[] }) {
    const [locale] = useLocale();

    const tableColumns: ColumnDefinition<ChecklistExecutions>[] = [
        {
            title: "Checklist",
            accessor: "checklist_name",
        },
        {
            title: "Ejecutado por",
            cell: row => (
                <div>
                    <P class={"!mb-0"}>{row.user.name.trim() !== "" ? row.user.name : `—`}</P>
                    <P class={"!mb-0"}>
                        {Intl.DateTimeFormat(locale().codeWithCountry, {
                            dateStyle: "long",
                            timeStyle: "short",
                        }).format(row.created_at)}
                    </P>
                </div>
            ),
        },
        {
            title: "Completado",
            cell: row => (
                <P class={"!mb-0"}>
                    {row.completed_at
                        ? Intl.DateTimeFormat(locale().codeWithCountry, {
                              dateStyle: "long",
                              timeStyle: "short",
                          }).format(row.completed_at)
                        : `—`}
                </P>
            ),
        },
        {
            title: "Acciones",
            cell: row => (
                <Show
                    when={row.completed_at}
                    fallback={<A href={`/checklists/executions/${row.id}`}>Reanudar</A>}
                >
                    <A href={`/checklists/executions_detail/${row.id}`}>Ver resultados</A>
                </Show>
            ),
        },
    ];

    return (
        <Table
            theadClass={"text-left"}
            tbodyTrClass={"border-b border-light-gray-300 [&>td]:py-3 px-2"}
            columns={tableColumns}
            data={props.data}
        />
    );
}
