import { Show, Suspense } from "solid-js";
import { createRetrieveLogicParserOptionsQuery } from "../../../../api/services/logic-parser/queries";
import { useFormState } from "../../../forms/state";
import { FilterFunctionSection } from "./FilterFunctionSection";
import { OperatorSection } from "./OperatorSection";
import { CreateQueryResult } from "@tanstack/solid-query";
import { LogicParserOptions } from "../../../../api/services/logic-parser/interface";

export function TargetEntityFieldsSection(props: { index: number }) {
    const assetParserOptionsQuery = createRetrieveLogicParserOptionsQuery(() => "asset");
    const venueParserOptionsQuery = createRetrieveLogicParserOptionsQuery(() => "venue");
    const locationParserOptionsQuery = createRetrieveLogicParserOptionsQuery(() => "location");
    return (
        <div class={""}>
            <FilterAndOperatorContent
                index={props.index}
                sectionModel="asset"
                parserOptionsQuery={assetParserOptionsQuery}
            />
            <FilterAndOperatorContent
                index={props.index}
                sectionModel="venue"
                parserOptionsQuery={venueParserOptionsQuery}
            />
            <FilterAndOperatorContent
                index={props.index}
                sectionModel="location"
                parserOptionsQuery={locationParserOptionsQuery}
            />
        </div>
    );
}

function FilterAndOperatorContent(props: {
    index: number;
    parserOptionsQuery: CreateQueryResult<LogicParserOptions, Error>;
    sectionModel: string;
}) {
    const form = useFormState<{
        model: string;
        entities: {
            filter: string;
            filterValue: string;
            operator: string;
        }[];
    }>();

    return (
        <Show when={form.values[`model`] == props.sectionModel}>
            <Suspense>
                <Show when={props.parserOptionsQuery.data}>
                    {parserOptions => {
                        return (
                            <div class="space-y-8">
                                <Show
                                    when={
                                        (form.values.entities ?? [])?.length > 1 &&
                                        props.index !== 0
                                    }
                                >
                                    <OperatorSection
                                        index={props.index}
                                        parserOptions={parserOptions()}
                                        defaultValue={form.values.entities[props.index].operator}
                                    />
                                </Show>
                                <FilterFunctionSection
                                    index={props.index}
                                    filterOptions={parserOptions()}
                                    defaultValues={{
                                        filter: form.values.entities[props.index].filter,
                                        filterValue: form.values.entities[props.index].filterValue,
                                    }}
                                />
                            </div>
                        );
                    }}
                </Show>
            </Suspense>
        </Show>
    );
}

{
    /* <Show when={form.values[`model`] == "asset"}>
        <Suspense>
            <Show when={assetParserOptionsQuery.data}>
                {parserOptions => {
                    return (
                        <div class="space-y-8">
                            <OperatorSection
                                index={props.index}
                                parserOptions={parserOptions()}
                                // defaultValue={
                                //     (props.entity as OperatorExpression).operator
                                // }
                            />
                            <FilterFunctionSection
                                index={props.index}
                                filterOptions={parserOptions()}
                                // defaultValues={{
                                //     filter: (props.entity as FunctionExpression)
                                //         .function,
                                //     filterValue: String(
                                //         (props.entity as FunctionExpression).value[0],
                                //     ),
                                // }}
                            />
                        </div>
                    );
                }}
            </Show>
        </Suspense>
</Show> 
*/
}

{
    /* 
<Show when={form.values[`model`] == "location"}>
    <Suspense>
        <Show when={locationParserOptionsQuery.data}>
            {parserOptions => {
                return (
                    <div class="space-y-8">
                        <OperatorSection
                            index={props.index}
                            parserOptions={parserOptions()}
                            // defaultValue={
                            //     (props.entity as OperatorExpression).operator
                            // }
                        />
                        <FilterFunctionSection
                            index={props.index}
                            filterOptions={parserOptions()}
                            // defaultValues={{
                            //     filter: (props.entity as FunctionExpression)
                            //         .function,
                            //     filterValue: String(
                            //         (props.entity as FunctionExpression).value[0],
                            //     ),
                            // }}
                        />
                    </div>
                );
            }}
        </Show>
    </Suspense>
</Show> */
}
