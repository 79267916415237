import {
    Accessor,
    Context,
    createMemo,
    createReaction,
    createResource,
    createSignal,
    For,
    getOwner,
    JSX,
    onCleanup,
    onMount,
    Resource,
    runWithOwner,
    Setter,
    useContext,
} from "solid-js";
import { MutableRefObject } from "./reactRefs";

// aliases for hard to remember types
export type OnClickAnchor = JSX.EventHandler<HTMLAnchorElement, MouseEvent>;
export type OnClickButton = JSX.EventHandler<HTMLButtonElement, MouseEvent>;
export type OnClickDiv = JSX.EventHandler<HTMLDivElement, MouseEvent>;
export type OnClickImage = JSX.EventHandler<HTMLImageElement, MouseEvent>;

export function useRequiredContext<T>(
    context: Context<T | undefined>,
    consumerName: string,
    providerName: string,
): T {
    const value = useContext(context);
    if (value === undefined) {
        throw new Error(`${consumerName} must be used within a ${providerName}`);
    }
    return value;
}

export function useElementEventListener<TElement extends Element>(
    ref: MutableRefObject<TElement>,
    type: string,
    listener: EventListener,
): void {
    onMount(() => ref.current!.addEventListener(type, listener));
    onCleanup(() => ref.current!.removeEventListener(type, listener));
}

export function useWindowEventListener<K extends keyof WindowEventMap>(
    type: K,
    listener: (event: WindowEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions | undefined,
): void {
    onMount(() => window.addEventListener(type, listener, options));
    onCleanup(() => window.removeEventListener(type, listener, options));
}

/** Creates a state that starts uninitialized and can be set with the returned setter.
 * The getter is a Resource and therefore will suspend before is initialized.
 * The setter will change the resource value AND mark it as resolved.
 * See https://github.com/solidjs/solid/issues/1864 for context on why we need this. */
export function createAsyncState<T>(): [Resource<T>, Setter<T>] {
    const [state, setState] = createSignal<T | undefined>();
    const source = createMemo(() => {
        const value = state();
        return value !== undefined ? { value } /* [1] */ : undefined;
    });
    const [resource] = createResource(source, ({ value }) => value); // [2]
    return [resource, setState as Setter<T>];

    /* [1]: We need to wrap the value in an object to avoid an edge case where
     *      the value is null or false, which would be interpreted as "no value"
     *      by createResource.
     * [2]: We make use of the fact that createResource will refetch if the
     *      source changes. If the setter changes the state, it will cause the
     *      source to change, which will in turn cause the resource to
     *      refetch and therefore mark the resource as resolved with the new
     *      value. */
}

/** Allows you to await the value of a Solid.js resource from outside components. */
export async function awaitResource<T>(resource: Resource<T>): Promise<T> {
    // Case 1: Already loaded
    if (resource.error) throw resource.error;
    const currentValue = resource();
    if (currentValue !== undefined) return currentValue;

    // Case 2: Loading...
    return new Promise((resolve, reject) => {
        const track = createReaction(() => {
            if (resource.error) reject(resource.error);
            else resolve(resource()!);
        });
        track(() => resource());
    });
}

/** Extends Solid's For component to add a separator between each item. */
export function SeparatedFor<T extends readonly unknown[], U extends JSX.Element>(props: {
    each: T | undefined | null | false;
    separator: JSX.Element;
    fallback?: JSX.Element;
    children: (item: T[number], index: Accessor<number>) => U;
}) {
    return (
        <For each={props.each} fallback={props.fallback}>
            {(item, index) => (
                <>
                    {index() > 0 && props.separator}
                    {props.children(item, index)}
                </>
            )}
        </For>
    );
}

/** @deprecated Use `useThrowToErrorBoundary` instead as its simpler.
 * For context see https://github.com/solidjs/solid/discussions/1174#discussioncomment-7483296. */
export function useRunWithErrorBoundary(): (fnThatMayThrow: () => void | Promise<void>) => void {
    const owner = getOwner();

    return function runWithErrorBoundary(fnThatMayThrow: () => void | Promise<void>): void {
        Promise.resolve(fnThatMayThrow()).catch(error => {
            runWithOwner(owner, () => {
                throw error;
            });
        });
    };
}

/** Solid.js Error Boundaries only catch render errors. That means they can't
 * be normally used to catch errors from event handlers or async functions.
 * You can use the `throwToErrorBoundary` function returned by this hook to
 * throw any error to the nearest Error Boundary.
 *
 * For context see https://github.com/solidjs/solid/discussions/1174#discussioncomment-7483296.
 *
 * Usage:
 * ```ts
 * function MyComponent() {
 *     const throwToErrorBoundary = useThrowToErrorBoundary();
 *
 *     function handleSomeEvent() {
 *         try {
 *             // Code that may throw
 *         } catch (error) {
 *             throwToErrorBoundary(error);
 *         }
 *     }
 *
 *     function handleAnotherWithAsyncCall() {
 *         asyncCall().then(...).catch(throwToErrorBoundary);
 *     }
 *
 *     return ...;
 * }
 * ```
 *
 * @remarks `throwToErrorBoundary` doesn't stop execution of the code after it. */
export function useThrowToErrorBoundary(): (error: unknown) => void {
    const owner = getOwner();

    return function throwToErrorBoundary(error: unknown): void {
        runWithOwner(owner, () => {
            throw error;
        });
    };
}

export function useInterval(handler: () => void, timeout: number): void {
    let interval: ReturnType<typeof setInterval>;
    onMount(() => (interval = setInterval(handler, timeout)));
    onCleanup(() => clearInterval(interval));
}

/** Used with <Switch> to avoid type casting when matching over subclasses. */
export function isInstance<T>(obj: unknown, cls: { new (...args: never[]): T }): T | undefined {
    return obj instanceof cls ? obj : undefined;
}
