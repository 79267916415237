import { createContext, createSignal, ParentProps, Show } from "solid-js";
import { useRequiredContext } from "../../utils/solidjs";
import { Button, GargamelButtonProps } from "./components";

const AccordionContext = createContext();

export default function Accordion(props: ParentProps) {
    return <AccordionContext.Provider value={{}}>{props.children}</AccordionContext.Provider>;
}

type AccordionItemProps = {
    summary: string;
    summaryOpen?: string;
    buttonProps?: GargamelButtonProps;
    replaceOpenIcon?: string;
    replaceCloseIcon?: string;
};
export function AccordionItem(props: ParentProps<AccordionItemProps>) {
    useRequiredContext(AccordionContext, "AccordionItem", "Accordion");
    const [isOpen, setIsOpen] = createSignal(false);
    const toggle = () => setIsOpen(x => !x);
    const replaceOpenIcon = () => props.replaceOpenIcon ?? "fas fa-caret-down";
    const replaceCloseIcon = () => props.replaceCloseIcon ?? "fas fa-caret-right";

    return (
        <>
            <Button
                onClick={toggle}
                icon={isOpen() ? replaceOpenIcon() : replaceCloseIcon()}
                bgStyle="text-only"
                {...props.buttonProps}
            >
                {isOpen() && props.summaryOpen ? props.summaryOpen : props.summary}
            </Button>
            <Show when={isOpen()}>{props.children}</Show>
        </>
    );
}
