import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";

export function createRetrieveWorkflowFormQuery(formId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["workflowForm", formId()],
        queryFn: () => api.workflow.retrieveWorkflowForm(formId()!),
        enabled: !!formId(),
    }));
}

export function createFormByBpmnElementQuery(
    params: Accessor<{
        workflowId: string;
        bpmnElementId: string;
    }>,
) {
    return createApiQuery(api => ({
        queryKey: ["formByBpmnElement", params()],
        queryFn: () => api.workflow.getFormByBpmnElement(params()),
    }));
}

export function createWorkflowExecutionListQuery() {
    return createApiQuery(api => ({
        queryKey: ["workflowExecutionList"],
        queryFn: () => api.workflow.listWorkflowExecutions.fetchJson(),
        select: api.workflow.listWorkflowExecutions.select,
    }));
}

export function createWorkflowExecutionDetailQuery(executionId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["workflowExecutionDetail", executionId()],
        queryFn: () => api.workflow.retrieveWorkflowExecution.fetchJson(executionId()),
        select: api.workflow.retrieveWorkflowExecution.select,
    }));
}

export function createWorkflowElementListQuery(executionId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["workflowElementList", executionId()],
        queryFn: () => api.workflow.listWorkflowElements(executionId()),
    }));
}

export function createFormResponseQuery(
    params: Accessor<{ formId: string | undefined; executionId: string | undefined }>,
) {
    return createApiQuery(api => ({
        queryKey: ["formResponse", params()],
        queryFn: () =>
            api.workflow.retrieveFormResponse(params() as { formId: string; executionId: string }),
        enabled: !!(params().formId && params().executionId),
    }));
}
