import { useParams } from "@solidjs/router";
import { create, string, type } from "superstruct";
import { PageWrapper } from "../ui/pageWrappers";
import { createFormByBpmnElementQuery } from "../../api/services/workflow/queries";
import { createEffect, createSignal, Match, Show, Suspense, Switch } from "solid-js";
import BackButton, { useGoBack } from "../ui/BackButton";
import { createActivateWorkflowMutation } from "../../api/services/workflow/mutations";
import SidePanel from "../ui/SidePanel";
import { createModalController } from "../ui/Modal";
import { useLocale } from "../i18n/context";
import { H1, H2, P } from "../../utils/typography";
import { JsonDebug } from "../../utils/debug";
import { Button } from "../ui/components";
import { FormValues } from "../forms/state";
import { RenderDynamicFields } from "../FormBuilder/RenderDynamicForm";
import { sleep } from "../../utils/mocks";
import {
    BpmnElement,
    BpmnModeler,
    BpmnViewer,
    IntermediateThrowEvent,
    StartEvent,
    UserTask,
} from "./bpmn";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import { StartEventDetail } from "./StartEventDetail";
import { ElementTitle, ExecutionAudienceSection } from "./utils";
import MessageTemplate from "./MessageTemplate";
import BpmnJsViewer from "bpmn-js/lib/Viewer";
import { showErrorToast } from "../../utils/errorHandling";
import ElementRegistry from "diagram-js/lib/core/ElementRegistry";
import { generateAndDownload } from "../../api/utils";
import { Workflow } from "../../api/services/workflow/workflows/interface";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";

export default function WorkflowDetailPage() {
    const params = create(useParams(), type({ id: string() }));
    const workflowDetailQuery = createWorkflowDetailQuery(() => params.id);
    const goBack = useGoBack("/workflows");

    return (
        <PageWrapper>
            <BackButton onClick={goBack} />
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={workflowDetailQuery.data}>
                    {workflow => (
                        <div>
                            <H1>{workflow().name}</H1>
                            <WorkflowBpmn workflow={workflow()} />
                        </div>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}

function WorkflowBpmn(props: { workflow: Workflow }) {
    const [editingBpmn, setEditingBpmn] = createSignal(false);
    const viewElementPanel = createModalController<BpmnElement, void>();

    return (
        <>
            <Show
                when={editingBpmn()}
                fallback={
                    <div>
                        <WorkflowViewerActions
                            workflow={props.workflow}
                            onClickEdit={() => setEditingBpmn(true)}
                        />
                        <BpmnViewer
                            bpmn={props.workflow.bpmn}
                            onClickElement={viewElementPanel.open}
                        />
                    </div>
                }
            >
                <BpmnModeler
                    workflow={props.workflow}
                    onDiscardChanges={() => setEditingBpmn(false)}
                />
            </Show>
            <SidePanel position="right" controller={viewElementPanel}>
                {element => (
                    // Fixed width allows user to develop muscle memory
                    <div class="w-192">
                        <ElementDetail
                            element={element}
                            workflow={props.workflow}
                            close={viewElementPanel.dismiss}
                        />
                    </div>
                )}
            </SidePanel>
        </>
    );
}

function WorkflowViewerActions(props: { workflow: Workflow; onClickEdit: () => void }) {
    const [locale] = useLocale();
    const activateWorkflowMutation = createActivateWorkflowMutation();

    // Using a isActivating signal to avoid showing a spinner during the confirm dialog.
    const [isActivating, setIsActivating] = createSignal(false);
    const handleActivateWorkflow = async () => {
        if (confirm(locale().workflows.activateWorkflowConfirm(props.workflow.name))) {
            setIsActivating(true);
            try {
                await validateWorkflow(props.workflow);
                await activateWorkflowMutation.mutateAsync(props.workflow.id);
            } catch (error) {
                showErrorToast(error);
            } finally {
                setIsActivating(false);
            }
        }
    };

    return (
        <div class="mb-2 flex items-baseline gap-3">
            <Button
                icon="fas fa-edit"
                bgStyle="text-only"
                onClick={props.onClickEdit}
                disabled={props.workflow.is_active}
                disabledReason={locale().workflows.cannotEditActiveWorkflow}
            >
                {locale().workflows.editBpmn}
            </Button>
            <WorkflowStatus isActive={props.workflow.is_active} />
            <Show when={!props.workflow.is_active}>
                <Button
                    bgStyle="outline"
                    onClick={handleActivateWorkflow}
                    pending={isActivating()}
                    pendingText={locale().workflows.activatingWorkflow}
                >
                    {locale().workflows.activateWorkflow}
                </Button>
            </Show>
            <div class="flex-1" />
            <Show when={props.workflow.bpmn}>
                {bpmn => (
                    <Button
                        icon="fas fa-download"
                        bgStyle="text-only"
                        onClick={() => {
                            const blob = new Blob([bpmn()], { type: "text/xml;charset=utf-8" });
                            generateAndDownload(`${props.workflow.name}.xml`, blob);
                        }}
                    >
                        Descargar BPMN
                    </Button>
                )}
            </Show>
        </div>
    );
}

async function validateWorkflow(workflow: Workflow): Promise<void> {
    const viewer = new BpmnJsViewer();
    if (!workflow.bpmn) throw new Error("No se puede publicar un workflow sin diagrama BPMN");
    await viewer.importXML(workflow.bpmn);
    const elementRegistry: ElementRegistry = viewer.get("elementRegistry");
    const elements = elementRegistry.getAll().map(e => BpmnElement.create(e, viewer));
    for (const element of elements) {
        element.validate();
    }
}

export function WorkflowStatus(props: { isActive: boolean }) {
    const [locale] = useLocale();

    return (
        <div class={"flex items-baseline gap-x-2"}>
            <span
                class={"block h-2 w-2 translate-y-[-0.1rem] rounded-full"}
                classList={{
                    "bg-success-500": props.isActive,
                    "bg-dark-gray-700": !props.isActive,
                }}
            />
            <P class={"!mb-0"}>
                {props.isActive ? locale().workflows.active : locale().workflows.inactive}
            </P>
        </div>
    );
}

export type ElementDetailProps<TElement extends BpmnElement> = {
    element: TElement;
    workflow: Workflow;
    close: () => void;
};

function ElementDetail(props: ElementDetailProps<BpmnElement>) {
    return (
        <PageWrapper>
            <ElementTitle element={props.element} />
            <Switch fallback={<NotImplementedDetail {...props} />}>
                <Match when={props.element instanceof IntermediateThrowEvent && props.element}>
                    {element => <IntermediateThrowEventDetail {...props} element={element()} />}
                </Match>
                <Match when={props.element instanceof StartEvent && props.element}>
                    {element => <StartEventDetail {...props} element={element()} />}
                </Match>
                <Match when={props.element.type === "bpmn:Task"}>
                    <TaskDetail {...props} />
                </Match>
                <Match when={props.element instanceof UserTask && props.element}>
                    {element => <UserTaskDetail {...props} element={element()} />}
                </Match>
            </Switch>
        </PageWrapper>
    );
}

function IntermediateThrowEventDetail(props: ElementDetailProps<IntermediateThrowEvent>) {
    const [locale] = useLocale();
    createEffect(() => console.debug(props.element));
    return (
        <Show
            when={props.element.message}
            fallback={<P>{locale().workflows.configure.noMessage}</P>}
        >
            {message => (
                <MessageTemplate message={message()} element={props.element} mode="viewer" />
            )}
        </Show>
    );
}

function TaskDetail(props: ElementDetailProps<BpmnElement>) {
    const [locale] = useLocale();
    return <P>{locale().workflows.useUserTaskInstead(props.element.name)}</P>;
}

function UserTaskDetail(props: ElementDetailProps<UserTask>) {
    const [locale] = useLocale();
    const formByBpmnElementQuery = createFormByBpmnElementQuery(() => ({
        workflowId: props.workflow.id,
        bpmnElementId: props.element.id,
    }));

    async function simulateSubmit(formValues: FormValues): Promise<void> {
        await sleep(1000);
        alert(locale().forms.simulationResult + "\n\n" + JSON.stringify(formValues, null, 4));
    }

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <ExecutionAudienceSection element={props.element} mode="viewer" />
            <Show when={formByBpmnElementQuery.data}>
                {form => (
                    <div>
                        <H2>Formulario</H2>
                        <a href={`/workflows/${props.workflow.id}/form-builder/${form().id}`}>
                            Editar formulario
                        </a>
                        <FormWrapper class="flex flex-col gap-3" onSubmit={simulateSubmit}>
                            <RenderDynamicFields fields={form().fields} />
                            <SubmitButton>{locale().forms.simulateSubmit}</SubmitButton>
                        </FormWrapper>
                    </div>
                )}
            </Show>
        </Suspense>
    );
}

function NotImplementedDetail(props: ElementDetailProps<BpmnElement>) {
    return <JsonDebug value={props.element} />;
}
