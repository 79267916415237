import { FrontOrganization } from "../../api/services/organization/interface";
import { NeedConfirmError } from "../../api/services/auth/interface";
import { parsedEnv } from "../../utils/parsedEnv";
import { createModalController, Modal, ModalController } from "../ui/Modal";
import Warning from "../ui/Warning";
import { P, Strong } from "../../utils/typography";
import { Button } from "../ui/components";
import { createSignal, Show } from "solid-js";
import { ErrorAlert } from "../../utils/components/ErrorAlert";
import {
    describeSignInMethod,
    FederatedSignInMethod,
} from "../../api/services/organization/signInMethods";

export function useNeedConfirmWorkaround() {
    const needConfirmModal = createModalController<NeedConfirmError, void>();
    const [error, setError] = createSignal<NeedConfirmError | undefined>();
    let prevError: NeedConfirmError | undefined;

    return {
        async beforeSignIn(_method: FederatedSignInMethod) {
            prevError = error();
            setError(undefined);
        },

        async afterSignIn(method: FederatedSignInMethod) {
            if (parsedEnv.VITE_FEATURE_FIREBASE_ACCOUNT_LINKING && prevError) {
                console.log({ method, prevError });
                if (parsedEnv.MODE === "development") alert("Falta linkear las cuentas aquí");
            }
        },

        async onNeedConfirmError(e: NeedConfirmError) {
            setError(e);
            needConfirmModal.open(e);
        },

        render(organization: FrontOrganization) {
            return (
                <>
                    <Show when={!needConfirmModal.state().isOpen && error()}>
                        {error => (
                            <NeedConfirmAlert error={error()} onClose={() => setError(undefined)} />
                        )}
                    </Show>
                    <NeedConfirmModal controller={needConfirmModal} organization={organization} />
                </>
            );
        },
    };
}

function NeedConfirmAlert(props: { error: NeedConfirmError; onClose: () => void }) {
    const prevMethod = () =>
        props.error.prevMethods?.map(describeSignInMethod).join(" o ") ?? "el otro método";

    return (
        <Show
            when={parsedEnv.VITE_FEATURE_FIREBASE_ACCOUNT_LINKING}
            fallback={
                <ErrorAlert text={`Ya tienes una cuenta iniciando sesión con ${prevMethod()}.`} />
            }
        >
            <Warning>
                Confirma tu identidad iniciando sesión con <Strong>{prevMethod()}</Strong>.
                <Button onClick={props.onClose} bgStyle="text-only">
                    Cancelar
                </Button>
            </Warning>
        </Show>
    );
}

function NeedConfirmModal(props: {
    controller: ModalController<NeedConfirmError, void>;
    organization: FrontOrganization;
}) {
    return (
        <Modal controller={props.controller}>
            {data => {
                const email = data.email;
                const currentMethod = describeSignInMethod(data.currentMethod);
                const prevMethod = data.prevMethods?.map(describeSignInMethod).join(" o ");

                return (
                    <>
                        <Modal.Header>
                            <h1 class="text-display-sm">
                                {parsedEnv.VITE_FEATURE_FIREBASE_ACCOUNT_LINKING
                                    ? "Confirmación de identidad"
                                    : "Cuenta preexistente"}
                            </h1>
                        </Modal.Header>
                        <div class="max-w-prose mt-3 flex flex-col">
                            <P class="mb-3">
                                Hemos detectado que ya tienes una cuenta en{" "}
                                {props.organization.name} con{" "}
                                {email ? (
                                    <>
                                        este correo: <Strong>{email}</Strong>
                                    </>
                                ) : (
                                    "ese mismo correo"
                                )}
                                , pero iniciando sesión con{" "}
                                {prevMethod ? <Strong>{prevMethod}</Strong> : "otro método"}.
                            </P>
                            <Show when={parsedEnv.VITE_FEATURE_FIREBASE_ACCOUNT_LINKING}>
                                <P class="mb-3">
                                    Por tu seguridad, necesitamos confirmar que seas tú.
                                </P>
                                <P class="mb-3">
                                    Para ello, simplemente inicia sesión con{" "}
                                    {prevMethod ?? "el otro método"}.
                                </P>
                                <P class="mb-3">
                                    Si lo haces, podrás iniciar sesión tanto con {currentMethod}{" "}
                                    como con {prevMethod ?? "el otro método"}.
                                </P>
                            </Show>
                            <Button onClick={props.controller.dismiss} class="w-20 self-center">
                                OK
                            </Button>
                        </div>
                    </>
                );
            }}
        </Modal>
    );
}
