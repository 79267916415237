import { compact } from "lodash";
import { createApiMutation, queryClient } from "../../utils";
import {
    AnswerStepParams,
    ChecklistSections,
    CreateChecklistExecutionsPayload,
    CreateChecklistPayload,
    CreateChecklistSectionsPayload,
    CreateConceptParams,
    CreateConceptStepParams,
    CreateEvaluationParams,
    CreateSectionStep,
    SectionStep,
} from "./interface";

export function createChecklistCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistCreateMutation"],
        mutationFn: (payload: CreateChecklistPayload) => api.checklist.createChecklist(payload),
    }));
}

export function createChecklistPatchMutation(id: string) {
    return createApiMutation(api => ({
        mutationKey: ["createChecklistPatchMutation"],
        mutationFn: (payload: CreateChecklistPayload) => api.checklist.updateChecklist(id, payload),
    }));
}

export function createChecklistExecutionsCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistExecutionsCreateMutation"],
        mutationFn: (payload: CreateChecklistExecutionsPayload) =>
            api.checklist.createChecklistExecutions(payload),
    }));
}

export function createChecklistSectionsCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistSectionsCreateMutation"],
        mutationFn: (payload: CreateChecklistSectionsPayload) =>
            api.checklist.createChecklistSections(payload),
        onSettled: (_data, _error, params: CreateChecklistSectionsPayload) => {
            queryClient.invalidateQueries({
                queryKey: ["checklistSectionsList", params.checklist],
            });
        },
    }));
}

export function createPatchChecklistSectionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["patchChecklistSectionMutation"],
        mutationFn: api.checklist.patchChecklistSection,
        onSettled: (updatedSection?: ChecklistSections) => {
            queryClient.invalidateQueries({
                queryKey: compact(["checklistSectionsList", updatedSection?.checklist]),
            });
        },
    }));
}

export function createDeleteChecklistSectionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["deleteChecklistSectionMutation"],
        mutationFn: api.checklist.deleteChecklistSection,
        onSettled: (_data, _error, id: string) => {
            queryClient.invalidateQueries({ queryKey: ["retrieveUniqSection", id] });
            queryClient.invalidateQueries({ queryKey: ["checklistSectionsList"] });
        },
    }));
}

export function createChecklistActivationMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistActivationMutation"],
        mutationFn: (id: string) => api.checklist.createChecklistActivation(id),
        onSettled: (_data, _error, checklistId: string) => {
            queryClient.invalidateQueries({ queryKey: ["checklist", checklistId] });
        },
    }));
}

export function createChecklistSectionStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistSectionStepMutation"],
        mutationFn: (payload: CreateSectionStep) => api.checklist.createSectionStep(payload),
        onSettled: (_data, _error, params: CreateSectionStep) => {
            queryClient.invalidateQueries({ queryKey: ["sectionStepList", params.sectionId] });
        },
    }));
}

export function createUpdateChecklistSectionStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateChecklistSectionStepMutation"],
        mutationFn: (payload: SectionStep) => api.checklist.updateSectionStep(payload),
        onSettled: (_data, _error, params: SectionStep) => {
            queryClient.invalidateQueries({ queryKey: ["retrieveSectionStep", params.id] });
            queryClient.invalidateQueries({ queryKey: ["sectionStepList", params.sectionId] });
        },
    }));
}

export function createDeleteSectionStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["deleteChecklistSectionStepMutation"],
        mutationFn: api.checklist.deleteSectionStep,
        onSettled: (_data, _error, id: string) => {
            queryClient.invalidateQueries({ queryKey: ["retrieveSectionStep", id] });
            queryClient.invalidateQueries({ queryKey: ["sectionStepList"] });
        },
    }));
}

export function createAnswerStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["answerStepMutation"],
        mutationFn: api.checklist.answerStep,
        onSettled: (_data, _error, params: AnswerStepParams) => {
            queryClient.invalidateQueries({ queryKey: ["currentStep", params.executionId] });
        },
    }));
}

export function createCompleteChecklistMutation() {
    return createApiMutation(api => ({
        mutationKey: ["completeChecklistMutation"],
        mutationFn: api.checklist.completeChecklist,
        onSettled: (_data, _error, executionId: string) => {
            queryClient.invalidateQueries({ queryKey: ["checklistExecutionsList"] });
            queryClient.invalidateQueries({ queryKey: ["checklistExecution", executionId] });
        },
    }));
}

// #region evaluations

export function createUpdateDefaultEvaluationAudiencesMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateDefaultEvaluationAudiencesMutation"],
        mutationFn: api.checklist.updateDefaultEvaluationAudiences,
    }));
}

export function createChecklistEvaluationCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistEvaluationCreateMutation"],
        mutationFn: api.checklist.createEvaluation,
        onSettled: (_data, _error, params: CreateEvaluationParams) => {
            queryClient.invalidateQueries({
                queryKey: ["listChecklistEvaluation", params.checklist],
            });
        },
    }));
}

export function createChecklistConceptCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistConceptCreateMutation"],
        mutationFn: api.checklist.createConcept,
        onSettled: (_data, _error, params: CreateConceptParams) => {
            queryClient.invalidateQueries({
                queryKey: ["listChecklistConcepts", params.evaluation],
            });
            queryClient.invalidateQueries({
                queryKey: ["listChecklistFormConceptsWithQuestion", params.evaluation],
            });
        },
    }));
}

export function createConceptStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["conceptStepCreateMutation"],
        mutationFn: api.checklist.createConceptStep,
        onSettled: (_data, _error, params: CreateConceptStepParams) => {
            queryClient.invalidateQueries({ queryKey: ["listConceptSteps", params.concept] });
            queryClient.invalidateQueries({ queryKey: ["listChecklistFormConceptsWithQuestion"] });
        },
    }));
}

// #endregion
