import { For, Match, Switch } from "solid-js";
import { AtlasWidgetType } from "../../api/services/home/implementations/atlas";
import { Page, Widget } from "../../api/services/home/interface";
import ActivitiesWidgetComponent from "./ActivitiesWidgetComponent";
import PostsWidgetComponent from "./PostWidgetComponent";

export default function DynamicPage(props: { page: Page }) {
    return <For each={props.page.widgets}>{widget => <DynamicWidget widget={widget} />}</For>;
}

function DynamicWidget(props: { widget: Widget }) {
    /* Dark magic so TypeScript trusts the type of the step inside Match.
     * For context, see https://github.com/solidjs/solid/issues/199. */
    const ofType = <const TType extends AtlasWidgetType>(type: TType) =>
        props.widget.type === type && (props.widget as Widget & { type: TType });

    return (
        <Switch fallback={<UnknownWidgetComponent widget={props.widget} />}>
            <Match when={ofType(AtlasWidgetType.ACTIVITIES)}>
                {widget => <ActivitiesWidgetComponent widget={widget()} />}
            </Match>
            <Match when={ofType(AtlasWidgetType.POSTS)}>
                {widget => <PostsWidgetComponent widget={widget()} />}
            </Match>
        </Switch>
    );
}

function UnknownWidgetComponent(_props: { widget: Widget }) {
    return null;
}
