import { useLocale } from "../../i18n/context";
import { Tab } from "../../ui/Tabs";
import { HistoryTable } from "../HistoryTable";
import { SearchBar } from "../../ui/SearchBar";

export function HistoryTab() {
    const [l] = useLocale();
    return (
        <Tab title={l().personalData.tabs.historyTab}>
            <div class={"space-y-4 py-4"}>
                <div class={"w-full md:w-[50%]"}>
                    <SearchBar variant="white" placeholder={l().menu.search} />
                </div>
                <HistoryTable />
            </div>
        </Tab>
    );
}
