import { NotificationsService, RegisterDeviceParams } from "../interface";
import { MockNotificationsService } from "./mock";
import {
    InboxNotification,
    NotificationType,
} from "../../../../modules/notifications/frontendModels";
import { notificationsClient } from "../../../clients/notifications";
import {
    any,
    array,
    boolean,
    Describe,
    Infer,
    integer,
    nullable,
    string,
    Struct,
    type,
} from "superstruct";
import { Paginated, PaginationParams, sDateTimeString } from "../../../utils";
import { showErrorToast } from "../../../../utils/errorHandling";

export class V3NotificationsService implements NotificationsService {
    private mock = new MockNotificationsService();

    paginateInbox = async (
        params: Required<PaginationParams>,
    ): Promise<Paginated<InboxNotification>> =>
        notificationsClient
            .get("/notifications/inbox")
            .query({ offset: (params.pageNumber - 1) * params.pageSize, limit: params.pageSize })
            .receive(sOffsetPagination(sV3InboxNotification()))
            .then(payload => ({
                count: payload.pagination.total,
                results: payload.data.map(deserializeInboxNotification),
            }));

    deleteNotification = async () => {
        showErrorToast(
            "Eliminar notificaciones no está disponible en nuestro servicio de mensajería aún.",
        );
    };

    addNotificationOnce = this.mock.addNotificationOnce;

    markAsRead = async (id: string): Promise<void> => {
        await notificationsClient
            .patch(`/notifications/inbox/check/by_notification_id/${id}`)
            .receive(boolean() as Describe<boolean>);
    };

    handleNewWorkflowExecution = this.mock.handleNewWorkflowExecution;

    registerDevice = async (params: RegisterDeviceParams): Promise<void> =>
        await notificationsClient
            .post("/notifications/devices/fcm_tokens")
            .sendJson(params)
            .receive(any());

    unregisterDevice = async (deviceToken: string): Promise<void> =>
        await notificationsClient
            .delete(`/notifications/devices/fcm_tokens/_unused_ownerId/${deviceToken}`)
            .receive(any());
}

function sOffsetPagination<T>(t: Struct<T>) {
    return type({
        data: array(t),
        pagination: type({
            total: integer(),
            offset: integer(),
            limit: integer(),
        }),
    });
}

type V3InboxNotification = Infer<ReturnType<typeof sV3InboxNotification>>;

function sV3InboxNotification() {
    return type({
        notificationId: string(),
        summary: type({
            title: string(),
            body: string(),
        }),
        context: type({
            type: string(),
            id: string(),
            action: string(),
        }),
        requestedAt: sDateTimeString(),
        checkedAt: nullable(sDateTimeString()),
    });
}

function deserializeInboxNotification(payload: V3InboxNotification): InboxNotification {
    return {
        id: payload.notificationId,
        title: payload.summary.title,
        createdAt: Temporal.Instant.from(payload.requestedAt),
        read: !!payload.checkedAt,
        type: NotificationType.TEST,
    };
}
