import { P } from "../../../utils/typography";
import { Badge, Button } from "../../ui/components";

const buttonHeight = "2.5rem";

export default function PeopleBulkLoadHeader() {
    return (
        <div>
            <div class="mb-8 flex justify-between">
                <div>
                    <div class="flex items-center gap-3">
                        <P class="text-display-sm font-bold">Carga masiva de personas</P>
                    </div>
                </div>
                <div class={"flex gap-x-3"}>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        onClick={() => console.log("")}
                        class={"!font-normal"}
                        style={{ height: buttonHeight }}
                    >
                        <i class="fas fa-download"></i>
                        Descargar archivo con errores de carga
                    </Button>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        onClick={() => console.log("")}
                        class={"!font-normal"}
                        style={{ height: buttonHeight }}
                    >
                        <i class="fas fa-download"></i>
                        Descargar reporte de carga masiva
                    </Button>
                </div>
            </div>
            <div class={"grid grid-cols-12"}>
                <div class={"col-span-12 space-y-4 md:col-span-5"}>
                    <div class={"flex items-center justify-between"}>
                        <P class={"font-bold"}>Inicio carga masiva</P>
                        <P>19 de Mayo 2023, 12:00 hrs</P>
                    </div>
                    <div class={"flex items-center justify-between"}>
                        <P class={"font-bold"}>Fin carga masiva</P>
                        <P>19 de Mayo 2023, 12:30 hrs</P>
                    </div>
                    <div class={"flex items-center justify-between"}>
                        <P class={"font-bold"}>Realizada por</P>
                        <Badge>
                            <P>Victor Magaña</P>
                        </Badge>
                    </div>
                </div>
            </div>
        </div>
    );
}
