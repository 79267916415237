import { EmailAndPasswordForm } from "../SignInMethodWidget";
import BackButton from "../../ui/BackButton";
import { FrontOrganization } from "../../../api/services/organization/interface";

export default function PasswordStep(props: {
    organization: FrontOrganization;
    defaultEmail: string;
    onGoBack: () => void;
}) {
    return (
        <div class={"flex flex-col items-start gap-y-4"}>
            <BackButton onClick={props.onGoBack} class={"!text-primary-900"} />

            <EmailAndPasswordForm {...props} />
        </div>
    );
}
