import { For, JSX } from "solid-js";

export type ColumnDefinition<TData> = {
    cell?: (data: TData) => JSX.Element;
    title: string;
    accessor?: keyof TData;
};
type TableProps<TData> = {
    columns: ColumnDefinition<TData>[];
    data: TData[];
    class?: string;
    theadClass?: string;
    tbodyTrClass?: string;
};
export function Table<T>(props: TableProps<T>) {
    return (
        <table class={`w-full ${props.class ?? ""}`}>
            <thead class={props.theadClass}>
                <tr>
                    <For each={props.columns}>{column => <th>{column.title}</th>}</For>
                </tr>
            </thead>
            <tbody>
                <For each={props.data}>
                    {row => (
                        <tr class={props.tbodyTrClass}>
                            <For each={props.columns}>
                                {column => {
                                    const foundProperty = column.accessor && row[column.accessor];
                                    if (typeof column.cell === "function") {
                                        return <td>{column.cell(row)}</td>;
                                    } else if (
                                        typeof column.cell === "string" ||
                                        typeof column.cell === "number"
                                    ) {
                                        return <td>{column.cell}</td>;
                                    } else if (foundProperty) {
                                        if (
                                            typeof foundProperty === "string" ||
                                            typeof foundProperty === "number"
                                        ) {
                                            return <td>{foundProperty}</td>;
                                        } else {
                                            return <td>{JSON.stringify(foundProperty)}</td>;
                                        }
                                    } else if (
                                        foundProperty === null ||
                                        foundProperty === undefined
                                    ) {
                                        return <td>&mdash;</td>;
                                    } else {
                                        return <td>{JSON.stringify(row)}</td>;
                                    }
                                }}
                            </For>
                        </tr>
                    )}
                </For>
            </tbody>
        </table>
    );
}
