import { atlasClient } from "../../../../modules/client/client";
import { array, Describe, Infer, lazy, nullable, optional, string, type } from "superstruct";
import { gaussianRandom } from "../../../../utils/mocks";
import { HomeService, Page, PostOverview, Widget } from "../interface";
import { getLeaves, Tree } from "../../../../utils/tree";

const { min, max, round } = Math;

export default class AtlasHomeService implements HomeService {
    retrieveHomePage = async (): Promise<Page> => {
        return await atlasClient
            .get("/organization/home-template")
            .receive(array(sBackendPage()))
            .then(pages => deserializePage(pages[0]));
    };

    listPostsByChannel = async (channelId: string): Promise<PostOverview[]> => {
        return await atlasClient
            .get(`/communications/posts/by-channel/${channelId}`)
            .query({ page: 0, size: 10, order: "asc" })
            .receive(sPaginatedPosts())
            .then(paginatedPosts => paginatedPosts.root.map(deserializePostOverview));
    };

    retrieveWidgetConfig = async (widgetConfigId: string): Promise<WidgetConfig> => {
        return await atlasClient
            .get(`/organization/widget-configuration/${widgetConfigId}`)
            .receive(sWidgetConfig());
    };
}

type BackendPage = Infer<ReturnType<typeof sBackendPage>>;

function sBackendPage() {
    return type({
        id: string(),
        name: string(),
        webScheme: sWebScheme(),
    });
}

type WebScheme = {
    widgetConfigurationId: string;
    value: string;
    children: WebScheme[];
};

function sWebScheme(): Describe<WebScheme> {
    return type({
        widgetConfigurationId: string(),
        value: string(),
        children: array(lazy(sWebScheme)),
    });
}

export enum AtlasWidgetType {
    ACTIVITIES = "WidgetTaskComponent",
    POSTS = "WidgetPostByChannelComponent",
    UNKNOWN = "", // just a sentinel value, the empty string is never used by the backend
}

export type WidgetConfig = Infer<ReturnType<typeof sWidgetConfig>>;

function sWidgetConfig() {
    return type({
        configuration: type({
            channelId: string(),
            channelIcon: string(),
            channelTitle: string(),
        }),
    });
}

function sPaginatedPosts() {
    return type({ root: array(sBackendPostOverview()) });
}

type BackendPostOverview = Infer<ReturnType<typeof sBackendPostOverview>>;

function sBackendPostOverview() {
    return type({
        id: string(),
        category: type({
            name: string(),
            icon: optional(nullable(string())),
        }),
        title: string(),
        fromTime: string(),
        description: string(),
        coverImageUrl: string(),
    });
}

function deserializePage(raw: BackendPage): Page {
    return {
        id: raw.id,
        name: raw.name,
        widgets: getLeaves(deserializeWebScheme(raw.webScheme)),
    };
}

function deserializeWebScheme(raw: WebScheme): Tree<Widget> {
    return {
        value: {
            configId: raw.widgetConfigurationId,
            type: deserializeWidgetType(raw.value),
        },
        children: raw.children.map(deserializeWebScheme),
    };
}

const knownWidgetTypes: Set<string> = new Set(Object.values(AtlasWidgetType));

function deserializeWidgetType(raw: string): AtlasWidgetType {
    return knownWidgetTypes.has(raw) ? (raw as AtlasWidgetType) : AtlasWidgetType.UNKNOWN;
}

function deserializePostOverview(raw: BackendPostOverview): PostOverview {
    return {
        id: raw.id,
        category: raw.category,
        title: raw.title,
        date: new Date(raw.fromTime),
        description: raw.description,
        coverImageUrl: raw.coverImageUrl,

        /* Mock information not provided by the backend */
        likes: min(max(round(gaussianRandom(100, 100)), 0), 1000),
        comments: min(max(round(gaussianRandom(2, 5)), 0), 10),
        // views: min(max(round(gaussianRandom(1000, 200)), 0), 10000),
    };
}
