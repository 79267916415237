import { OnClickButton, OnClickDiv } from "../../../utils/solidjs";
import { createResource, For, Show } from "solid-js";
import { AimLogo } from "../../ui/components";
import { useLocale } from "../../i18n/context";
import { P } from "../../../utils/typography";
import { tld } from "../../client/client";
import BackButton from "../../ui/BackButton";
import { DevSuspense, LineSkeleton } from "../../ui/skeletons";
import { getApiInstance } from "../../../api";

import { WorkspaceOverview } from "../../../api/services/auth/interface";

export default function WorkspaceStep(props: {
    staticEmail: string;
    onSelectWorkspace: (workspace: WorkspaceOverview) => void;
    onGoBack: OnClickButton;
}) {
    return <WorkspacePicker {...props} />;
}

export function WorkspacePicker(props: {
    staticEmail: string;
    onSelectWorkspace: (workspace: WorkspaceOverview) => void;
    onGoBack?: OnClickButton;
}) {
    const [locale] = useLocale();
    const api = getApiInstance();
    const [workspaces] = createResource(() => api.auth.listWorkspaces(props.staticEmail));

    return (
        <div class="relative flex flex-col items-center gap-3">
            <Show when={props.onGoBack}>
                <div class="left-0 top-0 absolute">
                    <BackButton onClick={props.onGoBack} />
                </div>
            </Show>
            <AimLogo />
            <DevSuspense fallback={<WorkspaceListSkeleton />}>
                <Show when={workspaces()}>
                    {workspaces => (
                        <WorkspaceList
                            workspaces={workspaces()}
                            email={props.staticEmail}
                            onSelectWorkspace={workspace => {
                                if (workspace.subdomain === "mycompany") {
                                    props.onSelectWorkspace(workspace);
                                } else {
                                    alert(locale().utils.notAvailableForDemo);
                                }
                            }}
                        />
                    )}
                </Show>
            </DevSuspense>
        </div>
    );
}

function WorkspaceList(props: {
    workspaces: WorkspaceOverview[];
    email: string;
    onSelectWorkspace: (workspace: WorkspaceOverview) => void;
}) {
    const [locale] = useLocale();
    const t = () => locale().auth;

    return (
        <Show when={props.workspaces.length > 0} fallback={<NoWorkspaces email={props.email} />}>
            <div class="max-w-prose">
                <P>{t().workspaceStepPlease}</P>
                <P class="text-sm">{t().youCanChangeItLater}</P>
                <For each={props.workspaces}>
                    {workspace => (
                        <WorkspaceCard
                            workspace={workspace}
                            onClick={() => props.onSelectWorkspace(workspace)}
                        />
                    )}
                </For>
            </div>
        </Show>
    );
}

function WorkspaceListSkeleton() {
    const [locale] = useLocale();
    const t = () => locale().auth;

    return (
        <div>
            <LineSkeleton>{t().workspaceStepPlease}</LineSkeleton>
            <LineSkeleton>
                <span class="text-sm">{t().youCanChangeItLater}</span>
            </LineSkeleton>
            <WorkspaceCardSkeleton />
            <WorkspaceCardSkeleton />
        </div>
    );
}

function NoWorkspaces(props: { email: string }) {
    const [locale] = useLocale();
    const t = () => locale().auth;

    return (
        <div class="max-w-prose">
            <P>
                {t().noWorkspaces1} <strong class="font-bold">{props.email}</strong>.
            </P>
            <P>{t().noWorkspaces2}</P>
        </div>
    );
}

function WorkspaceCard(props: { workspace: WorkspaceOverview; onClick: OnClickDiv }) {
    return (
        <div
            class="mb-3 rounded-md bg-white px-3 py-2 shadow-light-gray-200"
            role="button"
            onClick={e => props?.onClick(e)}
        >
            <div class="flex items-center gap-4">
                <div class="center-items h-8 w-24">
                    <img src={props.workspace.companyLogoUrl} alt="" class="h-full" />
                </div>
                <div class="text-display-xs">{props.workspace.companyName}</div>
            </div>
            <div class="text-light-gray-700">
                https://
                <span class="px-3 font-bold text-dark-gray-700">{props.workspace.subdomain}</span>
                .aimmanager.{tld}
            </div>
        </div>
    );
}

function WorkspaceCardSkeleton() {
    return <div class="skeleton mb-3 h-20 w-full rounded-md" />;
}
