import { createApiMutation } from "../../utils";
import { CreateAudiencePayload, CreateVariablePayload } from "./interface";

export function createMakeAudienceMutation() {
    return createApiMutation(api => ({
        mutationKey: ["makeAudience"],
        mutationFn: (payload: CreateAudiencePayload) => api.audience.createAudience(payload),
    }));
}

export function createMakeVariableMutation() {
    return createApiMutation(api => ({
        mutationKey: ["variable"],
        mutationFn: (payload: CreateVariablePayload) => api.audience.createVariable(payload),
    }));
}
