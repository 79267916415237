import { StructError, boolean, create, number } from "superstruct";
import { BinaryOperator, Expr, UnaryOperator, Value } from "./expressions";

// This will have all the variable values
export type InterpreterEnvironment = Partial<Record<string, Value>>;

export type InterpreterResult = { type: "ok"; value: Value } | { type: "error" };

/** @remarks - Not called `eval` so we don't call JavaScript's eval by accident. */
export function evaluate(expr: Expr, env: InterpreterEnvironment): InterpreterResult {
    try {
        return { type: "ok", value: EVAL(expr, env) };
    } catch (error) {
        if (error instanceof StructError) return { type: "error" };
        else throw error;
    }
}

function EVAL(expr: Expr, env: InterpreterEnvironment): Value {
    switch (expr.type) {
        case "binOp":
            return applyBinary(expr.op, EVAL(expr.lhs, env), EVAL(expr.rhs, env));
        case "unaryOp":
            return applyUnary(expr.op, EVAL(expr.operand, env));
        case "var":
            return env[expr.identifier];
        case "lit":
            return expr.value;
    }
}

function applyBinary(operator: BinaryOperator, lhs: Value, rhs: Value): Value {
    switch (operator) {
        case "<":
            return num(lhs) < num(rhs);
        case "<=":
            return num(lhs) <= num(rhs);
        case ">":
            return num(lhs) > num(rhs);
        case ">=":
            return num(lhs) >= num(rhs);
        case "==":
            return lhs === rhs;
        case "!=":
            return lhs !== rhs;
        case "and":
            return bool(lhs) && bool(rhs);
        case "or":
            return bool(lhs) || bool(rhs);
    }
}

function applyUnary(operator: UnaryOperator, operand: Value): Value {
    switch (operator) {
        case "not":
            return !create(operand, boolean());
    }
}

const num = (value: Value) => create(value, number());
const bool = (value: Value) => create(value, boolean());
