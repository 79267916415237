import { For, Show } from "solid-js";
import { InputUnion } from "../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { FormWrapper } from "../forms/FormWrapper";
import { RenderInput } from "./FBInputTypeToInput";
import { useFormBuilder } from "./FormBuilderUI";
import { OnSubmit, useFormState } from "../forms/state";
import SubmitButton from "../forms/SubmitButton";
import { parseCondition } from "../forms/conditions/parser";
import { showErrorToast } from "../../utils/errorHandling";
import { evaluate, InterpreterEnvironment } from "../forms/conditions/interpreter";
import { Value } from "../forms/conditions/expressions";
import DeleteButton from "../ui/DeleteButton";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";

export type FormPayload = {
    fields: InputUnion[];
};

export const mockDynamicFormPayload: FormPayload = {
    fields: [
        // {
        //     type: InputTypeEnum.text,
        //     id: "1",
        //     name: "input 1",
        //     label: "Text input",
        //     placeholder: "Text input",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.multiple_choice,
        //     id: "2",
        //     name: "input 2",
        //     label: "Multiple choice input",
        //     placeholder: "input 2",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     choices: [
        //         {
        //             choice: "option 1",
        //             image: null,
        //         },
        //         {
        //             choice: "option 2",
        //             image: null,
        //         },
        //         {
        //             choice: "option 3",
        //             image: null,
        //         },
        //     ],
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.integer,
        //     id: "3",
        //     name: "input 3",
        //     label: "Integer input",
        //     placeholder: "input 3",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.float,
        //     id: "4",
        //     name: "input 4",
        //     label: "Float input",
        //     placeholder: "input 4",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.decimal,
        //     id: "5",
        //     name: "input 5",
        //     label: "Decimal input",
        //     placeholder: "input 5",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.multiple_choice,
        //     id: "2",
        //     name: "input 2",
        //     label: "input 2",
        //     placeholder: "input 2",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     options: ["option 1", "option 2", "option 3"],
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.text,
        //     id: "3",
        //     name: "input 3",
        //     label: "input 3",
        //     placeholder: "input 3",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.text,
        //     id: "4",
        //     name: "input 4",
        //     label: "input 4",
        //     placeholder: "input 4",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.text,
        //     id: "5",
        //     name: "input 5",
        //     label: "input 5",
        //     placeholder: "input 5",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
        // {
        //     type: InputTypeEnum.text,
        //     id: "6",
        //     name: "input 6",
        //     label: "input 6",
        //     placeholder: "input 6",
        //     required: true,
        //     skippable: false,
        //     style_class: null,
        //     validators: [],
        // },
    ],
};
export function EditableForm(props: { onSelectField?: () => void; readonly?: boolean }) {
    const formBuilder = useFormBuilder();
    return (
        <FormWrapper>
            <For each={formBuilder?.payload.fields}>
                {field => {
                    return (
                        <div
                            class="flex rounded-xs py-2 hover:bg-light-gray-200"
                            classList={{
                                "bg-warning-100": formBuilder.selectedField() === field.id,
                            }}
                        >
                            <div
                                class="w-full px-2 py-1"
                                onClick={() => {
                                    formBuilder.setSelectedField(field.id);
                                    props.onSelectField?.();
                                }}
                            >
                                <RenderInput {...field} />
                            </div>
                            <DeleteButton onClick={() => formBuilder.removeField(field.id)} />
                        </div>
                    );
                }}
            </For>
        </FormWrapper>
    );
}

export function RenderDynamicForm<TFormValues extends Record<string, NonNullable<Value>>>(props: {
    fields: InputUnion[];
    onSubmit?: OnSubmit<TFormValues>;
    readonly?: boolean;
}) {
    return (
        <FormWrapper class="flex flex-col gap-3" onSubmit={props.onSubmit}>
            <RenderDynamicFields fields={props.fields} />
            <SubmitButton submittingText="Enviando...">Enviar</SubmitButton>
        </FormWrapper>
    );
}

/** Renders fields according to their `show_if` conditions. */
export function RenderDynamicFields(props: { fields: InputUnion[] }) {
    const [locale] = useLocale();
    const form = useFormState<Record<string, NonNullable<Value>>>();

    return (
        <For each={props.fields} fallback={<P>{locale().forms.withoutFields}</P>}>
            {field => (
                <Show when={shouldShow(field, form.values)}>
                    <RenderInput {...field} />
                </Show>
            )}
        </For>
    );
}

function shouldShow(field: InputUnion, env: InterpreterEnvironment): boolean {
    if (!field.show_if) return true;
    const parseResult = parseCondition(field.show_if);
    if (parseResult.type === "error") {
        showErrorToast(`La condición del campo "${field.label}" tiene errores de sintaxis.`);
        return true;
    }
    const interpreterResult = evaluate(parseResult.expr, env);
    if (interpreterResult.type === "error") {
        showErrorToast(
            `La condición del campo "${field.label}" tuvo un error durante su evaluación.`,
        );
        return true;
    }
    return !!interpreterResult.value;
}
