import { Switch, Match } from "solid-js";
import { FunctionParser } from "../../../../api/services/logic-parser/interface";
import IntegerField from "../../../forms/fields/IntegerField";
import SelectField from "../../../forms/fields/SelectField";
import TextField from "../../../forms/fields/TextField";

export function FunctionParserInputDispatcher(props: {
    func: FunctionParser;
    index: number;
    defaultValue?: string;
}) {
    const getDefaultValue = () => {
        switch (props.func.input_type) {
            case "int":
                return Number(props.defaultValue);
            case "str":
                return String(props.defaultValue);
            default:
                return props.defaultValue;
        }
    };
    return (
        <Switch
            fallback={
                <TextField
                    name={`entities[${props.index}].filterValue`}
                    label={props.func.function}
                    defaultValue={getDefaultValue() as string}
                />
            }
        >
            <Match when={(props.func.options?.length ?? 0) > 0}>
                <SelectField
                    onChange={e => {
                        console.log(e.currentTarget.value);
                    }}
                    name={`entities[${props.index}].filterValue`}
                    label={"Filtro"}
                    defaultValue={getDefaultValue() as string}
                    options={[
                        ["", "Seleccione una opción"],
                        ...(props.func.options as unknown as [
                            value: string | number,
                            label: string | number,
                        ][]),
                    ]}
                />
            </Match>
            <Match when={props.func.input_type == "int"}>
                <IntegerField
                    name={`entities[${props.index}].filterValue`}
                    label={"Filtro"}
                    defaultValue={getDefaultValue() as string}
                />
            </Match>
            <Match when={props.func.input_type == "str"}>
                <TextField
                    name={`entities[${props.index}].filterValue`}
                    label={"Filtro"}
                    defaultValue={getDefaultValue() as string}
                />
            </Match>
        </Switch>
    );
}
