import { createUniqueId, For, untrack } from "solid-js";
import { createField } from "../state";
import { selectIcon, selectStyling } from "../presentation";
import { InputWrapper } from "./parts/icons";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { FieldProps } from "./parts/types";

export default function SelectField(
    props: FieldProps<string | number, HTMLSelectElement> & {
        options: (string | number)[][];
    },
) {
    const [firstOptionValue] = untrack(() => props.options[0]) ?? [""];
    const field = createField(props, firstOptionValue);
    const inputId = createUniqueId();

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <InputWrapper icon={selectIcon}>
                <select
                    {...getCommonInputProps(inputId, props)}
                    {...selectStyling}
                    onInput={event => {
                        // currentTarget.value is always a string, but the option value may be a number
                        field.setValue(
                            typeof props.options[0][0] === "string"
                                ? String(event.currentTarget.value)
                                : Number(event.currentTarget.value),
                        );
                    }}
                >
                    <For each={props.options}>
                        {([optionValue, label]) => (
                            <option
                                value={optionValue}
                                selected={String(optionValue) === String(field.value)}
                            >
                                {label}
                            </option>
                        )}
                    </For>
                </select>
            </InputWrapper>
        </LabelAbove>
    );
}
