import { useLocale } from "../../i18n/context";
import { Hr, P, Strong } from "../../../utils/typography";
import BackButton from "../../ui/BackButton";
import { createEffect, createSignal, For, Show, untrack } from "solid-js";
import { DynamicEmailMethodsForm, FederatedMethodList } from "../SignInMethodWidget";
import SupportParagraph from "../../../utils/SupportParagraph";
import {
    ButtonSkeleton,
    DevSuspense,
    HeadingSkeleton,
    InputSkeleton,
    LineSkeleton,
} from "../../ui/skeletons";
import { parsedEnv } from "../../../utils/parsedEnv";
import { FormWrapper } from "../../forms/FormWrapper";
import CheckboxField from "../../forms/fields/CheckboxField";
import {
    deserializeSignInMethod,
    FrontOrganization,
} from "../../../api/services/organization/interface";
import { createOrganizationBySubdomainQuery } from "../../../api/services/organization/queries";
import { firebaseAuthService } from "../../../api/services/auth/implementations/firebase";
import _ from "lodash";
import {
    EmailSignInMethod,
    FederatedSignInMethod,
    FrontSignInMethod,
    isEmailMethod,
    mockSignInMethod,
    SignInMethodType,
} from "../../../api/services/organization/signInMethods";

export default function SignInMethodStep(props: {
    subdomain: string;
    defaultEmail: string | undefined;
    onMagicLinkSent: (email: string) => void;
    onContinueWithPassword: (organization: FrontOrganization, email: string) => void;
    onGoBack: () => void;
}) {
    const organizationQuery = createOrganizationBySubdomainQuery(() => props.subdomain);
    return (
        <div class="flex max-w-full flex-col items-start bg-inherit">
            <BackButton onClick={props.onGoBack} class={"!text-primary-900"} />
            <DevSuspense fallback={<SignInMethodStepSkeleton />}>
                <Show
                    when={organizationQuery.data}
                    fallback={<OrganizationNotFound subdomain={props.subdomain} />}
                >
                    {organization => <OrganizationFound organization={organization()} {...props} />}
                </Show>
            </DevSuspense>
        </div>
    );
}

function OrganizationFound(props: {
    organization: FrontOrganization;
    defaultEmail: string | undefined;
    onMagicLinkSent: (email: string) => void;
    onContinueWithPassword: (organization: FrontOrganization, email: string) => void;
    onGoBack: () => void;
}) {
    firebaseAuthService.useRestartCheck(untrack(() => props.organization));

    const [signInMethods, setSignInMethods] = createSignal(
        untrack(() => props.organization.signInMethods),
    );

    return (
        <div class="flex max-w-full flex-col items-start bg-inherit pt-2">
            <Show when={props.organization.logoUrl}>
                <div class="mb-2 flex justify-center">
                    <img src={props.organization.logoUrl} alt="" class="h-12 sm:h-16" />
                </div>
            </Show>
            <SignInMethodsDevtools
                defaultSignInMethods={props.organization.signInMethods}
                onChangeSignInMethods={setSignInMethods}
            />
            <SignInMethodList
                {...props}
                organization={{ ...props.organization, signInMethods: signInMethods() }}
            />
        </div>
    );
}

function SignInMethodStepSkeleton() {
    return (
        <>
            <OrganizationShipSkeleton />
            <SignInMethodListSkeleton />
        </>
    );
}

function OrganizationNotFound(props: { subdomain: string }) {
    const [locale] = useLocale();
    const t = () => locale().auth;
    return (
        <div class="flex max-w-full flex-col items-start bg-inherit pt-2 text-center">
            <P class={"!mb-0 mt-4 w-full text-center font-medium"}>
                {t().organizationNotFound(props.subdomain)}
            </P>
            <P>{t().organizationNotFoundDescription}</P>
        </div>
    );
}

function OrganizationShipSkeleton() {
    return (
        <div class="mb-4 flex items-center gap-4">
            <div class="skeleton h-16 w-32 rounded-md" />
            <div class="animate-pulse text-display-md text-black/20">&times;</div>
            <div class="skeleton h-16 w-32 rounded-md" />
        </div>
    );
}

function SignInMethodsDevtools(props: {
    defaultSignInMethods: FrontSignInMethod[];
    onChangeSignInMethods: (signInMethods: FrontSignInMethod[]) => void;
}) {
    const signInMethodTypes = Object.values(SignInMethodType);

    const [enabled, setEnabled] = createSignal(
        Object.fromEntries(
            signInMethodTypes.map(type => [
                type,
                props.defaultSignInMethods.some(method => method.type === type),
            ]),
        ) as Record<SignInMethodType, boolean>,
    );

    createEffect(() => {
        props.onChangeSignInMethods(
            signInMethodTypes
                .filter(type => enabled()[type])
                .flatMap(type => {
                    const matchingMethods = props.defaultSignInMethods.filter(
                        method => method.type === type,
                    );
                    if (_.isEmpty(matchingMethods))
                        return deserializeSignInMethod(mockSignInMethod(type));
                    return matchingMethods;
                }),
        );
    });

    return (
        <Show when={parsedEnv.MODE === "development"}>
            <FormWrapper class="fixed right-0 top-0 bg-black/50 text-white">
                <For each={signInMethodTypes}>
                    {type => (
                        <CheckboxField
                            name={type}
                            label={type}
                            defaultValue={enabled()[type]}
                            onChange={() => {
                                setEnabled(prev => ({
                                    ...prev,
                                    [type]: !prev[type],
                                }));
                            }}
                        />
                    )}
                </For>
            </FormWrapper>
        </Show>
    );
}

function SignInMethodList(props: {
    organization: FrontOrganization;
    defaultEmail: string | undefined;
    onMagicLinkSent: (email: string) => void;
    onContinueWithPassword: (organization: FrontOrganization, email: string) => void;
    onGoBack: () => void;
}) {
    const [locale] = useLocale();
    const t = () => locale().auth;

    const federatedMethods = () =>
        props.organization.signInMethods.filter(m => !isEmailMethod(m)) as FederatedSignInMethod[];
    const emailMethods = () =>
        props.organization.signInMethods.filter(isEmailMethod) as EmailSignInMethod[];

    return (
        <Show
            when={props.organization.signInMethods.length > 0}
            fallback={<NoSignInMethods organization={props.organization} />}
        >
            <h1 class="mb-8 text-center text-display-xs font-normal">
                {t().welcomeTo} {props.organization.name}
            </h1>
            {/*<p class="mb-4 max-w-full truncate text-center text-lg text-dark-gray-400">
                {t().myCompanyLink(props.organization.subdomain)}
            </p>*/}
            <div class="flex flex-col gap-4 bg-inherit">
                <FederatedMethodList federatedMethods={federatedMethods()} {...props} />
                <Show when={federatedMethods().length > 0 && emailMethods().length > 0}>
                    <Hr>{t().or}</Hr>
                </Show>
                <Show when={emailMethods().length > 0}>
                    <DynamicEmailMethodsForm emailMethods={emailMethods()} {...props} />
                </Show>
            </div>
        </Show>
    );
}

function SignInMethodListSkeleton() {
    return (
        <div class="w-full">
            <HeadingSkeleton />
            <LineSkeleton />
            <ButtonSkeleton />
            <LineSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <ButtonSkeleton />
        </div>
    );
}

/** It shouldn't happen, but here we handle the case where an organization
 * doesn't allow any sign-in method. */
function NoSignInMethods(props: { organization: FrontOrganization }) {
    const [locale] = useLocale();

    return (
        <div class="max-w-prose">
            <P>
                {locale().auth.noSignInMethods1}
                <Strong>{props.organization.name}</Strong>
                {locale().auth.noSignInMethods2}
            </P>
            <SupportParagraph />
        </div>
    );
}
