import {
    defensive,
    deserializeInstantDefensive,
    sDateTimeString,
    ServiceQuery,
} from "../../../utils";
import { boolean, nullable, string, Struct, type } from "superstruct";

export interface WorkflowCategoryEndpoints {
    listWorkflowCategories: ServiceQuery<
        [queryParams?: WorkflowCategoriesQueryParams],
        WorkflowCategory[]
    >;

    createWorkflowCategory(body: CreateWorkflowCategory): Promise<WorkflowCategory>;
    retrieveWorkflowCategory: ServiceQuery<[categoryId: string], WorkflowCategory>;
    updateWorkflowCategory(body: UpdateWorkflowCategory): Promise<void>;
    destroyWorkflowCategory(categoryId: string): Promise<void>;
}

export type WorkflowCategoriesQueryParams = { workflow_type?: string };

export type WorkflowCategory = Omit<WorkflowCategoryJson, "created_at" | "updated_at"> & {
    created_at?: Temporal.Instant;
    updated_at?: Temporal.Instant;
};

export type WorkflowCategoryJson = {
    id: string;
    created_at?: string;
    updated_at?: string;
    name: string;
    icon: string;
    description: string;
    starred: boolean;
    parent: string | null;
};

export function sWorkflowCategory(): Struct<WorkflowCategoryJson> {
    return type({
        id: string(),
        created_at: defensive(sDateTimeString(), undefined),
        updated_at: defensive(sDateTimeString(), undefined),
        name: string(),
        icon: defensive(string(), ""),
        description: defensive(string(), ""),
        starred: defensive(boolean(), false),
        parent: defensive(nullable(string()), null),
    });
}
export function deserializeWorkflowCategory(json: WorkflowCategoryJson): WorkflowCategory {
    return {
        ...json,
        created_at: deserializeInstantDefensive(json.created_at),
        updated_at: deserializeInstantDefensive(json.updated_at),
    };
}

export type CreateWorkflowCategory = {
    name: string;
    icon: string;
    description?: string;
    starred?: boolean;
} & (
    | {
          parent: string | null;
      }
    | {
          workflow_type: string;
      }
);

export type UpdateWorkflowCategory = Partial<CreateWorkflowCategory> & {
    id: string;
};
