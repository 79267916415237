import { For } from "solid-js";
import { InfoBubble } from "../ui/InfoBubble";
import { Table } from "../ui/Table";
import { AimLogo, Button, Icon } from "../ui/components";
import { useLocale } from "../i18n/context";

type WorkSpace = {
    id: number;
    name: string;
    code: string;
    businessLines: string[];
    roles: string[];
    address: string;
};
const workSpaces: WorkSpace[] = [
    {
        id: 1,
        name: "Plaza Santiago Norte",
        code: "PS-N",
        businessLines: ["Grandes Superficies", "Stand Alone"],
        roles: ["Gerente Comercial", "Comercial grandes superficies"],
        address: "Av. Vitacura 2670, Las Condes, Santiago, Chile",
    },
];

export function WorkPlacesTable() {
    const [l] = useLocale();
    return (
        <Table
            class={""}
            theadClass={"text-left border-b border-light-gray-300 [&>tr>th]:py-2"}
            tbodyTrClass={"border-b border-light-gray-300 [&>td]:py-2 px-1"}
            data={workSpaces}
            columns={[
                {
                    title: "ID",
                    cell: () => (
                        <div
                            class={
                                "flex h-10 w-10 items-center justify-center rounded-xs border border-light-gray-300"
                            }
                        >
                            <AimLogo class={"h-8 w-full max-w-8"} />
                        </div>
                    ),
                },
                {
                    title: l().personalData.name,
                    accessor: "name",
                },
                {
                    title: l().personalData.businessLine,
                    cell: row => (
                        <div class={"flex max-w-64 flex-wrap gap-1"}>
                            <For each={row.businessLines}>{line => <InfoBubble text={line} />}</For>
                        </div>
                    ),
                },
                {
                    title: l().personalData.roles,
                    cell: row => (
                        <div class={"flex max-w-64 flex-wrap gap-1"}>
                            <For each={row.roles}>{line => <InfoBubble text={line} />}</For>
                        </div>
                    ),
                },
                {
                    title: l().personalData.address,
                    accessor: "address",
                },
                {
                    title: "",
                    cell: () => (
                        <div>
                            <Button
                                onClick={() => alert("See workspace detail WIP.")}
                                bgStyle={"outline"}
                                class={"!border-light-gray-300 !p-2"}
                            >
                                <Icon name="fas fa-eye text-dark-gray-400" />
                            </Button>
                        </div>
                    ),
                },
            ]}
        />
    );
}
