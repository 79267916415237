import { registerPlugin } from "@capacitor/core";

export interface DefaultBrowserPlugin {
    /** Returns the package name of the default browser app configured on this device,
     * or null if we don't know.
     *
     * @example
     * const { value } = await DefaultBrowser.getDefaultBrowser();
     * value === "org.mozilla.firefox"
     *
     * @remarks
     * Android setup https://developer.android.com/training/package-visibility/use-cases#check-browser-available
     */
    getDefaultBrowser(): Promise<{ value: string | null }>;
}

const DefaultBrowser = registerPlugin<DefaultBrowserPlugin>("DefaultBrowser");

export default DefaultBrowser;
