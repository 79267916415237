export function formatDateTime(date: Date): string {
    return date
        .toLocaleDateString("es-CL", {
            day: "numeric",
            year: "numeric",
            month: "short",
            hour: "numeric",
            minute: "numeric",
        })
        .replace(",", " a las");
}

export function formatApproxNumber(n: number): string {
    if (n < 1_000) return n.toString();
    if (n < 1_000_000) return `${Math.round(n / 100) / 10}K`;
    return `${Math.round(n / 100_000) / 10}M`;
}
