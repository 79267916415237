import { Button } from "../modules/ui/components";
import { useLocale } from "../modules/i18n/context";
import { H1, P } from "./typography";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "@solidjs/router";
import { Show } from "solid-js";
import { PublicPageWithNavbarWrapper } from "../modules/ui/pageWrappers";

export default function RouteNotFoundPage() {
    const [locale] = useLocale();
    const t = () => locale().utils.routeNotFound;
    const navigate = useNavigate();

    return (
        <PublicPageWithNavbarWrapper>
            <div class="flex max-w-prose flex-col">
                <H1>{Capacitor.isNativePlatform() ? t().titleNative : t().titleWeb}</H1>
                <Show when={!Capacitor.isNativePlatform()} fallback={<P>{t().messageNative}</P>}>
                    <P>{t().messageWeb1}</P>
                    <P>{t().messageWeb2}</P>
                </Show>
                <Button class="self-center" icon="fas fa-home" onClick={() => navigate("/")}>
                    {t().goHome}
                </Button>
            </div>
        </PublicPageWithNavbarWrapper>
    );
}
