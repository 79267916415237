/* eslint-disable solid/style-prop */
// noinspection CssInvalidHtmlTagReference

import { createEffect, onMount, Show, Suspense } from "solid-js";
import { PageWrapper } from "../ui/pageWrappers";
import Warning from "../ui/Warning";
import {
    Adornment,
    AutoScale,
    Binding,
    ClickCreatingTool,
    CycleMode,
    Diagram,
    GraphLinksModel,
    GraphObject,
    LayeredDigraphLayering,
    LayeredDigraphLayout,
    Margin,
    Node,
    Panel,
    Part,
    Point,
    Shape,
    Size,
    Spot,
    Stretch,
    TextBlock,
} from "gojs";
import { initThemes } from "./themes";
import { createRef } from "../../utils/reactRefs";
import { OrganizationChartDebug } from "./OrganizationChartDebug";
import { createListPositionsQuery } from "../../api/services/positions/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import mockOrganizationChartGraph from "./mockOrganizationChartGraph.json";
import {
    OrganizationChartGraph,
    OrganizationChartNode,
    PersonLite,
} from "../../api/services/positions/interface";
import { createModalController, ModalController } from "../ui/Modal";
import SidePanel from "../ui/SidePanel";
import { JsonDebug } from "../../utils/debug";

const $ = GraphObject.make;
export default function OrganizationChartPage() {
    return (
        <PageWrapper>
            <Warning>Esto es una maqueta incompleta del Organigrama.</Warning>
            <OrganizationChartDebug />
            <OrganizationChart />
        </PageWrapper>
    );
}

function OrganizationChart() {
    const positionsQuery = createListPositionsQuery();

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={positionsQuery.data} fallback={<ErrorBlock error={positionsQuery.error} />}>
                {positions => <OrganizationChartRender graph={mockOrganizationChartGraph} />}
            </Show>
        </Suspense>
    );
}

function OrganizationChartRender(props: {
    //chartNodes: ChartNode[],
    graph: OrganizationChartGraph;
}) {
    let diagram: Diagram;
    const divRef = createRef<HTMLDivElement>();
    const panel = createModalController<OrganizationChartNode, void>();
    onMount(() => {
        diagram = createDiagram(divRef.current!);
        const ctx: ChartContext = { diagram, panel };
        diagram.nodeTemplate = createNodeTemplate(ctx);
        diagram.nodeTemplate.contextMenu = createContextMenu(diagram);
        diagram.linkTemplate = createLinkTemplate(ctx);
        initThemes(diagram);
    });

    createEffect(() => {
        diagram.model = new GraphLinksModel(
            props.graph.nodes.map(node => ({
                ...node,
                key: node.id,
            })),
            props.graph.edges.map(edge => ({
                ...edge,
                visible: true, // needed for CollapseOrExpandButton to work correctly
            })),
        );
    });

    const height = 999;
    return (
        <>
            <div
                ref={divRef}
                style={`border: 1px solid black; height: ${height}px; position: relative; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); background-color: rgb(31, 41, 55); cursor: auto;`}
            >
                <canvas
                    tabindex="0"
                    width="1246"
                    height={height}
                    style={`position: absolute; top: 0px; left: 0px; z-index: 2; user-select: none; touch-action: none; width: 1246px; height: ${height}px; cursor: auto`}
                />
                <div
                    style={`position: absolute; overflow: auto; width: 1246px; height: ${height}px; z-index: 1;`}
                >
                    <div style="position: absolute; width: 1902.58px; height: 1px;" />
                </div>
            </div>
            <SidePanel controller={panel} position="right">
                {node => <JsonDebug value={node} />}
            </SidePanel>
        </>
    );
}

function createDiagram(div: Element) {
    return new Diagram(div, {
        allowCopy: false,
        allowDelete: false,
        initialAutoScale: AutoScale.UniformToFill,
        maxSelectionCount: 1, // users can select only one part at a time
        validCycle: CycleMode.DestinationTree, // make sure users can only create trees
        "clickCreatingTool.archetypeNodeData": {
            // allow double-click in background to create a new node
            name: "(New person)",
            title: "(Title)",
            department: "(department)",
        },
        "clickCreatingTool.insertPart": function (this: { diagram: Diagram }, loc: Point) {
            // method override must be function, not =>
            const node = ClickCreatingTool.prototype.insertPart.call(this, loc);
            if (node !== null) {
                this.diagram.select(node);
                this.diagram.commandHandler.scrollToPart(node);
                this.diagram.commandHandler.editTextBlock(node.findObject("NAMETB") as TextBlock);
            }
            return node;
        },
        // layout: $(TreeLayout, {
        //     treeStyle: TreeStyle.LastParents,
        //     arrangement: TreeArrangement.Horizontal,
        //     // properties for most of the tree:
        //     angle: 90,
        //     layerSpacing: 35,
        //     // properties for the "last parents":
        //     alternateAngle: 90,
        //     alternateLayerSpacing: 35,
        //     alternateAlignment: TreeAlignment.Bus,
        //     alternateNodeSpacing: 20,
        // }),
        layout: new LayeredDigraphLayout({
            direction: 90,
            layeringOption: LayeredDigraphLayering.LongestPathSource,
        }),
        "undoManager.isEnabled": true, // enable undo & redo
        "themeManager.changesDivBackground": true,
        //"themeManager.currentTheme": document.getElementById("theme").value
    });
}

interface ChartContext {
    diagram: Diagram;
    panel: ModalController<OrganizationChartNode, void>;
}

function createNodeTemplate(ctx: ChartContext): Node {
    return (
        new Node(Panel.Spot, {
            isShadowed: true,
            shadowOffset: new Point(0, 2),
            selectionObjectName: "BODY",
        })
            .add(
                new Panel(Panel.Auto, { name: "BODY" }).add(
                    // define the node's outer shape
                    new Shape("RoundedRectangle", {
                        name: "SHAPE",
                        strokeWidth: 0,
                        portId: "",
                        spot1: Spot.TopLeft,
                        spot2: Spot.BottomRight,
                    }).theme("fill", "background"),
                    new Panel(Panel.Table, { margin: 0.5, defaultRowSeparatorStrokeWidth: 0.5 })
                        .theme("defaultRowSeparatorStroke", "divider")
                        .add(
                            new Panel(Panel.Table, { padding: new Margin(18, 18, 18, 24) }).add(
                                new Panel(Panel.Vertical, {
                                    column: 0,
                                    alignment: Spot.Left,
                                    stretch: Stretch.Vertical,
                                    defaultAlignment: Spot.Left,
                                }).add(
                                    new Panel(Panel.Horizontal).add(
                                        new TextBlock({
                                            editable: true,
                                            minSize: new Size(10, 14),
                                        })
                                            .bindTwoWay("text", "title")
                                            .theme("stroke", "text")
                                            .theme("font", "title"),
                                        DepartmentBadge(ctx),
                                    ),
                                    PeopleSummary(ctx),
                                ),
                                new Panel(Panel.Spot, { isClipping: true, column: 1 }).add(
                                    new Shape("Circle", {
                                        desiredSize: new Size(50, 50),
                                        strokeWidth: 0,
                                    }),
                                ),
                            ),
                        ),
                ), // end Auto Panel
                new Shape("RoundedLeftRectangle", {
                    alignment: Spot.Left,
                    alignmentFocus: Spot.Left,
                    stretch: Stretch.Vertical,
                    width: 6,
                    strokeWidth: 0,
                }).themeObject("fill", "", "levels", findLevelColor),
                $(
                    "Button",
                    $(Shape, "PlusLine", {
                        width: 8,
                        height: 8,
                        stroke: "#0a0a0a",
                        strokeWidth: 2,
                    }),
                    {
                        name: "BUTTON",
                        alignment: Spot.Right,
                        opacity: 0, // initially not visible
                        click: (e, button) => addEmployee(button.part, ctx.diagram),
                    },
                    // button is visible either when node is selected or on mouse-over
                    new Binding("opacity", "isSelected", s => (s ? 1 : 0)).ofObject(),
                ),
                CollapseOrExpandButton(ctx),
            )
            .theme("shadowColor", "shadow")
            // for sorting, have the Node.text be the data.title
            .bind("text", "title")
            // bind the Part.layerName to control the Node's layer depending on whether it isSelected
            .bindObject("layerName", "isSelected", sel => (sel ? "Foreground" : ""))
            //.bindTwoWay("isCollapsed")
            .bind("visible")
    );
}

function createLinkTemplate(ctx: ChartContext) {
    return ctx.diagram.linkTemplate.bind("visible");
}

// Allows to collapse/expand a "subtree", even for DAGs
function CollapseOrExpandButton(_ctx: ChartContext) {
    /* go.js comes with a "TreeExpanderButton", but it only works correctly for trees.
     * So we make a custom implementation that works for DAGs.
     */
    return (
        $("Button", {
            alignment: Spot.Bottom,
            desiredSize: new Size(20, 20),

            click: (e, obj) => {
                const node = obj.part as Node;
                e.diagram.startTransaction();

                /* Nodes have these relevant properties:
                 * - node.data.isCollapsed means we clicked the collapse button on this node
                 * - node.data.visible means this node should be rendered
                 *
                 * Links:
                 * - link.data.visible means this link should be rendered
                 *
                 * Setting visible to false on any of the parts will make the layout to rearrange.
                 */

                // The easy part: clicking the button will toggle the isCollapsed state of that node
                e.diagram.model.setDataProperty(node.data, "isCollapsed", !node.data.isCollapsed);

                /* The built-in TreeExpanderButton hides all descendant nodes, but when testing
                 * alternative behaviors, we found it more natural to hide the links to the children instead.
                 */
                for (const link of node.findLinksOutOf()) {
                    e.diagram.model.setDataProperty(link.data, "visible", !link.data.visible);
                }

                /* The hard part is that just hiding the links will leave some orphaned subtrees.
                 * We don't want to render those subtrees, so let's hide the orphaned nodes.
                 */
                for (const node of e.diagram.nodes) {
                    e.diagram.model.setDataProperty(
                        node.data,
                        "visible",
                        canReachRootThroughVisibleLinks(node),
                    );
                }

                e.diagram.commitTransaction("toggled visibility of dependencies");
            },
        })
            // hide the button for leaf nodes as it doesn't make sense to collapse them
            .bindObject("visible", "isTreeLeaf", leaf => !leaf)
            .add(
                new Shape({
                    name: "ButtonIcon",
                    figure: "MinusLine",
                    desiredSize: new Size(6, 6),
                }).bind("figure", "isCollapsed", collapsed =>
                    collapsed ? "PlusLine" : "MinusLine",
                ),
            )
    );
}

function canReachRootThroughVisibleLinks(node: Node): boolean {
    // This may not be the most efficient way, but it's very simple

    if (node.isTreeRoot) return true;
    return node
        .findLinksInto()
        .filter(link => link.visible)
        .any(link => canReachRootThroughVisibleLinks(link.fromNode!));
}

function DepartmentBadge(_ctx: ChartContext) {
    return new Panel(Panel.Auto, {
        margin: new Margin(0, 0, 0, 10),

        // needed because .bind doesn't run if department is undefined
        opacity: 0,
        pickable: false,
    })
        .bind("opacity", "department", dept => (dept ? 1 : 0))
        .bind("pickable", "department", dept => !!dept)
        .add(
            new Shape("Capsule", {
                parameter1: 6,
                parameter2: 6,
            })
                .theme("fill", "badge")
                .theme("stroke", "badgeBorder"),
            new TextBlock({
                editable: true,
                minSize: new Size(10, 12),
                margin: new Margin(2, -1),
            })
                .bindTwoWay("text", "department")
                .theme("stroke", "badgeText")
                .theme("font", "badge"),
        );
}

function PeopleSummary(ctx: ChartContext) {
    return new Panel(Panel.Vertical, {
        padding: new Margin(10, 20),
        minSize: new Size(100, 10),
    }).add(
        new Panel(Panel.Vertical, {
            defaultStretch: Stretch.Horizontal,
            itemTemplate: PersonSummary(ctx),
        }).bind("itemArray", "persons"),
        new TextBlock({
            alignment: Spot.Left,
            cursor: "Pointer",
            mouseEnter: (_event, obj) => {
                const nodeData = obj.part!.data;
                ctx.diagram.model.setDataProperty(nodeData, "additionalCountHovered", true);
            },
            mouseLeave: (_event, obj) => {
                const nodeData = obj.part!.data;
                ctx.diagram.model.setDataProperty(nodeData, "additionalCountHovered", false);
            },
            click: (_event, obj) => ctx.panel.open(obj.part!.data),
        })
            .bind("text", "additional_count", n => `y ${n} más`)
            .bind("opacity", "additional_count", n => (n > 0 ? 1 : 0))
            .theme("font", "normal")
            .themeData("stroke", "additionalCountHovered", undefined, convertSelectedToThemeProp),
    );
}

function PersonSummary(ctx: ChartContext) {
    const convertSelectedToThemeProp = (s: unknown) => (s ? "textHighlight" : "text");

    return new Panel(Panel.Vertical, {
        minSize: new Size(100, 45),
    }).add(
        new TextBlock({
            mouseEnter: (_event, obj) => {
                const idx = obj.panel!.itemIndex;
                const nodeData = obj.part!.data;
                const newPersons = [...nodeData.persons];
                newPersons[idx] = { ...newPersons[idx], isHovered: true };
                ctx.diagram.model.setDataProperty(nodeData, "persons", newPersons);
            },
            mouseLeave: (_event, obj) => {
                const idx = obj.panel!.itemIndex;
                const nodeData = obj.part!.data;
                const newPersons = [...nodeData.persons];
                newPersons[idx] = { ...newPersons[idx], isHovered: false };
                ctx.diagram.model.setDataProperty(nodeData, "persons", newPersons);
            },
            cursor: "Pointer",
            alignment: Spot.Left,
            click: (_event, obj) => {
                const person: PersonLite = obj.panel!.data;
                const position: OrganizationChartNode = obj.part!.data;
                console.log("clicked person", { person, position });
            },
        })
            .bind("text", "name")
            .theme("font", "normal")
            .themeData("stroke", "isHovered", undefined, convertSelectedToThemeProp),
        new TextBlock({
            alignment: Spot.Left,
        })
            .bind("text", "primary_email")
            .theme("font", "email")
            .theme("stroke", "subtext"),
    );
}

const convertSelectedToThemeProp = (s: boolean) => (s ? "textHighlight" : "text");

function createContextMenu(diagram: Diagram): Adornment {
    return $(
        "ContextMenu",
        $("ContextMenuButton", $(TextBlock, "Add Employee"), {
            click: (e, button) => addEmployee(getNode(button), diagram),
        }),
        $("ContextMenuButton", $(TextBlock, "Remove Role"), {
            click: (e, button) => {
                // reparent the subtree to this node's boss, then remove the node
                const node = getNode(button);
                if (node !== null) {
                    diagram.model.commit(m => {
                        const chl = node.findTreeChildrenNodes();
                        // iterate through the children and set their parent key to our selected node's parent key
                        while (chl.next()) {
                            const emp = chl.value;
                            m.setKeyForNodeData(emp.data, node.findTreeParentNode()!.data.key);
                        }
                        // and now remove the selected node itself
                        m.removeNodeData(node.data);
                    }, "reparent remove");
                }
            },
        }),
        $("ContextMenuButton", $(TextBlock, "Remove Department"), {
            click: (e, button) => {
                // remove the whole subtree, including the node itself
                const node = getNode(button);
                if (node !== null) {
                    diagram.commit(d => d.removeParts(node.findTreeParts()), "remove department");
                }
            },
        }),
    );
}

function getNode(obj: GraphObject): Node | null {
    return (obj.part as Adornment).adornedPart as Node | null;
}

function findLevelColor(node: Node) {
    return node.findTreeLevel();
}

function addEmployee(node: Part | null, diagram: Diagram) {
    if (!node) return;
    const thisemp = node.data;
    let newnode;
    diagram.model.commit(m => {
        const newemp = {
            name: "(New person)",
            title: "(Title)",
            department: thisemp.department,
            parent: thisemp.key,
        };
        m.addNodeData(newemp);
        newnode = diagram.findNodeForData(newemp);
        // set location so new node doesn't animate in from top left
        if (newnode) newnode.location = node.location;
    }, "add employee");
    diagram.commandHandler.scrollToPart(newnode);
}
