import { Locale } from "../Locale";
import { WorkflowCompletionStatus } from "../../../api/services/workflow/interface";
import { Priority } from "../../../api/services/task-manager/interface";

export const en: Locale = {
    name: "English",
    codeWithCountry: "en-US",

    activities: {
        noActivitiesAtAll: "You have no activities, enjoy!",
        noActivitiesThisDay: "You have no activities this day.",
        showAvailable: "Show available",
        priorities: {
            [Priority.Reminder]: "Reminder",
            [Priority.ThreeUpArrows]: "Critical",
            [Priority.TwoUpArrows]: "Very high",
            [Priority.OneUpArrow]: "High",
            [Priority.Medium]: "Medium",
            [Priority.OneDownArrow]: "Low",
            [Priority.TwoDownArrows]: "Very low",
            [Priority.ThreeDownArrows]: "Lowest",
        },
    },

    auth: {
        welcome: "Welcome",
        welcomeTo: "Welcome to",
        organizationUrl: "Enter your organization's URL",
        forgotOrganizationUrl: "Forgot your organization's URL?",
        findYourOrganization: "Find your organization",
        letsFindYourOrganization: "Let's find your organization",
        weSuggestUseYourEnterpriseEmail: "We suggest you use your enterprise email.",
        continueWithEmail: "Continue with Email",
        continuingWithEmail: "Continuing with Email...",
        magicLinkParagraph:
            "By using the magic link, we'll send you an email with a link to sign in without a password.",
        wrongMagicCode: "Wrong code. Please try again.",
        youAreNotInOrganization: (company: string) => `You are not registered in ${company}`,
        workspaceStepPlease: "Please select which workspace you'd like to sign into.",
        youCanChangeItLater: "You can change it later from the navigation bar.",
        noWorkspaces1: "Sorry, we couldn't find any workspaces associated with",
        noWorkspaces2: "Please check that it's correct or try another email.",
        loginWithGoogle: "Sign in with Google",
        or: "OR",
        email: "Email",
        emailPlaceholder: "your.name@company.com",
        continueWithMagicLink: "Continue with Magic Link",
        continuingWithMagicLink: "Continuing with Magic Link...",
        continueWithPassword: "Continue with Password",
        password: "Password",
        forgotPassword: "Forgot your password?",
        passwordResetEmailSent: "We've sent you an email with instructions to reset your password.",
        signIn: "Sign in",
        signingIn: "Signing in...",
        passwordRequired: "You must enter a password",
        wrongPassword: "Wrong password. Please try again.",
        noSignInMethods1: "Oops, there are no sign-in methods available for ",
        noSignInMethods2: ".",
        notImplementedWidget1: "Oops, ",
        notImplementedWidget2: " allows you to sign in through ",
        notImplementedWidget3:
            ", but that sign-in method is not yet available on this version of the AIM Manager app.",
        sessionExpired: "Your session has expired. Please sign in again.",
        createInvite: "Create invite",
        createInviteDescription: "Create an invite for a new user.",
        sendEmail: "Send email",
        validateEmail: "Validate email",
        validatingEmail: "Validating email...",
        emailLinkSent: "Email link sent",
        continue: "Continue",
        continuing: "Continuing...",
        myCompanyLink: (companyName: string) => `https://${companyName}.aimmanager.com`,
        invalidEmail: "Invalid email",
        checkYourEmail: "Check your email",
        weHaveSentACode_1: "We have sent a 6-digit code to the email",
        weHaveSentACode_2: "The code has an expiration, we recommend you use it soon.",
        cantFindTheCode: "Can't find the code?",
        cantFindTheMagicLink: "Can't find the magic link?",
        checkSpam: "Check your spam folder.",
        weHaveSentLinkToChangePassword: "We have sent a link to change the password to the email",
        weHaveSentMagicLink: "We have sent a magic link to sign in to the email",
        signInWithMagicLink: "Sign in with Magic Link",
        signingInWithMagicLink: "Signing in with Magic Link",
        resendEmail: "Resend email",
        enterNewEmail: "Enter new email",
        contactSupport: "Contact support",
        people: "People",
        searchUser: "Search user",
        createPerson: "Create person",
        restartNeeded: organizationName =>
            `For technical reasons, before signing in to ${organizationName}, we need you to restart the app. Please reopen the app manually after closing this message. Sorry for the inconvenience.`,
        exportPeopleToXLSX: "Export people to XLSX",
        peopleBulkLoad: "People massive load",
        peopleSearchLabel: "Enter the data of the person you are looking for",
        organizationNotFound: organizationName => `Organization ${organizationName} not found.`,
        organizationNotFoundDescription: "If the problem persists, contact your administrator.",
    },

    checklists: {
        noSections: "This checklist has no sections yet.",
        noSteps: "There are no steps yet.",
        checklistExecutions: "Checklist executions",
        noChecklistExecutions: "There are no checklist executions yet.",
    },

    forms: {
        requiredError: "This field is required",
        select: "Select",
        optional: "optional",
        withoutFields: "This form doesn't have fields yet.",
        simulateSubmit: "Simulate submit",
        simulationResult: "The following process variables would be submitted:",
    },

    menu: {
        pinnedTabs: {
            home: "Home",
            tools: "Tools",
            organization: "Organization",
            advanced: "Advanced",
            myAccount: "My account",
        },
        search: "Search",
        expanded: {
            supermarket: "Supermarket",
            training: "Training",
            qaTesting: "QA testing",
            complaints: "Complaints",
            selfManagementOfPosts: "Self-management of posts",
            myReports: "My reports",
        },
        logout: "Sign out",
        version: "Version",
        tabs: {
            home: "Home (mock)",
            posts: "Posts (mock)",
            activities: "Activities (mock)",
            linksOfInterest: "Links of interest",
            reports: "Reports",
            assets: "Assets",
            organization: "Organization (mock)",
            organizationChart: "Organization Chart (mock)",
            checklists: "Checklists",
            audiences: "Audiences",
            workflows: "Workflows",
        },
        notifications: "Notifications",
        profile: "Profile",
    },

    notifications: {
        title: "Notifications",
        noNotifications: "There are no notifications",
        newActivity: "New activity",
        newIncident: "New incident",
        newPost: "New post",
        newReport: "New report",
        systemUpdate: "System update",
        test: "Test notification",
        deleteNotification: "Delete notification",
        noMoreNotifications: "You don't have more notifications yet.",
        permissionDenied: "Looks like you've denied the permission for {notifications}.",
        optOutWarning: organizationName =>
            `AIM Manager respects your choice, however, ${organizationName} may have a policy of required notifications to stay on top of urgent tasks and company announcements.`,
        enableNotifications: "Enable {notifications}",
        nativeNotifications: "push notifications",
        webNotifications: "desktop notifications",
        markAsRead: "Mark as read",
        inboxNotificationsSseError:
            "Due to an error you won't be alerted of new in-app notifications.",
    },

    personalData: {
        welcomeToWorkspace: "Welcome to {company}'s workspace in AIM Manager",
        weCanHelpYou:
            "We help you to correctly use your session in AIM Manager, with a complete on-boarding team that will accompany you at all times to solve doubts and make the implementation of the platform easier with guided training and specialized support.",
        pleaseFillTheForm:
            "Please fill in the following fields. If you don't have time now, you can do it at any time from your user profile.",
        name: "Name",
        roles: "Roles",
        groups: "Groups",
        identityDocumentType: "ID type",
        identityDocumentTypes: {
            CL_CI: "Chilean RUT",
            PASSPORT: "Passport",
            PE_DNI: "Peruvian DNI",
            CO_CC: "Colombian Cédula de ciudadanía",
            MX_CURP: "Mexican CURP",
            MX_RFS: "Mexican RFC",
            MX_INE: "Mexican INE",
            MX_NSS: "Mexican NSS",
            SV_DUI: "Salvadoran DUI",
        },
        identityDocumentNumber: "ID number",
        rutPlaceholder: "12345678-k (dash optional)",
        invalidRut: "Invalid RUT",
        addAnotherIdentityDocument: "Add another identity document",
        email: "Email",
        phone: "Phone number",
        addAnotherPhone: "Add another phone number",
        photo: "Photo",
        optional: "optional",
        addFile: "Add file",
        submit: "Submit",
        submitting: "Submitting...",
        notNow: "Not now",
        editProfile: "Edit profile",
        changeProfilePicture: "Change profile picture",
        saveChanges: "Save changes",
        savingChanges: "Saving changes...",
        personCreated: "Person created",
        emailAlreadyInUse: "Email already in use",
        sendInvitation: "Send an invitation to the person to enter AIM Manager",
        invitationType: "Invitation type",
        wherePersonBelongs: "Where does the person you are creating belong?",
        wherePersonBelongsOptions: {
            ownOrganization: (val: string) => `The person belongs to ${val} (Mandate account)`,
            comercialPartner: "The person belongs to a business partner",
            contractorBusiness: "The person belongs to a contractor business",
        },
        selectWorkspace: `Select the workspace(s) for the person`,
        selectRoles: `Select the role(s) for the person`,
        dontFindRole: `Don't find the role you're looking for? You can create a role`,
        tabs: {
            documentsTab: "Documents",
            historyTab: "History",
            placesTab: "WorkPlaces",
            profileTab: "Profile",
            rolesTab: "Roles",
        },
        administrationData: "Administration data",
        businessLine: "Business lines",
        searchWorkspace: "Search workspace",
        address: "Address",
        searchDocuments: "Search documents",
        //Solicitar tipo de documento a usuario
        solicitNewDocument: "Request a document type from the user",
        actions: "Actions",
    },

    posts: {
        posts: "Posts",
        latestPosts: "Latest posts",
        fromChannel: "from the {channel} channel",
        goBack: "Go back",
        publishedIn: "Published in",
    },

    settings: {
        title: "Settings",
        language: "Language",
        simulateOnboarding: "Simulate onboarding",
        createInvite: "Create invite",
        simulateError: "Simulate error",
        simulatedError: "This is a simulated error.",
    },

    sync: {
        title: "Sync",
    },

    taskManagerHome: {
        addPhoto: "Add photo",
        camera: {
            promptLabelHeader: "How do you want to get the photo?",
            promptLabelCancel: "Cancel",
            promptLabelPhoto: {
                android: "From gallery",
                ios: "From photo library",
            },
            promptLabelPicture: "Take picture",
        },
        changePhoto: "Change photo",
        uploadPhoto: "Upload photo",
    },

    utils: {
        goBack: "Go back",
        loading: "Loading",
        monthsFull: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        monthsShort: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        atDate: "at",
        of: "of",
        unknownError: {
            title: "Uh-oh, something went wrong!",
            sorry: [
                "We're sorry, but it seems we've encountered an issue that we couldn't identify.",
                "But don't worry, our team is already on it working to fix it.",
            ].join(" "),
            meanwhile: "In the meantime, you can try the following:",
            refreshScreen: "Reload this screen",
            refreshPage: "Reload this page",
            goHome: "Return to the home page",
            restart: "Restart the app.",
            clearAppData: "Clear app data.",
            tryLater: "If the problem persists, please try again later.",
            thanks: "We appreciate your patience.",
        },
        showTechnicalDetails: "Show technical details",
        hideTechnicalDetails: "Hide technical details",
        support: {
            contactUsAt: "If you need help, don't hesitate to contact us at ",
            email: "soporte@aimmanager.com", // we don't have an english version
            or: " or ",
            url: "https://en.aimmanager.com/contact/",
        },
        notAvailableForDemo: "Not available for this demo",
        routeNotFound: {
            titleNative: "Screen Not Found",
            messageNative: "We're sorry, but this screen is not available yet.",
            titleWeb: "Page Not Found",
            messageWeb1: "We're sorry, but the page you are looking for no longer exists.",
            messageWeb2: "If it should, check the address is correctly typed.",
            goHome: "Back to Home",
        },
        confirmDiscardChanges: "Do you want to discard unsaved changes?",
        confirmExitApp: "Exit the app?",
        seeMore: "See more",
    },

    workflows: {
        noWorkflows: "There are no workflows yet.",
        noBpmn: "This workflow doesn't have a BPMN diagram yet.",
        editBpmn: "Edit BPMN",
        discardChanges: "Discard changes",
        saveChanges: "Save changes",
        changesSaved: "Changes saved",
        active: "Published",
        inactive: "Draft",
        activateWorkflow: "Publish workflow",
        activateWorkflowConfirm: workflowName =>
            `Are you sure you want to publish the "${workflowName}" workflow?\n\n` +
            `You won't be able to edit it again unless you create a new version.`,
        activatingWorkflow: "Publishing workflow...",
        executeWorkflow: "Execute workflow",
        executingWorkflow: "Executing workflow...",
        cannotExecuteInactiveWorkflow: "Can't execute a workflow draft. You must publish it first.",
        cannotEditActiveWorkflow: "Can't edit a published workflow. You must create a new version.",
        useUserTaskInstead: bpmnElementName =>
            `"${bpmnElementName}" is an element of type bpmn:Task. Please use a bpmn:UserTask instead.`,
        actionSubmit: "Submit",
        actionSuccess: "Action performed successfully",
        executionId: "Execution ID",
        createdAt: "Created at",
        updatedAt: "Updated at",
        status: "Status",
        completionStatuses: {
            [WorkflowCompletionStatus.InProcess]: "In process",
            [WorkflowCompletionStatus.Success]: "Completed successfully",
            [WorkflowCompletionStatus.Error]: "Completed with errors",
        },
        name: "Name",
        createWorkflow: "Create workflow",
        configure: {
            noMessage: "This event doesn't trigger a message to be sent.",
            addMessage: "Add message sending",
            form: {
                tabTitle: "Form",
                elementDoesntHaveIt: "This element doesn't have a form yet.",
                add: "Add form",
                edit: "Edit form",
                confirmSaveChanges:
                    "Before proceeding with the form, we need to save any changes to the BPMN diagram.\n\n" +
                    "Do you want to save the changes?",
            },
            message: "Message",
            expiration: "Expiration",
        },
        untitled: thing => `Untitled ${thing}`,
        element: "element",
        bpmnElementTypes: {
            "bpmn:IntermediateThrowEvent": "intermediate throw event",
            "bpmn:StartEvent": "start event",
            "bpmn:UserTask": "user task",
        },
    },
};
