import { assignHeaders, BadResponseError, Client } from "../../modules/client/client";
import { parsedEnv } from "../../utils/parsedEnv";
import { firebaseAuthService } from "../services/auth/implementations/firebase";
import { revokeSession, isAuthenticated } from "../../modules/auth/authContext";
import { is, pattern, string, type } from "superstruct";

export const notificationsClient = new Client(`${parsedEnv.VITE_SERVER_NOTIFICATIONS}`, {
    intercept: async (request, fetchData) => {
        if (await isAuthenticated()) {
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });
        }

        try {
            return await fetchData(request);
        } catch (error) {
            // Sessions older than 2024-06-19 may trigger this error on the notifications backend
            if (isMissingPassportError(error)) return await revokeSession();
            else throw error;
        }
    },
});

function isMissingPassportError(error: unknown): boolean {
    return (
        error instanceof BadResponseError &&
        error.response.status === 400 &&
        is(
            error.body,
            type({
                message: pattern(string(), /missing passport|aim-tenant-id|auth|token/i),
            }),
        )
    );
}
