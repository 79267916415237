import { NotificationsService } from "../interface";
import {
    InboxNotification,
    NotificationType,
} from "../../../../modules/notifications/frontendModels";
import { mockNotifications } from "../../../../modules/notifications/mockData";
import { sleep } from "../../../../utils/mocks";
import { WorkflowExecution } from "../../workflow/interface";
import { Paginated } from "../../../utils";

export class MockNotificationsService implements NotificationsService {
    private notifications: InboxNotification[] = mockNotifications;

    paginateInbox = async (): Promise<Paginated<InboxNotification>> => {
        await sleep(1000);
        return {
            count: this.notifications.length,
            results: this.notifications,
        };
    };

    deleteNotification = async (id: string): Promise<void> => {
        await sleep(1000);
        this.notifications = this.notifications.filter(n => n.id !== id);
    };

    addNotificationOnce = (notification: InboxNotification): void => {
        if (notification.id !== this.notifications[0]?.id)
            this.notifications = [notification, ...this.notifications];
    };

    markAsRead = async (id: string): Promise<void> => {
        this.notifications = this.notifications.map(n => {
            if (n.id === id) {
                return {
                    ...n,
                    read: true,
                };
            }
            return n;
        });
    };

    handleNewWorkflowExecution = (execution: WorkflowExecution): void => {
        this.addNotificationOnce({
            id: `n3w-4ct1v17y-${execution.id}`,
            title: "Solicitud de vacaciones",
            read: false,
            createdAt: Temporal.Instant.from(execution.createdAt),
            type: NotificationType.WORKFLOW_ACTIVITY,
            executionId: execution.id,
        });
    };

    registerDevice = async () => {
        /* Do nothing */
    };

    unregisterDevice = async () => {
        /* Do nothing */
    };
}
