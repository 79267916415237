import { PageWrapper } from "../ui/pageWrappers";
import { ParentProps, Show, Suspense } from "solid-js";
import { useParams } from "@solidjs/router";
import { CreateChecklistSection } from "./createNewChecklist/CreateChecklistSection";
import { CreateChecklist } from "./createNewChecklist/CreateChecklist";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import {
    createChecklistActivationMutation,
    createUpdateDefaultEvaluationAudiencesMutation,
} from "../../api/services/checklist/mutations";
import {
    Checklist,
    UpdateDefaultEvaluationAudiencesParams,
} from "../../api/services/checklist/interface";
import { showErrorToast, showSuccessToast } from "../../utils/errorHandling";
import { Button } from "../ui/components";
import { P } from "../../utils/typography";
import { createChecklistRetrieveQuery } from "../../api/services/checklist/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import UuidAudienceField from "../audiences/UuidAudienceField";

export default function NewChecklistPage() {
    const params = useParams<{ id?: string }>();

    return (
        <PageWrapper>
            <NumberedSection number={1} title={params.id ? "Editar Checklist" : "Crear Checklist"}>
                <CreateChecklist checklistId={params.id} />
            </NumberedSection>
            <Show when={params.id}>
                {checklistId => (
                    <>
                        <NumberedSection number={2} title="Recorrido">
                            <CreateChecklistSection checklistId={checklistId()} />
                        </NumberedSection>
                        <NumberedSection number={3} title="Audiencias">
                            <AudiencesForm checklistId={checklistId()} />
                        </NumberedSection>
                        <NumberedSection number={4} title="Publicación">
                            <ChecklistActivation checklistId={checklistId()} />
                        </NumberedSection>
                    </>
                )}
            </Show>
        </PageWrapper>
    );
}

function NumberedSection(props: ParentProps<{ number: number; title: string }>) {
    return (
        <section class="flex gap-4">
            <div class="flex flex-col items-center">
                <div class="flex h-8 w-8 items-center justify-center rounded-full border border-dark-gray-400 font-medium text-light-gray-700">
                    {props.number}
                </div>
                <div class="flex-1 border border-transparent border-l-dark-gray-400" />
                <div class="h-8 border border-dashed border-transparent border-l-light-gray-300" />
            </div>
            <div class="w-full pb-8">
                <h2 class="mb-4 mt-[-0.38rem] text-display-sm">{props.title}</h2>
                {props.children}
            </div>
        </section>
    );
}

function AudiencesForm(props: { checklistId: string }) {
    const updateMutation = createUpdateDefaultEvaluationAudiencesMutation();

    const handleSubmit = async (
        formValues: Omit<UpdateDefaultEvaluationAudiencesParams, "checklistId">,
    ) => {
        try {
            await updateMutation.mutateAsync({ checklistId: props.checklistId, ...formValues });
            showSuccessToast("Audiencias guardadas");
        } catch (error) {
            showErrorToast(`Error al guardar las audiencias: ${error}`);
        }
    };

    return (
        <FormWrapper class="gap-3.5 flex w-full flex-col gap-y-4 md:w-120" onSubmit={handleSubmit}>
            <UuidAudienceField name="report_audience" label="Audiencia del Reporte" />
            <UuidAudienceField name="read_audience" label="Audiencia de Visualización" />
            <SubmitButton icon="fas fa-save">Guardar cambios</SubmitButton>
        </FormWrapper>
    );
}

function ChecklistActivation(props: { checklistId: string }) {
    const checklistQuery = createChecklistRetrieveQuery(() => props.checklistId);
    const activateMutation = createChecklistActivationMutation();

    const activate = async (checklist: Checklist) => {
        if (
            confirm(
                `¿Estás seguro de publicar el checklist "${checklist.name}"?\n\n` +
                    `No podrás volver a modificarlo a menos que crees una nueva versión.`,
            )
        ) {
            await activateMutation.mutateAsync(checklist.id);
        }
    };

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={checklistQuery.data}>
                {checklist => (
                    <Show
                        when={!checklist().is_active}
                        fallback={
                            <P>
                                Este checklist ya fue publicado, y por tanto no puede ser modificado
                                a menos que crees una nueva versión.
                            </P>
                        }
                    >
                        <P class="mb-4">Publicar el checklist permite ejecutarlo.</P>
                        <Button
                            size="lg"
                            onClick={() => activate(checklist())}
                            icon="fas fa-check-circle"
                        >
                            Publicar checklist
                        </Button>
                    </Show>
                )}
            </Show>
        </Suspense>
    );
}
