import {
    CreateWorkflowType,
    deserializeWorkflowType,
    sWorkflowType,
    UpdateWorkflowType,
    WorkflowTypeEndpoints,
} from "./interface";
import { makeServiceQuery } from "../../../utils";
import { workflowClient } from "../../../../modules/client/client";
import { any, array } from "superstruct";
import { deserializeWorkflowExecution, sV3WorkflowExecution } from "../v3";

export class V3WorkflowTypeEndpoints implements WorkflowTypeEndpoints {
    myWorkflowTypes = makeServiceQuery({
        fetchJson: () => workflowClient.get("/workflows/my-workflow-types/").receiveJson(),
        responseSchema: array(sWorkflowType()),
        deserialize: arr => arr.map(deserializeWorkflowType),
    });

    allWorkflowTypes = makeServiceQuery({
        fetchJson: () => workflowClient.get("/workflows/workflow-types/").receiveJson(),
        responseSchema: array(sWorkflowType()),
        deserialize: arr => arr.map(deserializeWorkflowType),
    });

    createWorkflowType = (body: CreateWorkflowType) =>
        workflowClient
            .post("/workflows/workflow-types/")
            .sendJson(body)
            .receive(sWorkflowType())
            .then(deserializeWorkflowType);

    retrieveWorkflowType = makeServiceQuery({
        fetchJson: id => workflowClient.get(`/workflows/workflow-types/${id}/`).receiveJson(),
        responseSchema: sWorkflowType(),
        deserialize: deserializeWorkflowType,
    });

    updateWorkflowType = ({ id, ...body }: UpdateWorkflowType) =>
        workflowClient.patch(`/workflows/workflow-types/${id}/`).sendJson(body).receive(any());

    destroyWorkflowType = (id: string) =>
        workflowClient.delete(`/workflows/workflow-types/${id}/`).receiveNothing();

    myExecutions = makeServiceQuery({
        fetchJson: id =>
            workflowClient.get(`/workflows/workflow-types/${id}/my-executions/`).receiveJson(),
        responseSchema: array(sV3WorkflowExecution()),
        deserialize: arr => arr.map(deserializeWorkflowExecution),
    });
}
