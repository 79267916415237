import { Show, Suspense } from "solid-js";
import { PageWrapper } from "../ui/pageWrappers";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import TextField from "../forms/fields/TextField";
import { BorderedCard } from "../ui/cards";
import { useNavigate } from "@solidjs/router";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import { Tab, Tabs } from "../ui/Tabs";
import WorkflowExecutionList from "./WorkflowExecutionList";
import { WorkflowStatus } from "./WorkflowDetailPage";
import { Entry, RecordTable } from "../ui/RecordTable";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ThreeColumnsOnDesktop } from "./utils";
import { CreateWorkflow, Workflow } from "../../api/services/workflow/workflows/interface";
import { createWorkflowListQuery } from "../../api/services/workflow/workflows/queries";
import { createWorkflowMutation } from "../../api/services/workflow/workflows/mutations";
import UuidAudienceField from "../audiences/UuidAudienceField";

export default function WorkflowsPage() {
    return (
        <PageWrapper>
            <Tabs>
                <Tab title="Workflows">
                    <WorkflowList />
                    <CreateWorkflowForm />
                </Tab>
                <Tab title="Ejecuciones">
                    <WorkflowExecutionList />
                </Tab>
            </Tabs>
        </PageWrapper>
    );
}

function WorkflowList() {
    const workflowListQuery = createWorkflowListQuery();

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={workflowListQuery.data}>
                {workflowList => (
                    <ThreeColumnsOnDesktop each={workflowList()} fallback={<NoWorkflows />}>
                        {workflow => <WorkflowItem workflow={workflow} />}
                    </ThreeColumnsOnDesktop>
                )}
            </Show>
        </Suspense>
    );
}

function NoWorkflows() {
    const [locale] = useLocale();

    return <P>{locale().workflows.noWorkflows}</P>;
}

function WorkflowItem(props: { workflow: Workflow }) {
    const navigate = useNavigate();
    const [locale] = useLocale();

    return (
        <BorderedCard
            onClick={() => navigate(`/workflows/${props.workflow.id}`)}
            class={
                "col-span-1 cursor-pointer border-2 md:col-span-4 " +
                (!props.workflow.is_active && "border-dashed")
            }
        >
            <div class="float-right">
                <WorkflowStatus isActive={props.workflow.is_active} />
            </div>
            <P class={"text-h5 font-normal"}>{props.workflow.name}</P>
            <RecordTable>
                <Entry
                    name="Creado"
                    value={Intl.DateTimeFormat(locale().codeWithCountry, {
                        dateStyle: "long",
                        timeStyle: "short",
                    }).format(props.workflow.workflow_group.created_at)}
                />
                <Entry
                    name="Actualizado"
                    value={Intl.DateTimeFormat(locale().codeWithCountry, {
                        dateStyle: "long",
                        timeStyle: "short",
                    }).format(props.workflow.workflow_group.updated_at)}
                />
            </RecordTable>
        </BorderedCard>
    );
}

function CreateWorkflowForm() {
    const workflowMutation = createWorkflowMutation();
    const [locale] = useLocale();
    const t = () => locale().workflows;

    async function handleSubmit(formValues: CreateWorkflow): Promise<void> {
        await workflowMutation.mutateAsync(formValues);
    }

    return (
        <FormWrapper onSubmit={handleSubmit} class={"space-y-3"}>
            <TextField name="name" label={t().name} />
            <TextField
                name="category"
                label="Categoría"
                defaultValue="35c15783-6c0c-4d57-9ebf-6e2b36f7f464"
            />
            <UuidAudienceField
                name="read_executions_audience"
                label="¿Quién puede ver las ejecuciones?"
            />
            <SubmitButton>{t().createWorkflow}</SubmitButton>
        </FormWrapper>
    );
}
