import { H1, H2, P } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import { InboxNotification, NotificationType } from "./frontendModels";
import { Locale } from "../i18n/Locale";
import { Component, createResource, For, ParentProps, Show, Suspense } from "solid-js";
import { Button } from "../ui/components";
import Dropdown from "../ui/Dropdown";
import { PageWrapper } from "../ui/pageWrappers";
import { Dynamic } from "solid-js/web";
import {
    createDeleteNotificationMutation,
    createMarkAsReadMutation,
} from "../../api/services/notifications/mutations";
import { createInboxInfiniteQuery } from "../../api/services/notifications/queries";
import { DevSuspense, LineSkeleton } from "../ui/skeletons";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "@solidjs/router";
import InfiniteScroll from "../ui/InfiniteScroll";
import { showErrorToast, showSuccessToast } from "../../utils/errorHandling";
import { TechnicalDetails } from "../../utils/debug";
import { NotificationPermissionsView } from "./permissions";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";

export default function NotificationsPage() {
    const [locale] = useLocale();
    const t = () => locale().notifications;
    const [getTokenResult] = createResource(Capacitor.isNativePlatform(), () =>
        FirebaseMessaging.getToken(),
    );

    return (
        <div class="pb-4">
            <PageWrapper>
                <H1>{t().title}</H1>
                <NotificationPermissionsView />
                <NotificationsList ItemWrapper={MobileNotificationWrapper} />
                <TechnicalDetails>
                    <H2>Token</H2>
                    <Suspense fallback={<LineSkeleton />}>
                        <pre>{getTokenResult()?.token}</pre>
                    </Suspense>
                </TechnicalDetails>
            </PageWrapper>
        </div>
    );
}

export function NotificationsList(props: { ItemWrapper: Component<NotificationWrapperProps> }) {
    const [locale] = useLocale();
    const t = () => locale().notifications;
    const inboxInfiniteQuery = createInboxInfiniteQuery();

    return (
        <DevSuspense fallback={<NotificationsListSkeleton />}>
            <InfiniteScroll
                infiniteQuery={inboxInfiniteQuery}
                noItemsFallback={<P>{t().noNotifications}</P>}
            >
                {(notification, index) => (
                    <NotificationItem
                        notification={notification}
                        index={index()}
                        ItemWrapper={props.ItemWrapper}
                    />
                )}
            </InfiniteScroll>
        </DevSuspense>
    );
}

function NotificationsListSkeleton() {
    return (
        <For each={new Array(7)}>{() => <div class="skeleton h-15 mb-2 w-full rounded-xl" />}</For>
    );
}

function NotificationItem(props: {
    notification: InboxNotification;
    index: number;
    ItemWrapper: Component<NotificationWrapperProps>;
}) {
    const navigate = useNavigate();
    const deleteNotification = createDeleteNotificationMutation();
    const markAsReadMutation = createMarkAsReadMutation();

    const handleNotificationClicked = () => {
        markAsReadMutation.mutate(props.notification.id);
        switch (props.notification.type) {
            case NotificationType.POST:
                navigate(`/posts/${props.notification.id}`);
                break;
            case NotificationType.WORKFLOW_ACTIVITY:
                navigate("/activities");
                break;
            case NotificationType.TEST:
                if (!props.notification.read)
                    showSuccessToast("Notificación de prueba marcada como leída.");
                else showSuccessToast("Notificación de prueba ya fue marcada como leída.");
                break;
            default:
                showErrorToast(
                    "Esta notificación no tiene una acción definida al ser presionada. Puede que requiera una versión diferente de la app.",
                );
                break;
        }
    };

    return (
        <Dynamic
            component={props.ItemWrapper}
            read={props.notification.read}
            first={props.index === 0}
            onClick={handleNotificationClicked}
        >
            <NotificationItemContent
                notification={props.notification}
                onDelete={() => deleteNotification.mutate(props.notification.id)}
                onMarkAsRead={() => markAsReadMutation.mutate(props.notification.id)}
            />
        </Dynamic>
    );
}

export type NotificationWrapperProps = ParentProps<{
    /** Has the user seen the notification yet? */
    read: boolean;
    /** True if it's the first notification in the list. */
    first: boolean;
    onClick: () => void;
}>;

function MobileNotificationWrapper(props: NotificationWrapperProps) {
    return (
        <div
            role="button"
            class="mb-2 rounded-xl border border-light-gray-300 shadow-light-gray-200"
            classList={{
                "border-light-gray-200": props.read,
                "bg-white border-light-gray-300": !props.read,
            }}
            onClick={() => props.onClick()}
        >
            {props.children}
        </div>
    );
}

function NotificationItemContent(props: {
    notification: InboxNotification;
    onDelete: () => void;
    onMarkAsRead: () => void;
}) {
    const [locale] = useLocale();

    return (
        <div class="flex">
            <NotificationIcon notification={props.notification} />
            <div class="flex-1">
                <div class="flex">
                    <div class="flex-1">
                        <NotificationTitle notification={props.notification} />
                    </div>
                    <NotificationEllipsis
                        onDelete={props.onDelete}
                        onMarkAsRead={props.onMarkAsRead}
                        read={props.notification.read}
                    />
                </div>
                <div class="flex items-baseline gap-1 pb-2 text-sm text-dark-gray-400">
                    <span>{getNotificationSubtitle(props.notification.type, locale())}</span>
                    <span>&ndash;</span>
                    <span>
                        {Intl.DateTimeFormat(locale().codeWithCountry, {
                            dateStyle: "long",
                            timeStyle: "short",
                        }).format(props.notification.createdAt)}
                    </span>
                </div>
            </div>
        </div>
    );
}

function NotificationIcon(props: { notification: InboxNotification }) {
    return (
        <div class="p-2">
            <div class="center-items relative h-6 w-6">
                <i
                    class={
                        iconByNotificationType[props.notification.type] +
                        " text-md text-dark-gray-400"
                    }
                />
                <Show when={!props.notification.read}>
                    <i class="fas fa-circle absolute bottom-0 right-0 rounded-full border-2 border-white text-xs text-primary-500" />
                </Show>
            </div>
        </div>
    );
}

const iconByNotificationType: Record<NotificationType, string> = {
    [NotificationType.ACTIVITY]: "far fa-clipboard",
    [NotificationType.INCIDENT]: "fas fa-exclamation-circle",
    [NotificationType.POST]: "far fa-comment",
    [NotificationType.REPORT]: "fas fa-chart-pie",
    [NotificationType.SYSTEM_UPDATE]: "fas fa-cog",
    [NotificationType.WORKFLOW_ACTIVITY]: "far fa-clipboard",
    [NotificationType.TEST]: "fas fa-check",
};

function NotificationTitle(props: { notification: InboxNotification }) {
    return (
        <div
            class="pb-1 pt-2 leading-tight"
            classList={{
                "font-bold text-dark-gray-700": !props.notification.read,
                "text-light-gray-700": props.notification.read,
            }}
        >
            {props.notification.title}
        </div>
    );
}

function NotificationEllipsis(props: {
    onDelete: () => void;
    onMarkAsRead: () => void;
    read: boolean;
}) {
    const [locale] = useLocale();
    const t = () => locale().notifications;

    return (
        <Dropdown
            padding="none"
            trigger={onClick => (
                <div class="relative w-12">
                    {/* absolute is used, so it doesn't affect the layout of the notification subtitle */}
                    <button
                        class="absolute h-12 w-12"
                        onClick={event => {
                            event.stopPropagation();
                            onClick();
                        }}
                    >
                        <i class="fas fa-ellipsis-h text-md text-dark-gray-400" />
                    </button>
                </div>
            )}
        >
            <div class="-mt-2 me-1 min-w-max">
                <SpeechBubble>
                    <Show when={!props.read}>
                        <Button
                            bgStyle="text-only"
                            icon="fa fa-check-double"
                            onClick={props.onMarkAsRead}
                        >
                            {t().markAsRead}
                        </Button>
                    </Show>
                    <Button
                        variant="danger"
                        bgStyle="text-only"
                        icon="fas fa-times"
                        onClick={props.onDelete}
                    >
                        {t().deleteNotification}
                    </Button>
                </SpeechBubble>
            </div>
        </Dropdown>
    );
}

function SpeechBubble(props: ParentProps) {
    return (
        <div class="relative rounded-md border border-dark-gray-400 bg-white shadow-light-gray-200">
            <div class="absolute -top-px right-3 h-[0.6rem] w-[0.6rem] -translate-y-1/2 rotate-45 rounded-px border-l border-t border-dark-gray-400 bg-white" />
            {props.children}
        </div>
    );
}

function getNotificationSubtitle(type: NotificationType, locale: Locale): string {
    switch (type) {
        case NotificationType.ACTIVITY:
        case NotificationType.WORKFLOW_ACTIVITY:
            return locale.notifications.newActivity;
        case NotificationType.INCIDENT:
            return locale.notifications.newIncident;
        case NotificationType.POST:
            return locale.notifications.newPost;
        case NotificationType.REPORT:
            return locale.notifications.newReport;
        case NotificationType.SYSTEM_UPDATE:
            return locale.notifications.systemUpdate;
        case NotificationType.TEST:
            return locale.notifications.test;
    }
}
