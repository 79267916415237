import { P } from "../../../../utils/typography";
import UuidAudienceField from "../../../audiences/UuidAudienceField";
import { DottedSection } from "../NewActivityConfigTab";

export function ActivityResponsible() {
    return (
        <DottedSection
            title={"Responsables de la actividad"}
            subTitle={"Seleccione los responsables de la actividad (Revisar o eliminar texto)"}
        >
            <div>
                <P>Quienes realizarán la actividad?</P>
                <div
                    class={
                        "space-y-2 rounded-md border border-light-gray-300 bg-light-gray-200 p-2"
                    }
                >
                    {/* <SelectField name={"model"} options={[["", "Tipo de usuario"]]} />
                    <SelectField name={"entities"} options={[]} /> */}
                    <UuidAudienceField name="audience" />
                </div>
            </div>
        </DottedSection>
    );
}
