import { JSX, ParentProps } from "solid-js";

export function RecordTable(props: ParentProps) {
    return (
        <div class="mb-4 grid gap-x-4 gap-y-1" style={{ "grid-template-columns": "auto 1fr" }}>
            {props.children}
        </div>
    );
}

export function Entry(props: { name: string; value: JSX.Element }) {
    return (
        <>
            <div class="font-normal">{props.name}</div>
            <div>{props.value}</div>
        </>
    );
}
