import { H2 } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import WidgetWrapper from "./WidgetWrapper";
import { ActivitiesWidget } from "../../api/services/home/interface";
import ActivityItemList from "../Activities/ActivityItemList";
import { Tab, Tabs } from "../ui/Tabs";
import { Show } from "solid-js";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import WeeklyActivities from "../Activities/WeeklyActivities";
import MonthlyActivities from "../Activities/MonthlyActivities";

export default function ActivitiesWidgetComponent(_props: { widget: ActivitiesWidget }) {
    const [locale] = useLocale();
    const { sm } = useResponsiveBreakpoints();

    return (
        <WidgetWrapper>
            <H2>{locale().menu.tabs.activities}</H2>
            <Show when={sm()} fallback={<ActivityItemList />}>
                <Tabs>
                    <Tab title="Recomendadas">
                        <ActivityItemList />
                    </Tab>
                    <Tab title="Semana">
                        <WeeklyActivities />
                    </Tab>
                    <Tab title="Mes">
                        <MonthlyActivities />
                    </Tab>
                </Tabs>
            </Show>
        </WidgetWrapper>
    );
}
