import { nonCheckboxInputStyling, selectIcon } from "../presentation";
import { InputWrapper, ValidationIconWrapper } from "./parts/icons";
import { FieldProps } from "./parts/types";
import { createField } from "../state";
import { createSignal, createUniqueId, mergeProps } from "solid-js";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";

export default function IntegerField(
    props: FieldProps<string, HTMLInputElement> & {
        min?: number;
        max?: number;
        step?: number;
    },
) {
    const mergedProps = mergeProps(props, {
        validate: (inputValue: string) => {
            const outputValue = Number(inputValue);
            if (outputValue < (props.min ?? -Infinity)) return `Debe ser al menos ${props.min}`;
            if (outputValue > (props.max ?? +Infinity)) return `Debe ser a lo más ${props.max}`;
            if (outputValue % (props.step ?? 1) !== 0) return `Debe ser múltiplo de ${props.step}`;
            return true;
        },
    });
    const field = createField(mergedProps, "", Number);
    const inputId = createUniqueId();

    const [lastValidValue, setLastValidValue] = createSignal<string>("");
    const onChange = (
        e: Event & {
            currentTarget: HTMLInputElement;
            target: HTMLInputElement;
        },
    ) => {
        const value = e.target.value;
        if (!Number.isNaN(Number(value))) {
            setLastValidValue(Number(value).toFixed(0));
            field.setValue(Number(value).toFixed(0));
        } else {
            field.setValue(lastValidValue());
        }
    };

    return (
        <div>
            <InputWrapper icon={selectIcon}>
                <LabelAbove {...mergedProps} field={field} inputId={inputId}>
                    <ValidationIconWrapper field={field}>
                        <input
                            type={"number"}
                            {...getCommonInputProps(inputId, mergedProps)}
                            {...getInputValidationProps(field, mergedProps)}
                            {...nonCheckboxInputStyling}
                            value={field.value}
                            onChange={e => {
                                onChange(e);
                            }}
                            min={mergedProps.min}
                            max={mergedProps.max}
                            step={mergedProps.step}
                        />
                    </ValidationIconWrapper>
                </LabelAbove>
            </InputWrapper>
        </div>
    );
}
