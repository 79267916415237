import { createApiQuery } from "../../utils";

export function createGroupTypeListQuery() {
    return createApiQuery(api => ({
        queryKey: ["groupTypeList"],
        queryFn: () => api.audience.listGroupTypes.fetchJson(),
        select: api.audience.listGroupTypes.select,
    }));
}

export function createListAudiencesQuery() {
    return createApiQuery(api => ({
        queryKey: ["audienceList"],
        queryFn: () => api.audience.listAudiences.fetchJson(),
        select: api.audience.listAudiences.select,
    }));
}

export function createAudienceVariablesQuery() {
    return createApiQuery(api => ({
        queryKey: ["audienceVariables"],
        queryFn: () => api.audience.getVariables.fetchJson(),
        select: api.audience.getVariables.select,
    }));
}

export function createListPositionsQuery() {
    return createApiQuery(api => ({
        queryKey: ["positionList"],
        queryFn: () => api.audience.listPositions.fetchJson(),
        select: api.audience.listPositions.select,
    }));
}

export function createAudienceFunctionsQuery() {
    return createApiQuery(api => ({
        queryKey: ["audienceFunctions"],
        queryFn: () => api.audience.audienceFunctions.fetchJson(),
        select: api.audience.audienceFunctions.select,
    }));
}
export function createAudienceResultListQuery(audienceId: string) {
    return createApiQuery(api => ({
        queryKey: ["audienceResultList", audienceId],
        queryFn: () => api.audience.audienceResultList.fetchJson(audienceId),
        select: api.audience.audienceResultList.select,
    }));
}

export function createListGroupsQuery() {
    return createApiQuery(api => ({
        queryKey: ["groupList"],
        queryFn: () => api.audience.listGroups.fetchJson(),
        select: api.audience.listGroups.select,
    }));
}
