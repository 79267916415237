import { Capacitor } from "@capacitor/core";
import { onCleanup, onMount } from "solid-js";

// Fixes 8 seconds delay when closing popup https://stackoverflow.com/a/78641857/1750742
export function usePopupCancelDelayWorkaround() {
    // Mobile doesn't have this issue
    if (Capacitor.isNativePlatform()) return;

    // The 8 seconds delay is actually needed if the user agent is Firefox https://github.com/firebase/firebase-js-sdk/issues/6956
    if (navigator.userAgent.includes("Firefox")) return;

    const originalSetTimeout = window.setTimeout;
    onMount(() => {
        window.setTimeout = ((fn: TimerHandler, delay: number | undefined, ...args: unknown[]) => {
            // Check if the delay matches Firebase's _Timeout.AUTH_EVENT
            if (delay === 8000) {
                delay = 2000;
            }
            return originalSetTimeout(fn, delay, ...args);
        }) as typeof originalSetTimeout;
    });
    onCleanup(() => {
        window.setTimeout = originalSetTimeout;
    });
}
