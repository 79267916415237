import { useQueryClient } from "@tanstack/solid-query";
import { createApiMutation } from "../../utils";
import { CreatePerson } from "./interface";
import { CreateChecklistPayload } from "../checklist/interface";

export function createNewPersonMutation() {
    const queryClient = useQueryClient();

    return createApiMutation(api => ({
        mutationKey: ["createPerson"],
        mutationFn: (person: CreatePerson) => api.person.createPerson(person),
        onSuccess: data => {
            console.log("SUCCESS MUTATION", data);
            queryClient.refetchQueries({
                queryKey: ["people"],
            });
        },
    }));
}

export function createBulkPeopleLoadMutation(tenant_id: string) {
    return createApiMutation(api => ({
        mutationKey: ["createBulkPeopleLoadMutation"],
        mutationFn: (payload: { file: File; tenant_id: string }) =>
            api.person.bulkPeopleLoad(payload, tenant_id),
    }));
}
