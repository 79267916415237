import { InputTypeEnum } from "../../api/services/formbuilder/interfaces/InputTypeEnum";
import {
    InputUnion,
    BaseInput,
    Choice,
} from "../../api/services/formbuilder/interfaces/inputs/BaseInput";

export function makeBlankField(
    input: InputTypeEnum,
    id: number,
    options?: Partial<{
        name: string;
        label: string;
        required: boolean;
        skippable: boolean;
        choices: Choice[];

        // value_in_num_range
        start: number;
        stop: number;
        step: number;

        // checkboxes
        min_answers: number;
        max_answers: number;
    }>,
): InputUnion {
    const randomId = Math.random().toString(36).substring(7); //Backend expects id to be a valid integer. Passing index +1 for now

    const baseBlankInput: BaseInput = {
        id: "" + id,
        label: options?.label ?? "New Field " + input,
        name: options?.name ?? "field_" + id + "_" + input + "_" + randomId,
        placeholder: "",
        required: options?.required ?? false,
        skippable: false,
        style_class: null,
        type: input,
        validators: [],
        show_if: null,
    };
    switch (input) {
        case InputTypeEnum.multiple_choice:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.multiple_choice,
                choices: options?.choices ?? [],
            };
        case InputTypeEnum.date:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.date,
            };
        case InputTypeEnum.datetime:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.datetime,
            };
        case InputTypeEnum.time:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.time,
            };
        case InputTypeEnum.date_range:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.date_range,
            };
        case InputTypeEnum.num_range:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.num_range,
            };
        case InputTypeEnum.value_in_num_range:
            if (options?.start == null || options?.stop == null || options?.step == null)
                throw new Error("start, stop and step are required");
            return {
                ...baseBlankInput,
                type: InputTypeEnum.value_in_num_range,
                start: options.start,
                stop: options.stop,
                step: options.step,
            };
        case InputTypeEnum.voice_message:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.voice_message,
            };
        case InputTypeEnum.files:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.files,
                max_files: 1,
            };
        case InputTypeEnum.gps:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.gps,
            };
        case InputTypeEnum.stars:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.stars,
            };
        case InputTypeEnum.integer:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.integer,
            };
        case InputTypeEnum.float:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.float,
            };
        case InputTypeEnum.decimal:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.decimal,
            };
        case InputTypeEnum.code:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.code,
            };
        case InputTypeEnum.text:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.text,
            };
        case InputTypeEnum.boolean:
            return {
                ...baseBlankInput,
                type: InputTypeEnum.boolean,
                // See docs for BaseInput.required
                required: true,
            };
        case InputTypeEnum.checkboxes: {
            const choices = options?.choices ?? [];
            return {
                ...baseBlankInput,
                type: InputTypeEnum.checkboxes,
                choices: options?.choices ?? [],
                min_answers: options?.min_answers ?? 1,
                max_answers: options?.max_answers ?? choices.length,
            };
        }
        default:
            throw new Error("Invalid input type");
    }
}
