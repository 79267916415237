import RadioField from "../forms/fields/RadioField";

export default function AudienceOperatorForm(props: { identifier: string }) {
    //const [operator, setOperator] = createSignal<string>("and");
    return (
        <div>
            <RadioField
                name={`operator-${props.identifier}`}
                label={"Operador"}
                options={[
                    ["and", "AND"],
                    ["or", "OR"],
                ]}
            />
        </div>
    );
}
