import { createApiMutation } from "../../utils";
import { CreateActivityPayload, CreateActivityTaskPayload } from "./interface";

export function createActivityMutation() {
    return createApiMutation(api => ({
        mutationKey: ["activityTask"],
        mutationFn: (payload: CreateActivityPayload) => api.taskManager.createActivity(payload),
        invalidates: ["activityTasks"],
    }));
}

export function createActivityTaskMutation() {
    return createApiMutation(api => ({
        mutationKey: ["activityTask"],
        mutationFn: (payload: CreateActivityTaskPayload) =>
            api.taskManager.createActivityTask(payload),
        invalidates: ["activityTasks"],
    }));
}

export function createActivityFormResponseMutation() {
    return createApiMutation(api => ({
        mutationKey: ["activityFormResponse"],
        mutationFn: api.taskManager.formResponse,
    }));
}
