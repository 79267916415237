import { Diagram } from "gojs";

export function initThemes(diagram: Diagram): void {
    diagram.themeManager.set("light", {
        colors: {
            background: "#fff",
            text: "#111827",
            textHighlight: "#11a8cd",
            subtext: "#6b7280",
            badge: "#f0fdf4",
            badgeBorder: "#16a34a33",
            badgeText: "#15803d",
            divider: "#6b7280",
            shadow: "#9ca3af",
            tooltip: "#1f2937",
            levels: [
                "#AC193D",
                "#2672EC",
                "#8C0095",
                "#5133AB",
                "#008299",
                "#D24726",
                "#008A00",
                "#094AB2",
            ],
            dragOver: "#f0f9ff",
            link: "#9ca3af",
            div: "#f3f4f6",
        },
        fonts: {
            title: "500 1.125rem Inter, sans-serif",
            normal: "1rem Inter, sans-serif",
            badge: "500 0.75rem Inter, sans-serif",
            link: "600 0.875rem Inter, sans-serif",
            email: "500 0.875rem Inter, sans-serif",
        },
    });

    diagram.themeManager.set("dark", {
        colors: {
            background: "#111827",
            text: "#fff",
            subtext: "#d1d5db",
            badge: "#22c55e19",
            badgeBorder: "#22c55e21",
            badgeText: "#4ade80",
            shadow: "#111827",
            dragOver: "#082f49",
            link: "#6b7280",
            div: "#1f2937",
        },
    });
}
