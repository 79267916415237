import { checkboxStyling } from "../presentation";
import { LabelParent } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import { createUniqueId } from "solid-js";

export default function SwitchField(props: FieldProps<boolean, HTMLInputElement>) {
    const field = createField(props, false);
    const inputId = createUniqueId();

    return (
        /**
         * sr-only causing page scroll: https://stackoverflow.com/questions/66572172/sr-only-class-causing-page-scroll/66574427#66574427
         * the only way to prevent the page from scrolling is to use relative class in parent element: https://github.com/tailwindlabs/tailwindcss/discussions/12429#discussioncomment-7587178
         * */
        <LabelParent
            {...props}
            field={field}
            inputId={inputId}
            labelClass={`relative ${props.labelClass}`}
        >
            <input
                type="checkbox"
                {...getCommonInputProps(inputId, props)}
                {...checkboxStyling}
                checked={field.value}
                onChange={e => field.setValue(e.target.checked)}
                class="peer sr-only"
            />
            <div
                class="peer relative z-dropdown h-6 w-[2.75rem] rounded-full
            bg-light-gray-200 after:absolute after:start-0.5 after:top-0.5 after:h-5
            after:w-5 after:rounded-full
            after:border after:border-light-gray-50 after:bg-white after:shadow-light-gray-200 after:transition-all
            after:content-[''] peer-checked:bg-primary-500 peer-checked:after:translate-x-full peer-checked:after:border-light-gray-50 peer-checked:after:shadow-[none] peer-focus:outline-none
            peer-focus:ring-4 peer-focus:ring-light-gray-50 rtl:peer-checked:after:-translate-x-full"
            />
        </LabelParent>
    );
}
