import { PageWrapper } from "../ui/pageWrappers";
import { useParams } from "@solidjs/router";
import {
    createChecklistExecutionRetrieveQuery,
    createChecklistRetrieveQuery,
    createListChecklistConceptsQuery,
    createListChecklistEvaluationQuery,
    createListChecklistFormConceptsWithQuestionQuery,
    createListFormChecklistResponses,
    createListGradesQuery,
} from "../../api/services/checklist/queries";
import { Accessor, createMemo, For, Match, Show, Switch } from "solid-js";
import { P } from "../../utils/typography";
import {
    ChecklistFormConceptsWithQuestion,
    ChecklistFormResponse,
    Concept,
    Grade,
} from "../../api/services/checklist/interface";
import _ from "lodash";
import { FormValues } from "../forms/state";
import { useLocale } from "../i18n/context";
import { array, is, string } from "superstruct";
import { JsonDebug } from "../../utils/debug";
import { Badge } from "../ui/components";

export default function ChecklistExecutionDetailPage() {
    const params = useParams();
    const executionID = () => params.execution_id;
    const getExecutionQuery = createChecklistExecutionRetrieveQuery(executionID);

    return (
        <PageWrapper>
            <Show when={getExecutionQuery.data}>
                {data => {
                    return (
                        <ExecutionDetailContent
                            checklistID={data().checklist}
                            executionID={executionID()}
                        />
                    );
                }}
            </Show>
        </PageWrapper>
    );
}

type ExecutionDetailContentProps = {
    checklistID: string;
    executionID: string;
};
function ExecutionDetailContent(props: ExecutionDetailContentProps) {
    const checklistId = () => props.checklistID;
    const executionID = () => props.executionID;
    const getChecklist = createChecklistRetrieveQuery(checklistId);
    const getEvaluations = createListChecklistEvaluationQuery(checklistId);
    const getChecklistResponses = createListFormChecklistResponses(executionID);

    return (
        <div>
            <Show when={getChecklist.data}>
                {data => (
                    <div>
                        <P class={"text-xl font-medium"}>Checklist: {data().name}</P>
                    </div>
                )}
            </Show>
            <Show when={getChecklistResponses.data}>
                {responses => (
                    <Show when={getEvaluations.data}>
                        {evaluations => (
                            <div>
                                <For
                                    each={evaluations()}
                                    fallback={<P>Checklist sin evaluaciones</P>}
                                >
                                    {evaluation => (
                                        <div>
                                            <P class={"text-xl font-medium"}>{evaluation.name}</P>
                                            <ConceptsList
                                                evaluationID={evaluation.id}
                                                checklistResponses={responses()}
                                                executionID={executionID}
                                            />
                                        </div>
                                    )}
                                </For>
                            </div>
                        )}
                    </Show>
                )}
            </Show>
        </div>
    );
}

type CustomChecklistQuestionAndResponseObj = ChecklistFormConceptsWithQuestion &
    Pick<ChecklistFormResponse, "response" | "grade">;

function ConceptsList(props: {
    evaluationID: string;
    checklistResponses: ChecklistFormResponse[];
    executionID: Accessor<string>;
}) {
    const [locale] = useLocale();
    const evaluationID = () => props.evaluationID;
    const getConcepts = createListChecklistConceptsQuery(evaluationID);

    const getGrades = createListGradesQuery(evaluationID, props.executionID);
    const getChecklistFormConcepts = createListChecklistFormConceptsWithQuestionQuery(evaluationID);
    const getChecklistExecution = createChecklistExecutionRetrieveQuery(props.executionID);

    return (
        <>
            <Show when={getGrades.data}>
                {gradesData => {
                    console.log("gradesData", gradesData());
                    return (
                        <>
                            <div class={"mb-8 mt-2 flex items-center justify-between"}>
                                <div>
                                    <Show when={getChecklistExecution.data}>
                                        {data => {
                                            return (
                                                <>
                                                    <P class={"!mb-0"}>
                                                        <span class={"font-normal"}>
                                                            Evaluación realizada por
                                                        </span>
                                                        : {data().user.name}
                                                    </P>
                                                    <P class={"!mb-0"}>
                                                        <span class={"font-normal"}>
                                                            Iniciado el:
                                                        </span>{" "}
                                                        {Intl.DateTimeFormat(
                                                            locale().codeWithCountry,
                                                            {
                                                                dateStyle: "long",
                                                                timeStyle: "short",
                                                            },
                                                        ).format(data().created_at)}
                                                    </P>
                                                    <Show when={data().completed_at}>
                                                        {completed => (
                                                            <P class={"!mb-0"}>
                                                                <span class={"font-normal"}>
                                                                    Completado el:
                                                                </span>{" "}
                                                                {Intl.DateTimeFormat(
                                                                    locale().codeWithCountry,
                                                                    {
                                                                        dateStyle: "long",
                                                                        timeStyle: "short",
                                                                    },
                                                                ).format(completed())}
                                                            </P>
                                                        )}
                                                    </Show>
                                                </>
                                            );
                                        }}
                                    </Show>
                                </div>
                                <div
                                    class={
                                        "rounded-md border border-success-500 bg-success-100 p-4 text-center"
                                    }
                                >
                                    <P class={"!mb-0 text-md"}>Nota de evaluación</P>
                                    <P class={"!mb-0 text-display-xs font-medium"}>
                                        {gradesData()[0].final_grade.toFixed(2)}
                                    </P>
                                </div>
                            </div>
                            <Show when={getChecklistFormConcepts.data}>
                                {data => {
                                    const groupByChecklistForm = _.groupBy(
                                        [...data(), ...props.checklistResponses],
                                        "checklist_form",
                                    );
                                    const convertToCustomObject = Object.entries(
                                        groupByChecklistForm,
                                    ).reduce((acc, [_key, value]) => {
                                        const checklistFormConceptWithQuestion = value.find(
                                            item => "concept" in item,
                                        ) as ChecklistFormConceptsWithQuestion;
                                        const response = value.find(
                                            item => "response" in item,
                                        ) as ChecklistFormResponse;
                                        return [
                                            ...acc,
                                            {
                                                ...checklistFormConceptWithQuestion,
                                                response: response.response,
                                                grade: response.grade,
                                            },
                                        ];
                                    }, [] as CustomChecklistQuestionAndResponseObj[]);

                                    const groupByConcept = _.groupBy(
                                        convertToCustomObject,
                                        "concept",
                                    );
                                    return (
                                        <Show when={getConcepts.data}>
                                            {data => {
                                                return (
                                                    <ConceptListRender
                                                        concepts={data()}
                                                        groupByConcept={groupByConcept}
                                                        gradesData={gradesData()}
                                                    />
                                                );
                                            }}
                                        </Show>
                                    );
                                }}
                            </Show>
                        </>
                    );
                }}
            </Show>
        </>
    );
}

function ConceptListRender(props: {
    concepts: Concept[];
    groupByConcept: _.Dictionary<CustomChecklistQuestionAndResponseObj[]>;
    gradesData: Grade[];
}) {
    const gradeByConcept = createMemo(
        () =>
            Object.fromEntries(props.gradesData[0].grades.map(g => [g.id, g.grade])) as Record<
                Concept["id"],
                string
            >,
    );
    return (
        <div>
            <For each={props.concepts}>
                {concept => (
                    <div class={"mb-8 rounded-md border border-light-gray-400 p-4"}>
                        <P class={"font-medium"}>
                            {concept.name} (Nota {gradeByConcept()[concept.id]})
                        </P>
                        <div class={""}>
                            <For each={props.groupByConcept[concept.id]}>
                                {(item, index) => (
                                    <div
                                        class={
                                            "mb-2 flex justify-between rounded-xs border border-light-gray-300"
                                        }
                                    >
                                        <div class={"p-2"}>
                                            <P class="!mb-2">
                                                {index() + 1}
                                                .- {item.checklist_form_question}
                                            </P>
                                            <ResponseDispatcher response={item.response} />
                                        </div>
                                        <div
                                            class={
                                                "flex w-40 flex-col items-center justify-center bg-light-gray-100 py-4"
                                            }
                                        >
                                            <P class={"!mb-0"}>Nota</P>
                                            <P class={"!mb-0 text-xl font-medium"}>{item.grade}</P>
                                        </div>
                                    </div>
                                )}
                            </For>
                        </div>
                    </div>
                )}
            </For>
        </div>
    );
}

/*function ConceptQuestionAndResponseRender() {
    return (
        <For each={props.groupByConcept[concept.id]}>
            {(item, index) => (
                <div
                    class={
                        "mb-2 flex justify-between rounded-xs border border-light-gray-300"
                    }
                >
                    <div class={"p-2"}>
                        <P class={"!mb-0"}>
                            {index() + 1}
                            .- {item.checklist_form_question}
                        </P>
                        <ResponseDispatcher response={item.response} />
                    </div>
                    <Show
                        when={groupGradesByConcept[item.checklist_form]?.grade}
                    >
                        <div
                            class={
                                "flex w-40 flex-col items-center justify-center bg-light-gray-100 py-3"
                            }
                        >
                            <P class={"!mb-0"}>Nota</P>
                            <P class={"!mb-0 text-xl font-medium"}>
                                {
                                    groupGradesByConcept[item.checklist_form]
                                        ?.grade
                                }
                            </P>
                        </div>
                    </Show>
                </div>
            )}
        </For>
    )
}*/

function ResponseDispatcher(props: { response: FormValues }) {
    return (
        <div>
            <For each={Object.entries(props.response)}>
                {([fieldName, value]) => (
                    <Show
                        when={fieldName === "files" && is(value, array(string()))}
                        fallback={
                            <P class={"!mb-0 text-sm"}>
                                <span class={fieldName === "main" ? "font-medium" : "font-normal"}>
                                    {fieldName === "main" ? "Respuesta" : fieldName}:
                                </span>{" "}
                                <Switch
                                    fallback={
                                        <div class="text-xs">
                                            <JsonDebug value={value} />
                                        </div>
                                    }
                                >
                                    <Match
                                        when={
                                            typeof value === "string" || typeof value === "number"
                                        }
                                    >
                                        {value as string | number}
                                    </Match>
                                    <Match when={is(value, array(string()))}>
                                        <span class="inline-flex gap-1">
                                            <For each={value as string[]} fallback="Ninguna">
                                                {choice => <Badge>{choice}</Badge>}
                                            </For>
                                        </span>
                                    </Match>
                                </Switch>
                            </P>
                        }
                    >
                        <For each={value as string[]}>
                            {file => (
                                <P class={"!mb-0 text-sm"}>
                                    <span class={"font-normal"}>Archivo:</span>{" "}
                                    <a href={file} target={"_blank"}>
                                        Ver archivo
                                    </a>
                                </P>
                            )}
                        </For>
                    </Show>
                )}
            </For>
        </div>
    );
}
