import { Accessor, createSignal, ParentProps } from "solid-js";
import { createRef, MutableRefObject } from "../../utils/reactRefs";
import { useElementEventListener } from "../../utils/solidjs";
import { Button } from "./components";
import "./Carousel.css";

const emToPx = 16;
const carouselItemWidthPx = 26 * emToPx;
const carouselItemGapPx = 2 * emToPx;

export function Carousel(props: ParentProps) {
    const ref = createRef<HTMLDivElement>();
    const { prev, prevDisabled, next, nextDisabled } = createCarousel(
        ref,
        carouselItemWidthPx + carouselItemGapPx,
    );

    return (
        <div class="flex">
            <Button
                icon="fas fa-chevron-left"
                bgStyle="text-only"
                onClick={prev}
                disabled={prevDisabled()}
            />
            <div
                ref={ref}
                class="carousel flex flex-1 items-start overflow-x-auto overflow-y-hidden"
                style={{ gap: carouselItemGapPx + "px" }}
            >
                {props.children}
            </div>
            <Button
                icon="fas fa-chevron-right"
                bgStyle="text-only"
                onClick={next}
                disabled={nextDisabled()}
            />
        </div>
    );
}

function createCarousel(
    ref: MutableRefObject<HTMLDivElement>,
    scrollAmount: number,
): {
    prev: () => void;
    next: () => void;
    prevDisabled: Accessor<boolean>;
    nextDisabled: Accessor<boolean>;
} {
    const [prevDisabled, setPrevDisabled] = createSignal(true);
    const [nextDisabled, setNextDisabled] = createSignal(false);

    function prev() {
        scrollProgrammaticallyBy(-scrollAmount);
    }

    function next() {
        scrollProgrammaticallyBy(scrollAmount);
    }

    let isProgrammaticScroll = false;

    function scrollProgrammaticallyBy(amount: number) {
        isProgrammaticScroll = true;
        const targetScrollLeft = ref.current!.scrollLeft + amount;
        ref.current!.scrollTo({ left: targetScrollLeft, behavior: "smooth" });
        updateButtons(targetScrollLeft);
    }

    useElementEventListener(ref, "scroll", event => {
        if (isProgrammaticScroll) return;
        const target = event.target as HTMLDivElement;
        updateButtons(target.scrollLeft);
    });

    useElementEventListener(ref, "scrollend", () => {
        isProgrammaticScroll = false;
    });

    // When scrolling programmatically, targetScrollLeft is the scrollLeft after the scroll animation.
    function updateButtons(targetScrollLeft: number) {
        setPrevDisabled(targetScrollLeft <= 0);
        setNextDisabled(targetScrollLeft >= ref.current!.scrollWidth - ref.current!.clientWidth);
    }

    return { prev, next, prevDisabled, nextDisabled };
}

export function CarouselItem(props: ParentProps) {
    return (
        <div class="flex-shrink-0" style={{ width: carouselItemWidthPx + "px" }}>
            {props.children}
        </div>
    );
}
