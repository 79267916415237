import { CreatePerson, LegacyPerson, PersonService, UserProfile } from "../interface";
import MockPersonService from "./mock";
import { BadResponseError, EmailAlreadyInUseError } from "../../../../modules/client/client";
import { any, array, create, integer, nullable, optional, string, type } from "superstruct";
import { personClient } from "../../../clients/paulsen";
import { newPersonClient } from "../person";

export default class V3PersonService implements PersonService {
    mock = new MockPersonService();

    retrieveCurrentUserProfile = async (): Promise<UserProfile> => {
        let paulsenData;
        try {
            paulsenData = await personClient.get("/who_am_i").receive(type({ email: string() }));
        } catch (error) {
            console.error(error);
        }

        return { ...(await this.mock.retrieveCurrentUserProfile()), ...paulsenData };
    };

    retrieveIdentityOptions = this.mock.retrieveIdentityOptions;
    retrievePersonalData = this.mock.retrievePersonalData;
    updatePersonalData = this.mock.updatePersonalData;

    getPeople = async (): Promise<LegacyPerson[]> => {
        const profiles = await personClient.get("/profiles/").receive(array(sV3Profile()));
        return profiles.map(profile => ({
            id: profile.id,
            name: `${profile.first_name} ${profile.last_name}`,
            email: profile.email,
            phones: profile.phone ? (profile.phone.length > 0 ? [profile.phone] : []) : [],
            pictureUrl: null,
            identityDocuments: [],
        }));
    };

    getPerson = this.mock.getPerson;
    // getPerson = makeServiceQuery({
    //     fetchJson: async (id: string) => newPersonClient.get(`/persons/${id}`).receiveJson(),
    //     responseSchema: sLuchoPerson(),
    //     deserialize: deserializeLuchoPerson,
    // });

    createPerson = async (person: CreatePerson): Promise<void> => {
        try {
            await personClient
                .post("/profiles/")
                .sendJson({
                    send_invitation: person.send_invitation,
                    email: person.email,
                    first_name: person.name,
                    last_name: "",
                    phone: person.phones.length > 0 ? person.phones[0] : null,
                })
                .receive(any());
        } catch (e) {
            if (e instanceof BadResponseError) {
                const body = create(e.body, type({ email: optional(array(string())) }));
                if (body.email && Array.isArray(body.email) && body.email.length > 0) {
                    if (body.email[0] === "profile with this email already exists.") {
                        throw new EmailAlreadyInUseError();
                    }
                }
                console.log("BadResponseError", e.body);
            }
            throw e;
        }
    };

    async exportPersons(tenant_id: string): Promise<Blob> {
        return await newPersonClient.get(`/persons/export/?tenant_id=${tenant_id}`).receiveBlob();
    }

    async bulkPeopleLoad(data: { file: File }, tenant_id: string): Promise<void> {
        return await newPersonClient
            .post(`/persons/bulk-upload/?tenant_id=${tenant_id}`)
            .sendFormData(data)
            .receiveNothing();
    }
}

function sV3Profile() {
    return type({
        id: integer(),
        first_name: string(),
        last_name: string(),
        email: nullable(string()),
        phone: optional(string()),
        user: nullable(integer()),
    });
}

export function sV3UserProfile() {
    return type({
        last_name: string(),
        first_name: string(),
        email: string(),
        pictureUrl: nullable(string()),
    });
}

export function sV3WorkflowProfile() {
    return type({
        id: integer(),
        last_name: string(),
        first_name: string(),
        email: string(),
        username: string(),
    });
}
