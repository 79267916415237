import { For, Show } from "solid-js";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { Carousel, CarouselItem } from "../ui/Carousel";
import { useLocale } from "../i18n/context";
import { H2 } from "../../utils/typography";
import { useNavigate } from "@solidjs/router";
import { Badge, Icon } from "../ui/components";
import { formatApproxNumber, formatDateTime } from "../../utils/formatters";
import WidgetWrapper from "./WidgetWrapper";
import { WidgetConfig } from "../../api/services/home/implementations/atlas";
import { PostOverview, PostsWidget } from "../../api/services/home/interface";
import {
    createPostsByChannelQuery,
    createWidgetConfigQuery,
} from "../../api/services/home/queries";

export default function PostWidgetComponent(props: { widget: PostsWidget }) {
    const widgetQuery = createWidgetConfigQuery(() => props.widget.configId);
    const postsQuery = createPostsByChannelQuery(() => widgetQuery.data?.configuration.channelId);

    return (
        <WidgetWrapper>
            <PostsHeading widget={widgetQuery.data} />
            <Show when={postsQuery.data}>{posts => <PostList posts={posts()} />}</Show>
        </WidgetWrapper>
    );
}

function PostList(props: { posts: PostOverview[] }) {
    const { lg } = useResponsiveBreakpoints();

    return (
        <Show when={lg()} fallback={<PostListMobile posts={props.posts} />}>
            <PostListDesktop posts={props.posts} />
        </Show>
    );
}

function PostListMobile(props: { posts: PostOverview[] }) {
    return (
        <div class="flex flex-col gap-4">
            <For each={props.posts}>{post => <PostCardMobile post={post} />}</For>
        </div>
    );
}

function PostListDesktop(props: { posts: PostOverview[] }) {
    return (
        <Carousel>
            <For each={props.posts}>
                {post => (
                    <CarouselItem>
                        <PostCardDesktop post={post} />
                    </CarouselItem>
                )}
            </For>
        </Carousel>
    );
}

function PostsHeading(props: { widget: WidgetConfig | undefined }) {
    const [locale] = useLocale();

    return (
        <Show when={props.widget} fallback={<H2>{locale().posts.latestPosts}</H2>}>
            {widget => (
                <H2>
                    <i class={`${widget().configuration.channelIcon} me-2`} />
                    {locale().posts.latestPosts}{" "}
                    {locale().posts.fromChannel.replace(
                        "{channel}",
                        widget().configuration.channelTitle,
                    )}
                </H2>
            )}
        </Show>
    );
}

export function PostCardDesktop(props: { post: PostOverview }) {
    const navigate = useNavigate();

    return (
        <div
            class="rounded-md border border-light-gray-300"
            onClick={() => navigate(`/posts/${props.post.id}`)}
            role="button"
        >
            <div
                class="h-32 w-full rounded-t-2"
                style={{ background: `url(${props.post.coverImageUrl}) center/cover` }}
            />
            <div class="p-3">
                <PostCardBodyDesktop post={props.post} />
                <PostCardFooterDesktop post={props.post} />
            </div>
        </div>
    );
}

function PostCardBodyDesktop(props: { post: PostOverview }) {
    const icon = () => props.post.category.icon?.replace("fa-", "fas fa-");

    return (
        <div class="flex flex-col items-start gap-1">
            <Badge>
                <i class={`me-1 text-light-gray-700 ${icon()}`} /> {props.post.category.name}
            </Badge>
            <h3 class="m-0 font-bold text-dark-gray-700">{props.post.title}</h3>
            <p class="mt-0 mb-3 line-clamp-2 text-sm text-dark-gray-700">
                {props.post.description}
            </p>
        </div>
    );
}

function PostCardFooterDesktop(props: { post: PostOverview }) {
    return (
        <div class="flex text-sm text-dark-gray-400">
            <div class="flex flex-1 gap-1">
                <Icon name="fas fa-calendar" />
                {formatDateTime(props.post.date)}
            </div>
            <EngagementStatDesktop icon="far fa-thumbs-up" value={props.post.likes} />
            <EngagementStatDesktop icon="far fa-comment" value={props.post.comments} />
        </div>
    );
}

function EngagementStatDesktop(props: { icon: string; value: number | undefined }) {
    return (
        <Show when={props.value != null}>
            <div class="flex gap-1 px-2">
                <Icon name={props.icon} /> {formatApproxNumber(props.value!)}
            </div>
        </Show>
    );
}

export function PostCardMobile(props: { post: PostOverview }) {
    const navigate = useNavigate();

    return (
        <div
            role="button"
            onClick={() => navigate(`/posts/${props.post.id}`)}
            class="flex gap-2 rounded-md bg-white p-2 shadow-light-gray-200"
        >
            <CoverImageMobile coverImageUrl={props.post.coverImageUrl} />
            <div class="min-w-0 flex-1">
                <div class="mb-1 text-default leading-tight text-dark-gray-700">
                    {props.post.title}
                </div>
                <div class="text-xs text-dark-gray-400">
                    <DescriptionSummary description={props.post.description} />
                    <PostCardFooterMobile {...props.post} />
                </div>
            </div>
        </div>
    );
}

function CoverImageMobile(props: { coverImageUrl: string }) {
    const size = "4rem";

    return (
        <div
            class="rounded-md"
            style={{
                background: `url(${props.coverImageUrl}) center/cover`,
                width: size,
                height: size,
            }}
        />
    );
}

function DescriptionSummary(props: { description: string }) {
    return (
        <p class="mt-0 mb-1 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {props.description}
        </p>
    );
}

function PostCardFooterMobile(props: {
    date: Date;
    likes?: number;
    comments?: number;
    views?: number;
}) {
    return (
        <div class="flex items-center gap-2">
            <div class="leading-tight">{formatDateTime(props.date)}</div>
            <EngagementStatMobile icon="far fa-thumbs-up" value={props.likes} />
            <EngagementStatMobile icon="far fa-comment" value={props.comments} />
            <EngagementStatMobile icon="far fa-eye" value={props.views} />
        </div>
    );
}

function EngagementStatMobile(props: { icon: string; value: number | undefined }) {
    return (
        <Show when={props.value != null}>
            <div>&ndash;</div>
            <div class="flex items-center gap-1">
                <i class={props.icon} /> {formatApproxNumber(props.value!)}
            </div>
        </Show>
    );
}
