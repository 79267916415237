import { getOrCreateRef } from "../../../utils/reactRefs";
import { ValidationIconWrapper } from "./parts/icons";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import { createEffect, createUniqueId } from "solid-js";

export default function SubdomainField(props: FieldProps<string, HTMLInputElement>) {
    const inputRef = getOrCreateRef(props.staticRef);
    const focus = () => inputRef.current?.focus();
    const field = createField(props, "");
    const inputId = createUniqueId();

    createEffect(() => {
        if (field.value.length > 0) {
            field.setValue(field.value.trim());
        }
    });

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <ValidationIconWrapper field={field}>
                <div onClick={focus} class={"flex justify-center text-center"}>
                    <span onClick={focus} class="mr-1 text-md text-light-gray-500">
                        https://
                    </span>
                    <input
                        {...getCommonInputProps(inputId, props)}
                        {...getInputValidationProps(field, props)}
                        value={field.value}
                        ref={inputRef}
                        class={
                            "w-[7rem] border-b-[0.5px] border-b-dark-gray-400 !bg-transparent pl-2 text-md lowercase autofill:!bg-transparent focus-visible:outline-none"
                        }
                        autoCapitalize={"none"}
                    />
                    <span onClick={focus} class="ml-2 mr-4 pr-2 text-md text-light-gray-500">
                        .aimmanager.com
                    </span>
                </div>
            </ValidationIconWrapper>
        </LabelAbove>
    );
}
