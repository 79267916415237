import { P } from "../../../utils/typography";
import { Modal, ModalController } from "../../ui/Modal";
import { Button } from "../../ui/components";

export function EndTaskModal(props: { controller: ModalController<void, void> }) {
    return (
        <Modal controller={props.controller}>
            <Modal.Header>
                <div
                    class={
                        "h-4.5 w-4.5 flex items-center justify-center rounded-full bg-warning-100"
                    }
                >
                    <i class="far fa-save text-warning-0" />
                </div>
            </Modal.Header>

            <P class={"text-xl font-medium"}>No ha finalizado la actividad</P>
            <P class={"text-default"}>
                Se han realizado todas las tareas, pero la actividad no ha sido finalizada.
            </P>
            <P class={"text-default"}>
                Si la actividad no es finalizada y se cumple el tiempo límite, esta actividad
                quedará expirada.
            </P>

            <div class={"mt-4 flex flex-col items-center space-y-2 md:flex-row md:justify-between"}>
                <Button
                    bgStyle={"outline"}
                    class={"w-full md:w-auto"}
                    onClick={() => props.controller.dismiss()}
                >
                    Salir y finalizar en otro momento
                </Button>
                <Button class={"w-full md:w-auto"} onClick={() => props.controller.dismiss()}>
                    Finalizar actividad
                </Button>
            </div>
        </Modal>
    );
}
