import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";

export function createChecklistRetrieveQuery(id: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["checklist", id()],
        queryFn: () => api.checklist.retrieveUniqChecklist.fetchJson(id()!),
        select: api.checklist.retrieveUniqChecklist.select,
        enabled: !!id(),
    }));
}
export function createChecklistExecutionRetrieveQuery(id: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["checklistExecution", id()],
        queryFn: () => api.checklist.retrieveUniqChecklistExecution.fetchJson(id()!),
        select: api.checklist.retrieveUniqChecklistExecution.select,
        enabled: !!id(),
    }));
}
export function createRetrieveUniqSection(id: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["retrieveUniqSection", id()],
        queryFn: () => api.checklist.retrieveUniqSection.fetchJson(id()!),
        select: api.checklist.retrieveUniqSection.select,
        enabled: !!id(),
    }));
}
export function createGetChecklistEntityTargetType(id: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["checklistEntityTargetType", id()],
        queryFn: () => api.checklist.getChecklistEntityTargetType.fetchJson(id()!),
        select: api.checklist.getChecklistEntityTargetType.select,
        enabled: !!id(),
    }));
}
export function createRetrieveStep(id: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["retrieveSectionStep", id()],
        queryFn: () => api.checklist.retrieveStep.fetchJson(id()!),
        select: api.checklist.retrieveStep.select,
        enabled: !!id(),
    }));
}
export function createChecklistListQuery() {
    return createApiQuery(api => ({
        queryKey: ["checklistList"],
        queryFn: () => api.checklist.listChecklist.fetchJson(),
        select: api.checklist.listChecklist.select,
    }));
}

export function createChecklistEntitiesListQuery(checklistID: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["checklistEntitiesList", checklistID()],
        queryFn: () => api.checklist.listChecklistEntities.fetchJson(checklistID()!),
        select: api.checklist.listChecklistEntities.select,
        enabled: !!checklistID(),
    }));
}

export function createChecklistExecutionsListQuery() {
    return createApiQuery(api => ({
        queryKey: ["checklistExecutionsList"],
        queryFn: () => api.checklist.listChecklistExecutions.fetchJson(),
        select: api.checklist.listChecklistExecutions.select,
    }));
}

export function createChecklistSectionsListQuery(checklistID: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["checklistSectionsList", checklistID()],
        queryFn: () => api.checklist.listChecklistSections.fetchJson(checklistID()),
        select: api.checklist.listChecklistSections.select,
    }));
}

export function createSectionStepListQuery(sectionId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["sectionStepList", sectionId()],
        queryFn: () => api.checklist.listSectionSteps.fetchJson(sectionId()),
        select: api.checklist.listSectionSteps.select,
    }));
}

export function createListFormChecklistResponses(
    checklistExecutionID: Accessor<string | undefined>,
) {
    return createApiQuery(api => ({
        queryKey: ["listFormChecklistResponses", checklistExecutionID()],
        queryFn: () => api.checklist.listFormChecklistResponses.fetchJson(checklistExecutionID()!),
        select: api.checklist.listFormChecklistResponses.select,
        enabled: !!checklistExecutionID(),
    }));
}

export function createChecklistOverviewQuery(checklistID: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["checklistOverview", checklistID()],
        queryFn: () => api.checklist.listChecklistOverview.fetchJson(checklistID()),
        select: api.checklist.listChecklistOverview.select,
    }));
}

export function createCurrentStepQuery(executionId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["currentStep", executionId()],
        queryFn: () => api.checklist.getCurrentStep.fetchJson(executionId()!),
        select: api.checklist.getCurrentStep.select,
        enabled: !!executionId(),
    }));
}
// listChecklistFormConceptsWithQuestion
export function createListChecklistFormConceptsWithQuestionQuery(evaluationID: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["listChecklistFormConceptsWithQuestion", evaluationID()],
        queryFn: () =>
            api.checklist.listChecklistFormConceptsWithQuestion.fetchJson(evaluationID()),
        select: api.checklist.listChecklistFormConceptsWithQuestion.select,
    }));
}

/** Evaluations **/
export function createListChecklistEvaluationQuery(checklistID: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["listChecklistEvaluation", checklistID()],
        queryFn: () => api.checklist.listEvaluations.fetchJson(checklistID()),
        select: api.checklist.listEvaluations.select,
    }));
}

export function createListChecklistConceptsQuery(evaluationID: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["listChecklistConcepts", evaluationID()],
        queryFn: () => api.checklist.listConcepts.fetchJson(evaluationID()),
        select: api.checklist.listConcepts.select,
    }));
}

export function createListConceptStepsQuery(conceptID: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["listConceptSteps", conceptID()],
        queryFn: () => api.checklist.listConceptSteps.fetchJson(conceptID()),
        select: api.checklist.listConceptSteps.select,
    }));
}

export function createListGradesQuery(
    evaluationID: Accessor<string>,
    executionID: Accessor<string>,
) {
    return createApiQuery(api => ({
        queryKey: ["listGrades", evaluationID()],
        queryFn: () => api.checklist.listGrades.fetchJson(evaluationID(), executionID()),
        select: api.checklist.listGrades.select,
    }));
}

export function createListCategoriesQuery() {
    return createApiQuery(api => ({
        queryKey: ["listCategories"],
        queryFn: () => api.checklist.listChecklistCategories.fetchJson(),
        select: api.checklist.listChecklistCategories.select,
    }));
}
