import { JSX, Suspense } from "solid-js";
import { ModalContentRender, ModalController } from "./Modal";
import { GenericSuspenseFallback } from "./skeletons";

export default function SidePanel<TData>(props: {
    children: JSX.Element | ((data: TData) => JSX.Element);
    position: "left" | "right";
    controller: ModalController<TData, void>;
}) {
    return (
        <>
            <div
                class={`fixed inset-0 z-side-panel bg-black opacity-50 ${
                    props.controller.state().isOpen ? "block" : "hidden"
                }`}
                onClick={() => props.controller.dismiss()}
            />

            <div
                class={`fixed ${
                    props.position === "left" ? "left-0" : "right-0"
                } inset-y-0 z-side-panel transform overflow-y-auto bg-white transition-transform duration-300 ${
                    props.controller.state().isOpen
                        ? "translate-x-0"
                        : props.position === "left"
                        ? "-translate-x-full"
                        : "translate-x-full"
                }`}
                style={{ "max-width": "calc(100vw - 4rem)" }}
            >
                <Suspense
                    fallback={
                        // If props.children don't have a Suspense, load during the animation
                        <div class="w-180" /* [1] */>
                            <GenericSuspenseFallback />
                        </div>
                    }
                >
                    <ModalContentRender state={props.controller.state()}>
                        {props.children}
                    </ModalContentRender>
                </Suspense>
            </div>
        </>
    );

    /* [1] We don't know the children width, by some trial and error, this is the guess
     *     resulting in the least bad animation if the children takes too long to load.
     */
}
