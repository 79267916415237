import { useLocale } from "../i18n/context";
import { For } from "solid-js";
import { Locale } from "../i18n/Locale";
import { OnClickButton } from "../../utils/solidjs";

export const pinnedBarWidth = "5.75rem";

export default function PinnedBar(props: {
    activeTab: PinnedTabKey;
    onClickTab: (tab: PinnedTabKey) => void;
}) {
    const [locale] = useLocale();
    const t = () => locale().menu.pinnedTabs;

    return (
        <div class="flex flex-col items-center" style={{ width: pinnedBarWidth }}>
            <div class="my-3 flex flex-col items-center gap-3">
                <For each={pinnedTabs}>
                    {tab => (
                        <HandlerMenuItem
                            icon={tab.icon}
                            title={t()[tab.key]}
                            active={tab.key === props.activeTab}
                            onClick={() => props.onClickTab(tab.key)}
                        />
                    )}
                </For>
            </div>
        </div>
    );
}

export type PinnedTabKey = keyof Locale["menu"]["pinnedTabs"];

const pinnedTabs: { key: PinnedTabKey; icon: string }[] = [
    { key: "home", icon: "fas fa-home" },
    { key: "tools", icon: "fas fa-cubes" },
    { key: "organization", icon: "fas fa-user-shield" },
    { key: "advanced", icon: "fas fa-cog" },
    { key: "myAccount", icon: "fas fa-user" },
];

function HandlerMenuItem(props: {
    icon: string;
    title: string;
    active: boolean;
    onClick?: OnClickButton;
}) {
    return (
        <button
            onClick={e => props.onClick?.(e)}
            class="flex w-full flex-col items-center text-light-gray-700"
        >
            <div classList={{ "bg-primary-100 px-3 rounded-2xl": props.active }}>
                <i class={`text-md ${props.icon} leading-normal`} />
            </div>
            <div class="text-xs">{props.title}</div>
        </button>
    );
}
