import { FrontOrganization } from "../organization/interface";
import { string, type } from "superstruct";
import { FrontSignInMethod, SignInMethodType } from "../organization/signInMethods";

/** The "auth" service has endpoints for post-onboarding authentication. */
export interface AuthService {
    /** Returns the workspaces where `email` can sign in, in the order they should
     * appear on the workspace selection step (or workspace switcher).
     * @deprecated - Workspace are not needed anymore. The "select workspace"
     * step should be deleted. */
    listWorkspaces(email: string): Promise<WorkspaceOverview[]>;

    /** Sign in with email and password using the appropriate authentication
     * provider. Must dispatch a SignIn event on success, or throw
     * WrongPasswordError if the email and password don't match.
     * @see dispatchSignInEvent
     * @throws WrongPasswordError */
    signInWithEmailAndPassword(
        organization: FrontOrganization,
        email: string,
        password: string,
    ): Promise<void>;

    /**
     * @param organization - Only allow accounts with access to this organization.
     * @param email - If provided, this email will be prefilled during the Google sign-in flow.
     * @throws PopupBlockedError
     * @throws SignInCanceledError
     * @throws OutsideOrganizationError
     * @throws AlreadyInProgressError
     */
    signInWithGoogle(organization: FrontOrganization, email: string | undefined): Promise<void>;

    /** Similar to signInWithGoogle, but for SAML providers. */
    signInWithSaml(
        organization: FrontOrganization,
        email: string | undefined,
        method: FrontSignInMethod & { type: SignInMethodType.SAML },
    ): Promise<void>;

    /** Similar to signInWithGoogle, but for OIDC providers. */
    signInWithOidc(
        organization: FrontOrganization,
        email: string | undefined,
        method: FrontSignInMethod & { type: SignInMethodType.OIDC },
    ): Promise<void>;

    /** Used when user clicks "Forgot password".
     * Sends an email with instructions to reset password.
     * If `email` is not registered, we still tell the user the email was sent
     * (a lie, as we actually do nothing) to prevent an "email enumeration"
     * vulnerability. */
    sendPasswordResetEmail(organization: FrontOrganization, email: string): Promise<void>;

    /** Sign out from the authentication provider.
     * Must dispatch a SignOut event on success.
     * @see dispatchSignOutEvent */
    signOut(): Promise<void>;

    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#send-magic-code */
    sendMagicCode(email: string): Promise<void>;

    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#receive-magic-code
     *
     * Returns null if the magic code didn't match or the email isn't registered. */
    receiveMagicCode(request: ReceiveMagicCodeRequest): Promise<ReceiveMagicCodeResponse | null>;

    /** https://capawesome.io/plugins/firebase/authentication/#sendsigninlinktoemail
     *
     * Used to sign in user from magic link
     */
    sendSigninLinkToEmail(organization: FrontOrganization, email: string): Promise<void>;

    /**
     *
     * Used to create and invite a new user. Firebase will send an email to the email address
     */
    sendInvite(email: string): Promise<void>;

    /** https://capawesome.io/plugins/firebase/authentication/#issigninwithemaillink
     *
     * Used to validate the integrity of the link sent to the user's email address
     */
    isSignInWithEmailLink(link: string): Promise<boolean>;

    /** https://capawesome.io/plugins/firebase/authentication/#signinwithemaillink
     *
     * Used to sign in the user after they have clicked the link sent to their email address
     */
    signInWithEmailLink(params: { email: string; link: string }): Promise<void>;
}

export enum BackendVersion {
    Atlas = "atlas",
    V1 = "v1",
}

export class WrongPasswordError extends Error {
    constructor() {
        super("Wrong password");
        Object.setPrototypeOf(this, WrongPasswordError.prototype);
        Error.captureStackTrace?.(this, WrongPasswordError);
        this.name = this.constructor.name;
    }
}
export class WrongEmailFormatError extends Error {
    constructor() {
        super("Wrong email format");
        Object.setPrototypeOf(this, WrongEmailFormatError.prototype);
        Error.captureStackTrace?.(this, WrongEmailFormatError);
        this.name = this.constructor.name;
    }
}

/** e.g. the sign in with Google popup was blocked by the browser. */
export class PopupBlockedError extends Error {
    constructor() {
        super("Popup blocked");
        Object.setPrototypeOf(this, PopupBlockedError.prototype);
        Error.captureStackTrace?.(this, PopupBlockedError);
        this.name = this.constructor.name;
    }
}

/** Used to stop sign in promise execution when user cancels a sign in with
 * popup, e.g. Google sign in. */
export class SignInCanceledError extends Error {
    constructor(readonly canceledByUser: boolean) {
        super(`Sign in canceled ${canceledByUser ? "by user" : "programmatically"}`);
        Object.setPrototypeOf(this, SignInCanceledError.prototype);
        Error.captureStackTrace?.(this, SignInCanceledError);
        this.name = this.constructor.name;
    }
}

/** If someone outside the organization tries to sign in with a federated provider
 * (e.g. Google), there is no risk of email enumeration, so we can give them that
 * information instead of saying "wrong password".
 */
export class OutsideOrganizationError extends Error {
    constructor() {
        super("No perteneces a la organización");
        Object.setPrototypeOf(this, OutsideOrganizationError.prototype);
        Error.captureStackTrace?.(this, OutsideOrganizationError);
        this.name = this.constructor.name;
    }
}

/** May happen if the user signs in with some sign-in method, but previously
 * signed in with another method and the accounts have not been linked.
 *
 * Learn why accounts are not linked automatically for unverified email addresses
 * https://stackoverflow.com/a/75608487.
 */
export class NeedConfirmError extends Error {
    /**
     * @param email - The email address of the user if we know it.
     * @param currentMethod - The sign-in method that the user just tried.
     * @param prevMethods - The sign-in methods that already have an account for that
     * email address. If we don't know them (because [email enumeration protection](https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection)
     * is enabled), this will be undefined. This is never an empty array.
     */
    constructor(
        readonly email: string | undefined,
        readonly currentMethod: FrontSignInMethod,
        readonly prevMethods?: FrontSignInMethod[],
    ) {
        super("Need confirm");
        Object.setPrototypeOf(this, NeedConfirmError.prototype);
        Error.captureStackTrace?.(this, NeedConfirmError);
        this.name = this.constructor.name;
    }
}

/** Happens on Android when a federated sign-in method opens a tab in the browser app
 * and the user switches to the app (without closing the browser tab), then tries to
 * initiate another federated sign-in method.
 */
export class AlreadyInProgressError extends Error {
    constructor() {
        super("Another federated sign-in method is already in progress");
        Object.setPrototypeOf(this, AlreadyInProgressError.prototype);
        Error.captureStackTrace?.(this, AlreadyInProgressError);
        this.name = this.constructor.name;
    }
}

/** @deprecated - Use `Organization` instead */
export interface WorkspaceOverview {
    companyLogoUrl: string;
    /** e.g. "Mall Plaza". */
    companyName: string;
    /** e.g. "mycompany". */
    subdomain: string;
    backendVersion: BackendVersion;
}

/** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#receive-magic-code */
export interface ReceiveMagicCodeRequest {
    email: string;
    magicCode: string;
}

/** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#receive-magic-code */
export interface ReceiveMagicCodeResponse {
    subdomain: string;
    firebaseTenantId: string | null;
    firebaseCustomToken: string | null;
}

export function sReceiveMagicCodeResponse() {
    return type({
        subdomain: string(),
        firebase_tenant_id: string(),
        firebase_custom_token: string(),
    });
}
