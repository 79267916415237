import { Show, For } from "solid-js";
import { H1 } from "../../utils/typography";
import { AudienceResult } from "../../api/services/audience/interface";
import { createAudienceResultListQuery } from "../../api/services/audience/queries";

export function AudiencesPeopleResultsTable(props: { id: string }) {
    const audienceResultsQuery = createAudienceResultListQuery(props.id);

    return (
        <div>
            <H1>Listado de usuarios en la audiencia</H1>
            <Show when={audienceResultsQuery.data}>
                {results => (
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>ID organización</th>
                            </tr>
                        </thead>
                        <For each={results()}>
                            {result => <AudiencePeopleRow people={result} />}
                        </For>
                    </table>
                )}
            </Show>
        </div>
    );
}
function AudiencePeopleRow(props: { people: AudienceResult }) {
    return (
        <tr>
            <td>{props.people.id}</td>
            <td>
                {props.people.first_name} {props.people.last_name}
            </td>
            <td>{props.people.email}</td>
            <td>{props.people.organization}</td>
        </tr>
    );
}
