import { Modal, ModalController } from "../ui/Modal";
import WorkspaceStep from "./steps/WorkspaceStep";
import { AuthenticatedUser } from "./useAuthReturn";
import { LavandaCard } from "../ui/cards";
import { createSignal, Match, ParentProps, Switch, useTransition } from "solid-js";
import { OnClickButton } from "../../utils/solidjs";
import SignInMethodStep from "./steps/SignInMethodStep";
import { fireAndForget } from "../../utils/async";

import { WorkspaceOverview } from "../../api/services/auth/interface";

export default function SwitchWorkspaceModal(props: {
    controller: ModalController<AuthenticatedUser, void>;
}) {
    return (
        <Modal controller={props.controller}>
            {user => (
                <LavandaCard>
                    <SwitchWorkspaceStateMachine
                        email={user.email}
                        onSuccess={props.controller.dismiss}
                        onGoBack={props.controller.dismiss}
                    />
                </LavandaCard>
            )}
        </Modal>
    );
}

type SwitchWorkspaceState = WorkspaceStepState | SignInMethodStepState;
type WorkspaceStepState = { step: "workspace"; email: string };
type SignInMethodStepState = { step: "signInMethod"; email: string; workspace: WorkspaceOverview };

function SwitchWorkspaceStateMachine(props: {
    email: string;
    onSuccess: () => void;
    onGoBack: OnClickButton;
}) {
    const [state, setState] = createSignal<SwitchWorkspaceState>({
        step: "workspace",
        email: props.email,
    });

    const [pending, start] = useTransition();
    const transitionTo = (step: SwitchWorkspaceState) => fireAndForget(start(() => setState(step)));

    const advanceToSignInMethodStep = (email: string) => (workspace: WorkspaceOverview) =>
        transitionTo({ step: "signInMethod", email, workspace });
    const returnToWorkspaceStep = (email: string) => () =>
        transitionTo({ step: "workspace", email });

    return (
        <TransitionWrapper pending={pending()}>
            <Switch>
                <Match when={state().step === "workspace" && (state() as WorkspaceStepState)}>
                    {state => (
                        <WorkspaceStep
                            staticEmail={props.email}
                            onSelectWorkspace={advanceToSignInMethodStep(state().email)}
                            onGoBack={props.onGoBack}
                        />
                    )}
                </Match>
                <Match when={state().step === "signInMethod" && (state() as SignInMethodStepState)}>
                    {state => (
                        <SignInMethodStep
                            defaultEmail={state().email}
                            subdomain={state().workspace.subdomain}
                            onGoBack={returnToWorkspaceStep(state().email)}
                            onMagicLinkSent={() => alert("not implemented")}
                            onContinueWithPassword={() => alert("not implemented")}
                        />
                    )}
                </Match>
            </Switch>
        </TransitionWrapper>
    );
}

function TransitionWrapper(props: ParentProps<{ pending: boolean }>) {
    return (
        <div class="bg-inherit" classList={{ "opacity-50": props.pending }}>
            {props.children}
        </div>
    );
}
