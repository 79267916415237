import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";

export function createPeopleQuery() {
    return createApiQuery(api => ({
        queryKey: ["people"],
        queryFn: () => api.person.getPeople(),
    }));
}
export function createPersonQuery(id: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["person", id()],
        queryFn: () => api.person.getPerson(id()!),
        enabled: !!id(),
    }));
}

export function createPersonalDataQuery() {
    return createApiQuery(api => ({
        queryKey: ["personalData"],
        queryFn: () => api.person.retrievePersonalData(),
    }));
}

export function createExportPersons(tenant_id: string) {
    return createApiQuery(api => ({
        queryKey: ["exportPersons", tenant_id],
        queryFn: () => api.person.exportPersons(tenant_id),
        enabled: false,
    }));
}
