import { createSignal, Match, ParentProps, Switch } from "solid-js";
import FindOrganizationStep from "./steps/FindOrganizationStep";
import SignInMethodStep from "./steps/SignInMethodStep";
import { LavandaCard } from "../ui/cards";
import SubdomainStep from "./steps/SubdomainStep";
import MagicCodeStep from "./steps/MagicCodeStep";
import MagicLinkStep from "./steps/MagicLinkStep";
import PasswordStep from "./steps/PasswordStep";
import { FrontOrganization } from "../../api/services/organization/interface";

export default function SignInPage() {
    return (
        <GradientBackgroundWrapper>
            <LavandaCard class={"!bg-white !px-8"}>
                <LoginStateMachine />
            </LavandaCard>
        </GradientBackgroundWrapper>
    );
}

type LoginState =
    | SubdomainStepState
    | FindOrganizationStepState
    | MagicCodeStepState
    | SignInMethodStepState
    | MagicLinkStepState
    | PasswordStepState;

type SubdomainStepState = { step: "subdomain" };
type FindOrganizationStepState = { step: "findOrganization" };
type MagicCodeStepState = { step: "magicCode"; email: string };
type SignInMethodStepState = {
    step: "signInMethod";
    subdomain: string;
    email: string | undefined;
};
type MagicLinkStepState = { step: "magicLink"; subdomain: string; email: string };
type PasswordStepState = { step: "password"; organization: FrontOrganization; email: string };

/* We use a local state machine to manage the different steps of the login process.
 * We can't use the URL to manage the state because it would leak the user's
 * credentials to the browser history. */
function LoginStateMachine() {
    const [state, setState] = createSignal<LoginState>({ step: "subdomain" });
    /* Dark magic so TypeScript trusts the type of the step inside Match.
     * For context, see https://github.com/solidjs/solid/issues/199. */
    const stepIs = <const TStep extends LoginState["step"]>(step: TStep) =>
        state().step === step && (state() as LoginState & { step: TStep });

    return (
        <Switch>
            <Match when={stepIs("subdomain")}>
                <SubdomainStep
                    onSubmitSubdomain={subdomain =>
                        setState({ step: "signInMethod", subdomain, email: undefined })
                    }
                    onForgotSubdomain={() => setState({ step: "findOrganization" })}
                />
            </Match>
            <Match when={stepIs("findOrganization")}>
                <FindOrganizationStep
                    onSubmitEmail={email => setState({ step: "magicCode", email })}
                    onGoBack={() => setState({ step: "subdomain" })}
                />
            </Match>
            <Match when={stepIs("magicCode")}>
                {state => (
                    <MagicCodeStep
                        email={state().email}
                        onReceiveSubdomain={subdomain =>
                            setState({ step: "signInMethod", subdomain, email: state().email })
                        }
                        onGoBack={() => setState({ step: "findOrganization" })}
                    />
                )}
            </Match>
            <Match when={stepIs("signInMethod")}>
                {state => (
                    <SignInMethodStep
                        defaultEmail={state().email}
                        subdomain={state().subdomain}
                        onMagicLinkSent={email =>
                            setState({ step: "magicLink", subdomain: state().subdomain, email })
                        }
                        onContinueWithPassword={(organization, email) =>
                            setState({ step: "password", organization, email })
                        }
                        onGoBack={() => setState({ step: "subdomain" })}
                    />
                )}
            </Match>
            <Match when={stepIs("magicLink")}>
                {state => (
                    <MagicLinkStep
                        email={state().email}
                        subdomain={state().subdomain}
                        onGoBack={() =>
                            setState({
                                step: "signInMethod",
                                subdomain: state().subdomain,
                                email: state().email,
                            })
                        }
                    />
                )}
            </Match>
            <Match when={stepIs("password")}>
                {state => (
                    <PasswordStep
                        organization={state().organization}
                        defaultEmail={state().email}
                        onGoBack={() =>
                            setState({
                                step: "signInMethod",
                                subdomain: state().organization.subdomain,
                                email: state().email,
                            })
                        }
                    />
                )}
            </Match>
        </Switch>
    );
}

export function GradientBackgroundWrapper(props: ParentProps) {
    /*const a = "hsl(var(--hsl-primary-400))";
    const b = "hsl(var(--hsl-primary-900))";*/

    return (
        <div
            class="center-items h-screen w-screen overflow-x-hidden bg-light-gray-25"
            /*style={{ background: `radial-gradient(circle, ${a} 0%, ${b} 100%)` }}*/
            // style={{ background: `linear-gradient(to bottom right, ${a}, ${b})` }}
        >
            <div class="max-h-full overflow-y-auto px-4 py-4 sm:px-0">{props.children}</div>
        </div>
    );
}
