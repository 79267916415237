import { useNavigate, useParams } from "@solidjs/router";
import { P } from "../../utils/typography";
import Dropdown from "../ui/Dropdown";
import { Tabs } from "../ui/Tabs";
import { BorderedCard } from "../ui/cards";
import { Badge, Button } from "../ui/components";
import { PageWrapper } from "../ui/pageWrappers";
import { For, Show } from "solid-js";
import { ProfileTab } from "./tabs/ProfileTab";
import { WorkPlacesTab } from "./tabs/WorkPlacesTab";
import { DocumentsTab } from "./tabs/DocumentsTab";
import { HistoryTab } from "./tabs/HistoryTab";
import { RolesTab } from "./tabs/RolesTab";
import { createPersonQuery } from "../../api/services/person/queries";
import { DevSuspense } from "../ui/skeletons";
import { useLocale } from "../i18n/context";
import { LuchoPerson } from "../../api/services/person/LuchoPerson";

export function PeopleDetailPage() {
    const params = useParams<{ id: string }>();
    const personId = () => params.id;
    const personQuery = createPersonQuery(personId);
    return (
        <PageWrapper>
            <DevSuspense fallback={<p>Loading...</p>}>
                <Show when={personQuery.data}>
                    {person => (
                        <>
                            <PeopleDetailHeader person={person()} />
                            <PersonSummary person={person()} />
                            <PersonDetailTabs />
                        </>
                    )}
                </Show>
            </DevSuspense>
        </PageWrapper>
    );
}
function PersonDetailTabs() {
    return (
        <div>
            <Tabs>
                <ProfileTab />
                <WorkPlacesTab />
                <DocumentsTab />
                <HistoryTab />
                <RolesTab />
            </Tabs>
        </div>
    );
}

function PersonSummary(props: { person: LuchoPerson }) {
    return (
        <div class="grid grid-cols-12 py-4">
            <div class="col-span-12 space-y-3 md:col-span-5">
                <PersonSummaryItem
                    label="Identificación"
                    value={JSON.stringify(props.person.identifiers)}
                />
                <PersonSummaryItem
                    label="Correo"
                    value={props.person.emails.map(item => item.email).join(",")}
                />
                <PersonSummaryItem
                    label="Teléfono de contacto"
                    value={props.person.phone_number ?? ""}
                />
            </div>
            <div class="col-span-12 flex justify-end md:col-span-4 md:col-start-9">
                <PersonStatusCard />
            </div>
        </div>
    );
}

function PersonStatusCard() {
    return (
        <BorderedCard>
            <P class={"!mb-0 text-xs text-dark-gray-400"}>Estado</P>
            <P class="!mb-0 flex items-center gap-x-2 text-md text-success-500">
                <span class={"block h-2 w-2 rounded-full bg-success-500"} />
                Vigente
            </P>
            <P class={"!mb-0"}>
                <span class={"text-dark-gray-400"}>Última actividad: </span>
                <span class={"text-dark-gray-700"}>22 ene 24, 12:00</span>
            </P>
        </BorderedCard>
    );
}

function PersonSummaryItem(props: { label: string; value: string }) {
    return (
        <div class="flex">
            <P class={"!mb-0 w-full font-bold"}>{props.label}:</P>
            <div class={"w-full"}>
                <Badge>{props.value}</Badge>
            </div>
        </div>
    );
}
const actionOptions = [
    { label: "Suplantar Persona", value: "1", textColor: "!text-dark-gray-700" },
    { label: "Recalcular Perfil", value: "2", textColor: "!text-dark-gray-700" },
    { label: "Editar Persona", value: "3", textColor: "!text-dark-gray-700" },
    { label: "Invitar a socio comercial", value: "4", textColor: "!text-dark-gray-700" },
    { label: "Invitar a empresa contratista", value: "5", textColor: "!text-dark-gray-700" },
    { label: "Remover el acceso a AIM Manager", value: "6", textColor: "!text-dark-gray-700" },
    { label: "Enviar a lista negra", value: "7", textColor: "!text-error-400" },
];
function PeopleDetailHeader(props: { person: LuchoPerson }) {
    const [l] = useLocale();
    const navigate = useNavigate();
    const navigateBack = () => navigate("/people");

    return (
        <div class="flex items-center gap-x-3">
            <Button onClick={navigateBack} bgStyle={"text-only"}>
                <i class="fas fa-chevron-left text-display-sm" />
            </Button>
            <Button
                bgStyle={"text-only"}
                class={"block h-16 w-16 rounded-full bg-primary-400 text-white"}
            >
                <i class="fas fa-user text-display-sm" />
            </Button>
            <div>
                <P class={"!mb-0 text-display-sm font-medium"}>{props.person.name}</P>
            </div>
            <div>
                <Dropdown
                    itemsContainerClass={
                        "shadow-light-gray-200 rounded-xl bg-white !right-[inherit] !py-3 px-2"
                    }
                    trigger={onClick => (
                        <Button onClick={onClick} class={"flex items-center"}>
                            {l().personalData.actions}
                            <i class="fas fa-sort-down -mt-1" />
                        </Button>
                    )}
                >
                    <For each={actionOptions}>
                        {option => (
                            <Button
                                variant="primary"
                                bgStyle="text-only"
                                onClick={() => alert("WIP actions")}
                                class={`whitespace-nowrap !font-normal ${option.textColor}`}
                            >
                                {option.label}
                            </Button>
                        )}
                    </For>
                </Dropdown>
            </div>
        </div>
    );
}
