import { Show, For } from "solid-js";
import { H1 } from "../../utils/typography";
import { createAudienceFunctionsQuery } from "../../api/services/audience/queries";

export function AudiencesFunctionsTable() {
    const audienceFunctionsQuery = createAudienceFunctionsQuery();

    return (
        <div>
            <H1>Funciones disponibles</H1>
            <Show when={audienceFunctionsQuery.data}>
                {data => {
                    return (
                        <For each={data()}>
                            {fun => {
                                return (
                                    <div class={"flex"}>
                                        <div>Name:{fun.name}</div>
                                        <div>Arg: {fun.arg}</div>
                                    </div>
                                );
                            }}
                        </For>
                    );
                }}
            </Show>
        </div>
    );
}
