import { FieldProps } from "../forms/fields/parts/types";
import SelectField from "../forms/fields/SelectField";

export default function UuidAudienceField(props: FieldProps<string, HTMLSelectElement>) {
    return (
        <SelectField
            {...props}
            options={[
                ["", "--- Selecciona una audiencia ---"],
                ["018fabf6-f0ca-7ff4-8717-b2fc0c5e7f39", "Robinson, Alan, Xeo, Luis"],
            ]}
        />
    );
}
