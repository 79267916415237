import { P } from "../../utils/typography";
import ActivityItemMobile from "./ActivityItemMobile";
import { createMemo, For, mergeProps, Show, Suspense } from "solid-js";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import ActivityItemDesktop from "./ActivityItemDesktop";
import { useLocale } from "../i18n/context";
import { Calendar } from "../../api/services/task-manager/interface";
import { CreateQueryResult } from "@tanstack/solid-query";
import {
    createMyCalendarQuery,
    createRawActivitiesQuery,
} from "../../api/services/task-manager/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";

export default function ActivityItemList(props: {
    calendarQuery?: CreateQueryResult<Calendar>;
    startDate?: Temporal.PlainDate;
    endDate?: Temporal.PlainDate;
    reverse?: boolean;
}) {
    const p = mergeProps(
        {
            calendarQuery: createMyCalendarQuery(),
            startDate: Temporal.Now.plainDateISO().subtract({ months: 1 }),
            endDate: Temporal.Now.plainDateISO(),
            reverse: true,
        },
        props,
    );

    return (
        <>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show
                    when={p.calendarQuery.data}
                    fallback={<ErrorBlock error={p.calendarQuery.error} />}
                >
                    {calendar => <ActivityItemListImpl {...p} calendar={calendar()} />}
                </Show>
            </Suspense>
        </>
    );
}

function ActivityItemListImpl(props: {
    calendar: Calendar;
    startDate: Temporal.PlainDate;
    endDate: Temporal.PlainDate;
    reverse: boolean;
}) {
    const [locale] = useLocale();
    const { sm } = useResponsiveBreakpoints();
    const activities = createMemo(() =>
        props.calendar.getActivities(props.startDate, props.endDate),
    );

    function traverse<T>(arr: T[]): T[] {
        return props.reverse ? arr.reverse() : arr;
    }

    return (
        <div class="grid gap-y-px bg-light-gray-300">
            <For each={traverse(dateRange(props.startDate, props.endDate))}>
                {date => (
                    <>
                        <div class="flex justify-between bg-white p-3">
                            <h2 id={date.toString()}>
                                {Intl.DateTimeFormat(locale().codeWithCountry).format(date)}
                            </h2>
                            <div>Fecha límite</div>
                        </div>
                        <For
                            each={activities().filter(act =>
                                act.dueDate.toPlainDate().equals(date),
                            )}
                            fallback={<P>No hay actividades ese día.</P>}
                        >
                            {a =>
                                !sm() ? (
                                    <ActivityItemMobile activity={a} />
                                ) : (
                                    <ActivityItemDesktop activity={a} />
                                )
                            }
                        </For>
                    </>
                )}
            </For>
        </div>
    );
}

/** Array with dates between start and end (both inclusive). */
function dateRange(startDate: Temporal.PlainDate, endDate: Temporal.PlainDate) {
    const range = [];
    for (let d = startDate; Temporal.PlainDate.compare(d, endDate) <= 0; d = d.add({ days: 1 })) {
        range.push(d);
    }
    return range;
}
