import { Modal, ModalController } from "../../ui/Modal";
import { P } from "../../../utils/typography";
import { Button } from "../../ui/components";
import CheckboxField from "../../forms/fields/CheckboxField";
import { FormWrapper } from "../../forms/FormWrapper";

export function SelectEnclosuresModal(props: { controller: ModalController<unknown, unknown> }) {
    return (
        <Modal controller={props.controller}>
            <P class={"text-sm font-medium text-primary-700"}>Buscar recintos</P>

            <div class={"flex gap-x-3"}>
                <Button bgStyle={"text-only"} class={"!m-0 !p-0"}>
                    Seleccionar todo
                </Button>
                <P class={"!m-0"}>-</P>
                <Button bgStyle={"text-only"} class={"!m-0 !p-0"}>
                    Limpiar
                </Button>
            </div>

            <div>
                <FormWrapper class={"mt-3 flex flex-col gap-y-2"}>
                    <CheckboxField name={"enclosure"} label={"Parque alameda"} />
                    <CheckboxField name={"enclosure"} label={"Parque alameda"} />
                    <CheckboxField name={"enclosure"} label={"Parque alameda"} />
                    <CheckboxField name={"enclosure"} label={"Parque alameda"} />
                    <CheckboxField name={"enclosure"} label={"Parque alameda"} />

                    <div
                        class={
                            "mt-3 flex justify-between gap-x-3 border-t border-light-gray-200 pt-3"
                        }
                    >
                        <Button
                            class={"w-full"}
                            bgStyle={"outline"}
                            onClick={props.controller.dismiss}
                        >
                            Cancelar
                        </Button>
                        <Button class={"w-full"} type={"submit"}>
                            Aplicar
                        </Button>
                    </div>
                </FormWrapper>
            </div>
        </Modal>
    );
}
