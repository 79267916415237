import { For, Show, Suspense } from "solid-js";
import { createListAudiencesQuery } from "../../api/services/audience/queries";
import { PageWrapper } from "../ui/pageWrappers";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { Button } from "../ui/components";
import { useNavigate } from "@solidjs/router";
import { FormWrapper } from "../forms/FormWrapper";
import { LavandaCard } from "../ui/cards";

export default function AudiencesPage() {
    const audiencesListQuery = createListAudiencesQuery();
    const navigate = useNavigate();
    return (
        <PageWrapper>
            <div class="flex">
                <Button onClick={() => navigate("/audiences/create")}>Crear audiencia</Button>
            </div>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={audiencesListQuery.data}>
                    {audiencesList => {
                        return (
                            <For each={audiencesList()}>
                                {audience => {
                                    return (
                                        <a href={`/audiences/${audience.id}`}>
                                            <LavandaCard>
                                                <div class="flex justify-between">
                                                    <div>
                                                        <h1>{audience.name}</h1>
                                                    </div>
                                                    <div>
                                                        <p>{audience.id}</p>
                                                    </div>
                                                </div>
                                            </LavandaCard>
                                        </a>
                                    );
                                }}
                            </For>
                        );
                    }}
                </Show>
            </Suspense>
            <FormWrapper>
                {/* <SelectField
                label="Audiencia Single"
                name="audience"
                options={[
                    ["1", "Audiencia 1"],
                    ["2", "Audiencia 2"],
                    ["3", "Audiencia 3"],
                ]}
            />
                 <SelectField
                 multiple
                label="Audiencia Multiple"
                name="audiencemultiple"
                options={[
                    ["1", "Audiencia 1"],
                    ["2", "Audiencia 2"],
                    ["3", "Audiencia 3"],
                ]}
            /> */}
            </FormWrapper>
        </PageWrapper>
    );
}
