import { type, string, Describe, Infer } from "superstruct";
import { ServiceQuery } from "../../utils";

export interface GroupsService {
    // Functional Groups
    retrieveFunctionalGroups: ServiceQuery<[], FunctionalGroup[]>;
    getFunctionalGroup: ServiceQuery<[groupId: string], FunctionalGroup>;
    createFunctionalGroup: (payload: CreateUpdateFunctionalGroup) => Promise<FunctionalGroup>;
    putFunctionalGroup: (
        groupId: string,
        payload: CreateUpdateFunctionalGroup,
    ) => Promise<FunctionalGroup>;
    patchFunctionalGroup: (
        groupId: string,
        payload: CreateUpdateFunctionalGroup,
    ) => Promise<FunctionalGroup>;
    deleteFunctionalGroup: (groupId: string) => Promise<void>;

    // Group
    retrieveGroups: ServiceQuery<[], Group[]>;
    getGroup: ServiceQuery<[groupId: string], Group>;
    createGroup: (payload: CreateUpdateGroup) => Promise<Group>;
    putGroup: (groupId: string, payload: CreateUpdateGroup) => Promise<Group>;
    patchGroup: (groupId: string, payload: CreateUpdateGroup) => Promise<Group>;
    deleteGroup: (groupId: string) => Promise<void>;
    // GroupMembers. Api says it return a Group object, but i think it should return other object
    retrieveGroupMembers: ServiceQuery<[groupId: string], Group>;
}

export type Group = {
    id: string;
    name: string;
    description: string;
    type: string;
    tenant: string;
};

export function sGroup(): Describe<Group> {
    return type({
        id: string(),
        name: string(),
        description: string(),
        type: string(),
        tenant: string(),
    });
}
export function sCreateUpdateGroup() {
    return type({
        name: string(),
        description: string(),
    });
}

export type CreateUpdateGroup = Infer<ReturnType<typeof sCreateUpdateGroup>>;

export function sFunctionalGroup() {
    return type({
        id: string(),
        name: string(),
        description: string(),
        tenant: string(),
    });
}

export type FunctionalGroup = Infer<ReturnType<typeof sFunctionalGroup>>;

export function sCreateUpdateFunctionalGroup() {
    return type({
        name: string(),
        description: string(),
    });
}

export type CreateUpdateFunctionalGroup = Infer<ReturnType<typeof sCreateUpdateFunctionalGroup>>;
