import { nonCheckboxInputStyling } from "../presentation";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";
import { ErrorMessage } from "./parts/ErrorMessage";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import { createUniqueId } from "solid-js";

export default function MagicCodeField(props: Omit<FieldProps<string, HTMLInputElement>, "label">) {
    const field = createField(props, "");
    const inputId = createUniqueId();

    return (
        <div class={props.parentClass}>
            <input
                {...getCommonInputProps(inputId, props)}
                {...getInputValidationProps(field, props)}
                class={
                    nonCheckboxInputStyling.class +
                    " h-20 rounded-none border-l-0 border-r-0 border-t-0 bg-transparent text-display-md focus-visible:outline-none"
                }
                style={nonCheckboxInputStyling.style}
                value={field.value}
                autofocus={true}
                maxlength={6}
            />
            <ErrorMessage field={field} />
        </div>
    );
}
