import { createEffect } from "solid-js";
import { menuTabs } from "../menu/utils";
import { useNavigate } from "@solidjs/router";
import { useAuth } from "./authContext";
import { GenericSuspenseFallback } from "../ui/skeletons";

export default function IndexPage() {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    createEffect(() => {
        if (isAuthenticated() === true) {
            navigate(Object.values(menuTabs)[0].href, { replace: true });
        } else if (isAuthenticated() === false) {
            navigate("/login", { replace: true });
        }
    });

    return <GenericSuspenseFallback />;
}
