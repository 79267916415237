import { HomeService, Page } from "../interface";
import AtlasHomeService, { AtlasWidgetType } from "./atlas";
import { getUserOr401 } from "../../../../modules/auth/authContext";

export default class MockHomeService implements HomeService {
    private atlas = new AtlasHomeService();

    retrieveHomePage = async (): Promise<Page> => {
        const user = await getUserOr401();
        const page = await this.atlas.retrieveHomePage();
        return {
            ...page,
            widgets: user.email.endsWith("@aimmanager.com")
                ? page.widgets
                : page.widgets.filter(widget => widget.type !== AtlasWidgetType.POSTS),
        };
    };

    listPostsByChannel = this.atlas.listPostsByChannel;
    retrieveWidgetConfig = this.atlas.retrieveWidgetConfig;
}
