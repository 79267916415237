import { ColumnDefinition, Table } from "../ui/Table";
import { P } from "../../utils/typography";
import { Badge, Button, Icon } from "../ui/components";
import { formattedDate } from "../../utils/formatDate";

type Document = {
    type: string;
    state: "active" | "pending" | "expired";
    lastLoadedDocument: string;
    loadedBy: string;
    loadedAt: string;
};
const documents: Document[] = [
    {
        type: "Contrato",
        state: "active",
        lastLoadedDocument: "Contrato.pdf",
        loadedBy: "Juan Perez",
        loadedAt: "2021-09-01",
    },
    {
        type: "Carnet de identidad",
        state: "expired",
        lastLoadedDocument: "Carnet.pdf",
        loadedBy: "Juan Perez",
        loadedAt: "2021-09-01",
    },
    {
        type: "Certificado de antecedentes",
        state: "pending",
        lastLoadedDocument: "Certificado.pdf",
        loadedBy: "Juan Perez",
        loadedAt: "2021-09-01",
    },
];
const tableColumns: ColumnDefinition<Document>[] = [
    {
        title: "Tipo",
        accessor: "type",
    },
    {
        title: "Estado",
        cell: row => {
            const parsedState = {
                active: "Activo",
                pending: "Pendiente",
                expired: "Expirado",
            };
            const stateIcon = {
                active: <i class="fas fa-check" />,
                pending: <i class="fas fa-exclamation-circle" />,
                expired: <i class="fas fa-times" />,
            };
            const color = {
                active: "text-success-500",
                pending: "text-warning-0",
                expired: "text-error-500",
            };
            return (
                <P class={`!mb-0 ${color[row.state]}`}>
                    <span class="mr-2 text-xs">{stateIcon[row.state]}</span>
                    <span class={""}>{parsedState[row.state]}</span>
                </P>
            );
        },
    },
    {
        title: "Último documento cargado",
        cell: row => {
            return (
                <div>
                    <P class={"!mb-0"}>{row.lastLoadedDocument}</P>
                    <Badge>{formattedDate(row.loadedAt, "only-numbers")}</Badge>
                </div>
            );
        },
    },
    {
        title: "Cargado por",
        cell: row => {
            return (
                <div>
                    <P class={"!mb-0"}>{row.loadedBy}</P>
                    <Badge>c0rr30@3l3ctr0n1c0.c0m</Badge>
                </div>
            );
        },
    },
    {
        title: "",
        cell: () => (
            <div>
                <Button
                    onClick={() => alert("See documents detail WIP.")}
                    bgStyle={"outline"}
                    class={"!border-light-gray-300 !p-2"}
                >
                    <Icon name="fas fa-eye text-dark-gray-400" />
                </Button>
            </div>
        ),
    },
];
export function DocumentsTable() {
    return (
        <div>
            <Table
                theadClass={"text-left"}
                tbodyTrClass={"border-b border-light-gray-300 [&>td]:py-3 px-2"}
                columns={tableColumns}
                data={documents}
            />
        </div>
    );
}
