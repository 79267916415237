import { P } from "../../../utils/typography";
import { useLocale } from "../../i18n/context";
import { InfoBubble } from "../../ui/InfoBubble";
import { Tab } from "../../ui/Tabs";
import { BorderedCard } from "../../ui/cards";
import { Button } from "../../ui/components";

export function ProfileTab() {
    const [l] = useLocale();
    return (
        <Tab title={l().personalData.tabs.profileTab}>
            <div class={"space-y-4 py-4"}>
                <P class={"!mb-0 text-lg font-medium text-dark-gray-400"}>
                    {l().personalData.administrationData}
                </P>
                <RolesSection />
                <BusinessLineSection />
            </div>
        </Tab>
    );
}
function BusinessLineSection() {
    const [l] = useLocale();
    return (
        <section>
            <P class={"!mb-2 flex items-center gap-x-2 font-bold"}>
                {l().personalData.businessLine}
                <span
                    class={
                        "flex h-8 w-8 items-center justify-center rounded-full border border-dark-gray-700"
                    }
                >
                    4
                </span>
            </P>
            <div class="flex gap-2">
                <InfoBubble text="Grandes Superficies" />
                <InfoBubble text="Stand Alone" />
                <InfoBubble text="Centros Comerciales" />
                <InfoBubble text="Parques Logisticos Bodenor Flexcenter" />
            </div>
        </section>
    );
}
function RolesSection() {
    const [l] = useLocale();
    return (
        <section>
            <P class={"!mb-2 flex items-center gap-x-2 font-bold"}>
                {l().personalData.roles}
                <span
                    class={
                        "flex h-8 w-8 items-center justify-center rounded-full border border-dark-gray-700"
                    }
                >
                    2
                </span>
            </P>
            <div class="flex gap-2">
                <RoleCard />
                <RoleCard />
                <AddRoleCard />
            </div>
        </section>
    );
}
function RoleCard() {
    return (
        <BorderedCard class={""}>
            <P class={"!mb-2 text-md font-medium"}>Gerente Comercial</P>
            <P class={"!mb-0 text-sm text-dark-gray-400"}>Comercial grandes superficies</P>
        </BorderedCard>
    );
}

function AddRoleCard() {
    const onClickAddRole = () => {
        alert("WIP add role");
    };
    return (
        <BorderedCard>
            <Button
                variant="primary"
                bgStyle="text-only"
                onClick={onClickAddRole}
                class={"!p-0 flex flex-col opacity-50"}
            >
                <i class="fas fa-plus text-6"></i>
                <P class={"!mb-0"}>Agregar Cargo</P>
            </Button>
        </BorderedCard>
    );
}
