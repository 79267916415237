import TextField from "../../../forms/fields/TextField";
import SelectField from "../../../forms/fields/SelectField";
import TextAreaField from "../../../forms/fields/TextAreaField";
import { Button } from "../../../ui/components";
import { createSignal, Show } from "solid-js";
import { DottedSection } from "../NewActivityConfigTab";
import { Priority } from "../../../../api/services/task-manager/interface";
import { useLocale } from "../../../i18n/context";

export function ConfigInformation() {
    const [locale] = useLocale();
    const [moreDetail, setMoreDetail] = createSignal<boolean>(false);
    return (
        <DottedSection
            title={"Información de configuración"}
            subTitle={"Ingrese la información según corresponda (revisar o eliminar texto)"}
        >
            <TextField name={"activityName"} label={"Nombre de la actividad"} />

            <SelectField
                name={"priority"}
                label={"Prioridad"}
                options={Priority.getOptions(locale())}
                defaultValue={Priority.Medium}
            />

            <TextAreaField
                name={"description"}
                optional
                label={"Descripción de la pregunta o instrucciones de respuesta para el usuario"}
                placeholder={"Escribe una descripción"}
                rows={5}
            />

            <div>
                <Button
                    bgStyle={"text-only"}
                    onClick={() => setMoreDetail(prev => !prev)}
                    class={"!px-0"}
                >
                    {moreDetail() ? "Ocultar detalle" : "Agregar mas detalles"}
                </Button>
                <Show when={moreDetail()}>
                    <SelectField name={"tags"} optional options={[]} label={"Agregar tags"} />
                </Show>
            </div>
        </DottedSection>
    );
}
