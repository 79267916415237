import { deserializeOrganization, OrganizationService, sOrganization } from "../interface";
import { MockOrganizationService } from "./mock";
import { makeServiceQuery } from "../../../utils";
import { publicAuthClient } from "../../../clients/paulsen";

export class V3OrganizationService implements OrganizationService {
    private mock = new MockOrganizationService();

    getCurrentOrganization = this.mock.getCurrentOrganization;

    getOrganizationBySubdomain = makeServiceQuery({
        fetchJson: async (subdomain: string) =>
            publicAuthClient.get(`/organizations/${subdomain}/`).receiveJson(),
        responseSchema: sOrganization(),
        deserialize: deserializeOrganization,
    });
}
