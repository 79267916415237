import {
    ActivityFormJson,
    ActivityItem,
    ActivityTask,
    CreateActivityPayload,
    CreateActivityTaskPayload,
    deserializeActivityForm,
    Priority,
    RecurrenceActivity,
    sActivityFormJson,
    sRecurrenceActivity,
    TaskManagerService,
} from "../interface";
import { makeServiceQuery } from "../../../utils";
import { array, enums, Infer, string, type } from "superstruct";
import { datetime, RRule } from "rrule";
import { sV3ActivityTask } from "./v3";
import { InputTypeEnum } from "../../formbuilder/interfaces/InputTypeEnum";
import { makeBlankField } from "../../../../modules/FormBuilder/makeBlankField";

export default class MockTaskManagerService implements TaskManagerService {
    private recurrences = mockRecurrences(Temporal.Now.plainDateISO(), Temporal.Now.instant());
    private activityTasks: ActivityTask[] = [];

    retrieveActivityDetail = makeServiceQuery({
        fetchJson: async (_activityId: string, _recurrenceId: string) => {
            const mock = mockMyActivity(0, Temporal.Now.zonedDateTimeISO());
            return mock;
        },
        responseSchema: sMockActivityJson(),
        deserialize: (activity: MockActivityJson) => {
            return new ActivityItem({
                ...activity,
                startDate: Temporal.ZonedDateTime.from(activity.dueDate),
                dueDate: Temporal.ZonedDateTime.from(activity.dueDate),
            });
        },
    });
    getOrCreateRecurrenceActivity = makeServiceQuery({
        fetchJson: async (_activityId: string, _recurrenceId: string) => {
            return {
                id: `${_activityId}-${_recurrenceId}`,
                activity: _activityId,
                recurrence_id: _recurrenceId,
            };
        },
        responseSchema: sRecurrenceActivity(),
        deserialize: activity => activity,
    });

    retrieveMyCalendar = makeServiceQuery({
        fetchJson: async () => this.recurrences,
        responseSchema: array(sMockRecurrence()),
        deserialize: recurrences => ({
            getActivities(
                startDate: Temporal.PlainDate,
                endDate: Temporal.PlainDate,
            ): ActivityItem[] {
                return recurrences
                    .flatMap(({ rruleStr, ...recurrence }) =>
                        RRule.fromString(rruleStr)
                            .between(toRRuleDateTime(startDate), toRRuleDateTime(endDate), true)
                            .map((jsDate, index) => {
                                const id = `${recurrence.id}-${index + 1}`;
                                const startDate = jsDateToZdt(jsDate);
                                const dueDate = startDate.add({ hours: 8 });
                                return new ActivityItem({
                                    ...recurrence,
                                    id,
                                    startDate,
                                    dueDate,
                                });
                            }),
                    )
                    .sort(ActivityItem.compare);
            },
            get activityRecurrences(): never {
                throw new Error("Not implemented");
            },
        }),
    });

    async createActivity(activity: CreateActivityPayload) {
        console.log("create mock activity", { activity });
        return { id: "1" };
    }
    createActivityTask(payload: CreateActivityTaskPayload): never {
        console.log("create mock activity task", { payload });
        throw new Error("Not implemented");
    }

    activateActivity(activityId: string): void {
        console.log("activate activity", activityId);
        throw new Error("Not implemented");
    }

    getActivityTasks = makeServiceQuery({
        fetchJson: async () => this.activityTasks,
        responseSchema: array(sMockActivityTask()),
        deserialize: tasks => tasks,
    });

    async createRecurrenceActivity(): Promise<RecurrenceActivity> {
        throw new Error("Not implemented");
    }
    retrieveActivities = this.retrieveMyCalendar;
    startEvent(): void {
        console.log("start event");
        throw new Error("Not implemented");
    }

    messageStartEvent(): void {
        console.log("message start event");
        throw new Error("Not implemented");
    }

    checklistExecution(): void {
        console.log("checklist execution start event");
        throw new Error("Not implemented");
    }

    formResponse(): Promise<void> {
        console.log("form response start event");
        throw new Error("Not implemented");
    }

    retrieveForm = makeServiceQuery({
        fetchJson: async (formId: string): Promise<ActivityFormJson> => ({
            id: formId,
            description: {
                fields: [makeBlankField(InputTypeEnum.text, 42)],
            },
        }),
        responseSchema: sActivityFormJson(),
        deserialize: deserializeActivityForm,
    });

    get getRecurrenceTasks(): never {
        throw new Error("Not implemented");
    }

    get retrieveFormResponse(): never {
        throw new Error("Not implemented");
    }
}

type MockActivityJson = Infer<ReturnType<typeof sMockActivityJson>>;

function sMockActivityJson() {
    return type({
        id: string(),
        title: string(),
        venue: type({ name: string() }),
        priority: enums(Priority.getEnumValues()),
        dueDate: string(),
    });
}

function mockMyActivity(index: number, today: Temporal.ZonedDateTime): MockActivityJson {
    switch (index) {
        case 0:
            return {
                id: "c1t4-m3d1c4",
                title: "Cita médica 1 solo vez",
                venue: { name: "Parque Alameda" },
                priority: Priority.Reminder,
                dueDate: today.toString(),
            };
        case 1:
            return {
                id: "r3c0r-d4t0r10",
                title: "Recordatorio inicio vigencia audiencia se repite",
                venue: { name: "Parque Alameda" },
                priority: Priority.Reminder,
                dueDate: today.toString(),
            };
        default:
            return {
                id: `s4c4r-l4-b4sur4-${index}`,
                title: "Sacar la basura",
                venue: { name: index === 2 ? "Parque Alameda" : "Parque Alameda - Boulevard" },
                priority: index === 2 ? Priority.ThreeUpArrows : Priority.TwoUpArrows,
                dueDate: today.add({ days: index - 1 }).toString(),
            };
    }
}

type Recurrence = {
    id: string;
    title: string;
    venue: { name: string };
    priority: Priority;
    rruleStr: string;
};

const sMockActivityTask = sV3ActivityTask;

function sMockRecurrence() {
    return type({
        id: string(),
        title: string(),
        venue: type({ name: string() }),
        priority: enums(Object.values(Priority) as Priority[]),
        rruleStr: string(),
    });
}

function mockRecurrences(today: Temporal.PlainDate, now: Temporal.Instant): Recurrence[] {
    return [
        {
            id: "c1t4-m3d1c4",
            title: "Cita médica 1 solo vez",
            venue: { name: "Parque Alameda" },
            priority: Priority.Reminder,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(today),
                count: 1,
            }).toString(),
        },
        {
            id: "r3c0r-d4t0r10",
            title: "Recordatorio inicio vigencia audiencia se repite",
            venue: { name: "Parque Alameda" },
            priority: Priority.Reminder,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(today),
                count: 2,
            }).toString(),
        },
        {
            id: `s4c4r-l4-b4sur4-ur63n73`,
            title: "Sacar la basura",
            venue: { name: "Parque Alameda" },
            priority: Priority.ThreeUpArrows,
            rruleStr: new RRule({
                dtstart: toRRuleDateTime(now.add({ hours: 3 })),
                count: 1,
            }).toString(),
        },
        {
            id: `s4c4r-l4-b4sur4`,
            title: "Sacar la basura",
            venue: { name: "Parque Alameda - Boulevard" },
            priority: Priority.TwoUpArrows,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(today.add({ days: 3 })),
            }).toString(),
        },
    ];
}

function toRRuleDateTime(date: Temporal.PlainDate | Temporal.Instant) {
    const d =
        date instanceof Temporal.Instant
            ? date.toZonedDateTimeISO(Temporal.Now.timeZoneId())
            : date;

    return d instanceof Temporal.PlainDate
        ? datetime(d.year, d.month, d.day)
        : datetime(d.year, d.month, d.day, d.hour, d.minute, d.second);
}

function jsDateToZdt(jsDate: Date): Temporal.ZonedDateTime {
    return Temporal.ZonedDateTime.from({
        year: jsDate.getUTCFullYear(),
        month: jsDate.getUTCMonth() + 1,
        day: jsDate.getUTCDate(),
        hour: jsDate.getUTCHours(),
        minute: jsDate.getUTCMinutes(),
        timeZone: Temporal.Now.timeZoneId(),
    });
}
