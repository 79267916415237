import { ChecklistSections, SectionStep } from "../../../../api/services/checklist/interface";
import { P } from "../../../../utils/typography";
import { Tab, Tabs } from "../../../ui/Tabs";
import { For, Show, Suspense, untrack } from "solid-js";
import { ConfigStepForm, CreateSectionStepModal, receiveStep } from "./CreateChecklistConfigStep";
import { StepForm } from "./CreateChecklistTask";
import { createSectionStepListQuery } from "../../../../api/services/checklist/queries";
import { GenericSuspenseFallback } from "../../../ui/skeletons";
import { useLocale } from "../../../i18n/context";
import { BorderedCard } from "../../../ui/cards";
import SidePanel from "../../../ui/SidePanel";
import { createModalController } from "../../../ui/Modal";
import { PageWrapper } from "../../../ui/pageWrappers";
import { FormWrapper } from "../../../forms/FormWrapper";
import SubmitButton from "../../../forms/SubmitButton";
import { StepFields } from "../../checklistExecutions/utils";
import DeleteButton from "../../../ui/DeleteButton";
import {
    createDeleteChecklistSectionMutation,
    createDeleteSectionStepMutation,
    createPatchChecklistSectionMutation,
} from "../../../../api/services/checklist/mutations";
import { Button } from "../../../ui/components";
import { after } from "../../../../api/services/checklist/implementations/v3";
import EditableName from "../../../ui/EditableName";

export function ChecklistSection(props: { section: ChecklistSections }) {
    const [locale] = useLocale();
    const stepsQuery = createSectionStepListQuery(() => props.section.id);
    const addStepModal = createModalController<void, void>();
    const patchSectionMutation = createPatchChecklistSectionMutation();
    const deleteSectionMutation = createDeleteChecklistSectionMutation();

    const handleRename = async (name: string) => {
        await patchSectionMutation.mutateAsync({ id: props.section.id, name });
    };

    const handleClickDelete = async () => {
        if (confirm(`¿Seguro de eliminar la sección "${props.section.name}"?`))
            await deleteSectionMutation.mutateAsync(props.section.id);
    };

    return (
        <div class={"rounded-md border border-light-gray-300"}>
            <header class={"pr-2.5 flex items-baseline border-b border-light-gray-300 py-4 pl-4"}>
                <EditableName nameOnBackend={props.section.name} onRename={handleRename} />
                <DeleteButton onClick={handleClickDelete} class="text-lg" />
            </header>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={stepsQuery.data}>
                    {steps => (
                        <div class="p-2">
                            <div class="mb-1 flex flex-col gap-2">
                                <For each={steps()} fallback={<P>{locale().checklists.noSteps}</P>}>
                                    {(step, index) => (
                                        <SectionStepCard step={step} index={index()} />
                                    )}
                                </For>
                            </div>
                            <Button
                                icon="fas fa-plus"
                                bgStyle="text-only"
                                onClick={() => addStepModal.open()}
                            >
                                Agregar paso
                            </Button>
                            <CreateSectionStepModal
                                controller={addStepModal}
                                sectionId={props.section.id}
                                order={after(steps())}
                            />
                        </div>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

function SectionStepCard(props: { step: SectionStep; index: number }) {
    const deleteStepMutation = createDeleteSectionStepMutation();
    const stepPanel = createModalController<SectionStep, void>();

    const handleClickDelete = async (event: Event) => {
        event.stopPropagation();
        if (confirm(`¿Seguro de eliminar el paso "${untrack(() => props.step.mainField.label)}"`)) {
            await deleteStepMutation.mutateAsync(props.step.id);
        }
    };

    return (
        <>
            <BorderedCard
                onClick={() => stepPanel.open(props.step)}
                class="flex flex-row items-baseline py-2 pr-1"
            >
                <P class="!mb-0 flex-1">
                    Paso {props.index + 1}: {props.step.mainField.label}
                </P>
                <DeleteButton onClick={handleClickDelete} />
            </BorderedCard>
            <SidePanel position="left" controller={stepPanel}>
                {step => <StepDetails step={step} />}
            </SidePanel>
        </>
    );
}

function StepDetails(props: { step: SectionStep }) {
    return (
        <PageWrapper>
            <Tabs>
                <Tab title="Configuración del paso">
                    <ConfigStepForm defaultValues={receiveStep(props.step)} />
                </Tab>
                <Tab title="ExecutionPreview">
                    <FormWrapper onSubmit={values => alert(JSON.stringify(values, null, 4))}>
                        <StepFields step={props.step} />
                        <SubmitButton>Simular envío</SubmitButton>
                    </FormWrapper>
                </Tab>
                <Tab title="Tareas">
                    <StepForm step={props.step} />
                </Tab>
            </Tabs>
        </PageWrapper>
    );
}
