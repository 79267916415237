import {
    allIdentityTypes,
    EditablePersonalData,
    IdentityOptions,
    PersonalData,
    PersonService,
    UserProfile,
} from "../interface";
import { getUserOr401 } from "../../../../modules/auth/authContext";
import { makeServiceQuery } from "../../../utils";
import { deserializeLuchoPerson, sLuchoPerson } from "../LuchoPerson";

export default class MockPersonService implements Partial<PersonService> {
    private personalDataEdits: Record<string, EditablePersonalData> = {};

    retrieveCurrentUserProfile = async (): Promise<UserProfile> => {
        return await this.retrievePersonalData();
    };

    retrieveIdentityOptions = async (): Promise<IdentityOptions> => {
        return {
            allowedIdentityTypes: [...allIdentityTypes],
            defaultIdentityType: "CL_CI",
        };
    };
    retrievePersonalData = async (): Promise<PersonalData> => {
        const user = await getUserOr401();
        const profile: PersonalData = {
            name: user.name,
            email: user.email,
            pictureUrl: user.picture,
            identityDocuments: [],
            phones: [],
        };
        if (this.personalDataEdits[user.id]) {
            Object.assign(profile, this.personalDataEdits[user.id]);
        }
        return profile;
    };

    updatePersonalData = async (data: EditablePersonalData): Promise<void> => {
        const user = await getUserOr401();
        this.personalDataEdits[user.id] = data;
    };

    getPerson = makeServiceQuery({
        fetchJson: async (_id: string) => this.mockLuchoPerson,
        responseSchema: sLuchoPerson(),
        deserialize: deserializeLuchoPerson,
    });

    createPerson = async (person: PersonalData): Promise<void> => {
        const user = await getUserOr401();
        this.personalDataEdits = { ...this.personalDataEdits, [user.id]: person };
    };

    exportPersons(tenant_id: string): Promise<Blob> {
        const obj = { hello: tenant_id };
        const blob = new Blob([JSON.stringify(obj, null, 2)], {
            type: "application/json",
        });
        return Promise.resolve(blob);
    }

    private mockLuchoPerson = {
        id: "ffec2b11-8fbe-430e-852c-e8d8a815f252",
        passport: "213f70d3-7981-42b8-aeea-7c9355ef950e",
        name: "Daniel Ward",
        employee_id: "EMP00043",
        phone_number: "553.202.7453",
        address: "42597 Martin Vista\nRyanchester, HI 93420",
        birth_date: "1977-12-31",
        hire_date: "2020-08-27",
        emails: [
            {
                email: "patricia67@example.com",
                is_primary: true,
            },
            {
                email: "aprilhenry@example.org",
                is_primary: false,
            },
        ],
        identifiers: [],
        positions: [
            {
                id: "477dd9f0-ca1d-48b6-8359-4c09d63e2404",
                title: "Operations Head",
                department: "Inc",
                is_root: false,
                organization: "9f693cda-66cc-4864-9176-130b1714d42c",
                tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
                parent: "9c2d1b7d-541c-4c38-8e70-2d3e460bc283",
                people: [
                    "9cb9efd5-ec0d-47a5-865c-27ed5ce00f81",
                    "3546c356-4aa7-403e-8c8b-9c955297ac42",
                    "2ff956f0-5798-4677-9945-be20f113a3a5",
                    "ffec2b11-8fbe-430e-852c-e8d8a815f252",
                    "23ee3f01-6a64-4a71-b504-f0b3709f4db8",
                    "5baf94fa-7e54-4653-ba54-ac16f29cee81",
                    "93f203ad-8837-499a-9563-21e1e38ea0cc",
                    "30039894-b1a8-43a6-b623-e85758e32ab1",
                    "e27b9019-89ad-4271-a6d7-e4b50aeaccab",
                    "d7c87a7d-3022-4a06-b7f7-ba23c0b45611",
                    "54786974-d713-427f-81a1-281da40da869",
                    "9396d6d4-7ee0-45e9-9690-3665d0f21615",
                    "efbc0698-e8b2-4df8-9113-75ea96b1ec89",
                ],
            },
        ],
        preferred_notification_channels: [],
        unstructured_metadata:
            "{'skills': ['Editor, commissioning', 'Tax adviser'], 'certifications': ['far Certification', 'like Certification'], 'emergency_phone': '5529705489', 'emergency_contact': 'Michael Miller', 'previous_companies': ['Mcpherson-Lee']}",
        groups: [
            {
                id: "0d1dcfb1-f4d1-42e6-b9ac-5314bd0e4367",
                name: "Operations Department",
                description: "Often ready thousand according bed country.",
                type: "e8f87689-4e22-410a-b4f4-78d1138cc1f8",
                tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            },
            {
                id: "1246ea3e-b0af-478b-a848-18f82895c004",
                name: "Project A",
                description: "Accept similar realize.",
                type: "cf42c9f6-8ced-40db-8e0f-c7ae270d43ed",
                tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            },
            {
                id: "9afeb6ed-4687-46d9-8735-453f0ac6f139",
                name: "Project B",
                description: "Cold two believe ago.",
                type: "cf42c9f6-8ced-40db-8e0f-c7ae270d43ed",
                tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
            },
        ],
        tenant: "5bfa800d-9fea-4cef-8993-ae7524c40516",
    };
}
