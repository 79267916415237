import { useLocale } from "../i18n/context";
import { A } from "@solidjs/router";
import { menuTabs } from "./utils";
import { JSX, Show } from "solid-js";
import { mapValues } from "../../utils/miniLodash";
import { PinnedTabKey } from "./PinnedBar";
import LoremIpsum from "./LoremIpsum";
import { parsedEnv } from "../../utils/parsedEnv";

export default function ExpandedMenu(props: { activePinnedTab: PinnedTabKey }) {
    const [locale] = useLocale();
    const items = useMenuItems();

    return (
        <Show
            when={props.activePinnedTab === "home"}
            fallback={
                <LoremIpsum
                    variant="short"
                    title={locale().menu.pinnedTabs[props.activePinnedTab]}
                />
            }
        >
            <nav>
                <div class="flex flex-col gap-2 p-3">
                    <MenuItem
                        href={menuTabs.home.href}
                        icon={menuTabs.home.icon}
                        title={locale().menu.tabs.home}
                        size="lg"
                    />
                </div>
                <div class="flex flex-col gap-1 border-t border-t-light-gray-300 p-3">
                    {items.activities()}
                    {items.organization()}
                    <Show when={parsedEnv.VITE_FEATURE_ORGANIZATION_CHART}>
                        {items.organizationChart()}
                    </Show>
                    {items.checklists()}
                    {items.audiences()}
                    {items.workflows()}
                    {items.posts()}
                </div>
            </nav>
        </Show>
    );
}

function useMenuItems(): Record<keyof typeof menuTabs | "empty", () => JSX.Element> {
    const [locale] = useLocale();
    const t = () => locale().menu.tabs;

    return {
        ...mapValues(menuTabs, ({ href, icon }, key) => {
            return () => <MenuItem href={href} icon={icon} title={t()[key]} size="md" />;
        }),
        empty: () => <div />,
    };
}

function MenuItem(props: {
    href: string;
    end?: boolean;
    icon: string;
    title: string;
    size: "lg" | "md";
}) {
    return (
        <A
            href={props.href}
            end={props.end}
            classList={{
                "text-md": props.size === "lg",
                "text-default": props.size === "md",
            }}
            class="flex items-center gap-2 rounded-md py-1 ps-3 text-light-gray-700"
            activeClass="bg-primary-100"
        >
            <div
                classList={{
                    "h-text-lg w-text-lg": props.size === "lg",
                    "h-text-md w-text-md": props.size === "md",
                }}
                class="center-items"
            >
                <i class={props.icon} />
            </div>
            <div class="whitespace-nowrap">{props.title}</div>
        </A>
    );
}
