import { useNavigate } from "@solidjs/router";
import { Button } from "../ui/components";
import Dropdown from "../ui/Dropdown";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { Show } from "solid-js";
import { SearchBar } from "../ui/SearchBar";
import SwitchField from "../forms/fields/SwitchField";
//import { createActivityTasksQuery } from "../../api/services/task-manager/queries";
import { createModalController } from "../ui/Modal";
import ActivitiesSidePanel from "./modals/ActivitiesSidePanel";
import { CalendarControls, CalendarControlsProps } from "../ui/calendar";
import { FormWrapper } from "../forms/FormWrapper";
//import { createActivityTasksQuery } from "../../api/services/task-manager/queries";

export default function ActivityHeader(props: CalendarControlsProps) {
    //const query = createActivityTasksQuery();
    const { sm } = useResponsiveBreakpoints();
    const stepPanel = createModalController<unknown, void>();
    return (
        <>
            <div
                class={
                    "flex justify-between border-b border-light-gray-200 bg-white px-3 py-2 md:border-0"
                }
            >
                <HeaderTitleDropdown />
                <Show when={!sm()}>
                    <HeaderMobileMenu />
                </Show>
                <Show when={sm()}>
                    <a
                        href={"/activities/create"}
                        class={"gap-2 rounded-xs bg-primary-500 px-4 py-2 text-default text-white"}
                    >
                        Nueva actividad
                    </a>
                </Show>
            </div>
            {/* <div>
                <Show when={query.data}>{data => <div>{JSON.stringify(data())}</div>}</Show>
            </div> */}
            <Show when={sm()}>
                <div class={"mt-6 flex"}>
                    <div class={"flex w-[50%] items-center gap-x-4"}>
                        <CalendarControls {...props} />
                        <SearchBar variant={"gray"} />
                    </div>
                    <div class={"flex w-[50%] items-end justify-end gap-x-4"}>
                        <FormWrapper class={"flex h-full items-center"}>
                            <SwitchField
                                name={"switch"}
                                label={"Mostrar solo actividades disponibles"}
                                labelClass={"flex-row-reverse"}
                            />
                        </FormWrapper>
                        <Button
                            bgStyle={"outline"}
                            class={
                                "flex h-full items-center !border-light-gray-300 !text-sm !font-semibold !text-light-gray-700"
                            }
                            onClick={() => stepPanel.open(null)}
                        >
                            <i class="fas fa-filter" />
                            <span>Filtros</span>
                        </Button>
                    </div>
                </div>
                <ActivitiesSidePanel controller={stepPanel} />
            </Show>
        </>
    );
}

function HeaderTitleDropdown() {
    return (
        <Dropdown
            itemsContainerClass={"!right-[inherit]"}
            trigger={onClick => (
                <button
                    class={"flex items-center gap-x-2 font-semibold md:text-display-sm"}
                    onClick={onClick}
                >
                    Mis actividades <i class="fas fa-chevron-down text-md" />
                </button>
            )}
        >
            <div
                class={
                    "flex flex-col rounded-md border-light-gray-200 bg-white shadow-light-gray-200"
                }
            >
                <a
                    href={"#"}
                    class={"whitespace-nowrap p-0 px-3 py-2 text-left text-light-gray-700"}
                >
                    Ver mis actividades
                </a>
                <a
                    href={"#"}
                    class={"whitespace-nowrap p-0 px-3 py-2 text-left text-light-gray-700"}
                >
                    Ver actividades de mi equipo
                </a>
            </div>
        </Dropdown>
    );
}

function HeaderMobileMenu() {
    const navigate = useNavigate();

    return (
        <Dropdown
            trigger={onClick => (
                <button
                    class={"flex items-center gap-x-2 text-default font-semibold"}
                    onClick={onClick}
                >
                    <i class="fas fa-ellipsis-h" />
                </button>
            )}
        >
            <div class={"rounded-md border-light-gray-200 bg-white shadow-light-gray-200"}>
                <Button
                    bgStyle={"text-only"}
                    class={"whitespace-nowrap p-0 text-left !text-primary-700"}
                >
                    Ver lista
                </Button>
                <Button
                    bgStyle={"text-only"}
                    class={"whitespace-nowrap p-0 text-left !text-primary-700"}
                    onClick={() => navigate("/activities/agenda")}
                >
                    Ver agenda
                </Button>
            </div>
        </Dropdown>
    );
}
