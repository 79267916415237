import { useLocale } from "../../i18n/context";
import { StringField } from "./abstract";

import { FieldProps } from "./parts/types";

export default function EmailField(props: FieldProps<string, HTMLInputElement>) {
    const [l] = useLocale();
    const isEmail = (val: string) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(val) || l().auth.invalidEmail;
    };
    return <StringField type="email" {...props} validate={isEmail} />;
}
