import {
    CreateWorkflowCategory,
    deserializeWorkflowCategory,
    sWorkflowCategory,
    UpdateWorkflowCategory,
    WorkflowCategoriesQueryParams,
    WorkflowCategoryEndpoints,
} from "./interface";
import { makeServiceQuery } from "../../../utils";
import { workflowClient } from "../../../../modules/client/client";
import { any, array } from "superstruct";

export class V3WorkflowCategoryEndpoints implements WorkflowCategoryEndpoints {
    listWorkflowCategories = makeServiceQuery({
        fetchJson: (queryParams?: WorkflowCategoriesQueryParams) =>
            workflowClient.get("/workflows/categories/").query(queryParams).receiveJson(),
        responseSchema: array(sWorkflowCategory()),
        deserialize: arr => arr.map(deserializeWorkflowCategory),
    });

    createWorkflowCategory = (body: CreateWorkflowCategory) =>
        workflowClient
            .post("/workflows/categories/")
            .sendJson(body)
            .receive(sWorkflowCategory())
            .then(deserializeWorkflowCategory);

    retrieveWorkflowCategory = makeServiceQuery({
        fetchJson: id => workflowClient.get(`/workflows/categories/${id}/`).receiveJson(),
        responseSchema: sWorkflowCategory(),
        deserialize: deserializeWorkflowCategory,
    });

    updateWorkflowCategory = ({ id, ...body }: UpdateWorkflowCategory) =>
        workflowClient.patch(`/workflows/categories/${id}/`).sendJson(body).receive(any());

    destroyWorkflowCategory = (id: string) =>
        workflowClient.delete(`/workflows/categories/${id}/`).receiveNothing();
}
