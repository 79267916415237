import PinnedBar, { pinnedBarWidth, PinnedTabKey } from "./PinnedBar";
import { createSignal, ParentProps, Show } from "solid-js";
import SemiNavbar from "./SemiNavbar";
import ExpandedMenu from "./ExpandedMenu";
import SlidingMenu from "./SlidingMenu";
import CurrentOrganizationLogo from "../auth/CurrentOrganizationLogo";
import { parsedEnv } from "../../utils/parsedEnv";
import { Badge } from "../ui/components";

const expandedMenuWidth = "16rem";

export default function DesktopLayout(props: ParentProps) {
    const [collapsed, setCollapsed] = createSignal(false);
    const toggleCollapsed = () => setCollapsed(x => !x);

    const showAdminColumn = parsedEnv.VITE_USER_ROLE === "admin";
    const bgEnvNavColor = parsedEnv.VITE_COLOR ?? "inherit";
    const badgeEnvText = parsedEnv.VITE_BADGE;
    const [activePinnedTab, setActivePinnedTab] = createSignal<PinnedTabKey>("home");

    return (
        <div
            class="grid h-screen grid-flow-col overflow-hidden bg-layout"
            style={{
                "grid-template-columns": showAdminColumn
                    ? `auto ${expandedMenuWidth} 1fr`
                    : `${expandedMenuWidth} 1fr`,
                "grid-template-rows": "auto 1fr",
                "border-top": `10px solid ${bgEnvNavColor}`,
            }}
        >
            <Show when={showAdminColumn}>
                <div class="bg-sidebar">
                    <Burger onClick={toggleCollapsed} />
                </div>
                <div class="bg-sidebar">
                    <PinnedBar activeTab={activePinnedTab()} onClickTab={setActivePinnedTab} />
                </div>
            </Show>
            <div class={"flex h-full"}>
                <div class={showAdminColumn ? "self-center px-3" : "flex self-center"}>
                    {!showAdminColumn && <Burger onClick={toggleCollapsed} />}
                    <CurrentOrganizationLogo />
                    <Show when={badgeEnvText}>
                        <div class={"ml-2 self-center"}>
                            <Badge class={"!bg-dark-gray-700 text-white"}>{badgeEnvText}</Badge>
                        </div>
                    </Show>
                </div>
            </div>
            <div class="col-span-2 flex overflow-y-auto">
                <SlidingMenu collapsed={collapsed()} expandedMenuWidth={expandedMenuWidth}>
                    <ExpandedMenu activePinnedTab={activePinnedTab()} />
                </SlidingMenu>
                <main class="flex-1 overflow-y-auto rounded-ss-4 bg-white">{props.children}</main>
            </div>
            <SemiNavbar />
        </div>
    );
}

function Burger(props: { onClick: () => void }) {
    const size = "2.75rem";

    return (
        <div class="flex justify-center" style={{ width: pinnedBarWidth }}>
            <button
                onClick={() => props.onClick()}
                class="center-items my-3 rounded-full"
                style={{ width: size, height: size, "background-color": "#DBE1EB" }}
            >
                <i class="fas fa-bars text-md text-light-gray-700" />
            </button>
        </div>
    );
}
