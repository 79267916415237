import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";

export const createRetrieveLogicParserOptionsQuery = (model: Accessor<string>) => {
    return createApiQuery(api => ({
        queryKey: ["retrieveLogicParserOptions", model()],
        queryFn: () => api.logicParser.retrieveLogicParserOptions.fetchJson(model()),
        select: api.logicParser.retrieveLogicParserOptions.select,
    }));
};

export const createRetrieveParserLogicQuery = (id: Accessor<string | undefined>) => {
    return createApiQuery(api => ({
        queryKey: ["retrieveParserLogic"],
        queryFn: () => api.logicParser.retrieveLogicDetail.fetchJson(id()!),
        select: api.logicParser.retrieveLogicDetail.select,
        enabled: !!id(),
    }));
};
