import { Capacitor } from "@capacitor/core";
import { AlreadyInProgressError } from "../../api/services/auth/interface";
import {
    describeSignInMethod,
    FederatedSignInMethod,
} from "../../api/services/organization/signInMethods";
import { showErrorToast } from "../../utils/errorHandling";

export default function useAlreadyInProgressWorkaround() {
    return {
        async onAlreadyInProgressError(
            _error: AlreadyInProgressError,
            method: FederatedSignInMethod,
        ) {
            showErrorToast(
                `Cancela el inicio de sesión con ${describeSignInMethod(
                    method,
                )}. Si ya lo hiciste ${
                    Capacitor.isNativePlatform()
                        ? "cierra y vuelve abrir la app"
                        : "recarga la página"
                }.`,
            );
        },
    };
}
