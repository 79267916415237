import { FieldProps } from "./parts/types";
import { LabelAfter } from "./parts/layouts";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";
import { createField } from "../state";
import { createUniqueId } from "solid-js";

export default function ColorField(props: FieldProps<string, HTMLInputElement>) {
    const field = createField(props, "");
    const inputId = createUniqueId();

    return (
        <LabelAfter
            {...props}
            field={field}
            inputId={inputId}
            parentClass={`border border-black-2 rounded-md p-1 ${props.parentClass}`}
        >
            <input
                type="color"
                {...getCommonInputProps(inputId, props)}
                {...getInputValidationProps(field, props)}
                value={field.value}
                class={"input-color"}
            />
        </LabelAfter>
    );
}
