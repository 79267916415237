import { PageWrapper } from "../ui/pageWrappers";
import { FormWrapper } from "../forms/FormWrapper";
import { createGroupTypeListQuery } from "../../api/services/audience/queries";
import { Show, Suspense } from "solid-js";
import { GenericSuspenseFallback } from "../ui/skeletons";
import CheckboxesField from "../forms/fields/CheckboxesField";

export default function NewPostPage() {
    const groupTypeListQuery = createGroupTypeListQuery();

    return (
        <PageWrapper>
            <pre>NewPostPage</pre>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={groupTypeListQuery.data}>
                    {groupTypeList => (
                        <FormWrapper>
                            <CheckboxesField
                                name="groupTypes"
                                label="GroupTypes"
                                options={groupTypeList().map(groupType => [
                                    groupType.id,
                                    groupType.name,
                                ])}
                            />
                        </FormWrapper>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}
