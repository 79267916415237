import { SetStoreFunction, Store } from "solid-js/store";
import { InputUnion } from "../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { FormPayload } from "./RenderDynamicForm";

export class FormBuilder {
    constructor(
        private store: Store<FormPayload>,
        private setStore: SetStoreFunction<FormPayload>,
    ) {}
    build(): FormPayload {
        return this.store;
    }
    addField(field: InputUnion) {
        this.setStore("fields", this.store.fields.length, field);
        return this;
    }

    addOptionToField(fieldId: string, option: string) {
        const fieldIndex = this.store.fields.findIndex(field => field.id === fieldId);
        if (fieldIndex === -1) {
            throw new Error(`Field with id ${fieldId} not found`);
        }
        const field = this.store.fields[fieldIndex];
        if (field.type !== "multiple_choice") {
            throw new Error(`Field with id ${fieldId} is not a multiple_choice field`);
        }
        this.setStore("fields", fieldIndex, {
            ...field,
            choices: [
                ...field.choices,
                {
                    choice: option,
                    image: null,
                },
            ],
        });
        return this;
    }

    removeField(fieldId: string) {
        this.setStore(
            "fields",
            this.store.fields.filter(field => field.id !== fieldId),
        );
        return this;
    }

    exportToJSON() {
        return JSON.stringify(this.build());
    }
    setFieldData(fieldId: string, data: Partial<InputUnion>) {
        const fieldIndex = this.store.fields.findIndex(field => field.id === fieldId);
        if (fieldIndex === -1) {
            throw new Error(`Field with id ${fieldId} not found`);
        }

        this.setStore("fields", fieldIndex, data);
        return this;
    }
}
