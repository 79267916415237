import { For, Show, untrack } from "solid-js";
import { LogicParserOptions } from "../../../../api/services/logic-parser/interface";
import SelectField from "../../../forms/fields/SelectField";
import { useFormState } from "../../../forms/state";
import { FrontendTargetEntity } from "../CreateChecklist";
import { FunctionParserInputDispatcher } from "./InputDispatcher";

export function FilterFunctionSection(props: {
    index: number;
    filterOptions: LogicParserOptions;
    defaultValues?: {
        filter: string;
        filterValue: string;
    };
}) {
    const form = useFormState<{
        entities: FrontendTargetEntity[];
    }>();
    const onChangeFilterType = (filter: string) => {
        const func = props.filterOptions.functions.find(item => item.function == filter);
        if (func) {
            form.setValues(values => {
                const index = untrack(() => props.index);
                values.entities[index].inputType = func.input_type;
                values.entities[index].filterValue = parseValue(
                    values.entities[index].filterValue,
                    func.input_type,
                );
                values.entities[index].frontendInputType =
                    (func.options?.length ?? 0) > 0 ? "select" : "text";

                values.entities[index].filterValue = "";
            });
        }
    };

    function parseValue(value: string | number | (string | number)[], inputType: string) {
        if (inputType == "int") {
            return Number(value);
        }
        return value;
    }
    return (
        <div class={"flex gap-x-4"}>
            <SelectField
                name={`entities[${props.index}].filter`}
                label={"Tipo de Filtro"}
                defaultValue={props.defaultValues?.filter}
                options={[
                    ["", "Seleccione una opción"],
                    ...(props.filterOptions.functions.map(item => [
                        item.function,
                        getNameFromFunction(item.function),
                    ]) as [value: string | number, label: string | number][]),
                ]}
                onChange={e => onChangeFilterType(e.currentTarget.value)}
            />

            <For each={props.filterOptions.functions}>
                {item => {
                    return (
                        <Show when={form.values.entities[props.index]?.filter == item.function}>
                            <FunctionParserInputDispatcher
                                func={item}
                                index={props.index}
                                defaultValue={props.defaultValues?.filterValue}
                            />
                        </Show>
                    );
                }}
            </For>
        </div>
    );
}

const functionNameDict: Record<string, string> = {
    filter_venue: "Por Recinto",
    filter_type: "Por Tipo",
    filter_code: "Por Código",
    filter_last_status: "Por Último Estado",
    filter_category: "Por Categoría",
};
function getNameFromFunction(functionName: string): string {
    return functionNameDict[functionName] ?? functionName;
}
