import "filepond/dist/filepond.min.css";
import { createUniqueId, ParentProps } from "solid-js";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import { nonCheckboxInputStyling } from "../presentation";

export default function HTMLFileField(props: ParentProps<FieldProps<File, HTMLInputElement>>) {
    const field = createField<File | undefined>(props, undefined);
    const inputId = createUniqueId();

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <input
                type="file"
                {...getCommonInputProps(inputId, props)}
                onChange={e => {
                    field.setValue(e.currentTarget.files?.[0]);
                }}
                {...nonCheckboxInputStyling}
                required={!props.optional}
            />
        </LabelAbove>
    );
}
