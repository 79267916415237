import "solid-devtools";
import "temporal-polyfill/global";
import { render } from "solid-js/web";
import { RouteDefinition, Router, useNavigate } from "@solidjs/router";
import IndexPage from "./modules/auth/IndexPage";
import { I18nProvider, useLocale } from "./modules/i18n/context";
import HomePage from "./modules/home/HomePage";
import SafeAreaWrapper from "./utils/SafeAreaWrapper";
import LoginDemoPage from "./modules/auth/LoginDemoPage";
import PostsPage from "./modules/home/PostsPage";
import ProfilePage from "./modules/personal-data/ProfilePage";
import ProtectedRouteWrapper from "./modules/menu/ProtectedRouteWrapper";
import {
    LoremAdvancedPage,
    LoremAssetsPage,
    LoremLinksPage,
    LoremMyAccountPage,
    LoremOrganizationPage,
    LoremReportsPage,
    LoremToolsPage,
} from "./utils/loremPages";
import SettingsPage from "./modules/settings/SettingsPage";
import GenericErrorBoundary from "./utils/GenericErrorBoundary";
import NotificationsPage from "./modules/notifications/NotificationsPage";
import { AuthProvider } from "./modules/auth/authContext";
import { lazy, onMount, ParentProps, Suspense } from "solid-js";
import SignInPage from "./modules/auth/SignInPage";
import RouteNotFoundPage from "./utils/RouteNotFoundPage";
import EditProfilePage from "./modules/personal-data/EditProfilePage";
import { QueryClientProvider } from "@tanstack/solid-query";
import { SolidQueryDevtools } from "@tanstack/solid-query-devtools";
import { queryClient } from "./api/utils";
import CreateAccountFromInvite from "./modules/onboarding/CreateAccountFromInvite";
import InvitePage from "./modules/onboarding/InvitePage";
import "toastify-js/src/toastify.css";
import SyncPage from "./modules/sync/SyncPage";
import PeoplePage from "./modules/personal-data/PeoplePage";
import SignInFromMagicLink from "./modules/auth/SignInFromMagicLink";
import { PeopleDetailPage } from "./modules/personal-data/PeopleDetailPage";
import { attachDevtoolsOverlay } from "@solid-devtools/overlay";
import { App } from "@capacitor/app";
import { fireAndForget } from "./utils/async";
import WorkflowsPage from "./modules/workflows/WorkflowsPage";
import WorkflowDetailPage from "./modules/workflows/WorkflowDetailPage";
import ActivitiesPage from "./modules/Activities/ActivitiesPage";
import WorkflowExecutionDetailPage from "./modules/workflows/WorkflowExecutionDetailPage";
import { GenericSuspenseFallback } from "./modules/ui/skeletons";
import AgendaPage from "./modules/Activities/AgendaPage";
import { ChecklistsPage } from "./modules/checklists/ChecklistsPage";
import { MaintenanceChecklistPage } from "./modules/checklists/MaintenanceChecklistPage";
import NewPostPage from "./modules/posts/NewPostPage";
import ActivitiesDetailPage from "./modules/Activities/ActivitiesDetailPage";
import AudiencesPage from "./modules/audiences/AudiencesPage";
import AudiencesDetailPage from "./modules/audiences/AudiencesDetailPage";
import AudienceCreatePage from "./modules/audiences/AudienceCreatePage";
import NewChecklistPage from "./modules/checklists/NewChecklistPage";
import WorkflowFormBuilderPage from "./modules/workflows/WorkflowFormBuilderPage";
import ChecklistExecutionsListPage from "./modules/checklists/ChecklistExecutionsListPage";
import ChecklistExecutionsExecutePage from "./modules/checklists/ChecklistExecutionsExecutePage";
import ChecklistsExecutePage from "./modules/checklists/ChecklistsExecutePage";
import ChecklistExecutionDetailPage from "./modules/checklists/ChecklistExecutionDetailPage";
import ActivitiesPlaygroundPage from "./modules/Activities/ActivitiesPlaygroundPage";
import ChunkLoadErrorBoundary from "./utils/ChunkLoadErrorBoundary";
import { ClockProvider } from "./utils/clock";
import CreateActivityPage from "./modules/Activities/CreateActivityPage";
import PeopleBulkLoadPage from "./modules/personal-data/PeopleBulkLoadPage";
import OrganizationChartPage from "./modules/organization-chart/OrganizationChartPage";
import WorkflowsPlayground from "./modules/workflows/WorkflowsPlayground";

attachDevtoolsOverlay();

const OnboardingPage = lazy(() => import("./modules/personal-data/OnboardingPage"));
const PostDetailPage = lazy(() => import("./modules/posts/PostDetailPage"));

const protectedRoutes: RouteDefinition = {
    path: "/",
    component: ProtectedRouteWrapper,
    children: [
        { path: "/activities", component: ActivitiesPage },
        { path: "/activities/:id/:recurrenceId", component: ActivitiesDetailPage },
        { path: "/activities/create", component: CreateActivityPage },
        { path: "/activities/create/:id", component: CreateActivityPage },
        { path: "/activities/agenda", component: AgendaPage },
        { path: "/activities/playground", component: ActivitiesPlaygroundPage },
        { path: "/advanced", component: LoremAdvancedPage },
        { path: "/assets", component: LoremAssetsPage },
        { path: "/audiences", component: AudiencesPage },

        { path: "/audiences/:id", component: AudiencesDetailPage },
        { path: "/audiences/create", component: AudienceCreatePage },
        { path: "/checklists", component: ChecklistsPage },
        { path: "/checklists/:id/execute", component: ChecklistsExecutePage },
        // Refactor needed: "/checklists/:id/execute/:id" should be ChecklistExecutionsExecutePage
        { path: "/checklists/executions", component: ChecklistExecutionsListPage },
        { path: "/checklists/executions/:execution_id", component: ChecklistExecutionsExecutePage },
        {
            path: "/checklists/executions_detail/:execution_id",
            component: ChecklistExecutionDetailPage,
        },
        { path: "/checklists/create/:id?", component: NewChecklistPage },
        { path: "/checklists/reporte-de-mantenimiento", component: MaintenanceChecklistPage },
        { path: "/home", component: HomePage },
        { path: "/invite", component: InvitePage },
        { path: "/links", component: LoremLinksPage },
        { path: "/my-account", component: LoremMyAccountPage },
        { path: "/notifications", component: NotificationsPage },
        { path: "/organization", component: LoremOrganizationPage },
        { path: "/org-chart", component: OrganizationChartPage },
        { path: "/people", component: PeoplePage },
        { path: "/people/:id", component: PeopleDetailPage },
        { path: "/people-bulk-load/:id", component: PeopleBulkLoadPage },
        { path: "/posts", component: PostsPage },
        { path: "/posts/:id", component: PostDetailPage },
        { path: "/posts/new", component: NewPostPage },
        { path: "/profile", component: ProfilePage },
        { path: "/profile/edit", component: EditProfilePage },
        { path: "/reports", component: LoremReportsPage },
        { path: "/settings", component: SettingsPage },
        { path: "/sync", component: SyncPage },
        { path: "/tools", component: LoremToolsPage },
        { path: "/workflows", component: WorkflowsPage },
        { path: "/workflows/:id", component: WorkflowDetailPage },
        {
            path: "/workflows/:workflowId/form-builder/:formId?",
            component: WorkflowFormBuilderPage,
        },
        { path: "/workflows/executions/:id", component: WorkflowExecutionDetailPage },
        { path: "/workflows/playground", component: WorkflowsPlayground },
    ],
};

const publicRoutes: RouteDefinition[] = [
    { path: "/", component: IndexPage },
    { path: "/from-invite", component: CreateAccountFromInvite },
    { path: "/from-magic-link", component: SignInFromMagicLink },
    { path: "/login", component: LoginDemoPage },
    { path: "/onboarding", component: OnboardingPage },
    { path: "/sign-in", component: SignInPage },
    protectedRoutes,
    { path: "*", component: RouteNotFoundPage },
];

function RootLayout(props: ParentProps) {
    useAndroidBackButton();

    return (
        <GenericErrorBoundary>
            <ChunkLoadErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <ClockProvider>
                            <Suspense fallback={<GenericSuspenseFallback />}>
                                {props.children}
                            </Suspense>
                        </ClockProvider>
                    </AuthProvider>
                    <SolidQueryDevtools />
                </QueryClientProvider>
            </ChunkLoadErrorBoundary>
        </GenericErrorBoundary>
    );
}

function useAndroidBackButton() {
    const navigate = useNavigate();
    const [locale] = useLocale();

    onMount(() => {
        App.addListener("backButton", ({ canGoBack }) => {
            const pathname = window.location.pathname;
            if (canGoBack) {
                navigate(-1);
            } else if (!["/", "/login", "/home"].includes(pathname)) {
                navigate("/", { replace: true });
            } else if (confirm(locale().utils.confirmExitApp)) {
                fireAndForget(App.exitApp());
            }
        });
    });
    // no onCleanup as this never unmounts in practice
}

// Don't inline this variable, or it will mess up the hot reload
const root = document.getElementById("root")!;

// This must be done after everything else, or the code generated by Solid will crash
render(
    () => (
        <SafeAreaWrapper>
            <I18nProvider>
                <Router root={RootLayout}>{publicRoutes}</Router>
            </I18nProvider>
        </SafeAreaWrapper>
    ),
    root,
);
