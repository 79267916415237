import BaseViewer from "bpmn-js/lib/BaseViewer";
import BpmnReplace, { TargetElement } from "bpmn-js/lib/features/replace/BpmnReplace";
import { BaseElement, EventDefinition, Moddle } from "bpmn-moddle";
import { ElementLike } from "diagram-js/lib/core/Types";
import { StructError, boolean, coerce, create, string, type } from "superstruct";
import Modeling from "bpmn-js/lib/features/modeling/Modeling";

/** Facade for bpmn-js element.
 *
 * @remarks
 * This object and its subclasses have setters.
 * They may return new objects, but even in that case they invalidate the
 * previous instance, as bpmn-js mutates the underlying element asynchronously.
 */
export class BpmnElement {
    protected constructor(
        protected element: BpmnJsElement,
        protected viewer: BaseViewer,
    ) {}

    static create(element: BpmnJsElement | ElementLike, viewer: BaseViewer): BpmnElement {
        return new ({
            "bpmn:IntermediateThrowEvent": IntermediateThrowEvent,
            "bpmn:StartEvent": StartEvent,
            "bpmn:UserTask": UserTask,
        }[(element as BpmnJsElement).type] ?? BpmnElement)(element as BpmnJsElement, viewer);
    }

    get type(): KnownElementType | UnknownElementType {
        return this.element.type in knownElementTypes
            ? (this.element.type as KnownElementType)
            : new UnknownElementType(this.element.type);
    }

    /** This is the ID on the BPMN diagram, not the ID of the "workflow element" on V3. */
    get id(): string {
        return this.element.id;
    }

    /** Name or label of this element. May be blank. */
    get name(): string {
        return this.element.businessObject.name;
    }

    /** How much time before the activity expires. */
    get duration(): Temporal.Duration | undefined {
        const secondsStr = this.element.businessObject.eventDefinitions
            ?.map(def => def.$attrs?.time_in_seconds)
            .find(x => x !== undefined);
        if (secondsStr === undefined) return undefined;
        const seconds = Number.parseInt(secondsStr);
        if (Number.isNaN(seconds)) return undefined;
        return Temporal.Duration.from({ seconds });
    }

    /** Throws if this element makes the workflow unable to be activated. */
    validate(): void {
        /* Do nothing unless overrode. */
    }

    protected findMessageEventDefinition(element: BpmnJsElement): EventDefinition | undefined {
        return element.businessObject.eventDefinitions?.find(
            def => def.$type === "bpmn:MessageEventDefinition",
        );
    }

    /** This will add a bpmn:MessageEventDefinition to the bpmn-js element if it
     * doesn't have one, and assign attrs to it (they will be merged).
     */
    protected setMessageEventDefinition(attrs: Record<string, string>): {
        bpmnJsElement: BpmnJsElement;
        definition: EventDefinition;
    } {
        const bpmnReplace: BpmnReplace = this.viewer.get("bpmnReplace");
        const bpmnJsElement = this.findMessageEventDefinition(this.element)
            ? this.element
            : bpmnReplace.replaceElement(this.element, {
                  type: this.element.type,
                  eventDefinitionType: "bpmn:MessageEventDefinition",
              } as TargetElement);
        const definition = this.findMessageEventDefinition(bpmnJsElement)!;
        Object.assign((definition.$attrs ??= {}), attrs);
        return { bpmnJsElement, definition };
    }
}

export class IntermediateThrowEvent extends BpmnElement {
    get message(): Message | undefined {
        // The message should be fully valid, or the workflow would be illegal to activate
        const def = this.findMessageEventDefinition(this.element);
        try {
            return create(
                def && {
                    ...def.$attrs,
                    title: this.getExtensionElement(def, "aim:Title")?.$body,
                    body: this.getExtensionElement(def, "aim:Body")?.$body,
                },
                type({
                    push: coerce(boolean(), string(), x => x === "true"),
                    mail: coerce(boolean(), string(), x => x === "true"),
                    emails: string(),
                    title: string(),
                    body: string(),
                }),
            );
        } catch (error) {
            if (error instanceof StructError) return undefined;
            else throw error;
        }
    }

    /** Add/update the message to send with this event. */
    setMessage(
        message: Message = {
            push: false,
            mail: false,
            emails: "",
            title: "",
            body: "",
        },
    ): IntermediateThrowEvent {
        const { bpmnJsElement, definition } = this.setMessageEventDefinition({
            push: message.push ? "true" : "false",
            mail: message.mail ? "true" : "false",
            emails: message.emails,
        });
        this.setExtensionElement(definition, "aim:Title", message.title);
        this.setExtensionElement(definition, "aim:Body", message.body);
        return new IntermediateThrowEvent(bpmnJsElement, this.viewer);
    }

    private getExtensionElement(
        parent: BaseElement,
        type: string,
    ): (BaseElement & { $body?: string }) | undefined {
        return parent.extensionElements?.values.find(element => element.$type === type);
    }

    private setExtensionElement(parent: BaseElement, type: string, body: string): void {
        const moddle: Moddle = this.viewer.get("moddle");
        parent.extensionElements ??= moddle.create("bpmn:ExtensionElements");
        const values = (parent.extensionElements.values ??= []);
        const newExtensionElement = moddle.create(type, { $body: body });

        const index = values.findIndex(element => element.$type === type);
        if (index === -1) {
            values.push(newExtensionElement);
        } else {
            values[index] = newExtensionElement;
        }
    }

    override validate() {
        if (!this.message) throw new Error(`"${this.name}" requiere un mensaje.`);
    }
}

export class StartEvent extends BpmnElement {
    hasMessageEventDefinition(): boolean {
        return this.findMessageEventDefinition(this.element) !== undefined;
    }

    /** The ID of the workflow form to be filled by the user creating the workflow execution.
     *
     *  @remarks
     *  `formId` may be undefined because the element was just created
     *  and the admin user hasn't assigned a form yet.
     */
    get formId(): string | undefined {
        return this.findMessageEventDefinition(this.element)?.$attrs?.form_id;
    }

    /** Assigns a form to this element in RAM.
     * This will be reflected on the backend when the user presses "Save changes".
     */
    setFormId(formId: string): void {
        this.setMessageEventDefinition({ form_id: formId });
    }

    get executionAudience(): string | undefined {
        return this.element.businessObject.$attrs.execution_audience;
    }

    setExecutionAudience(executionAudience: string): void {
        const modeling: Modeling = this.viewer.get("modeling");
        modeling.updateProperties(this.element, { execution_audience: executionAudience });
    }

    override validate() {
        if (this.hasMessageEventDefinition() && !this.formId)
            throw new Error(
                `"${this.name}" requiere un formulario pues es un evento de inicio de mensaje.`,
            );
        if (!this.executionAudience)
            throw new Error(`"${this.name}" requiere una audiencia de ejecución.`);
    }
}

export class UserTask extends BpmnElement {
    /** The ID of the workflow form to be filled by the user executing this task.
     *
     *  @remarks
     *  `formId` may be undefined because the element was just created
     *  and the admin user hasn't assigned a form yet.
     */
    get formId(): string | undefined {
        return this.element.businessObject.$attrs.form_id;
    }

    /** Assigns a form to this element in RAM.
     * This will be reflected on the backend when the user presses "Save changes".
     */
    setFormId(formId: string): void {
        const modeling: Modeling = this.viewer.get("modeling");
        modeling.updateProperties(this.element, { form_id: formId });
    }

    get executionAudience(): string | undefined {
        return this.element.businessObject.$attrs.execution_audience;
    }

    setExecutionAudience(executionAudience: string): void {
        const modeling: Modeling = this.viewer.get("modeling");
        modeling.updateProperties(this.element, { execution_audience: executionAudience });
    }

    override validate() {
        if (!this.formId) throw new Error(`"${this.name}" requiere un formulario.`);
        if (!this.executionAudience)
            throw new Error(`"${this.name}" requiere una audiencia de ejecución.`);
    }
}

// Refine official type as it has `businessObject: any`.
type _OfficialType = import("bpmn-js/lib/model/Types").Element;
export interface BpmnJsElement extends _OfficialType {
    businessObject: {
        name: string;
        $attrs: Partial<Record<string, string>>;
        eventDefinitions?: EventDefinition[];
    };
}

export type KnownElementType = keyof typeof knownElementTypes;
const knownElementTypes = {
    "bpmn:BoundaryEvent": true,
    "bpmn:IntermediateCatchEvent": true,
    "bpmn:IntermediateThrowEvent": true,
    "bpmn:StartEvent": true,
    "bpmn:Task": true,
    "bpmn:UserTask": true,
};

export class UnknownElementType {
    constructor(readonly type: string) {}
}

export type Message = {
    /** Send as a push notification? */
    push: boolean;
    /** Send as an email? */
    mail: boolean;
    /** List of email addresses separated by comma (must be without spaces?). */
    emails: string;
    /** Notification title or email subject. */
    title: string;
    /** This may be an HTML template for emails (they use the workflow process_vars). */
    body: string;
};
