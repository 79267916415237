import CheckYourEmail from "../CheckYourEmail";
import { useLocale } from "../../i18n/context";

export const buttonWidth = "20rem";

export default function MagicLinkStep(props: {
    subdomain: string;
    email: string;
    onGoBack: () => void;
}) {
    const [locale] = useLocale();
    return (
        <CheckYourEmail
            email={props.email}
            weHaveSentWhat={locale().auth.weHaveSentMagicLink}
            cantFindTheWhat={locale().auth.cantFindTheMagicLink}
            onResendEmail={() => {}}
            onGoBack={props.onGoBack}
        />
    );
}
