import { assignHeaders, Client } from "../../../modules/client/client";
import { parsedEnv } from "../../../utils/parsedEnv";
import { isAuthenticated } from "../../../modules/auth/authContext";
import { firebaseAuthService } from "../auth/implementations/firebase";

export const newPersonClient = new Client(`${parsedEnv.VITE_SERVER_NEW_PEOPLE}`, {
    intercept: async (request, fetchData) => {
        if (await isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });

        try {
            return await fetchData(request);
        } catch (error) {
            // if (
            //     isDjangoDebug500(
            //         error,
            //         "AttributeError",
            //         "'AnonymousUser' object has no attribute 'tenant'",
            //     )
            // )
            //     return revokeSession();

            throw error;
        }
    },
});
