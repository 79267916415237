import { useLocale } from "./context";
import { localeByCode, LanguageCode } from "./localeByCode";

import SelectField from "../forms/fields/SelectField";
import { FormWrapper } from "../forms/FormWrapper";
import Warning from "../ui/Warning";

export default function LanguageSelector() {
    const [locale, { languageCode, setLanguageCode }] = useLocale();
    const t = () => locale().settings;

    return (
        <FormWrapper>
            <Warning>
                La traducción a inglés está incompleta. También falta español mexicano.
            </Warning>
            <SelectField
                name="code"
                label={
                    <span class="flex items-center">
                        <i class="fas fa-language me-1 text-display-sm" />
                        {t().language}
                    </span>
                }
                defaultValue={languageCode()}
                options={Object.entries(localeByCode).map(([code, locale]) => [code, locale.name])}
                onChange={event => setLanguageCode(event.currentTarget.value as LanguageCode)}
            />
        </FormWrapper>
    );
}
