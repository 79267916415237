import {
    Accessor,
    batch,
    createContext,
    createEffect,
    createSignal,
    onCleanup,
    onMount,
    untrack,
} from "solid-js";
import { createStore, produce, SetStoreFunction, Store } from "solid-js/store";
import { useRequiredContext, useThrowToErrorBoundary } from "../../utils/solidjs";
import { useLocale } from "../i18n/context";
import { Json } from "../../api/utils";
import _ from "lodash";
import { diff } from "./diff";
import { cloneFormValues } from "./utils";

export type FormValues = Record<string, Json | File | undefined>;

export interface FieldState<TInputValue = unknown, TOutputValue = TInputValue>
    extends FieldStateProps<TInputValue> {
    /** @see createField */
    blankValue: TInputValue;
    lastValidation: FieldValidation | undefined;
    toOutputValue: (inputValue: TInputValue) => TOutputValue;
}

/** The props of the Field component that are required to create the {@link FieldState}.
 *
 * @remarks - If those props change, the FieldState needs to be updated as well.
 * This is done using a `createEffect` in {@link createField}.
 */
export type FieldStateProps<TInputValue> = {
    defaultValue?: TInputValue;
    optional?: boolean;
    validate?: (value: TInputValue) => true | string;
    requiredMessage?: string;
};

/** Returns a new FieldState instance for a field being created.
 *
 * @see createField
 */
export function makeFieldState<TInputValue, TOutputValue>(
    props: FieldStateProps<TInputValue>,
    blankValue: TInputValue,
    toOutputValue: (inputValue: TInputValue) => TOutputValue,
): FieldState<TInputValue, TOutputValue> {
    return untrack(() => ({
        // FieldStateProps (we keep the state updated when the props change)
        defaultValue: props.defaultValue,
        optional: props.optional,
        validate: props.validate,
        requiredMessage: props.requiredMessage,

        // These never change, but we need to store them somewhere
        blankValue,
        toOutputValue,

        /* Even if the field has a `defaultValue`, it may have a custom
         * validation that depends on other fields. Therefore, the initial validation
         * must be done later in such case. */
        lastValidation: undefined,
    }));
}

export const FormStateContext = createContext<FormController<FormValues>>();

/** A low-level API to control the state of a form. */
export interface FormState {
    setValueStore: SetStoreFunction<Partial<Record<string, unknown>>>;

    /** Field state by `name`. */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldStore: Store<Record<string, FieldState<any>>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldStore: SetStoreFunction<Record<string, FieldState<any>>>;

    /** Supports fieldName syntax like `obj.arr[0]` to get the corresponding form value.
     */
    getValue(fieldName: string): unknown;
    /** Supports fieldName syntax like `obj.arr[0]` to set the corresponding form value,
     * without revalidating the field.
     */
    setValueWithoutValidation(fieldName: string, newValue: unknown): void;
    /** Revalidates every field. */
    updateFormValidation(): void;
}

export type OnSubmit<TFormValues extends FormValues> = (
    formValues: TFormValues,
) => void | Promise<void>;

export interface FormController<TFormValues extends FormValues> {
    /** A reactive object with the form values.
     *
     * @remarks
     * Generally, this is an object whose keys are the field `name`s,
     * and whose values are the current contents of those fields.
     *
     * Syntax like `obj.arr[0]` is supported for field `name`s, and will result
     * in `form.values` having nested objects and/or arrays.
     * Note that in such case there will not be a 1:1 mapping from `form.values`
     * to fields anymore, as there may be paths in `form.values` without a
     * corresponding field.
     *
     * `form.values` may have additional values that are not referenced by any field,
     * those values will still be submitted. Those values won't be validated.
     *
     * There is no guarantee the values are valid,
     * invalid fields still have their input values in the form values.
     *
     * Unfilled fields will have their "blank value" (e.g. `""`, `false`),
     * instead of `undefined`.
     *
     * Fields that have been unmounted (e.g. because they are shown conditionally
     * and the condition is not satisfied) won't be present in this object,
     * i.e. reading them will return `undefined`, even if they are also unfilled.
     *
     * Initially is an empty object.
     * If the form has `defaultValues` or a field has a `defaultValue`,
     * there may be a one-frame delay where this object is still empty.
     */
    values: Store<TFormValues>;

    /** Receives a function that mutates the form values directly.
     *
     * @remarks
     * You can mutate the values in the same way as https://www.solidjs.com/docs/latest/api#produce.
     *
     * Revalidates every affected field when finished.
     * This means that values that weren't mutated by `fn` won't be revalidated.
     *
     * If you need setting a value without revalidating, see {@link FieldController.setValue}.
     */
    setValues: (fn: (values: TFormValues) => void) => void;

    /** This object allows more advanced ways to update a field. */
    getFieldController<TName extends keyof TFormValues & string>(
        fieldName: TName,
    ): FieldController<_.GetFieldType<TFormValues, TName>>;

    /** Restores the currently mounted fields to their initial state, including validation. */
    reset(): void;

    /** Returns true if `onSubmit` is async and the promise is pending.
     *
     * @remarks
     * Also works for the {@link FormController.triggerSecondarySubmit} callback.
     */
    submitting: Accessor<boolean>;

    /** Useful to create forms with multiple submit buttons.
     *
     * @remarks
     * If you need multiple submit buttons, only one of them can have `type="submit"`,
     * so the secondary button(s) won't trigger `<form onSubmit />`.
     *
     * Call this method in the `onClick` of those secondary buttons,
     * and `onValid` will be called if the field values are valid.
     */
    triggerSecondarySubmit(onSubmit: OnSubmit<TFormValues>): void;

    /** Used internally by the forms core. */
    state: FormState;
}

export function createForm<TFormValues extends FormValues>(): FormController<TFormValues> {
    const [locale] = useLocale();

    const [valueStore, setValueStore] = createStore<Partial<Record<string, unknown>>>({});
    const [fieldStore, setFieldStore] = createStore<Record<string, FieldState>>({});

    /* Initial validation for fields with a default value.
     * This is done after every field has been mounted, as the validation of a
     * field may depend on the values of other fields. */
    onMount(() => {
        for (const [fieldName, field] of Object.entries(fieldStore)) {
            if (field.defaultValue !== undefined) {
                getFieldController(fieldName).updateValidation();
            }
        }
    });

    const [submitting, setSubmitting] = createSignal(false);
    const throwToErrorBoundary = useThrowToErrorBoundary();

    function handleSubmit(onSubmit: OnSubmit<TFormValues>): void {
        if (updateFormValidation()) {
            const formValues = cloneFormValues(valueStore as Store<TFormValues>);
            for (const [fieldName, field] of Object.entries(fieldStore)) {
                const inputValue = _.get(formValues, fieldName);
                const outputValue = field.toOutputValue(inputValue);
                _.set(formValues, fieldName, outputValue);
            }

            setSubmitting(true);
            Promise.resolve(onSubmit(formValues)) // Promise.resolve as onSubmit may not be a promise
                .catch(throwToErrorBoundary)
                .finally(() => setSubmitting(false));
        }
    }

    /** Returns the error message, or "" if the field is valid. */
    function getErrorMessage(fieldName: string, value: unknown): string {
        const field = fieldStore[fieldName];

        if (isBlank(value) && !field.optional) {
            return field.requiredMessage ?? locale().forms.requiredError;
        }

        const customValidation = field.validate?.(value);
        if (typeof customValidation === "string") {
            return customValidation;
        }

        return "";
    }

    function updateFormValidation(): boolean {
        let valid = true;
        for (const fieldName of Object.keys(fieldStore)) {
            valid &&= getFieldController(fieldName).updateValidation();
        }
        return valid;
    }

    function reset(): void {
        batch(() => {
            for (const fieldName of Object.keys(fieldStore)) {
                const field = fieldStore[fieldName];
                setValueWithoutValidation(fieldName, field.defaultValue ?? field.blankValue);
                setFieldStore(
                    fieldName,
                    makeFieldState(field, field.blankValue, field.toOutputValue),
                );
            }
        });
    }

    function getFieldController<TName extends keyof TFormValues & string>(
        fieldName: TName,
    ): FieldController<_.GetFieldType<TFormValues, TName>> {
        return {
            get value() {
                return getValue(fieldName);
            },
            setValue(newValue, options) {
                if (!fieldStore[fieldName]) throw new Error(`Field "${fieldName}" not found`);
                batch(() => {
                    setValueWithoutValidation(fieldName, newValue);
                    if (!options?.deferValidation) {
                        this.updateValidation();
                    } else if (this.lastValidation !== undefined) {
                        setFieldStore(fieldName, "lastValidation", "stale", true);
                    }
                });
            },
            get lastValidation() {
                // Use `?.` as the field may be not fully mounted during the first frame
                return fieldStore[fieldName]?.lastValidation;
            },
            setBackendErrorMessage(message: string) {
                setFieldStore(fieldName, "lastValidation", { error: message, stale: false });
            },
            updateValidation() {
                const error = getErrorMessage(fieldName, getValue(fieldName));
                setFieldStore(fieldName, "lastValidation", { error, stale: false });
                return !error;
            },
        };
    }

    function getValue<TName extends string>(fieldName: TName): _.GetFieldType<TFormValues, TName> {
        return _.get(valueStore, fieldName) as _.GetFieldType<TFormValues, TName>;
    }

    function setValueWithoutValidation(fieldName: string, newValue: unknown): void {
        setValueStore(
            produce(valueStore => {
                // Use cloneFormValues because https://runkit.com/robin40/6646c41776eeb40008d2dbf5
                _.set(valueStore, fieldName, cloneFormValues(newValue));
            }),
        );
    }

    return {
        values: valueStore as Store<TFormValues>,
        setValues(fn) {
            batch(() => {
                let changes = new Set<string>();
                setValueStore(
                    produce(valueStore => {
                        changes = diff(valueStore, fn as (obj: Record<string, unknown>) => void);
                    }),
                );
                for (const fieldName of changes) {
                    if (fieldStore[fieldName]) {
                        getFieldController(fieldName).updateValidation();
                    }
                }
            });
        },
        getFieldController,
        reset,
        submitting,
        triggerSecondarySubmit: handleSubmit,
        state: {
            setValueStore,
            fieldStore,
            setFieldStore,
            getValue,
            setValueWithoutValidation,
            updateFormValidation,
        },
    };
}

export function useFormState<TFormValues extends FormValues>(): FormController<TFormValues> {
    return useRequiredContext(
        FormStateContext,
        "useFormState",
        "FormWrapper",
    ) as unknown as FormController<TFormValues>;
}

export function isBlank(value: unknown): boolean {
    return (typeof value === "string" && value.trim() === "") || value === undefined;
}

/** Allows to get or set the value of a field created with {@link createField}. */
export interface FieldController<TInputValue> {
    /** Reactive value of the field input, even if invalid.
     *
     * @see FormController.values
     */
    get value(): TInputValue;

    /** Changes the contents of the field input,
     * updating the field validation in the process.
     */
    setValue(
        newValue: TInputValue,
        options?: {
            /** If true, the field won't be revalidated immediately.
             *
             * @remarks
             * The field will be validated again on any of the following events:
             * - `setValue` is called again without `deferValidation: true`
             * - the field is blurred
             * - {@link FieldController.updateValidation} is called
             * - the form is submitted
             *
             * @defaultValue `false`
             */
            deferValidation?: boolean;
        },
    ): void;

    /** Reactive validation result, corresponding to the last time the field
     * validation was updated.
     *
     * @remarks
     * Most fields won't be validated until they are blurred, so the user
     * is not overwhelmed by error messages for required fields.
     *
     * This behavior is controlled by the `deferValidation` option of
     * {@link FieldController.setValue}.
     *
     * Returns undefined if the field hasn't been validated yet.
     */
    get lastValidation(): FieldValidation | undefined;

    /** Makes the field to show an error, regardless of its current value,
     * until the field validation is updated.
     */
    setBackendErrorMessage(message: string): void;

    /** Returns whether the current field value is valid,
     * updating the icon and error message shown to the user in the process.
     *
     * @remarks
     * This will remove any error set by {@link FieldController.setBackendErrorMessage}.
     */
    updateValidation(): boolean;
}

/** Validation result for the last time a field validation was updated. */
interface FieldValidation {
    /** Localized error message if the field is invalid, or an empty string otherwise. */
    error: string;
    /** If true, this validation may be outdated because it was deferred.
     * @see FieldController.setValue
     */
    stale: boolean;
}

/** Used by Field components to add a field to a {@link FormWrapper}'s state.
 *
 * @typeParam TInputValue - Type of the HTML input/select/etc. value,
 * or if no HTML element is associated to the field, the type of whatever representation
 * is used to store what the current (and maybe invalid) value that the user is editing.
 * @typeParam TOutputValue - Type of the value when the form is submitted.
 *
 * @param props - The props passed to the corresponding Field component.
 * @param blankValue - The value when the field is empty, you can think about
 * it as a "default defaultValue". For example, a text field blankValue is "".
 * @param toOutputValue - Useful when the type of the value to be submitted is different
 * from the type of the value of the HTML input/select/etc.
 */
export function createField<TInputValue, TOutputValue = TInputValue>(
    props: FieldStateProps<TInputValue> & { name: string },
    blankValue: TInputValue,
    toOutputValue = (inputValue: TInputValue) => inputValue as unknown as TOutputValue,
): FieldController<TInputValue> {
    const form = useFormState();

    // Ensure the field name doesn't change
    const fieldName = untrack(() => props.name);
    createEffect(() => {
        if (props.name !== fieldName)
            throw new Error(
                `A field name changed from "${fieldName}" to "${props.name}". See docs for FieldProps.name if you need this.`,
            );
    });

    // Add field state to form state.
    batch(() => {
        form.state.setValueWithoutValidation(fieldName, props.defaultValue ?? blankValue);
        form.state.setFieldStore(fieldName, makeFieldState(props, blankValue, toOutputValue));
    });
    onCleanup(() => form.state.setFieldStore({ [fieldName]: undefined }));

    // Update the field state when the props change
    createEffect(() => {
        /* Note: `setFieldStore(fieldName, props)` doesn't work as in Solid.js
         * each individual prop is reactive, but the props object itself is not.
         */
        form.state.setFieldStore(fieldName, {
            defaultValue: props.defaultValue,
            optional: props.optional,
            validate: props.validate,
            requiredMessage: props.requiredMessage,
        } satisfies {
            // Use satisfies to make TypeScript force us to pass every prop
            [Prop in keyof Required<FieldStateProps<TInputValue>>]:
                | FieldStateProps<TInputValue>[Prop]
                | undefined;
        });
    });

    return form.getFieldController(fieldName) as FieldController<TInputValue>;
}
