import { P } from "../../utils/typography";
import { createSignal } from "solid-js";
import { createModalController } from "../ui/Modal";
import { SelectEnclosuresModal } from "./modals/SelectEnclosuresModal";
import { LimitDateModal } from "./modals/LimitDateModal";
import { PriorityModal } from "./modals/PriorityModal";
import SwitchField from "../forms/fields/SwitchField";
import { FormWrapper } from "../forms/FormWrapper";

export default function ActivityBubbleMenu() {
    const [showFilter, setShowFilter] = createSignal(false);
    const selectEnclosuresModal = createModalController();
    const limitDateModal = createModalController();
    const priorityModal = createModalController();

    return (
        <>
            <div class={"fixed bottom-32 left-2 flex gap-x-2 rounded-12 bg-light-gray-300 p-1"}>
                <div
                    class={"flex h-12 w-12 items-center justify-center rounded-full bg-white"}
                    onClick={() => setShowFilter(true)}
                >
                    <i class="fas fa-filter text-lg"></i>
                </div>
            </div>
            {showFilter() && (
                <FormWrapper
                    class={"left-0 top-0 fixed z-dropdown h-[88%] w-screen bg-light-gray-200 pt-4"}
                >
                    <div
                        class={
                            "flex justify-between border-b border-light-gray-200 bg-white px-3 py-2"
                        }
                    >
                        <div
                            class={"flex items-center gap-x-3"}
                            onClick={() => setShowFilter(false)}
                        >
                            <i class="fas fa-chevron-left text-primary-500" />
                            <P class={"!mb-0 font-semibold"}>Filtrar por</P>
                        </div>
                    </div>

                    <div class={"mt-2 bg-white py-3"}>
                        <div
                            class={"p-3 font-normal text-primary-900"}
                            onClick={() => {
                                selectEnclosuresModal.open({});
                            }}
                        >
                            Recintos
                        </div>
                        <div
                            class={"p-3 font-normal text-primary-900"}
                            onClick={() => {
                                limitDateModal.open({});
                            }}
                        >
                            Fecha limite
                        </div>
                        <div
                            class={"p-3 font-normal text-primary-900"}
                            onClick={() => {
                                priorityModal.open({});
                            }}
                        >
                            Prioridad
                        </div>
                        <div class={"p-3"}>
                            <SwitchField
                                name={"show_programed_activities"}
                                labelClass={"justify-between"}
                                label={"Mostrar actividades programadas"}
                            />
                        </div>
                        <div class={"p-3"}>
                            <SwitchField
                                name={"show_reminders"}
                                labelClass={"justify-between"}
                                label={"Mostrar recordatorios"}
                            />
                        </div>
                    </div>
                </FormWrapper>
            )}

            <SelectEnclosuresModal controller={selectEnclosuresModal} />
            <LimitDateModal controller={limitDateModal} />
            <PriorityModal controller={priorityModal} />
        </>
    );
}
