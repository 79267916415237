import {
    Workflow,
    WorkflowEndpoints,
    deserializeWorkflow,
    sWorkflow,
    CreateWorkflow,
} from "./interface";
import { workflowClient } from "../../../../modules/client/client";
import { array, string, type } from "superstruct";

export default class V3WorkflowEndpoints implements WorkflowEndpoints {
    listWorkflows = async (): Promise<Workflow[]> =>
        workflowClient
            .get("/workflows/workflows/")
            .receive(array(sWorkflow()))
            .then(array => array.map(deserializeWorkflow));

    createWorkflow = async (body: CreateWorkflow): Promise<{ id: string }> =>
        workflowClient
            .post("/workflows/workflows/")
            .sendJson(body)
            .receive(type({ id: string() }));

    retrieveWorkflow = async (workflowId: string): Promise<Workflow> =>
        workflowClient
            .get(`/workflows/workflows/${workflowId}`)
            .receive(sWorkflow())
            .then(deserializeWorkflow);
}
