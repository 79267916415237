import { createApiMutation, queryClient } from "../../../utils";

export function createWorkflowTypeMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createWorkflowType"],
        mutationFn: api.workflow.createWorkflowType,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["myWorkflowTypes"] });
            queryClient.invalidateQueries({ queryKey: ["allWorkflowTypes"] });
        },
    }));
}

export function createUpdateWorkflowTypeMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateWorkflowType"],
        mutationFn: api.workflow.updateWorkflowType,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["myWorkflowTypes"] });
            queryClient.invalidateQueries({ queryKey: ["allWorkflowTypes"] });
        },
    }));
}

export function createDestroyWorkflowTypeMutation() {
    return createApiMutation(api => ({
        mutationKey: ["destroyWorkflowType"],
        mutationFn: api.workflow.destroyWorkflowType,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["myWorkflowTypes"] });
            queryClient.invalidateQueries({ queryKey: ["allWorkflowTypes"] });
        },
    }));
}
