import { PageWrapper } from "../ui/pageWrappers";
import { P } from "../../utils/typography";
import { useNavigate, useParams } from "@solidjs/router";
import {
    createChecklistExecutionRetrieveQuery,
    createChecklistRetrieveQuery,
    createCurrentStepQuery,
    createRetrieveStep,
    createRetrieveUniqSection,
} from "../../api/services/checklist/queries";
import { Show, Suspense } from "solid-js";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import { StepFields } from "./checklistExecutions/utils";
import {
    createAnswerStepMutation,
    createCompleteChecklistMutation,
} from "../../api/services/checklist/mutations";
import { FormValues } from "../forms/state";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { Button } from "../ui/components";
import { SectionStep } from "../../api/services/checklist/interface";

export default function ChecklistExecutionsExecutePage() {
    const params = useParams();
    const executionID = () => params.execution_id;
    const getExecutionQuery = createChecklistExecutionRetrieveQuery(executionID);

    return (
        <PageWrapper>
            <Show when={getExecutionQuery.data}>
                {data => (
                    <ChecklistExecutionsDetail
                        checklistID={data().checklist}
                        executionID={executionID()}
                    />
                )}
            </Show>
        </PageWrapper>
    );
}

function ChecklistExecutionsDetail(props: { checklistID: string; executionID: string }) {
    const checklistId = () => props.checklistID;
    const getChecklist = createChecklistRetrieveQuery(checklistId);

    // const getChecklistResponses = createListFormChecklistResponses(executionID);
    // const getChecklistOverview = createChecklistOverviewQuery(checklistId);
    const currentStepQuery = createCurrentStepQuery(() => props.executionID);

    return (
        <>
            <Show when={getChecklist.data}>
                {data => {
                    return (
                        <div>
                            <P class={"text-display-xs font-medium"}>Checklist: {data().name}</P>
                        </div>
                    );
                }}
            </Show>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={currentStepQuery.data}>
                    {currentStep => (
                        <Show
                            when={currentStep().id}
                            fallback={<AllStepsCompleted executionId={props.executionID} />}
                        >
                            {stepId => (
                                <SectionStepRender
                                    executionID={props.executionID}
                                    stepID={stepId()}
                                />
                            )}
                        </Show>
                    )}
                </Show>
            </Suspense>
            {/*<Show when={getChecklistOverview.data}>
                {data => {
                    return (
                        <div>
                            <P>Checklist Overview</P>
                            <JsonDebug value={data()} />
                            <P>Checklist Overview</P>
                        </div>
                    );
                }}
            </Show>
            <Show when={getChecklistResponses.data}>{data => <JsonDebug value={data()} />}</Show>
            <Show when={getChecklist.data}>
                {data => {
                    return (
                        <div>
                            <P>Checklist Name: {data().name}</P>
                            <SectionList checklistID={data().id} />
                        </div>
                    );
                }}
            </Show>*/}
        </>
    );
}

function SectionStepRender(props: { executionID: string; stepID: string }) {
    const getSectionStep = createRetrieveStep(() => props.stepID);

    return (
        <Show when={getSectionStep.data}>
            {step => {
                return <SectionStepForm step={step()} executionID={props.executionID} />;
            }}
        </Show>
    );
}

function SectionStepForm(props: { step: SectionStep; executionID: string }) {
    const answerStepMutation = createAnswerStepMutation();
    const sectionStepQuery = createRetrieveUniqSection(() => props.step.sectionId);
    const handleSubmit = async (formValues: FormValues) => {
        await answerStepMutation.mutateAsync({
            executionId: props.executionID,
            stepId: props.step.id,
            formValues,
        });
    };

    return (
        <>
            <Show when={sectionStepQuery.data}>
                {section => <P class={"text-lg font-medium"}>{section().name}</P>}
            </Show>
            <FormWrapper onSubmit={handleSubmit} class={"pl-8"}>
                <StepFields step={props.step} />
                <SubmitButton class={"mt-4"}>Continuar</SubmitButton>
            </FormWrapper>
        </>
    );
}

function AllStepsCompleted(props: { executionId: string }) {
    const completeMutation = createCompleteChecklistMutation();
    const navigate = useNavigate();

    const completeChecklist = async () => {
        if (
            confirm("¿Está seguro de completar el checklist? Esta operación no se puede deshacer.")
        ) {
            await completeMutation.mutateAsync(props.executionId);
            navigate("/activities");
        }
    };

    return (
        <div>
            <P>Has completado todos los pasos</P>
            <Button onClick={completeChecklist}>Finalizar checklist</Button>
        </div>
    );
}

// function SectionList(props: { checklistID: string }) {
//     const listSections = createChecklistSectionsListQuery({ checklist: props.checklistID });
//
//     return (
//         <div class={"grid grid-cols-12 gap-4"}>
//             <Show when={listSections.data}>
//                 {sections => {
//                     return (
//                         <For each={sections()}>
//                             {section => (
//                                 <div class={"col-span-12"}>
//                                     <P class={"font-bold"}>Section: {section.name}</P>
//                                     <SectionStepForm sectionID={section.id} />
//                                 </div>
//                             )}
//                         </For>
//                     );
//                 }}
//             </Show>
//         </div>
//     );
// }

// function SectionStepForm(props: { sectionID: string }) {
//     const getSectionSteps = createSectionStepListQuery(() => props.sectionID);
//     return (
//         <>
//             <Show when={getSectionSteps.data}>
//                 {steps => (
//                     <For each={steps()}>
//                         {(step, index) => (
//                             <P>
//                                 {index() + 1} {step.id}
//                             </P>
//                         )}
//                     </For>
//                 )}
//             </Show>
//         </>
//     );
// }
