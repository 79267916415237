import { useLocale } from "../i18n/context";
import BackButton from "../ui/BackButton";
import emailImage from "../../assets/imgs/emial-bg-circle.png";
import { H1, P, Strong } from "../../utils/typography";
import { Button } from "../ui/components";
import { buttonWidth } from "./steps/MagicLinkStep";

const emailImageWidth = "120px";

export default function CheckYourEmail(props: {
    email: string;
    weHaveSentWhat: string;
    cantFindTheWhat: string;
    onResendEmail: () => void;
    onGoBack: () => void;
}) {
    const [locale] = useLocale();

    return (
        <div>
            <BackButton onClick={props.onGoBack} />
            <div class={"flex flex-col gap-y-2 text-center"}>
                <img
                    src={emailImage}
                    alt=""
                    style={{ width: emailImageWidth }}
                    class={"mx-auto block"}
                />
                <div>
                    <H1 class={"!mb-0 font-normal"}>{locale().auth.checkYourEmail}</H1>
                    <P class={"!mb-0 text-lg text-dark-gray-400"}>{props.weHaveSentWhat}</P>
                    <P class={"text-lg"}>
                        <Strong>{props.email}</Strong>
                    </P>
                </div>

                <div class={"mt-4 flex flex-col items-center gap-y-4"}>
                    <P class={"!mb-0"}>
                        {props.cantFindTheWhat} {locale().auth.checkSpam}
                    </P>
                    <Button
                        class={"!border !border-primary-400 !bg-transparent !text-primary-400"}
                        style={{ width: buttonWidth }}
                    >
                        {locale().auth.resendEmail}
                    </Button>
                    <Button
                        class={"!border !border-primary-400 !bg-transparent !text-primary-400"}
                        style={{ width: buttonWidth }}
                        onClick={props.onGoBack}
                    >
                        {locale().auth.enterNewEmail}
                    </Button>
                    <Button
                        class={"!border !border-primary-400 !bg-transparent !text-primary-400"}
                        style={{ width: buttonWidth }}
                    >
                        {locale().auth.contactSupport}
                    </Button>
                </div>
            </div>
        </div>
    );
}
