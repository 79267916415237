import MagicCodeField from "../../forms/fields/MagicCodeField";
import SubmitButton from "../../forms/SubmitButton";
import BackButton from "../../ui/BackButton";
import { H1, P, Strong } from "../../../utils/typography";
import { createSignal, onMount, Show } from "solid-js";
import { useLocale } from "../../i18n/context";
import { parsedEnv } from "../../../utils/parsedEnv";
import Toastify from "toastify-js";
import { createReceiveMagicCodeMutation } from "../../../api/services/auth/mutations";
import { FormWrapper } from "../../forms/FormWrapper";
import emailImage from "../../../assets/imgs/emial-bg-circle.png";
import { ErrorAlert } from "../../../utils/components/ErrorAlert";

const emailImageWidth = "120px";

export default function MagicCodeStep(props: {
    email: string;
    /** Called when the backend sends the subdomain but the organization
     * doesn't allow sign in with magic code. */
    onReceiveSubdomain: (subdomain: string) => void;
    onGoBack: () => void;
}) {
    const [locale] = useLocale();
    const [wrong, setWrong] = createSignal(false);
    const receiveMagicCodeMutation = createReceiveMagicCodeMutation();
    onMount(() => {
        if (parsedEnv.MODE === "development")
            Toastify({
                text: "El código de prueba es 123456.",
            }).showToast();
    });

    async function handleSubmit(form: { magicCode: string }): Promise<void> {
        const response = await receiveMagicCodeMutation.mutateAsync({
            email: props.email,
            magicCode: form.magicCode,
        });
        if (response?.firebaseCustomToken) {
            /* Case 1: Magic code is correct and organization allows signing in
             * with magic code, so the backend gave us the custom token to
             * complete the sign in. */
            alert("Inicio de sesión con magic code no implementado aún");
            // await FirebaseAuthentication.setTenantId({ tenantId: response.firebaseTenantId });
            // await FirebaseAuthentication.signInWithCustomToken({ token: response.firebaseCustomToken });
        } else if (response) {
            /* Case 2: Magic code is correct but organization forbids signing in
             * with magic code, so let the state machine continue the sign in. */
            props.onReceiveSubdomain(response.subdomain);
        } else {
            /* Case 3: Incorrect magic code or email is not registered.
             * Never tell the user the email is not registered! */
            setWrong(true);
        }
    }

    return (
        <div>
            <BackButton onClick={props.onGoBack} />
            <div class={"flex flex-col gap-y-3 text-center"}>
                <img
                    src={emailImage}
                    alt={"imagen de email"}
                    style={{ width: emailImageWidth }}
                    class={"mx-auto block"}
                />

                <div>
                    <H1 class={"!mb-0"}>{locale().auth.checkYourEmail}</H1>
                    <P class={"!mb-0 text-light-gray-700"}>
                        {locale().auth.weHaveSentACode_1} <Strong>{props.email}</Strong>
                    </P>
                    <P class={"text-dark-gray-400"}>{locale().auth.weHaveSentACode_2}</P>
                </div>

                <FormWrapper<{ magicCode: string }> onSubmit={handleSubmit}>
                    <MagicCodeField
                        name="magicCode"
                        placeholder="123456"
                        parentClass={"[&>input]:text-center"}
                    />
                    <Show when={wrong()}>
                        <ErrorAlert text={locale().auth.wrongMagicCode} />
                    </Show>
                    <SubmitButton submittingText={locale().auth.continuing} class={"mt-3 w-full"}>
                        {locale().auth.continue}
                    </SubmitButton>
                    <P class={"mt-2 text-dark-gray-700"}>{locale().auth.cantFindTheCode}</P>
                </FormWrapper>
            </div>
        </div>
    );
}
