import { create, Describe, Struct } from "superstruct";
import { Json } from "../api/utils";
import { Preferences } from "@capacitor/preferences";

export default function JsonStorage<T extends Json>(key: string, schema: Describe<T> | Struct<T>) {
    return {
        async get(): Promise<T | undefined> {
            try {
                const result = await Preferences.get({ key });
                return create(JSON.parse(result.value!), schema);
            } catch (_) {
                return undefined;
            }
        },

        async set(value: T | undefined | ((prev: T | undefined) => T | undefined)): Promise<void> {
            if (typeof value === "function") value = value(await this.get());
            if (value === undefined) await Preferences.remove({ key });
            else await Preferences.set({ key, value: JSON.stringify(value) });
        },
    };
}
