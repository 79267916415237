import ActivityItemList from "./ActivityItemList";
import ActivityHeader from "./ActivityHeader";
import ActivityBubbleMenu from "./ActivityBubbleMenu";
import Warning from "../ui/Warning";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { createSignal, Show } from "solid-js";
import { createMyCalendarQuery } from "../../api/services/task-manager/queries";

export default function ActivitiesPage() {
    const { sm } = useResponsiveBreakpoints();
    const myCalendarQuery = createMyCalendarQuery();
    const [endDate, setEndDate] = createSignal(Temporal.Now.plainDateISO());
    const startDate = () => endDate().subtract({ months: 1 });

    return (
        <div class={"relative min-h-screen md:p-4"}>
            <Warning>Esto es una maqueta incompleta de la feature.</Warning>
            <ActivityHeader
                onPrev={() => setEndDate(d => d.subtract({ days: 1 }))}
                onNext={() => setEndDate(d => d.add({ days: 1 }))}
                date={endDate()}
                onChangeDate={setEndDate}
                onToday={() => setEndDate(Temporal.Now.plainDateISO())}
            />
            <ActivityItemList
                calendarQuery={myCalendarQuery}
                startDate={startDate()}
                endDate={endDate()}
                reverse
            />
            <Show when={!sm()}>
                <ActivityBubbleMenu />
            </Show>
        </div>
    );
}
