import { useParams } from "@solidjs/router";
import { create, string, type } from "superstruct";
import {
    createFormByBpmnElementQuery,
    createFormResponseQuery,
    createWorkflowElementListQuery,
    createWorkflowExecutionDetailQuery,
} from "../../api/services/workflow/queries";
import { Component, For, Show, Suspense } from "solid-js";
import { JsonDebug, TechnicalDetails } from "../../utils/debug";
import { PageWrapper } from "../ui/pageWrappers";
import { H1, P } from "../../utils/typography";
import { createModalController } from "../ui/Modal";
import SidePanel from "../ui/SidePanel";
import { WorkflowExecution } from "../../api/services/workflow/interface";
import { Dynamic } from "solid-js/web";
import { createPerformWorkflowActionMutation } from "../../api/services/workflow/mutations";
import { FormValues } from "../forms/state";
import { showErrorToast } from "../../utils/errorHandling";
import Toastify from "toastify-js";
import { useLocale } from "../i18n/context";
import { Entry, RecordTable } from "../ui/RecordTable";
import { BpmnElement, BpmnOverlay, BpmnViewer } from "./bpmn";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { Countdown } from "../Activities/ActivityItemMobile";
import { Priority } from "../../api/services/task-manager/interface";
import { RenderDynamicFields } from "../FormBuilder/RenderDynamicForm";
import SubmitButton from "../forms/SubmitButton";
import { FormWrapper } from "../forms/FormWrapper";
import { ElementTitle } from "./utils";
import { useClock } from "../../utils/clock";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";

export default function WorkflowExecutionDetailPage() {
    const params = create(useParams(), type({ id: string() }));
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => params.id);
    const workflowDetailQuery = createWorkflowDetailQuery(
        () => executionDetailQuery.data?.workflowId,
    );
    const panelController = createModalController<BpmnElement, void>();
    const [locale] = useLocale();

    const handleActionSuccess = () => {
        panelController.dismiss();
        Toastify({
            text: locale().workflows.actionSuccess,
            style: { backgroundColor: "green" },
        }).showToast();
    };

    return (
        <PageWrapper>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={workflowDetailQuery.data}>
                    {workflow => (
                        <div>
                            <H1>{workflow().name}</H1>
                            <ExecutionMetadata executionId={params.id} />
                            <BpmnExecutionViewer
                                bpmn={workflow().bpmn}
                                executionId={params.id}
                                onClickElement={panelController.open}
                            />
                        </div>
                    )}
                </Show>
                <Show when={executionDetailQuery.data}>
                    {execution => (
                        <SidePanel position="right" controller={panelController}>
                            {bpmnElement => (
                                <ElementExecution
                                    element={bpmnElement}
                                    workflowId={execution().workflowId}
                                    executionId={params.id}
                                    onActionSuccess={handleActionSuccess}
                                />
                            )}
                        </SidePanel>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}

function ExecutionMetadata(props: { executionId: string }) {
    const [locale] = useLocale();
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => props.executionId);

    return (
        <Show when={executionDetailQuery.data}>
            {execution => (
                <div>
                    <RecordTable>
                        <Entry
                            name={locale().workflows.executionId}
                            value={<code>{execution().id}</code>}
                        />
                        <Entry
                            name={locale().workflows.createdAt}
                            value={Intl.DateTimeFormat(locale().codeWithCountry, {
                                dateStyle: "long",
                                timeStyle: "short",
                            }).format(execution().createdAt)}
                        />
                        <Entry
                            name={locale().workflows.updatedAt}
                            value={Intl.DateTimeFormat(locale().codeWithCountry, {
                                dateStyle: "long",
                                timeStyle: "short",
                            }).format(execution().updatedAt)}
                        />
                        <Entry
                            name={locale().workflows.status}
                            value={locale().workflows.completionStatuses[execution().status]}
                        />
                    </RecordTable>
                    <TechnicalDetails>
                        <JsonDebug value={execution()} />
                    </TechnicalDetails>
                </div>
            )}
        </Show>
    );
}

function BpmnExecutionViewer(props: {
    bpmn: string | null;
    executionId: string;
    onClickElement?: (element: BpmnElement) => void;
}) {
    const workflowElementListQuery = createWorkflowElementListQuery(() => props.executionId);

    return (
        <BpmnViewer bpmn={props.bpmn} onClickElement={props.onClickElement}>
            <Show when={workflowElementListQuery.data}>
                {workflowElementList => (
                    <For
                        each={workflowElementList().filter(
                            workflowElement => workflowElement.isActive,
                        )}
                    >
                        {workflowElement => (
                            <BpmnOverlay bpmnElementId={workflowElement.bpmnElementId}>
                                {bpmnElement => (
                                    <div
                                        class="isolate h-full w-full bg-warning-200/50"
                                        onClick={() => props.onClickElement?.(bpmnElement)}
                                    />
                                )}
                            </BpmnOverlay>
                        )}
                    </For>
                )}
            </Show>
        </BpmnViewer>
    );
}

type ElementExecutionProps = {
    element: BpmnElement;
    workflowId: string;
    executionId: string;
    onActionSuccess: () => void;
};

function ElementExecution(props: ElementExecutionProps) {
    const getWidget = (element: BpmnElement): Component<ElementExecutionProps> => {
        switch (element.type) {
            case "bpmn:BoundaryEvent":
                return BoundaryEventExecution;
            case "bpmn:UserTask":
                return UserTaskExecution;
            default:
                return NotImplementedElementExecution;
        }
    };

    return (
        <PageWrapper>
            <div class="min-w-140">
                <ElementTitle element={props.element} />
                <Suspense fallback={<GenericSuspenseFallback />}>
                    <Dynamic component={getWidget(props.element)} {...props} />
                </Suspense>
            </div>
        </PageWrapper>
    );
}

function NotImplementedElementExecution(props: ElementExecutionProps) {
    return <JsonDebug value={props.element} />;
}

function BoundaryEventExecution(props: ElementExecutionProps) {
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => props.executionId);

    return (
        <Show when={executionDetailQuery.data}>
            {execution => (
                <Show when={props.element.duration} fallback={<P>Sin tiempo de expiración.</P>}>
                    {duration => <TimerEvent duration={duration()} execution={execution()} />}
                </Show>
            )}
        </Show>
    );
}

function TimerEvent(props: { duration: Temporal.Duration; execution: WorkflowExecution }) {
    const { nowInstant } = useClock();
    const remainingTime = () =>
        nowInstant().until(Temporal.Instant.from(props.execution.updatedAt).add(props.duration));

    return (
        <Countdown
            remainingTime={remainingTime()}
            // Hack for demo, priority should come from the workflow in the final version
            priority={Priority.ThreeUpArrows}
        />
    );
}

function UserTaskExecution(props: ElementExecutionProps) {
    const [locale] = useLocale();
    const performWorkflowActionMutation = createPerformWorkflowActionMutation();
    const formByBpmnElementQuery = createFormByBpmnElementQuery(() => ({
        workflowId: props.workflowId,
        bpmnElementId: props.element.id,
    }));
    const formResponseQuery = createFormResponseQuery(() => ({
        formId: formByBpmnElementQuery.data?.id,
        executionId: props.executionId,
    }));

    const handleSubmit = async (formValues: FormValues) => {
        try {
            await performWorkflowActionMutation.mutateAsync({
                executionId: props.executionId,
                bpmnElementId: props.element.id,
                formValues,
            });
            props.onActionSuccess();
        } catch (error) {
            showErrorToast(error);
        }
    };

    return (
        <Show when={formByBpmnElementQuery.data}>
            {form => (
                <Show when={formResponseQuery.data !== undefined}>
                    <Show when={formResponseQuery.data !== null}>
                        <JsonDebug value={formResponseQuery.data} />
                    </Show>
                    <FormWrapper
                        class="flex flex-col gap-3"
                        onSubmit={handleSubmit}
                        defaultValues={formResponseQuery.data?.formValues ?? undefined}
                    >
                        <RenderDynamicFields fields={form().fields} />
                        <SubmitButton>{locale().workflows.actionSubmit}</SubmitButton>
                    </FormWrapper>
                </Show>
            )}
        </Show>
    );
}
