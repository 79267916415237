import {
    ParentProps,
    Resource,
    Show,
    Suspense,
    createContext,
    createEffect,
    createResource,
} from "solid-js";
import {
    FirebaseMessaging,
    PermissionStatus as FirebaseMessagingPermissionStatus,
} from "@capacitor-firebase/messaging";
import { useRequiredContext } from "../../utils/solidjs";
import { useLocale } from "../i18n/context";
import { createCurrentOrganizationQuery } from "../../api/services/organization/queries";
import { Capacitor } from "@capacitor/core";
import { LineSkeleton } from "../ui/skeletons";
import { Button } from "../ui/components";
import { P } from "../../utils/typography";

const NotificationPermissionsContext = createContext<{
    status: Resource<FirebaseMessagingPermissionStatus>;
    requestPermissionsAgain(): void;
}>();

/** Used so we request notification permissions upon sign-in if needed,
 * instead of requiring the user going to the notifications tab before being able
 * to get notifications (catch 22).
 */
export function NotificationPermissionsProvider(props: ParentProps) {
    const [status, { mutate: mutateStatus }] = createResource(FirebaseMessaging.checkPermissions);

    createEffect(() => {
        if (status()?.receive === "prompt") request();
    });

    function request() {
        FirebaseMessaging.requestPermissions().then(mutateStatus);
    }

    return (
        <NotificationPermissionsContext.Provider
            value={{ status, requestPermissionsAgain: request }}
        >
            {props.children}
        </NotificationPermissionsContext.Provider>
    );
}

/** Render so the user knows the state of notification permissions when relevant. */
export function NotificationPermissionsView() {
    const { status, requestPermissionsAgain } = useRequiredContext(
        NotificationPermissionsContext,
        "NotificationPermissionsView",
        "NotificationPermissionsProvider",
    );

    return (
        <Suspense fallback={null}>
            <Show when={status()?.receive === "denied"}>
                <PermissionDenied onRetry={requestPermissionsAgain} />
            </Show>
        </Suspense>
    );
}

function PermissionDenied(props: { onRetry: () => void }) {
    const [locale] = useLocale();
    const t = () => locale().notifications;
    const organizationQuery = createCurrentOrganizationQuery();
    const notifications = () =>
        Capacitor.isNativePlatform() ? t().nativeNotifications : t().webNotifications;

    return (
        <div class="mb-3">
            <P>{t().permissionDenied.replace("{notifications}", notifications())}</P>
            <Suspense fallback={<LineSkeleton />}>
                <Show when={organizationQuery.data}>
                    {organization => <P>{t().optOutWarning(organization.name)}</P>}
                </Show>
            </Suspense>
            <Button onClick={props.onRetry}>
                {t().enableNotifications.replace("{notifications}", notifications())}
            </Button>
        </div>
    );
}
