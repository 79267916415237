import { For } from "solid-js";
import { FormWrapper } from "../forms/FormWrapper";
import CheckboxField from "../forms/fields/CheckboxField";
import { P } from "../../utils/typography";

type Role = {
    id: number;
    name: string;
    active: boolean;
};
const roles: Role[] = [
    {
        id: 1,
        name: "Gerente Comercial",
        active: true,
    },
    {
        id: 2,
        name: "Comercial grandes superficies",
        active: true,
    },
    {
        id: 3,
        name: "Jefe de Zona",
        active: false,
    },
    {
        id: 4,
        name: "Jefe de Zona",
        active: false,
    },
];
export function RoleList() {
    return (
        <div class={"rounded-md border border-light-gray-300"}>
            <P
                class={
                    "!mb-0 border-b border-light-gray-300 bg-light-gray-200 p-2 text-md font-medium"
                }
            >
                Configuración de la cuenta
            </P>
            <ul>
                <FormWrapper>
                    <For each={roles}>
                        {role => (
                            <li class={"border-b border-light-gray-300 p-2 last:border-none"}>
                                <CheckboxField
                                    name={`role_${role.id}`}
                                    label={role.name}
                                    defaultValue={role.active}
                                    parentClass={"flex-row-reverse justify-between"}
                                />
                            </li>
                        )}
                    </For>
                </FormWrapper>
            </ul>
        </div>
    );
}
