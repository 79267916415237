import { createApiMutation, queryClient } from "../../../utils";

export function createWorkflowCategoryMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createWorkflowCategory"],
        mutationFn: api.workflow.createWorkflowCategory,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["listWorkflowCategories"] });
        },
    }));
}

export function createUpdateWorkflowCategoryMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateWorkflowCategory"],
        mutationFn: api.workflow.updateWorkflowCategory,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["listWorkflowCategories"] });
        },
    }));
}

export function createDestroyWorkflowCategoryMutation() {
    return createApiMutation(api => ({
        mutationKey: ["destroyWorkflowCategory"],
        mutationFn: api.workflow.destroyWorkflowCategory,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["listWorkflowCategories"] });
        },
    }));
}
