import Avatar from "../auth/Avatar";
import { AuthenticatedUser } from "../auth/useAuthReturn";
import { Show } from "solid-js";
import { useAuth } from "../auth/authContext";
import { Button } from "../ui/components";
import { useLocale } from "../i18n/context";
import { makeProfileUpdate, PersonalDataFields, PersonalDataFormValues } from "./PersonalDataForm";
import { PageWrapper } from "../ui/pageWrappers";
import SubmitButton from "../forms/SubmitButton";
import { FormWrapper } from "../forms/FormWrapper";
import { createPersonalDataQuery } from "../../api/services/person/queries";
import { getApiInstance } from "../../api";
import { useNavigate } from "@solidjs/router";
import Warning from "../ui/Warning";

export default function EditProfilePage() {
    const { user } = useAuth();

    return (
        <PageWrapper>
            <Warning>
                Esta funcionalidad está incompleta. Falta:
                <ul>
                    <li>Validaciones de tipo de documento que no sean RUT.</li>
                    <li>Poder añadir múltiples números de teléfono.</li>
                    <li>Poder cambiar la foto de perfil.</li>
                </ul>
            </Warning>
            <Show when={user()}>{user => <EditProfileContent user={user()} />}</Show>
        </PageWrapper>
    );
}

function EditProfileContent(props: { user: AuthenticatedUser }) {
    const [locale] = useLocale();
    const navigate = useNavigate();

    const handleSubmit = async (formValues: PersonalDataFormValues) => {
        const api = getApiInstance();
        await api.person.updatePersonalData(makeProfileUpdate(formValues));
        navigate("/profile");
    };
    const personalDataQuery = createPersonalDataQuery();
    return (
        <div class="flex flex-col items-center gap-4">
            <div class="flex flex-col items-center gap-3">
                <Avatar picture={props.user.picture} size="lg" />
                <Button
                    bgStyle="outline"
                    size="sm"
                    onClick={() => alert(locale().utils.notAvailableForDemo)}
                >
                    {locale().personalData.changeProfilePicture}
                </Button>
            </div>
            <Show when={personalDataQuery.data}>
                {personalData => (
                    <FormWrapper<PersonalDataFormValues>
                        defaultValues={personalData()}
                        onSubmit={handleSubmit}
                    >
                        <div class="flex flex-col items-center gap-4">
                            <PersonalDataFields readonlyEmail={true} />
                            <SubmitButton
                                size="lg"
                                class="w-80"
                                submittingText={locale().personalData.savingChanges}
                            >
                                {locale().personalData.saveChanges}
                            </SubmitButton>
                        </div>
                    </FormWrapper>
                )}
            </Show>
        </div>
    );
}
