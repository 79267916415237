const defaultOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
};
export function normalizeDate(date: string) {
    const splitDate = date.split("-");
    const isCorrectDate = date.indexOf(":") === -1;
    if (isCorrectDate && splitDate[0].length === 4) {
        return `${splitDate[1]}-${splitDate[2]}-${splitDate[0]}`;
    }
    return date;
}
export function defaultFormattedDate(
    date: Temporal.PlainDate | Temporal.ZonedDateTime | string | undefined | Temporal.Instant,
    options?: Intl.DateTimeFormatOptions,
) {
    const _options = options ?? defaultOptions;
    const parseToDate =
        typeof date === "string" ? new Date(normalizeDate(date)) : date ? date : undefined;
    if (parseToDate) {
        return Intl.DateTimeFormat("es-ES", _options).format(parseToDate);
    }
    return "";
}

export function formattedDate(
    date: Temporal.PlainDate | string | undefined,
    format?: "default" | "only-numbers" | Intl.DateTimeFormatOptions,
) {
    if (date) {
        const defaultFormat = defaultFormattedDate(date, {
            month: "long",
            day: "numeric",
            year: "numeric",
        });
        if (format === "default") {
            return defaultFormat;
        } else if (format === "only-numbers") {
            return defaultFormattedDate(date, {});
        } else if (typeof format === "object") {
            return defaultFormattedDate(date, format);
        }
        return defaultFormat;
    }
    return "";
}

// 18/1/2024 - 17:32
export function formatDateWithHour(date: string) {
    return formattedDate(date, {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });
}

export function formatDateHourThenDate(date: string) {
    const __date = formattedDate(date, {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
    const _date = __date.split(", ");
    return _date.reverse().join(" ∙ ");
}

export function formatDateAndMonth(date: Temporal.PlainDate | string) {
    return formattedDate(date, {
        day: "numeric",
        month: "short",
    });
}

/** @deprecated - Use getHmsDurationBetween. */
export function calculateRemainingTime(targetTime: Date): {
    hours: number;
    minutes: number;
    seconds: number;
} {
    const now = new Date();
    const target = new Date(targetTime);

    if (target < now) {
        // If the target time has already passed, add a day to calculate the remaining time until tomorrow
        target.setDate(target.getDate() + 1);
    }

    const difference = target.getTime() - now.getTime();
    const totalSeconds = Math.floor(difference / 1000);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
}

/** Gets the duration between two Temporal.ZonedDateTime values.
 *
 * The duration is balanced to Hours Minute Seconds,
 * so it is for example 1h 30m 0s instead of 90m.
 *
 * @remarks - The duration can be negative! you may want to use
 * duration.sign(), .abs() and/or .negated().
 */
export function getHmsDurationBetween(
    from: Temporal.ZonedDateTime,
    to: Temporal.ZonedDateTime,
): Temporal.Duration {
    return from.until(to).round({ largestUnit: "hour" });
}

export function printRemainingTime(hours: number, minutes: number, seconds: number): string {
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
}
