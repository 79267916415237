import { useLocale } from "../../i18n/context";
import { SearchBar } from "../../ui/SearchBar";
import { Tab } from "../../ui/Tabs";
import { WorkPlacesTable } from "../WorkPlacesTable";

export function WorkPlacesTab() {
    const [l] = useLocale();

    return (
        <Tab title={l().personalData.tabs.placesTab}>
            <div class={"space-y-4 py-4"}>
                <SearchBar variant="white" placeholder={l().personalData.searchWorkspace} />
                <WorkPlacesTable />
            </div>
        </Tab>
    );
}
