import { StringField } from "./abstract";
import { FieldProps } from "./parts/types";
import { parseCondition } from "../conditions/parser";

export default function ConditionField(props: FieldProps<string, HTMLInputElement>) {
    return (
        <StringField
            type="text"
            inputClass="font-mono"
            errorClass="font-mono whitespace-pre"
            validate={condition => {
                if (condition === "") return true;
                const result = parseCondition(condition);
                if (result.type === "error") return result.message;
                return true;
            }}
            {...props}
        />
    );
}
