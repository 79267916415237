import { SectionStep } from "../../../api/services/checklist/interface";
import { RenderInput } from "../../FormBuilder/FBInputTypeToInput";
import { RenderDynamicFields } from "../../FormBuilder/RenderDynamicForm";

export function StepFields(props: { step: SectionStep }) {
    return (
        <div class="grid grid-cols-1 gap-12 lg:w-256 lg:grid-cols-3">
            <div class="col-span-1 gap-12 text-md md:text-lg lg:col-span-2 [&>div>label]:mb-2">
                <RenderInput {...props.step.mainField} />
            </div>
            <div class="col-span-1 flex flex-col gap-4">
                <RenderDynamicFields fields={props.step.fields} />
            </div>
        </div>
    );
}
