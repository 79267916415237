import { createEffect, createMemo, ParentProps, Show, Suspense, untrack } from "solid-js";
import { useLocale } from "../i18n/context";
import Avatar from "../auth/Avatar";
import ProfileCardContent from "../personal-data/ProfileCardContent";
import SettingsTab from "../settings/SettingsTab";
import { OnClickButton } from "../../utils/solidjs";
import Dropdown from "../ui/Dropdown";
import { useAuth } from "../auth/authContext";
import { NotificationsList, NotificationWrapperProps } from "../notifications/NotificationsPage";
import { H2 } from "../../utils/typography";
import { SearchBar } from "../ui/SearchBar";
import Toastify from "toastify-js";
import { createInboxInfiniteQuery } from "../../api/services/notifications/queries";
import { NotificationPermissionsView } from "../notifications/permissions";

export default function SemiNavbar() {
    return (
        <div class={`flex`}>
            <div class="flex-1 items-center px-4 py-3">
                <SearchBar variant="white" />
            </div>
            <div class="flex flex-1 items-center justify-end gap-3 py-3 pe-3">
                {/*<SyncTab />*/}
                <SettingsTab />
                <NotificationsDropdown />
                <ProfileDropdown />
            </div>
        </div>
    );
}

function ProfileCard() {
    return (
        <Card>
            <ProfileCardContent />
        </Card>
    );
}

function Card(props: ParentProps) {
    return (
        <div class="rounded-md bg-white shadow-light-gray-200" style={{ "min-width": "27rem" }}>
            {props.children}
        </div>
    );
}

function ProfileDropdown() {
    const { user } = useAuth();

    return (
        <Dropdown
            trigger={onClick => (
                <button class="center-items h-16 w-16" onClick={onClick}>
                    <Avatar picture={user()?.picture} size="lg" />
                </button>
            )}
        >
            <ProfileCard />
        </Dropdown>
    );
}

function NotificationsDropdown() {
    return (
        <Dropdown trigger={onClick => <NotificationsButton onClick={onClick} />}>
            <NotificationsCard />
        </Dropdown>
    );
}

function NotificationsButton(props: { onClick?: OnClickButton }) {
    const [locale] = useLocale();

    return (
        <button
            aria-label={locale().menu.notifications}
            onClick={e => props.onClick?.(e)}
            class="center-items h-16 w-16"
        >
            <i class="far fa-bell text-xl text-dark-gray-400" />
            <UnreadNotifications />
        </button>
    );
}

export function UnreadNotifications() {
    const inboxQuery = createInboxInfiniteQuery();

    return (
        <Suspense fallback={null}>
            <Show when={inboxQuery.data}>
                {notifications => {
                    /* While the backend doesn't provide an unread counter,
                     * count the unread notifications up to where the user has scrolled. */
                    const unreadCount = createMemo(() =>
                        notifications().pages.reduce(
                            (count, page) =>
                                count +
                                page.results.filter(notification => !notification.read).length,
                            0,
                        ),
                    );
                    return (
                        <Show when={unreadCount() > 0}>
                            <UnreadCounter count={unreadCount()} />
                        </Show>
                    );
                }}
            </Show>
        </Suspense>
    );
}

function UnreadCounter(props: { count: number }) {
    let prevCount = untrack(() => props.count);
    createEffect(() => {
        if (props.count > prevCount) {
            Toastify({
                text: "Tienes nuevas notificaciones",
                duration: 2000,
                close: true,
            }).showToast();
        }
        prevCount = props.count;
    });

    return (
        <div class="absolute right-0 top-0 rounded-full border-2 border-white text-md text-primary-500">
            {props.count}
        </div>
    );
}

function NotificationsCard() {
    const [locale] = useLocale();

    return (
        <Card>
            <div class="bg-light-gray-100 px-3 pb-1 pt-2">
                <H2>{locale().notifications.title}</H2>
                <NotificationPermissionsView />
                <NotificationsList ItemWrapper={DesktopNotificationWrapper} />
            </div>
        </Card>
    );
}

function DesktopNotificationWrapper(props: NotificationWrapperProps) {
    return (
        <div
            role="button"
            classList={{
                "bg-white border-x border-y border-light-gray-300 rounded-md mb-1": !props.read,
            }}
            onClick={() => props.onClick()}
        >
            {props.children}
        </div>
    );
}
