import LoremIpsum from "../modules/menu/LoremIpsum";

export function LoremAdvancedPage() {
    return <LoremIpsum title="Advanced" variant="short" />;
}

export function LoremAssetsPage() {
    return <LoremIpsum title="Assets" variant="long" />;
}

export function LoremLinksPage() {
    return <LoremIpsum title="Links" variant="long" />;
}

export function LoremMyAccountPage() {
    return <LoremIpsum title="My account" variant="short" />;
}

export function LoremOrganizationPage() {
    return <LoremIpsum title="Organization" variant="short" />;
}

export function LoremReportsPage() {
    return <LoremIpsum title="Reports" variant="long" />;
}

export function LoremToolsPage() {
    return <LoremIpsum title="Tools" variant="short" />;
}
