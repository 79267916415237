import { array, boolean, Describe, integer, nullable, string, Struct, type } from "superstruct";
import { ServiceQuery } from "../../utils";

export interface PositionService {
    listPositions: ServiceQuery<[], Position[]>;
}

export type OrganizationChartGraph = {
    organization_id: string;
    organization_name: string;
    nodes: OrganizationChartNode[];
    edges: OrganizationChartEdge[];
};

export function sOrganizationChartGraph(): Struct<OrganizationChartGraph> {
    return type({
        organization_id: string(),
        organization_name: string(),
        nodes: array(sOrganizationChartNode()),
        edges: array(sOrganizationChartEdge()),
    });
}

export type OrganizationChartNode = {
    id: string;
    title: string;
    persons: PersonLite[];
    additional_count: number;
};

function sOrganizationChartNode(): Struct<OrganizationChartNode> {
    return type({
        id: string(),
        title: string(),
        persons: array(sPersonLite()),
        additional_count: integer(),
    });
}

export type OrganizationChartEdge = {
    from: string;
    to: string;
};

function sOrganizationChartEdge(): Struct<OrganizationChartEdge> {
    return type({
        from: string(),
        to: string(),
    });
}

export type PersonLite = {
    id: string;
    passport: string;
    name: string;
    primary_email: string;
};

function sPersonLite(): Struct<PersonLite> {
    return type({
        id: string(),
        passport: string(),
        name: string(),
        primary_email: string(),
    });
}

export type Position = {
    id: string;
    title: string;
    department: string;
    is_root: boolean;
    organization: string;
    tenant: string;
    parent: string | null;
    people: string[];
};

export function sPosition(): Describe<Position> {
    return type({
        id: string(),
        title: string(),
        department: string(),
        is_root: boolean(),
        organization: string(),
        tenant: string(),
        parent: nullable(string()),
        people: array(string()),
    });
}
