import { createApiMutation, queryClient } from "../../../utils";

export function createWorkflowMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createWorkflow"],
        mutationFn: api.workflow.createWorkflow,
        // onMutate: async (name: string) => {
        //     await queryClient.cancelQueries({ queryKey: ["workflowList"]});
        //     const prevWorkflowList = queryClient.getQueryData<Workflow[]>(["workflowList"]);
        //     queryClient.setQueryData(["workflowList"], (old: Workflow[]) => [...old, {
        //         id: Math.random().toString(),
        //         isActive: false,
        //         name,
        //         bpmn: null,
        //     }]);
        //     return { prevWorkflowList };
        // },
        // onError: (_err, _id, context) => {
        //     queryClient.setQueryData(["workflowList"], context!.prevWorkflowList);
        // },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["workflowList"] });
        },
    }));
}
