import { defensive, ServiceQuery } from "../../../utils";
import { WorkflowExecution } from "../interface";
import { string, Struct, type } from "superstruct";

export interface WorkflowTypeEndpoints {
    myWorkflowTypes: ServiceQuery<[], WorkflowType[]>;
    allWorkflowTypes: ServiceQuery<[], WorkflowType[]>;

    createWorkflowType(body: CreateWorkflowType): Promise<WorkflowType>;
    retrieveWorkflowType: ServiceQuery<[workflowTypeId: string], WorkflowType>;
    updateWorkflowType(body: UpdateWorkflowType): Promise<void>;
    destroyWorkflowType(workflowTypeId: string): Promise<void>;

    myExecutions: ServiceQuery<[workflowTypeId: string], WorkflowExecution[]>;
}

export type WorkflowType = {
    id: string;
    name: string;
    color: string;
    icon: string;
    admin_audience: string;
    read_audience: string;
};

export function sWorkflowType(): Struct<WorkflowType> {
    return type({
        id: string(),
        name: string(),
        color: defensive(string(), ""),
        icon: defensive(string(), ""),
        admin_audience: string(),
        read_audience: string(),
    });
}

export function deserializeWorkflowType(json: WorkflowType): WorkflowType {
    return json;
}

export type CreateWorkflowType = Omit<WorkflowType, "id">;
export type UpdateWorkflowType = Omit<WorkflowType, "admin_audience" | "read_audience">;
