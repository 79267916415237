import { ColumnDefinition, Table } from "../ui/Table";
import { P } from "../../utils/typography";
import { formatDateWithHour } from "../../utils/formatDate";

type History = {
    date: string;
    action: string;
    user: string;
    role: string;
};

const columns: ColumnDefinition<History>[] = [
    {
        title: "Fecha",
        accessor: "date",
        cell: row => {
            return <P>{formatDateWithHour(row.date)}</P>;
        },
    },
    {
        title: "Acción",
        accessor: "action",
    },
    {
        title: "Usuario",
        accessor: "user",
    },
    {
        title: "Rol",
        accessor: "role",
    },
];

const history: History[] = [
    {
        action: "Inicio de sesión",
        date: "2021-09-01, 12:43",
        role: "Gerente",
        user: "Juan Perez",
    },
    {
        action: "Cambio de organización",
        date: "2021-09-02, 09:15",
        role: "Supervisor",
        user: "Maria Rodriguez",
    },
    {
        action: "Inicio de sesión",
        date: "2021-09-03, 14:27",
        role: "Administrador",
        user: "Pedro Gomez",
    },
    {
        action: "Inicio de sesión",
        date: "2021-09-03, 14:27",
        role: "Administrador",
        user: "Pedro Gomez",
    },
];

export function HistoryTable() {
    return (
        <Table
            theadClass={"text-left"}
            tbodyTrClass={"border-b border-light-gray-300 [&>td]:py-3 px-2"}
            columns={columns}
            data={history}
        />
    );
}
