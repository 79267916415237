import { Tab } from "../../ui/Tabs";
import { P } from "../../../utils/typography";
import { Button } from "../../ui/components";
import { For } from "solid-js";
import { useParams } from "@solidjs/router";

export default function NewActivityNotificationsTab() {
    const params = useParams<{ id?: string }>();

    return (
        <Tab title={"Notificaciones"} disabled={!params.id}>
            <div class={"pt-12"}>
                <div class={"space-y-3"}>
                    <NewActivityNotificationItem />
                </div>
            </div>
        </Tab>
    );
}

export function NewActivityNotificationItem(props: { nonEditable?: boolean }) {
    const notificationTypes = ["Notificación push", "Email"];
    return (
        <div class={"flex w-full justify-between rounded-md border border-light-gray-300 p-3"}>
            <div class={"flex gap-x-2"}>
                <div>
                    <div
                        class={
                            "flex h-10 w-10 items-center justify-center rounded-full bg-light-gray-200"
                        }
                    >
                        <i class="far fa-comment text-xl text-light-gray-900"></i>
                    </div>
                </div>
                <div>
                    <P class={"font-semibold"}>Ingrese su nombre</P>
                    <div class={"flex flex-wrap gap-2"}>
                        <For each={notificationTypes}>
                            {type => {
                                return (
                                    <P
                                        class={
                                            "py-o.5 rounded-sm border border-light-gray-300 px-1 text-sm"
                                        }
                                    >
                                        {type}
                                    </P>
                                );
                            }}
                        </For>
                    </div>
                </div>
            </div>
            {!props.nonEditable && (
                <div class={"flex gap-x-2"}>
                    <Button
                        bgStyle={"text-only"}
                        class={"!h-10 !rounded-full bg-light-gray-200 !px-3"}
                    >
                        <i class="fas fa-pen text-light-gray-900"></i>
                    </Button>
                    <Button bgStyle={"text-only"} class={"!h-10 !rounded-full bg-error-100 !px-3"}>
                        <i class="fas fa-trash text-error-600"></i>
                    </Button>
                </div>
            )}
        </div>
    );
}
