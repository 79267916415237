import { Button } from "../../ui/components";
import SidePanel from "../../ui/SidePanel";
import { ModalController } from "../../ui/Modal";
import { ParentProps, Show } from "solid-js";
import Accordion, { AccordionItem } from "../../ui/Accordion";
import { FormWrapper } from "../../forms/FormWrapper";
import SelectField from "../../forms/fields/SelectField";
import TextField from "../../forms/fields/TextField";
import DateField from "../../forms/fields/DateField";
import { createForm } from "../../forms/state";
import SwitchField from "../../forms/fields/SwitchField";
import { Priority } from "../../../api/services/task-manager/interface";
import { useLocale } from "../../i18n/context";

export default function ActivitiesSidePanel(props: { controller: ModalController<unknown, void> }) {
    const [locale] = useLocale();
    const form = createForm();
    return (
        <SidePanel position={"left"} controller={props.controller}>
            <div class={"relative min-w-[400px] space-y-8 pt-12"}>
                <Button
                    onClick={() => props.controller.dismiss()}
                    bgStyle={"text-only"}
                    class={"absolute right-2 top-2 !text-light-gray-400"}
                >
                    <i class="fas fa-times" />
                </Button>
                <div class={"!m-0 flex justify-between px-4"}>
                    <h1 class={"text-display-xs font-bold"}>Filtros</h1>
                    <div class={"flex"}>
                        <Button bgStyle={"text-only"} class={"!px-2"}>
                            Limpiar todo
                        </Button>
                        <Button bgStyle={"text-only"} class={"!px-2"}>
                            Guardar filtro
                        </Button>
                    </div>
                </div>
                <div>
                    <FormWrapper staticForm={form}>
                        <Accordion>
                            <ActivityAccordionItem summary={"ID"}>
                                <TextField name={"id"} optional />
                            </ActivityAccordionItem>
                            <ActivityAccordionItem summary={"Prioridad"}>
                                <SelectField
                                    name={"priority"}
                                    optional
                                    options={Priority.getOptions(locale())}
                                />
                            </ActivityAccordionItem>
                            <ActivityAccordionItem summary={"Estado"}>
                                <SelectField name={"state"} optional options={[]} />
                            </ActivityAccordionItem>
                            <ActivityAccordionItem summary={"Responsable"}>
                                <SelectField name={"owner"} optional options={[]} />
                            </ActivityAccordionItem>
                            <ActivityAccordionItem summary={"Responsable Administrador"}>
                                <SelectField name={"admin_owner"} optional options={[]} />
                            </ActivityAccordionItem>
                            <ActivityAccordionItem summary={"Responsable lector"}>
                                <SelectField name={"read_owner"} optional options={[]} />
                            </ActivityAccordionItem>
                            <ActivityAccordionItem summary={"Fecha objetivo"}>
                                <SelectField
                                    name={"date_type"}
                                    optional
                                    options={[
                                        ["between_dates", "Entre fechas"],
                                        ["exact_date", "Fecha exacta"],
                                    ]}
                                />
                                <div class={"flex gap-x-2"}>
                                    <DateField name={"initial_date"} type={"date"} optional />
                                    <Show when={form.values.date_type === "between_dates"}>
                                        <DateField name={"end_date"} type={"date"} optional />
                                    </Show>
                                </div>
                            </ActivityAccordionItem>
                        </Accordion>
                        <SwitchField
                            name={"recurrent"}
                            label={"Recurrente"}
                            labelClass={"!px-4 py-3"}
                        />
                    </FormWrapper>
                </div>
            </div>
        </SidePanel>
    );
}

function ActivityAccordionItem(props: ParentProps<{ summary: string }>) {
    return (
        <AccordionItem
            summary={props.summary}
            replaceOpenIcon={"fas fa-chevron-up !text-light-gray-400 mr-2"}
            replaceCloseIcon={"fas fa-chevron-down !text-light-gray-400 mr-2"}
            buttonProps={{
                class:
                    "w-full flex flex-row-reverse !justify-between !text-light-gray-900 !py-3 " +
                    "!border-b border-light-gray-200 first:!border-t !h-16 !font-normal",
            }}
        >
            <div class={"space-y-2 px-3"}>{props.children}</div>
        </AccordionItem>
    );
}
