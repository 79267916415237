import { P } from "../../../utils/typography";
import { Modal, ModalController } from "../../ui/Modal";

export function TaskDetailModal(props: { controller: ModalController<void, void> }) {
    return (
        <Modal controller={props.controller}>
            <P class={"text-xl font-medium"}>Instrucciones</P>
            <P class={"text-default"}>
                Asegúrate de tener a mano bolsas de basura resistentes, guantes desechables y, si es
                necesario, una carretilla o un contenedor de ruedas para transportar grandes
                cantidades de basura.
            </P>
            <P class={"font-semibold"}>
                Asegúrate de sacar la basura en el momento correcto para evitar sanciones y para
                mantener el entorno limpio.
            </P>
            <ol>
                <li>Toma una foto de la basura acumulada.</li>
                <li>
                    Coloca las bolsas de basura en el área de recolección designada, ya sea un
                    contenedor común o una zona específica fuera del recinto.
                </li>
                <li>
                    Asegúrate de cerrar bien las bolsas de basura para evitar que se rompan o se
                    derramen. Amarra un nudo en la parte superior para un sellado adicional.
                </li>
                <li>
                    Después de sacar la basura, verifica que el área donde se almacenaba esté limpia
                    y ordenada. Recoge cualquier residuo que pueda haberse caído durante el proceso.
                    Una vez esté todo finalizado, toma una foto.
                </li>
                <li>
                    Si la distancia al área de recolección es considerable, utiliza una carretilla o
                    un contenedor de ruedas para transportar la basura de manera eficiente y sin
                    esfuerzo.
                </li>
                <li>
                    Después de completar el proceso, lávate las manos adecuadamente para mantener
                    una buena higiene personal.
                </li>
                <li>Finalmente, confirma manualmente que finalizaste la actividad.</li>
            </ol>
        </Modal>
    );
}
