import { createApiQuery } from "../../../utils";
import { Accessor } from "solid-js";

export function createMyWorkflowTypesQuery() {
    return createApiQuery(api => ({
        queryKey: ["myWorkflowTypes"],
        queryFn: () => api.workflow.myWorkflowTypes.fetchJson(),
        select: api.workflow.myWorkflowTypes.select,
    }));
}

export function createAllWorkflowTypesQuery() {
    return createApiQuery(api => ({
        queryKey: ["allWorkflowTypes"],
        queryFn: () => api.workflow.allWorkflowTypes.fetchJson(),
        select: api.workflow.allWorkflowTypes.select,
    }));
}

export function createRetrieveWorkflowTypeQuery(id: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["workflowType", id()],
        queryFn: () => api.workflow.retrieveWorkflowType.fetchJson(id()),
        select: api.workflow.retrieveWorkflowType.select,
    }));
}

export function createMyExecutionsQuery(workflowTypeId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["myExecutions", workflowTypeId()],
        queryFn: () => api.workflow.myExecutions.fetchJson(workflowTypeId()),
        select: api.workflow.myExecutions.select,
    }));
}
