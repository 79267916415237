import { Tab } from "../../ui/Tabs";
import { P } from "../../../utils/typography";
import { Button } from "../../ui/components";
import { useParams } from "@solidjs/router";
import { createActivityTasksQuery } from "../../../api/services/task-manager/queries";
import { For, Show, createSignal } from "solid-js";
import { createActivityTaskMutation } from "../../../api/services/task-manager/mutations";
import SelectField from "../../forms/fields/SelectField";

export default function NewActivityTasksTab() {
    const params = useParams<{ id?: string }>();
    const activityTasksQuery = createActivityTasksQuery(() => params.id);
    return (
        <Tab title={"Tareas"} disabled={!params.id}>
            <div class={"pt-12"}>
                <Show when={activityTasksQuery.data} fallback={<NoTaskSection />}>
                    {data => {
                        return (
                            <div class={"mt-10 space-y-3"}>
                                <For each={data()} fallback={<NoTaskSection />}>
                                    {task => {
                                        return (
                                            <>
                                                {JSON.stringify(task, null, 4)}
                                                <NewActivityTaskItem />
                                            </>
                                        );
                                    }}
                                </For>
                            </div>
                        );
                    }}
                </Show>
            </div>
        </Tab>
    );
}

// type AddActivityTaskFormValues = {
//     content_type: "form" | "checklist"
// }
function NoTaskSection() {
    const params = useParams<{ id: string }>();
    const id = () => params.id;
    const taskMutation = createActivityTaskMutation();
    const [addingTask, setAddingTask] = createSignal<boolean>(false);
    const addTask = () => {
        setAddingTask(true);
    };

    const submitTask = () => {
        taskMutation.mutate({
            activity: id(),
            content_type: "text",
            object_id: "1",
        });
    };
    return (
        <Show
            when={!addingTask()}
            fallback={
                <div>
                    <P>Selecciona el modelo</P>
                    <SelectField
                        name={"content_type"}
                        options={[
                            ["form", "Formulario"],
                            ["checklist", "Checklist"],
                        ]}
                    />
                    <Button onClick={submitTask}>fake add</Button>
                </div>
            }
        >
            <div class={"space-y-4 rounded-3 border border-light-gray-300 p-4"}>
                <div class={"inline-block rounded-full border-2 border-error-600/10 p-0.5"}>
                    <div class={"flex rounded-full border-2 border-error-600/30 p-0.5"}>
                        <i class="fas fa-exclamation-circle text-xl text-error-600" />
                    </div>
                </div>
                <div>
                    <P class={"font-semibold"}>No existen tareas en esta actividad</P>
                    <P>La actividad no tiene tareas asociadas, por lo que será sólo informativa.</P>

                    <Button
                        type="button"
                        bgStyle={"text-only"}
                        class={"ml-auto mt-3"}
                        onClick={addTask}
                    >
                        Agregar tarea
                    </Button>
                </div>
            </div>
        </Show>
    );
}

export function NewActivityTaskItem(props: { nonEditable?: boolean }) {
    return (
        <div class={"flex w-full justify-between rounded-md border border-light-gray-300 p-3"}>
            <div class={"flex gap-x-2"}>
                <div>
                    <div
                        class={
                            "flex h-10 w-10 items-center justify-center rounded-full bg-light-gray-200"
                        }
                    >
                        <i class="far fa-comment text-xl text-light-gray-900"></i>
                    </div>
                </div>
                <div>
                    <P class={"font-semibold"}>Ingrese su nombre</P>
                    <P>Respuesta de texto libre</P>
                </div>
            </div>
            {!props.nonEditable && (
                <div class={"flex gap-x-2"}>
                    <Button
                        bgStyle={"text-only"}
                        class={"!h-10 !rounded-full bg-light-gray-200 !px-3"}
                    >
                        <i class="fas fa-pen text-light-gray-900"></i>
                    </Button>
                    <Button bgStyle={"text-only"} class={"!h-10 !rounded-full bg-error-100 !px-3"}>
                        <i class="fas fa-trash text-error-600"></i>
                    </Button>
                </div>
            )}
        </div>
    );
}
